import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../../../util';
import { cssScope } from './css_scope';
import { DbTableTypes } from '../../../../db_types';
import { Link } from '../../../link';
import { routeToUrl } from '../../../../route_util';
import { AdminBreadcrumb } from '../../../../admin_breadcrumb_types';
import { pushBreadcrumb } from '../../../../admin_util';
import { AdminHeader } from '../../../admin_header/admin_header';
import * as controller from './controller';

type Props = {
    tableName: keyof DbTableTypes;
    hiddenFilterFieldId?: string;
    hiddenFilterValue?: number;
    breadcrumbs?: AdminBreadcrumb[];
};

const c = scopedClasses(cssScope);

export const Listing = mobxReactLite.observer(function Listing(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const scrollRef = React.useRef(document.createElement('div'));

    const scrollToTop = React.useCallback(
        () => {
            scrollRef.current.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        },
        [],
    );

    React.useEffect(
        () => {
            const unsubscribe = controller.afterPagination.subscribe(scrollToTop);
            return () => {
                unsubscribe();
            };
        },
        [],
    );

    if (!(controller.state.staticLoaded && controller.state.dynamicLoaded)) {
        return null;
    }

    const tableSettings = controller.getTableSettings();
    const rows = controller.getSortedRows();
    const listingFilterFieldIds = controller.getListingFilterFieldIds();
    const listingFieldIds = controller.getListingFieldIds();

    const detailBreadcrumbs = pushBreadcrumb(props.breadcrumbs, {
        name: tableSettings.namePlural,
        route: {
            id: '/admin/listing',
            params: {
                tableName: props.tableName,
                hiddenFilterFieldId: props.hiddenFilterFieldId,
                hiddenFilterValue: props.hiddenFilterValue,
            },
        },
    });

    return h('div', { className: c('root') },
        h(AdminHeader, {
            title: tableSettings.namePlural,
            breadcrumbs: props.breadcrumbs,
        }),

        (listingFilterFieldIds.length > 0 ?
            h('div', { className: c('filters') },
                listingFilterFieldIds.map((fieldId) => {
                    return h('div', { className: c('filter'), key: fieldId },
                        controller.getFilterElement(fieldId),
                    );
                }),
            ) : null
        ),

        h('div', { className: c('table') },
            h('div', { className: c('table-head') },
                listingFieldIds.map((fieldId) => {
                    const fieldConfig = tableSettings.fields[fieldId];
                    return h('div', { className: c('table-head-cell'), key: fieldId },
                        fieldConfig.name,
                    );
                }),
            ),
            h('div', { className: c('table-rows') },
                rows.map((row) => h(Link,
                    {
                        className: c('table-row'),
                        url: routeToUrl({
                            id: '/admin/detail',
                            params: {
                                tableName: props.tableName,
                                rowId: row.id,
                                hiddenFilterFieldId: props.hiddenFilterFieldId,
                                hiddenFilterValue: props.hiddenFilterValue,
                                breadcrumbs: detailBreadcrumbs,
                            },
                        }),
                        key: row.id,
                    },
                    listingFieldIds.map((fieldId) => h('div', { className: c('table-row-cell'), key: fieldId },
                        controller.getFieldElement(row, fieldId),
                    )),
                )),
            ),
        ),
    );
});
