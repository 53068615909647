import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { formatFloat, h, scopedClasses } from '../../../../util';
import { cssScope } from './css_scope';
import { DbAdapter, DbTables } from '../../../../db_types';
import { QualityAssuranceTable } from '../quality_assurance_table/quality_assurance_table';
import { getZoomXtenderWorkingMagnification, getZoomXtenderWorkingMagnificationOld, getZoomXtenderWorkingNumericalAperture, getZoomXtenderWorkingNumericalApertureOld } from '../../../../wizard_util';
import { FieldLabel } from '../../../field_label/field_label';
import { createTwoWayBinding } from '../../../two_way_binding';
import { Select } from '../../../select/select';
import { NumberInput } from '../../../number_input';
import { sortById } from '../../../../db_util';

type Props = {
    tables: DbTables;
};

const c = scopedClasses(cssScope);

export const ZoomXtenderFit = mobxReactLite.observer(function ZoomXtenderFit(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const state = mobxReactLite.useLocalObservable(() => {
        return {
            zoomXtenderId: 1,
            coreZoom: 0,
            workingDistance: 0,
        };
    });

    const sortedZoomXtenders = Object.values(props.tables.zoomXtender).sort((a, b) => a.id - b.id);
    const zoomXtenderOptions = new Map<number, string>();
    for (const zoomXtender of sortedZoomXtenders) {
        zoomXtenderOptions.set(zoomXtender.id, zoomXtender.coreName + ': ' + zoomXtender.name);
    }

    return h('div', { className: c('root') },
        h('div', { className: c('form-fields') },
            h('div', { className: c('form-field') },
                h(FieldLabel, { label: 'Zoom Xtender' }),
                createTwoWayBinding({
                    observable: state,
                    property: 'zoomXtenderId',
                    childType: Select,
                    childProps: {
                        options: zoomXtenderOptions,
                    },
                }),
            ),

            h('div', { className: c('form-field') },
                h(FieldLabel, { label: 'Core Mag' }),
                createTwoWayBinding({
                    observable: state,
                    property: 'coreZoom',
                    childType: NumberInput,
                    childProps: {
                        fractionDigits: 2,
                        nonNegative: true,
                        emptyZero: true,
                    },
                }),
            ),

            h('div', { className: c('form-field') },
                h(FieldLabel, { label: 'Working Distance' }),
                createTwoWayBinding({
                    observable: state,
                    property: 'workingDistance',
                    childType: NumberInput,
                    childProps: {
                        fractionDigits: 2,
                        nonNegative: true,
                        emptyZero: true,
                    },
                }),
            ),
        ),

        (state.coreZoom > 0 && state.workingDistance > 0 ?
            (() => {
                const zoomXtender = props.tables.zoomXtender[state.zoomXtenderId];

                const adapter: DbAdapter = {
                    id: 3,
                    productFamilyId: 1,
                    name: '1X Adapter',
                    magnification: 1,
                    warnSensorDiagonalStandard: 0,
                    warnSensorDiagonalObjective: 0,
                    maxSensorDiagonalStandard: 16,
                    maxSensorDiagonalObjective: 16,
                    wavelengthVisible: true,
                    wavelengthNearInfrared: true,
                    wavelengthShortWaveInfrared: false,
                };

                const naCoefficients = [];
                for (const coefficient of sortById(Object.values(props.tables.zoomXtenderNaCoefficient))) {
                    if (coefficient.zoomXtenderId === zoomXtender.id) {
                        naCoefficients.push(coefficient.coefficient);
                    }
                }

                const magCoefficients = [];
                for (const coefficient of sortById(Object.values(props.tables.zoomXtenderMagnificationCoefficient))) {
                    if (coefficient.zoomXtenderId === zoomXtender.id) {
                        magCoefficients.push(coefficient.coefficient);
                    }
                }

                return h('div', { className: c('table') },
                    h(QualityAssuranceTable, {
                        head: [
                            'Type',
                            'Adapter',
                            'New Value',
                            'Old Value',
                        ],
                        rows: [
                            [
                                'NA',
                                adapter.name,
                                formatFloat(getZoomXtenderWorkingNumericalAperture(zoomXtender, state.coreZoom, state.workingDistance)),
                                formatFloat(getZoomXtenderWorkingNumericalApertureOld(naCoefficients, state.workingDistance, state.coreZoom)),
                            ],
                            [
                                'Mag',
                                adapter.name,
                                formatFloat(getZoomXtenderWorkingMagnification(zoomXtender, adapter, state.coreZoom, state.workingDistance)),
                                formatFloat(getZoomXtenderWorkingMagnificationOld(zoomXtender, adapter, magCoefficients, state.workingDistance, state.coreZoom)),
                            ],
                        ],
                    }),
                );
            })() : null
        ),
    );
});
