import * as mobx from 'mobx';
import { AdminSettings } from './admin_settings_types';
import { DbUser } from './db_types';

export type Store = {
    storageUrlPrefix: string;
    imageLogo: string;
    imageLogoBlack: string;
    imageNavitarLogo: string;
    imageLensDiagram: string;
    adminSettings: AdminSettings;
    user: DbUser | null;
    url: string;
    loadingIndicatorCount: number;
    snackBarMessages: {
        id: string;
        type: 'success' | 'error';
        content: string;
    }[];
    dealerId: string;
};

export const store: Store = mobx.observable({
    storageUrlPrefix: '',
    imageLogo: '',
    imageLogoBlack: '',
    imageNavitarLogo: '',
    imageLensDiagram: '',
    adminSettings: {} as AdminSettings,
    user: null,
    url: '',
    loadingIndicatorCount: 0,
    snackBarMessages: [],
    dealerId: '',
});

