import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../util';
import { cssScope } from './css_scope';

type Props = {
    content: string;
    icon: React.ReactElement;
};

const c = scopedClasses(cssScope);

export const Tooltip = mobxReactLite.observer(function Tooltip(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    return h('div', { className: c('root') },
        h('div', { className: c('icon') },
            props.icon,
        ),
        h('div', { className: c('content') },
            h('div', { className: c('content-inner') },
                props.content,
            ),
        ),
    );
});
