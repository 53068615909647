import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { assertNotNull, h, scopedClasses } from '../../../../util';
import { cssScope } from './css_scope';
import { wizardStore } from '../../../../wizard_store';
import { getMountOptions, solutionIsZoom } from '../../../../wizard_util';
import { createTwoWayBinding } from '../../../two_way_binding';
import { Select } from '../../../select/select';
import { Checkbox } from '../../../checkbox/checkbox';
import { FieldLabel } from '../../../field_label/field_label';
import { ProceedButton } from './proceed_button/proceed_button';
import { SolutionDetails } from './solution_details/solution_details';
import { WizardTitleBar } from '../../../wizard_title_bar/wizard_title_bar';
import { LensIcon } from '../../../icons/lens';
import { navigateTo } from '../../../../wizard_navigator';

type Props = {};

const c = scopedClasses(cssScope);

export const LensDetail = mobxReactLite.observer(function LensDetail(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const onBackClick = React.useCallback(
        () => {
            history.back();
        },
        [],
    );

    const onFinalizeClick = React.useCallback(
        () => {
            navigateTo('contact');
        },
        [],
    );

    const solution = assertNotNull(wizardStore.selectedLensSolution);

    return h('div', { className: c('root') },
        h(WizardTitleBar, {
            backText: 'Adjust Lens Build',
            backOnClick: onBackClick,
            titleText: 'Lens Selector Guide',
            titleIcon: h(LensIcon, { size: 38 }),
            bottomBorder: true,
        }),

        h(SolutionDetails, {
            solution: solution,
        }),

        h('div', { className: c('form-and-proceed') },
            h('div', { className: c('form') },
                h('div', { className: c('form-field') },
                    h(FieldLabel, { label: 'Camera Mount (optional)', bold: true }),
                    createTwoWayBinding({
                        observable: wizardStore,
                        property: 'lensMountId',
                        childType: Select,
                        childProps: {
                            options: getMountOptions(''),
                        },
                    }),
                ),

                h('div', { className: c('form-field') },
                    h(FieldLabel, { label: 'Additional Features (optional)', bold: true }),
                    h('div', { className: c('form-checkboxes') },
                        createTwoWayBinding({
                            observable: wizardStore,
                            property: 'lensNeedFeatureCoaxialIllumination',
                            childType: Checkbox,
                            childProps: {
                                label: 'Coaxial Illumination',
                            },
                        }),
                        createTwoWayBinding({
                            observable: wizardStore,
                            property: 'lensNeedFeatureFineFocus',
                            childType: Checkbox,
                            childProps: {
                                label: 'Fine Focus',
                            },
                        }),
                        createTwoWayBinding({
                            observable: wizardStore,
                            property: 'lensNeedFeatureMotorization',
                            childType: Checkbox,
                            childProps: {
                                label: 'Motorization',
                            },
                        }),
                        createTwoWayBinding({
                            observable: wizardStore,
                            property: 'lensNeedFeatureApertureControl',
                            childType: Checkbox,
                            childProps: {
                                label: 'Aperture Control',
                            },
                        }),
                        (solutionIsZoom(solution) ?
                            createTwoWayBinding({
                                observable: wizardStore,
                                property: 'lensNeedFeatureDetentedZoom',
                                childType: Checkbox,
                                childProps: {
                                    label: 'Detented Zoom',
                                },
                            }) : null
                        ),
                        createTwoWayBinding({
                            observable: wizardStore,
                            property: 'lensNeedFeatureRingLight',
                            childType: Checkbox,
                            childProps: {
                                label: 'Ring Light',
                            },
                        }),
                    ),
                ),
            ),

            h('div', { className: c('proceed-buttons') },
                h('div', { className: c('proceed-button') },
                    h(ProceedButton, {
                        title: 'Finalize Selection',
                        color: 'blue',
                        onClick: onFinalizeClick,
                    }),
                ),
            ),
        ),
    );
});
