import { DbZoomXtenderMagnificationCoefficient } from '../db_types';

export const dbDataZoomXtenderMagnificationCoefficient: { [index: string]: DbZoomXtenderMagnificationCoefficient } = {
    // Zoom 6000: ZoomXtender (1-50011)
    '1': {
        id: 1,
        zoomXtenderId: 1,
        coefficient: 0.242,
    },
    '2': {
        id: 2,
        zoomXtenderId: 1,
        coefficient: 0.00263,
    },

    // Zoom 6000: ZoomXtender (1-6044)
    '3': {
        id: 3,
        zoomXtenderId: 2,
        coefficient: 0.242,
    },
    '4': {
        id: 4,
        zoomXtenderId: 2,
        coefficient: 0.00263,
    },

    // 12x Zoom: ZoomXtender (3 spacers)
    '5': {
        id: 5,
        zoomXtenderId: 3,
        coefficient: 0.2089,
    },
    '6': {
        id: 6,
        zoomXtenderId: 3,
        coefficient: 0.00263,
    },

    // 12x Zoom: ZoomXtender (2 spacers)
    '7': {
        id: 7,
        zoomXtenderId: 4,
        coefficient: 0.2089,
    },
    '8': {
        id: 8,
        zoomXtenderId: 4,
        coefficient: 0.00263,
    },

    // 12x Zoom: ZoomXtender (1 spacer)
    '9': {
        id: 9,
        zoomXtenderId: 5,
        coefficient: 0.2089,
    },
    '10': {
        id: 10,
        zoomXtenderId: 5,
        coefficient: 0.00263,
    },
};
