import { DbSingleShotObjective } from '../db_types';

export const dbDataSingleShotObjective: { [index: string]: DbSingleShotObjective } = {
    '1': {
        id: 1,
        productFamilyId: 5,
        name: '160mm Objective Lens',
        focalLength: 160,
        stopSemiDiameter: 14.88,
        workingDistance: 50,
        wavelengthVisible: true,
        wavelengthNearInfrared: false,
        wavelengthShortWaveInfrared: false,
    },
    '2': {
        id: 2,
        productFamilyId: 5,
        name: '110mm Objective Lens',
        focalLength: 110,
        stopSemiDiameter: 14.63,
        workingDistance: 39,
        wavelengthVisible: true,
        wavelengthNearInfrared: false,
        wavelengthShortWaveInfrared: false,
    },
    '3': {
        id: 3,
        productFamilyId: 5,
        name: '80mm Objective Lens',
        focalLength: 80,
        stopSemiDiameter: 14.72,
        workingDistance: 25,
        wavelengthVisible: true,
        wavelengthNearInfrared: false,
        wavelengthShortWaveInfrared: false,
    },
    '4': {
        id: 4,
        productFamilyId: 5,
        name: '53mm Objective Lens',
        focalLength: 53,
        stopSemiDiameter: 14.363,
        workingDistance: 12,
        wavelengthVisible: true,
        wavelengthNearInfrared: false,
        wavelengthShortWaveInfrared: false,
    },
};
