import * as mobxReactLite from 'mobx-react-lite';
import { h } from '../util';
import { TypedStringInput } from './typed_string_input/typed_string_input';

type Props = {
    value: string;
    onChange: (value: string) => void;
};

function emailToString(email: string): string {
    return email;
}

function stringToEmail(s: string): string {
    return s.toLowerCase().trim();
}

function valueEqual(value1: string, value2: string): boolean {
    return value1 === value2;
}

export const EmailInput = mobxReactLite.observer(function EmailInput(props: Props) {
    return h(TypedStringInput, {
        value: props.value,
        valueToString: emailToString,
        stringToValue: stringToEmail,
        valueEqual: valueEqual,
        onChange: props.onChange,
        autoCapitalize: 'none',
    });
});
