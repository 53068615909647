import * as React from 'react';
import * as mobx from 'mobx';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../util';
import { cssScope } from './css_scope';
import { store } from '../../store';
import { resetWizardStore } from '../../wizard_store';
import { MenuIcon } from '../icons/menu';
import { CloseIcon } from '../icons/close';
import { navigateTo } from '../../wizard_navigator';

type Props = {};

const c = scopedClasses(cssScope);

export const WizardHeader = mobxReactLite.observer(function WizardHeader(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const state = mobxReactLite.useLocalObservable(() => {
        return {
            menuOpen: false,
        };
    });

    const onOpticalWizardLogoClick = React.useCallback(
        (e) => {
            e.preventDefault();
            mobx.runInAction(() => {
                state.menuOpen = false;
                resetWizardStore();
                navigateTo('home');
            });
        },
        [],
    );

    const onMenuButtonClick = React.useCallback(
        () => {
            mobx.runInAction(() => {
                state.menuOpen = !state.menuOpen;
            });
        },
        [],
    );

    return h('div', { className: c('root', 'menu-open-' + state.menuOpen, `dealer-${store.dealerId !== ''}`) },
        h('div', { className: c('navitar-logo') },
            h('a',
                {
                    className: c('navitar-logo-link'),
                    href: 'https://navitar.com',
                },
                h('img', { src: store.imageNavitarLogo, width: '126', height: '38' }),
            ),
        ),

        h('div', { className: c('optical-wizard-logo') },
            h('div', { className: c('optical-wizard-logo-link'), onClick: onOpticalWizardLogoClick },
                h('img', { src: store.imageLogo, width: '196', height: '30' }),
            ),
        ),

        h('div', { className: c('menu-button'), onClick: onMenuButtonClick },
            state.menuOpen ? h(CloseIcon, { size: 24 }) : h(MenuIcon, { size: 24 }),
        ),

        h('div', { className: c('links') },
            h('div', { className: c('link', 'home-link'), onClick: onOpticalWizardLogoClick },
                'Optical Wizard Home',
            ),

            h('a', { className: c('link'), href: 'https://go.navitar.com/l/540462/2022-03-02/3zvmlxt', target: '_blank' },
                'Have a Question?',
            ),
        ),
    );
});
