import { DbTableTypes } from './db_types';
import { AdminBreadcrumb } from './admin_breadcrumb_types';

export type Route = {
    id: '/';
} | {
    id: '/quality-assurance/6fe89101d6be452ba9acb05890f869bd';
} | {
    id: '/content-import/b06e6f0f421f44379cae18ac07380860';
} | {
    id: '/404';
} | {
    id: '/login';
} | {
    id: '/send-password-reset';
} | {
    id: '/reset-password';
    params: {
        token: string;
    };
} | {
    id: '/admin/listing';
    params: {
        tableName: keyof DbTableTypes;
        hiddenFilterFieldId?: string;
        hiddenFilterValue?: number;
        breadcrumbs?: AdminBreadcrumb[];
    };
} | {
    id: '/admin/detail';
    params: {
        tableName: keyof DbTableTypes;
        rowId: number | null;
        hiddenFilterFieldId?: string;
        hiddenFilterValue?: number;
        breadcrumbs?: AdminBreadcrumb[];
    };
} | {
    id: '/admin/documentation';
} | {
    id: '/admin/change-password';
};

function validAdminBreadcrumb(breadcrumb: AdminBreadcrumb): boolean {
    if (!(
        typeof breadcrumb.name === 'string'
        && typeof breadcrumb.route === 'object'
        && typeof breadcrumb.route.params === 'object'
    )) {
        return false;
    }

    const route = breadcrumb.route;

    if (route.id === '/admin/listing') {
        return (
            typeof route.params.tableName === 'string'
            && (route.params.hiddenFilterFieldId === undefined || typeof route.params.hiddenFilterFieldId === 'string')
            && (route.params.hiddenFilterValue === undefined || typeof route.params.hiddenFilterValue === 'number')
        );
    } else if (breadcrumb.route.id === '/admin/detail') {
        return (
            typeof route.params.tableName === 'string'
            && (route.params.rowId === null || typeof route.params.rowId === 'number')
            && (route.params.hiddenFilterFieldId === undefined || typeof route.params.hiddenFilterFieldId === 'string')
            && (route.params.hiddenFilterValue === undefined || typeof route.params.hiddenFilterValue === 'number')
        );
    } else {
        return false;
    }
}

function validAdminBreadcrumbs(breadcrumbs: AdminBreadcrumb[] | undefined): boolean {
    if (breadcrumbs === undefined) return true;
    if (!Array.isArray(breadcrumbs)) return false;
    return breadcrumbs.every(validAdminBreadcrumb);
}

export const specs: { [A in Route['id']]: ((params: any) => boolean) | null } = {
    '/': null,
    '/quality-assurance/6fe89101d6be452ba9acb05890f869bd': null,
    '/content-import/b06e6f0f421f44379cae18ac07380860': null,
    '/404': null,
    '/login': null,
    '/send-password-reset': null,
    '/reset-password': (params) => typeof params.token === 'string',
    '/admin/listing': (params) => (
        typeof params.tableName === 'string'
        && (params.hiddenFilterFieldId === undefined || typeof params.hiddenFilterFieldId === 'string')
        && (params.hiddenFilterValue === undefined || typeof params.hiddenFilterValue === 'number')
        && validAdminBreadcrumbs(params.breadcrumbs)
    ),
    '/admin/detail': (params) => (
        typeof params.tableName === 'string'
        && (params.rowId === null || typeof params.rowId === 'number')
        && (params.hiddenFilterFieldId === undefined || typeof params.hiddenFilterFieldId === 'string')
        && (params.hiddenFilterValue === undefined || typeof params.hiddenFilterValue === 'number')
        && validAdminBreadcrumbs(params.breadcrumbs)
    ),
    '/admin/documentation': null,
    '/admin/change-password': null,
};

export const publicRouteIds: { [A in Route['id']]?: true } = {
    '/': true,
    '/quality-assurance/6fe89101d6be452ba9acb05890f869bd': true,
    '/404': true,
    '/login': true,
    '/send-password-reset': true,
    '/reset-password': true,
};
