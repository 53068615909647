import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { defaultValueIfUndefined, h, scopedClasses } from '../../../../../util';
import { cssScope } from './css_scope';
import { getSolutionPartNames } from '../../../../../wizard_util';
import { wavelengthOptions } from '../../../../../wizard_options';
import { wizardStore } from '../../../../../wizard_store';

type Props = {};

const c = scopedClasses(cssScope);

export const SolutionDetails = mobxReactLite.observer(function SolutionDetails(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    return h('div', { className: c('root') },
        (wizardStore.selectedCameraId !== null ?
            h('div', { className: c('box') },
                h('div', { className: c('title') },
                    'Camera Selection',
                ),

                h('div', { className: c('fields') },
                    h('div', { className: c('field') },
                        h('div', { className: c('field-label') },
                            'Model Name',
                        ),
                        h('div', { className: c('field-value') },
                            wizardStore.tables.camera[wizardStore.selectedCameraId].partNumber,
                        ),
                    ),
                ),
            ) : null
        ),

        (wizardStore.selectedLensSolution !== null ?
            h('div', { className: c('box') },
                h('div', { className: c('title') },
                    'Lens Selection',
                ),

                h('div', { className: c('fields') },
                    h('div', { className: c('field') },
                        h('div', { className: c('field-label') },
                            'Product Family',
                        ),
                        h('div', { className: c('field-value') },
                            wizardStore.selectedLensSolution.productFamily.name,
                        ),
                    ),

                    h('div', { className: c('field') },
                        h('div', { className: c('field-label') },
                            'Parts',
                        ),
                        h('div', { className: c('field-value') },
                            getSolutionPartNames(wizardStore.selectedLensSolution),
                        ),
                    ),

                    h('div', { className: c('field') },
                        h('div', { className: c('field-label') },
                            'Wavelength',
                        ),
                        h('div', { className: c('field-value') },
                            defaultValueIfUndefined(wavelengthOptions.get(wizardStore.lensWavelength), ''),
                        ),
                    ),
                ),
            ) : null
        ),
    );
});
