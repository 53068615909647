import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../util';
import { cssScope } from './css_scope';

type Props = {
    label: string;
    bold?: boolean;
    required?: boolean;
};

const c = scopedClasses(cssScope);

export const FieldLabel = mobxReactLite.observer(function FieldLabel(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    return h('div',
        {
            className: c(
                'root',
                'bold-' + (props.bold === true),
                'required-' + (props.required === true),
            ),
        },
        props.label,
    );
});
