import { DbZoomXtenderNaCoefficient } from '../db_types';

export const dbDataZoomXtenderNaCoefficient: { [index: string]: DbZoomXtenderNaCoefficient } = {
    // Zoom 6000: ZoomXtender (1-50011)
    '5': {
        id: 5,
        zoomXtenderId: 1,
        coefficient: -0.564,
    },
    '6': {
        id: 6,
        zoomXtenderId: 1,
        coefficient: 4.89,
    },
    '7': {
        id: 7,
        zoomXtenderId: 1,
        coefficient: -1.71,
    },
    '8': {
        id: 8,
        zoomXtenderId: 1,
        coefficient: 0.325,
    },
    '9': {
        id: 9,
        zoomXtenderId: 1,
        coefficient: -0.0244,
    },

    // Zoom 6000: ZoomXtender (1-6044)
    '10': {
        id: 10,
        zoomXtenderId: 2,
        coefficient: -0.564,
    },
    '11': {
        id: 11,
        zoomXtenderId: 2,
        coefficient: 4.89,
    },
    '12': {
        id: 12,
        zoomXtenderId: 2,
        coefficient: -1.71,
    },
    '13': {
        id: 13,
        zoomXtenderId: 2,
        coefficient: 0.325,
    },
    '14': {
        id: 14,
        zoomXtenderId: 2,
        coefficient: -0.0244,
    },

    // 12x Zoom: ZoomXtender (3 spacers)
    '15': {
        id: 15,
        zoomXtenderId: 3,
        coefficient: 0.08299,
    },
    '16': {
        id: 16,
        zoomXtenderId: 3,
        coefficient: 3.037348,
    },
    '17': {
        id: 17,
        zoomXtenderId: 3,
        coefficient: -0.453478,
    },
    '18': {
        id: 18,
        zoomXtenderId: 3,
        coefficient: 0.028208,
    },

    // 12x Zoom: ZoomXtender (2 spacers)
    '19': {
        id: 19,
        zoomXtenderId: 4,
        coefficient: 0.08299,
    },
    '20': {
        id: 20,
        zoomXtenderId: 4,
        coefficient: 3.037348,
    },
    '21': {
        id: 21,
        zoomXtenderId: 4,
        coefficient: -0.453478,
    },
    '22': {
        id: 22,
        zoomXtenderId: 4,
        coefficient: 0.028208,
    },

    // 12x Zoom: ZoomXtender (1 spacer)
    '23': {
        id: 23,
        zoomXtenderId: 5,
        coefficient: 0.08299,
    },
    '24': {
        id: 24,
        zoomXtenderId: 5,
        coefficient: 3.037348,
    },
    '25': {
        id: 25,
        zoomXtenderId: 5,
        coefficient: -0.453478,
    },
    '26': {
        id: 26,
        zoomXtenderId: 5,
        coefficient: 0.028208,
    },
};
