import * as React from 'react';
import * as mobx from 'mobx';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../../util';
import { cssScope } from './css_scope';
import { routeFromUrl, routeToUrl } from '../../../route_util';
import { store } from '../../../store';
import { LoadingSpinner } from '../../loading_spinner/loading_spinner';
import { Link } from '../../link';
import { AccountCircleIcon } from '../../icons/account_circle';
import { AccountMenu } from './account_menu';
import { DbTableTypes } from '../../../db_types';
import { Listing } from './listing/listing';
import { BottomBar as ListingBottomBar } from './listing/bottom_bar';
import { Detail } from './detail/detail';
import { BottomBar as DetailBottomBar } from './detail/bottom_bar';
import { Documentation } from './documentation/documentation';
import { ChangePassword } from './change_password/change_password';
import { BottomBar as ChangePasswordBottomBar } from './change_password/bottom_bar';
import { MenuBookIcon } from '../../icons/menu_book';

type Props = {};

const c = scopedClasses(cssScope);

const sidebarLinks: (keyof DbTableTypes)[] = [
    'user',
    'cameraSensorFormat',
    'cameraSensor',
    'camera',
    'mount',
    'cameraMount',
    'commonSensor',
    'productFamily',
    'adapter',
    'standardCore',
    'standardCoreNaCoefficient',
    'standardAttachment',
    'objectiveCore',
    'objectiveCoreStopDiamCoefficient',
    'objectiveAttachment',
    'singleShotCore',
    'singleShotObjective',
    'machineVisionLens',
    'zoomXtender',
    'zoomXtenderMagnificationCoefficient',
    'zoomXtenderNaCoefficient',
];

export const Admin = mobxReactLite.observer(function Admin(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const state = mobxReactLite.useLocalObservable(() => {
        return {
            accountMenuOpen: false,
        };
    });

    const onAccountMenuOpen = React.useCallback(
        () => {
            mobx.runInAction(() => {
                state.accountMenuOpen = true;
            });
        },
        [],
    );

    const onAccountMenuClose = React.useCallback(
        () => {
            mobx.runInAction(() => {
                state.accountMenuOpen = false;
            });
        },
        [],
    );

    const route = routeFromUrl(store.url);

    let child = null;
    let bottomBar = null;
    if (route.id === '/admin/listing') {
        child = h(Listing, { ...route.params });
        bottomBar = h(ListingBottomBar, { ...route.params });
    } else if (route.id === '/admin/detail') {
        child = h(Detail, { ...route.params });
        bottomBar = h(DetailBottomBar, { ...route.params });
    } else if (route.id === '/admin/documentation') {
        child = h(Documentation, {});
    } else if (route.id === '/admin/change-password') {
        child = h(ChangePassword, {});
        bottomBar = h(ChangePasswordBottomBar, {});
    }

    return h('div', { className: c('root') },
        h('div', { className: c('sidebar') },
            h('div', { className: c('sidebar-title') }, 'Admin'),

            (store.loadingIndicatorCount > 0 ?
                h('div', { className: c('sidebar-loading-spinner') },
                    h(LoadingSpinner, { size: 20, color: '#3B3B3B' }),
                ) : null
            ),

            h(Link,
                {
                    className: c('sidebar-documentation', 'active-' + (route.id === '/admin/documentation')),
                    url: routeToUrl({
                        id: '/admin/documentation',
                    }),
                },
                h(MenuBookIcon, { size: 24 }),
            ),

            h('div', { className: c('sidebar-links') },
                sidebarLinks.map((tableName) => {
                    let active = false;
                    if (route.id === '/admin/listing' || route.id === '/admin/detail') {
                        if (route.params.breadcrumbs !== undefined && route.params.breadcrumbs.length > 0) {
                            active = route.params.breadcrumbs[0].route.params.tableName === tableName;
                        } else {
                            active = route.params.tableName === tableName;
                        }
                    }
                    return h(Link,
                        {
                            className: c(
                                'sidebar-link',
                                'active-' + active,
                            ),
                            url: routeToUrl({
                                id: '/admin/listing',
                                params: {
                                    tableName: tableName,
                                },
                            }),
                            key: tableName,
                        },
                        store.adminSettings[tableName].namePlural,
                    );
                }),
                h(Link,
                    {
                        className: c(
                            'sidebar-link',
                            'active-' + (route.id === '/admin/change-password'),
                        ),
                        url: routeToUrl({
                            id: '/admin/change-password',
                        }),
                    },
                    'Change Password',
                ),
            ),
        ),
        h('div', { className: c('content') },
            child,
        ),
        h('div', { className: c('account'), onClick: onAccountMenuOpen },
            h('div', { className: c('account-icon') },
                h(AccountCircleIcon, { size: 24 }),
            ),
            h('div',
                {
                    className: c('account-text'),
                    title: (store.user !== null ? store.user.email : null),
                },
                (store.user !== null ?
                    store.user.email : null
                ),
            ),
        ),
        h('div', { className: c('bottom-bar') },
            bottomBar,
        ),
        (state.accountMenuOpen ?
            h(AccountMenu, { onClose: onAccountMenuClose }) : null
        ),
    );
});
