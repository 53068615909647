import * as mobxReactLite from 'mobx-react-lite';
import { h } from '../../util';

type Props = {
    size: number;
};

export const CameraIcon = mobxReactLite.observer(function CameraIcon(props: Props) {
    return h('svg',
        {
            width: props.size.toString(),
            height: props.size.toString(),
            viewBox: '0 0 80 80',
            xmlns: 'http://www.w3.org/2000/svg',
        },
        h('path', {
            d: 'M10 20C7.34784 20 4.8043 21.0536 2.92893 22.9289C1.05357 24.8043 0 27.3478 0 30L0 60C0 62.6522 1.05357 65.1957 2.92893 67.0711C4.8043 68.9464 7.34784 70 10 70H70C72.6522 70 75.1957 68.9464 77.0711 67.0711C78.9464 65.1957 80 62.6522 80 60V30C80 27.3478 78.9464 24.8043 77.0711 22.9289C75.1957 21.0536 72.6522 20 70 20H64.14C61.4881 19.9994 58.9449 18.9455 57.07 17.07L52.93 12.93C51.0551 11.0545 48.5119 10.0006 45.86 10H34.14C31.4881 10.0006 28.9449 11.0545 27.07 12.93L22.93 17.07C21.0551 18.9455 18.5119 19.9994 15.86 20H10ZM12.5 30C11.837 30 11.2011 29.7366 10.7322 29.2678C10.2634 28.7989 10 28.163 10 27.5C10 26.837 10.2634 26.2011 10.7322 25.7322C11.2011 25.2634 11.837 25 12.5 25C13.163 25 13.7989 25.2634 14.2678 25.7322C14.7366 26.2011 15 26.837 15 27.5C15 28.163 14.7366 28.7989 14.2678 29.2678C13.7989 29.7366 13.163 30 12.5 30ZM57.5 42.5C57.5 47.1413 55.6563 51.5925 52.3744 54.8744C49.0925 58.1563 44.6413 60 40 60C35.3587 60 30.9075 58.1563 27.6256 54.8744C24.3437 51.5925 22.5 47.1413 22.5 42.5C22.5 37.8587 24.3437 33.4075 27.6256 30.1256C30.9075 26.8437 35.3587 25 40 25C44.6413 25 49.0925 26.8437 52.3744 30.1256C55.6563 33.4075 57.5 37.8587 57.5 42.5V42.5Z',
        }),
    );
});
