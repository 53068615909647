import * as React from 'react';
import * as mobx from 'mobx';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../util';
import { cssScope } from './css_scope';
import { ChevronRightIcon } from '../icons/chevron_right';
import { Option } from './option';

type Props = {
    options: Map<any, string>;
    value: any;
    onChange: (value: any) => void;
    groupDividers?: any[],
};

const c = scopedClasses(cssScope);

export const Select = mobxReactLite.observer(function Select(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const rootRef = React.useRef<HTMLElement>(null);

    const state = mobxReactLite.useLocalObservable(() => {
        return {
            open: false,
            search: '',
        };
    });

    const onDocumentClick = React.useCallback(
        (e) => {
            if (state.open && rootRef.current !== null && !rootRef.current.contains(e.target)) {
                mobx.runInAction(() => {
                    state.open = false;
                });
            }
        },
        [],
    );

    const onButtonClick = React.useCallback(
        () => {
            if (!state.open) {
                mobx.runInAction(() => {
                    state.open = true;
                    state.search = '';
                });
            }
        },
        [],
    );

    const onOptionClick = React.useCallback(
        (value: any) => {
            mobx.runInAction(() => {
                state.open = false;
            });

            propsRef.current.onChange(value);
        },
        [],
    );

    React.useEffect(
        () => {
            document.addEventListener('click', onDocumentClick);
            return () => {
                document.removeEventListener('click', onDocumentClick);
            };
        },
        [],
    );

    const onSearchChange = React.useCallback(
        (e) => {
            mobx.runInAction(() => {
                state.search = e.currentTarget.value;
            });
        },
        [],
    );

    const onSearchKeyDown = React.useCallback(
        (e) => {
            if (e.key === 'Escape') {
                mobx.runInAction(() => {
                    state.search = '';
                });
            }
        },
        [],
    );

    let selectedTitle = props.options.get(props.value);
    if (selectedTitle === undefined) {
        selectedTitle = 'Unknown';
        console.error('Select props.value', props.value, 'is not in props.options', props.options);
    }

    let filteredOptions = props.options;
    if (state.search !== '') {
        const tempOptions = new Map<any, string>();

        for (const [k, v] of props.options) {
            if (v.toLowerCase().includes(state.search.toLowerCase())) {
                tempOptions.set(k, v);
            }
        }

        filteredOptions = tempOptions;
    }

    const optionElements = [];
    for (const [k, v] of filteredOptions) {
        optionElements.push(
            h(Option, {
                title: v,
                value: k,
                borderBottom: props.groupDividers !== undefined && props.groupDividers.indexOf(k) >= 0,
                key: k,
                onClick: onOptionClick,
            })
        );
    }

    return h('div', { className: c('root'), ref: rootRef },
        h('div', { className: c('button'), onClick: onButtonClick },
            h('div', { className: c('button-text') }, selectedTitle),
            h('div', { className: c('button-icon') },
                h(ChevronRightIcon, { size: 24 }),
            ),
        ),
        (state.open ?
            h('div', { className: c('menu') },
                h('div', { className: c('search') },
                    h('input', {
                        className: c('search-input'),
                        type: 'text',
                        placeholder: 'Search…',
                        value: state.search,
                        autoFocus: true,
                        onChange: onSearchChange,
                        onKeyDown: onSearchKeyDown,
                    }),
                    h('div', { className: c('search-icon') },
                        h(ChevronRightIcon, { size: 24 }),
                    ),
                ),
                h('div', { className: c('options') },
                    optionElements,
                ),
            ) :
            null
        ),
    );
});
