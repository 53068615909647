import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../util';
import { cssScope } from './css_scope';
import { LoadingSpinner } from '../loading_spinner/loading_spinner';
import { ChevronRightIcon } from '../icons/chevron_right';

type Props = {
    itemsPerPage: number;
    pageId: number;
    itemCount: number;
    previousLoading: boolean;
    nextLoading: boolean;
    onPreviousClick: () => void;
    onNextClick: () => void;
};

const c = scopedClasses(cssScope);

export const AdminPagination = mobxReactLite.observer(function AdminPagination(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    if (props.pageId === 0 && props.itemCount < props.itemsPerPage && !props.previousLoading && !props.nextLoading) {
        return null;
    }

    return h('div', { className: c('root') },
        h('div',
            {
                className: c(
                    'previous',
                    'enabled-' + (props.pageId > 0 || props.previousLoading),
                ),
                onClick: props.onPreviousClick,
            },
            (props.previousLoading ?
                h(LoadingSpinner, { size: 20, color: '#3B3B3B' }) :
                h('div', { className: c('previous-icon') },
                    h(ChevronRightIcon, { size: 24 }),
                )
            ),
        ),
        h('div', { className: c('current') },
            'Page ' + (props.pageId + 1),
        ),
        h('div',
            {
                className: c(
                    'next',
                    'enabled-' + (props.itemCount === props.itemsPerPage || props.nextLoading),
                ),
                onClick: props.onNextClick,
            },
            (props.nextLoading ?
                h(LoadingSpinner, { size: 20, color: '#3B3B3B' }) :
                h('div', { className: c('next-icon') },
                    h(ChevronRightIcon, { size: 24 }),
                )
            ),
        ),
    );
});
