import * as React from 'react';
import * as mobx from 'mobx';
import * as mobxReactLite from 'mobx-react-lite';
import { assertNotNull, h, scopedClasses } from '../../util';
import { cssScope } from './css_scope';
import { ChevronRightIcon } from '../icons/chevron_right';
import { Option } from './option';
import { DbTables, DbTableTypes } from '../../db_types';
import { adminSettingsUniqueName, adminSettingsUniqueNameMultiEllipsis } from '../../admin_util';
import { MultiEllipsis } from '../multi_ellipsis/multi_ellipsis';
import { store } from '../../store';

type Props = {
    tables: DbTables;
    tableName: keyof DbTableTypes;
    emptyValue: number | null,
    emptyTitle: string,
    value: number | null;
    onChange: (value: number | null) => void;
};

const c = scopedClasses(cssScope);
const itemsPerPage = 100;

function shallowArrayEqual(a: any[], b: any[]): boolean {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

function useLazyMemo(f: () => any, deps: any[]): () => any {
    const valueRef = React.useRef();
    const oldDepsRef = React.useRef(deps);
    const newDepsRef = React.useRef(deps);
    newDepsRef.current = deps;

    const getValue = React.useCallback(
        (): any => {
            if (valueRef.current === undefined || !shallowArrayEqual(oldDepsRef.current, newDepsRef.current)) {
                valueRef.current = f();
                oldDepsRef.current = newDepsRef.current;
            }
            return valueRef.current;
        },
        [],
    );

    return getValue;
}

export const AdminForeignKeySelect = mobxReactLite.observer(function AdminForeignKeySelect(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const rootRef = React.useRef<HTMLElement>(null);

    const state = mobxReactLite.useLocalObservable(() => {
        return {
            open: false,
            search: '',
            page: 0,
        };
    });

    const getEntries = useLazyMemo(
        (): [number, string][] => {
            const entries: [number, string][] = [];
            for (const row of Object.values(propsRef.current.tables[propsRef.current.tableName])) {
                entries.push([
                    row.id,
                    adminSettingsUniqueName(store.adminSettings, propsRef.current.tables, propsRef.current.tableName, row.id),
                ]);
            }
            entries.sort((a, b) => a[1].localeCompare(b[1]));
            return entries;
        },
        [props.tables, props.tableName],
    );

    const getFilteredPaginatedEntries = useLazyMemo(
        (): [number, string][] => {
            const entries: [number, string][] = [];
            let skip = state.page * itemsPerPage;
            for (const entry of getEntries()) {
                if (state.search === '' || entry[1].toLowerCase().includes(state.search.toLowerCase())) {
                    if (entries.length === itemsPerPage) {
                        break;
                    } else if (skip > 0) {
                        --skip;
                    } else {
                        entries.push(entry);
                    }
                }
            }
            return entries;
        },
        [state.search, state.page],
    );

    const getOptionElements = React.useCallback(
        (): React.ReactElement[] => {
            const optionElements: React.ReactElement[] = [
                h(Option, {
                    title: propsRef.current.emptyTitle,
                    value: propsRef.current.emptyValue,
                    key: propsRef.current.emptyValue,
                    onClick: onOptionClick,
                }),
            ];

            for (const entry of getFilteredPaginatedEntries()) {
                const multiEllipsisItems = adminSettingsUniqueNameMultiEllipsis(
                    store.adminSettings,
                    propsRef.current.tables,
                    propsRef.current.tableName,
                    entry[0],
                );

                optionElements.push(
                    h(Option, {
                        title: h(MultiEllipsis, { items: multiEllipsisItems }),
                        value: entry[0],
                        key: entry[0],
                        onClick: onOptionClick,
                    })
                );
            }

            return optionElements;
        },
        [],
    );

    const onDocumentClick = React.useCallback(
        (e) => {
            if (state.open && rootRef.current !== null && !rootRef.current.contains(e.target)) {
                mobx.runInAction(() => {
                    state.open = false;
                });
            }
        },
        [],
    );

    const onButtonClick = React.useCallback(
        () => {
            if (!state.open) {
                mobx.runInAction(() => {
                    state.open = true;
                    state.search = '';
                    state.page = 0;
                });
            }
        },
        [],
    );

    const onOptionClick = React.useCallback(
        (value: number | null) => {
            mobx.runInAction(() => {
                state.open = false;
            });

            propsRef.current.onChange(value);
        },
        [],
    );

    const hasPreviousPage = React.useCallback(
        (): boolean => {
            return state.page >= 1;
        },
        [],
    );

    const hasNextPage = React.useCallback(
        (): boolean => {
            const entries = getFilteredPaginatedEntries();
            return entries.length === itemsPerPage;
        },
        [],
    );

    const onPreviousPage = React.useCallback(
        () => {
            if (!hasPreviousPage()) return;

            mobx.runInAction(() => {
                state.page -= 1;
            });
        },
        [],
    );

    const onNextPage = React.useCallback(
        () => {
            if (!hasNextPage()) return;

            mobx.runInAction(() => {
                state.page += 1;
            });
        },
        [],
    );

    React.useEffect(
        () => {
            document.addEventListener('click', onDocumentClick);
            return () => {
                document.removeEventListener('click', onDocumentClick);
            };
        },
        [],
    );

    const onSearchChange = React.useCallback(
        (e) => {
            mobx.runInAction(() => {
                state.search = e.currentTarget.value;
                state.page = 0;
            });
        },
        [],
    );

    const onSearchKeyDown = React.useCallback(
        (e) => {
            if (e.key === 'Escape') {
                mobx.runInAction(() => {
                    state.search = '';
                    state.page = 0;
                });
            }
        },
        [],
    );

    let selectedTitle = '';
    if (props.value === props.emptyValue) {
        selectedTitle = props.emptyTitle;
    } else {
        selectedTitle = adminSettingsUniqueName(store.adminSettings, props.tables, props.tableName, assertNotNull(props.value));
    }

    return h('div', { className: c('root'), ref: rootRef },
        h('div', { className: c('button'), onClick: onButtonClick },
            h('div', { className: c('button-text') }, selectedTitle),
            h('div', { className: c('button-icon') },
                h(ChevronRightIcon, { size: 24 }),
            ),
        ),
        (state.open ?
            h('div', { className: c('menu') },
                h('div', { className: c('search') },
                    h('input', {
                        className: c('search-input'),
                        type: 'text',
                        placeholder: 'Search…',
                        value: state.search,
                        autoFocus: true,
                        onChange: onSearchChange,
                        onKeyDown: onSearchKeyDown,
                    }),
                    h('div', { className: c('search-icon') },
                        h(ChevronRightIcon, { size: 24 }),
                    ),
                ),
                h('div', { className: c('options') },
                    getOptionElements(),
                ),
                (hasPreviousPage() || hasNextPage() ?
                    h('div', { className: c('pagination') },
                        h('div', { className: c('pagination-previous', 'enabled-' + hasPreviousPage()), onClick: onPreviousPage },
                            h(ChevronRightIcon, { size: 24 }),
                        ),
                        h('div', { className: c('pagination-next', 'enabled-' + hasNextPage()), onClick: onNextPage },
                            h(ChevronRightIcon, { size: 24 }),
                        ),
                    ) : null
                ),
            ) :
            null
        ),
    );
});
