import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../../../util';
import { cssScope } from './css_scope';
import { FieldLabel } from '../../../field_label/field_label';
import { TextInput } from '../../../text_input/text_input';
import { createTwoWayBinding } from '../../../two_way_binding';
import { Form } from '../../../form';

type Props = {
    label: string;
    fieldId: string;
    onChange: (fieldId: string, value: string) => void;
};

const c = scopedClasses(cssScope);

export const StringFilter = mobxReactLite.observer(function StringFilter(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const state = mobxReactLite.useLocalObservable(() => {
        return {
            value: '',
        };
    });

    const onSubmit = React.useCallback(
        () => {
            propsRef.current.onChange(propsRef.current.fieldId, state.value);
        },
        [],
    );

    return h('div', { className: c('root') },
        h(FieldLabel, { label: props.label }),
        h(Form, { onSubmit: onSubmit },
            createTwoWayBinding({
                observable: state,
                property: 'value',
                childType: TextInput,
                childProps: {
                    onBlur: onSubmit,
                },
            }),
        ),
    );
});
