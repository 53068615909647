import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { formatFloat, h } from '../../../util';
import { DbTables } from '../../../db_types';
import { QualityAssuranceTable } from './quality_assurance_table/quality_assurance_table';
import { wavelengthsToString } from './quality_assurance_util';
import { negativeOneToInfinity } from '../../../wizard_util';

type Props = {
    tables: DbTables;
};

export const DataMachineVisionLens = mobxReactLite.observer(function DataMachineVisionLens(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const items = Object.values(props.tables.machineVisionLens).sort((a, b) => {
        if (a.productFamilyId < b.productFamilyId) return -1;
        if (a.productFamilyId > b.productFamilyId) return 1;

        return a.focalLength - b.focalLength;
    });

    return h(QualityAssuranceTable, {
        head: [
            'Product Family',
            'Part Number',
            'Megapixels',
            'Focal Length',
            'F-Number Low',
            'F-Number High',
            'Max Sensor Diagonal',
            'Min Working Distance',
            'Pupil Position',
            'Resolvable Frequency Center',
            'Resolvable Frequency Edge',
            'Wavelengths',
        ],
        rows: items.map((item) => ([
            props.tables.productFamily[item.productFamilyId].name,
            item.partNumber,
            formatFloat(item.megapixels),
            formatFloat(item.focalLength),
            formatFloat(item.fNumberLow),
            formatFloat(negativeOneToInfinity(item.fNumberHigh)),
            formatFloat(item.maxSensorDiagonal),
            formatFloat(item.minWorkingDistance),
            formatFloat(item.pupilPosition),
            formatFloat(item.resolvableFrequencyCenter),
            formatFloat(item.resolvableFrequencyEdge),
            wavelengthsToString(item),
        ])),
    });
});
