import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../../../util';
import { cssScope } from './css_scope';
import { store } from '../../../../store';
import { LensIcon } from '../../../icons/lens';
import { CameraIcon } from '../../../icons/camera';
import { ImagingSystemIcon } from '../../../icons/imaging_system';
import { navigateTo } from '../../../../wizard_navigator';

type Props = {};

const c = scopedClasses(cssScope);

export const Home = mobxReactLite.observer(function Home(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const onLensClick = React.useCallback(
        () => {
            navigateTo('lens_listing');
        },
        [],
    );

    const onCameraClick = React.useCallback(
        () => {
            navigateTo('camera_listing');
        },
        [],
    );

    return h('div', { className: c('root') },
        h('div', { className: c('logo') },
            h('img', { src: store.imageLogoBlack, width: '520', height: '80' }),
        ),
        h('div', { className: c('logo-description') },
            'Lens, Camera and Complete Imaging System Configuration Tool',
        ),
        h('div', { className: c('what-are-you-looking-for') },
            'What are you looking for today?',
        ),
        h('div', { className: c('looking-description') },
            h('p', {},
                'The Optical Wizard lens selector tool helps you determine the right lens for your specific application requirements. Simply enter your camera sensor, working distance and object size.'
            ),
            h('p', {},
                'Looking for a camera first? Enter the camera configurator track to select a suitable Pixelink camera model for your needs.',
            ),
        ),
        h('div', { className: c('cards') },
            h('div', { className: c('card'), onClick: onLensClick },
                h('div', { className: c('card-icon') },
                    h(LensIcon, { size: 80 }),
                ),
                h('div', { className: c('card-title') },
                    'Lens',
                ),
            ),

            h('div', { className: c('card-separator') }),

            h('div', { className: c('card'), onClick: onCameraClick },
                h('div', { className: c('card-icon') },
                    h(CameraIcon, { size: 80 }),
                ),
                h('div', { className: c('card-title') },
                    'Camera',
                ),
            ),

            h('div', { className: c('card-separator') }),

            h('div', { className: c('card'), onClick: onCameraClick },
                h('div', { className: c('card-icon') },
                    h(ImagingSystemIcon, { size: 80 }),
                ),
                h('div', { className: c('card-title') },
                    'Complete Imaging System',
                ),
            ),
        ),
    );
});
