import * as React from 'react';
import * as mobx from 'mobx';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../../../util';
import { cssScope } from './css_scope';
import { BackIcon } from '../../../icons/back';
import { DbCamera } from '../../../../db_types';

type Props = {
    filteredCameras: DbCamera[];
    getTableAbsoluteTop: () => number;
};

const c = scopedClasses(cssScope);

export const StickyResults = mobxReactLite.observer(function StickyResults(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const state = mobxReactLite.useLocalObservable(() => {
        return {
            page: 0,
            showStickyResults: true,
        };
    });


    const onStickyResultsClick = React.useCallback(
        () => {
            window.scrollTo({
                top: propsRef.current.getTableAbsoluteTop(),
                left: 0,
                behavior: 'smooth',
            });
        },
        [],
    );

    const onScroll = React.useCallback(
        () => {
            const showStickyResults = window.scrollY + window.innerHeight < propsRef.current.getTableAbsoluteTop() + 250;
            if (state.showStickyResults !== showStickyResults) {
                mobx.runInAction(() => {
                    state.showStickyResults = showStickyResults;
                });
            }
        },
        [],
    );

    // onScroll
    React.useEffect(
        () => {
            onScroll();
            document.addEventListener('scroll', onScroll, { passive: true });
            return () => {
                document.removeEventListener('scroll', onScroll);
            };
        },
        [],
    );

    return h('div', { className: c('sticky-results', 'show-' + state.showStickyResults), onClick: onStickyResultsClick },
        h('div', { className: c('sticky-results-text') },
            h('span', { className: c('sticky-results-text-number') },
                props.filteredCameras.length,
            ),
            ' Matching Results',
        ),
        h('div', { className: c('sticky-results-icon') },
            h(BackIcon, { size: 18 }),
        ),
    );
});
