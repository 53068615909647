import * as React from 'react';
import * as mobx from 'mobx';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../util';
import { store, Store } from '../../store';
import { SuccessIcon } from './success_icon';
import { ErrorIcon } from './error_icon';
import { cssScope } from './css_scope';
import { CloseIcon } from '../icons/close';

type Props = {
    message: Store['snackBarMessages'][0];
};

const c = scopedClasses(cssScope);

export const Message = mobxReactLite.observer(function Message(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const onDismiss = React.useCallback(
        () => {
            mobx.runInAction(() => {
                store.snackBarMessages = store.snackBarMessages.filter((x) => {
                    return x.id !== propsRef.current.message.id;
                });
            });
        },
        [],
    );

    return h('div', { className: c('message', 'message-type-' + props.message.type) },
        h('div', { className: c('message-icon') },
            (props.message.type === 'success' ?
                h(SuccessIcon, { size: 18 }) :
                h(ErrorIcon, { size: 18 })
            ),
        ),
        h('div', { className: c('message-content') }, props.message.content),
        h('div', { className: c('message-dismiss'), onClick: onDismiss },
            h(CloseIcon, { size: 24 }),
        ),
    );
});
