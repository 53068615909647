import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../../../util';
import { cssScope } from './css_scope';
import { FieldLabel } from '../../../field_label/field_label';
import { AdminForeignKeySelect } from '../../../admin_foreign_key_select/admin_foreign_key_select';
import { DbTables, DbTableTypes } from '../../../../db_types';

type Props = {
    label: string;
    fieldId: string;
    tables: DbTables;
    tableName: keyof DbTableTypes;
    emptyValue: number | null,
    emptyTitle: string,
    value: number | null;
    onChange: (fieldId: string, value: number | null) => void;
};

const c = scopedClasses(cssScope);

export const ForeignKeyFilter = mobxReactLite.observer(function ForeignKeyFilter(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const onChange = React.useCallback(
        (value: number | null) => {
            propsRef.current.onChange(propsRef.current.fieldId, value);
        },
        [],
    );

    return h('div', { className: c('root') },
        h(FieldLabel, { label: props.label }),
        h(AdminForeignKeySelect, {
            tables: props.tables,
            tableName: props.tableName,
            emptyValue: props.emptyValue,
            emptyTitle: props.emptyTitle,
            value: props.value,
            onChange: onChange,
        }),
    );
});
