type WavelengthObj = {
    wavelengthVisible: boolean;
    wavelengthNearInfrared: boolean;
    wavelengthShortWaveInfrared: boolean;
};

export function wavelengthsToString(obj: WavelengthObj): string {
    const result = [];

    if (obj.wavelengthVisible) {
        result.push('Visible');
    }

    if (obj.wavelengthNearInfrared) {
        result.push('NIR');
    }

    if (obj.wavelengthShortWaveInfrared) {
        result.push('SWIR');
    }

    return result.join(', ');
}

export function booleanToString(x: boolean): string {
    return x ? 'Yes' : 'No';
}
