import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../util';
import { cssScope } from './css_scope';

type Props = {
    title: string;
    onClick: () => void;
};

const c = scopedClasses(cssScope);

export const ResetButton = mobxReactLite.observer(function ResetButton(props: Props) {
    return h('div', { className: c('root'), onClick: props.onClick },
        props.title,
    );
});
