import * as React from 'react';
import * as mobx from 'mobx';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../util';
import { cssScope } from './css_scope';
import { clamp } from '../../wizard_util';

type Props = {
    value: number;
    minBound: number;
    maxBound: number;
    getRectWidth: () => number;
    onChangeValue: (value: number) => void;
};

const c = scopedClasses(cssScope);

function clientX(e: React.MouseEvent | React.TouchEvent | MouseEvent | TouchEvent) {
    if ('touches' in e) {
        return e.touches[0].clientX;
    } else {
        return e.clientX;
    }
}

export const Handle = mobxReactLite.observer(function Handle(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const state = mobxReactLite.useLocalObservable(() => {
        return {
            dragging: false,
            startX: 0,
            startValue: 0,
        };
    });

    const onMouseDown = React.useCallback(
        (e: React.MouseEvent | React.TouchEvent) => {
            mobx.runInAction(() => {
                state.startX = clientX(e);
                state.startValue = propsRef.current.value;
                state.dragging = true;
            });
            window.addEventListener('mousemove', onMouseMove);
            window.addEventListener('touchmove', onMouseMove);
            window.addEventListener('mouseup', onMouseUp);
            window.addEventListener('touchend', onMouseUp);
        },
        [],
    );

    const onMouseMove = React.useCallback(
        (e: MouseEvent | TouchEvent) => {
            if (!state.dragging) return;
            const deltaX = clientX(e) - state.startX;
            const deltaPercent = deltaX / propsRef.current.getRectWidth();
            const deltaValue = deltaPercent * (props.maxBound - props.minBound);
            propsRef.current.onChangeValue(state.startValue + deltaValue);
        },
        [],
    );

    const onMouseUp = React.useCallback(
        () => {
            mobx.runInAction(() => {
                state.dragging = false;
            });
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('touchmove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
            window.removeEventListener('touchend', onMouseUp);
        },
        [],
    );

    // Remove event handlers
    React.useEffect(
        () => {
            return () => {
                window.removeEventListener('mousemove', onMouseMove);
                window.removeEventListener('touchmove', onMouseMove);
                window.removeEventListener('mouseup', onMouseUp);
                window.removeEventListener('touchend', onMouseUp);
            };
        },
        [],
    );

    const leftPercent = clamp((props.value - props.minBound) / (props.maxBound - props.minBound), 0, 1);

    return h('div', {
        className: c('handle'),
        style: {
            left: (leftPercent * 100) + '%',
        },
        onMouseDown: onMouseDown,
        onTouchStart: onMouseDown,
        onMouseMove: onMouseMove,
        onTouchMove: onMouseMove,
        onMouseUp: onMouseUp,
        onTouchEnd: onMouseUp,
    });
});
