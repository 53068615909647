import * as React from 'react';
import * as mobx from 'mobx';
import * as mobxReactLite from 'mobx-react-lite';
import { defaultValueIfUndefined, formatFloat, h, scopedClasses } from '../../../../util';
import { cssScope } from './css_scope';
import { wizardStore } from '../../../../wizard_store';
import { dbOptions } from '../../../../db_options';
import { formatMegapixelResolution } from '../../../../wizard_util';
import { BackIcon } from '../../../icons/back';
import { navigateTo } from '../../../../wizard_navigator';

type Props = {
    cameraId: number;
};

const c = scopedClasses(cssScope);

export const SolutionRow = mobxReactLite.observer(function SolutionRow(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const onClick = React.useCallback(
        () => {
            mobx.runInAction(() => {
                wizardStore.selectedCameraId = propsRef.current.cameraId;
                navigateTo('camera_detail');
            });
        },
        [],
    );

    const camera = wizardStore.tables.camera[props.cameraId];
    const sensor = wizardStore.tables.cameraSensor[camera.cameraSensorId];
    const sensorFormat = wizardStore.tables.cameraSensorFormat[sensor.cameraSensorFormatId];

    return h('div', { className: c('solution-row'), onClick: onClick },
        h('div', { className: c('solution-row-cell', 'solution-column-model-name') },
            camera.partNumber,
        ),
        h('div', { className: c('solution-row-cell', 'solution-column-resolution') },
            formatMegapixelResolution(sensor.resolutionWidth, sensor.resolutionHeight),
        ),
        h('div', { className: c('solution-row-cell', 'solution-column-sensor-size') },
            sensorFormat.name,
            h('span', { className: c('solution-mobile-units') }, ' sensor'),
        ),
        h('div', { className: c('solution-row-cell', 'solution-column-pixel-size') },
            formatFloat(sensor.pixelSize) + 'μm',
            h('span', { className: c('solution-mobile-units') }, ' pixel size'),
        ),
        h('div', { className: c('solution-row-cell', 'solution-column-frame-rate') },
            sensor.frameRate + 'fps',
        ),
        h('div', { className: c('solution-row-cell', 'solution-column-color-mono') },
            defaultValueIfUndefined(dbOptions.camera.colorMono.get(camera.colorMono), ''),
        ),
        h('div', { className: c('solution-row-cell', 'solution-column-interface') },
            defaultValueIfUndefined(dbOptions.camera.interface.get(camera.interface), ''),
        ),
        h('div', { className: c('solution-row-cell', 'solution-column-sensor-model') },
            sensor.name,
        ),
        h('div', { className: c('solution-row-cell', 'solution-column-select') },
            h('div', { className: c('solution-select-button') },
                'Select',
            ),
        ),
        h('div', { className: c('solution-column-arrow') },
            h(BackIcon, { size: 18 }),
        ),
    );
});
