import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../../../util';
import { cssScope } from './css_scope';
import { FieldLabel } from '../../../field_label/field_label';
import { Select } from '../../../select/select';

type Props = {
    label: string;
    fieldId: string;
    options: Map<any, string>;
    value: any;
    onChange: (fieldId: string, value: any) => void;
};

const c = scopedClasses(cssScope);

export const EnumFilter = mobxReactLite.observer(function EnumFilter(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const onChange = React.useCallback(
        (value: any) => {
            propsRef.current.onChange(propsRef.current.fieldId, value);
        },
        [],
    );

    return h('div', { className: c('root') },
        h(FieldLabel, { label: props.label }),
        h(Select, {
            options: props.options,
            value: props.value,
            onChange: onChange,
        }),
    );
});
