import { Route, specs } from './routes';

export function routeToUrl(route: Route): string {
    if (!('params' in route) || route.params === undefined) {
        return route.id;
    }

    return route.id + '?params=' + encodeURIComponent(JSON.stringify(route.params));
}

export function routeFromUrl(url: string): Route {
    const parsedUrl = parseUrl(url);
    const id = parsedUrl.id as Route['id'];
    const spec = specs[id];

    if (spec === null) {
        return {
            id: id,
        } as any;
    }

    if (spec === undefined || parsedUrl.params === undefined || parsedUrl.params === null || !spec(parsedUrl.params)) {
        return {
            id: '/404',
        };
    }

    return {
        id: id,
        params: parsedUrl.params,
    } as any;
}

export function parseUrl(url: string): { id: string; params?: any } {
    const parts = url.split('?');
    const id = parts[0];

    if (parts.length !== 2) {
        return {
            id: id,
        };
    }

    const searchParams = new URLSearchParams(parts[1]);
    const rawParams = searchParams.get('params');
    if (rawParams === null) {
        return {
            id: id,
        };
    }

    try {
        const params = JSON.parse(rawParams);

        return {
            id: id,
            params: params,
        };
    } catch(error) {
        console.error('Invalid route params:\n', error);

        return {
            id: id,
        };
    }
}
