import { DbAdapter, DbStandardCore, DbMachineVisionLens, DbObjectiveAttachment, DbProductFamily, DbStandardAttachment, DbTableIds, DbTables, DbTableTypes, DbUser, DbCommonSensor, DbObjectiveCore, DbSingleShotCore, DbZoomXtender, DbZoomXtenderMagnificationCoefficient, DbZoomXtenderNaCoefficient, DbStandardCoreNaCoefficient, DbObjectiveCoreStopDiamCoefficient, DbSingleShotObjective, DbCameraSensorFormat, DbCameraSensor, DbCamera, DbMount, DbCameraMount } from './db_types';
import { generateDbId } from './util';

export function createDbTables(): DbTables {
    return {
        user: {},
        cameraSensorFormat: {},
        cameraSensor: {},
        camera: {},
        mount: {},
        cameraMount: {},
        commonSensor: {},
        productFamily: {},
        adapter: {},
        standardCore: {},
        standardCoreNaCoefficient: {},
        standardAttachment: {},
        objectiveCore: {},
        objectiveCoreStopDiamCoefficient: {},
        objectiveAttachment: {},
        singleShotCore: {},
        singleShotObjective: {},
        machineVisionLens: {},
        zoomXtender: {},
        zoomXtenderMagnificationCoefficient: {},
        zoomXtenderNaCoefficient: {},
    };
}

export function createDbTableIds(): DbTableIds {
    return {
        user: [],
        cameraSensorFormat: [],
        cameraSensor: [],
        camera: [],
        mount: [],
        cameraMount: [],
        commonSensor: [],
        productFamily: [],
        adapter: [],
        standardCore: [],
        standardCoreNaCoefficient: [],
        standardAttachment: [],
        objectiveCore: [],
        objectiveCoreStopDiamCoefficient: [],
        objectiveAttachment: [],
        singleShotCore: [],
        singleShotObjective: [],
        machineVisionLens: [],
        zoomXtender: [],
        zoomXtenderMagnificationCoefficient: [],
        zoomXtenderNaCoefficient: [],
    };
}

type DbCreate = {
    [A in keyof DbTableTypes]: () => DbTableTypes[A];
};

export const dbCreate: DbCreate = {
    user: (): DbUser => ({
        id: generateDbId(),
        email: '',
        role: 'admin',
        name: '',
    }),
    cameraSensorFormat: (): DbCameraSensorFormat => ({
        id: generateDbId(),
        name: '',
        order: 0,
    }),
    cameraSensor: (): DbCameraSensor => ({
        id: generateDbId(),
        name: '',
        cameraSensorFormatId: 0,
        pixelSize: 0,
        resolutionWidth: 0,
        resolutionHeight: 0,
        frameRate: 0,
        bitDepth: 0,
    }),
    camera: (): DbCamera => ({
        id: generateDbId(),
        cameraSensorId: 0,
        partNumber: '',
        interface: '',
        colorMono: '',
        nearInfrared: false,
        autofocus: false,
        autofocusFocalLength: '',
        shutterType: '',
        housing: '',
        trigger: false,
        datasheet: '',
    }),
    mount: (): DbMount => ({
        id: generateDbId(),
        name: '',
        order: 0,
    }),
    cameraMount: (): DbCameraMount => ({
        id: generateDbId(),
        cameraId: 0,
        mountId: 0,
    }),
    commonSensor: (): DbCommonSensor => ({
        id: generateDbId(),
        name: '',
        width: 0,
        height: 0,
    }),
    productFamily: (): DbProductFamily => ({
        id: generateDbId(),
        name: '',
        order: 0,
        productPageUrl: '',
    }),
    adapter: (): DbAdapter => ({
        id: generateDbId(),
        productFamilyId: 0,
        name: '',
        magnification: 0,
        warnSensorDiagonalStandard: 0,
        warnSensorDiagonalObjective: 0,
        maxSensorDiagonalStandard: 0,
        maxSensorDiagonalObjective: 0,
        wavelengthVisible: false,
        wavelengthNearInfrared: false,
        wavelengthShortWaveInfrared: false,
    }),
    standardCore: (): DbStandardCore => ({
        id: generateDbId(),
        productFamilyId: 0,
        name: '',
        magnificationLow: 0,
        magnificationHigh: 0,
        attachmentRequired: false,
        noAttachmentWorkingDistance: 0,
        wavelengthVisible: false,
        wavelengthNearInfrared: false,
        wavelengthShortWaveInfrared: false,
    }),
    standardCoreNaCoefficient: (): DbStandardCoreNaCoefficient => ({
        id: generateDbId(),
        standardCoreId: 0,
        coefficient: 0,
    }),
    standardAttachment: (): DbStandardAttachment => ({
        id: generateDbId(),
        productFamilyId: 0,
        name: '',
        magnification: 0,
        workingDistance: 0,
        wavelengthVisible: false,
        wavelengthNearInfrared: false,
        wavelengthShortWaveInfrared: false,
    }),
    objectiveCore: (): DbObjectiveCore => ({
        id: generateDbId(),
        productFamilyId: 0,
        name: '',
        focalLengthLow: 0,
        focalLengthHigh: 0,
        adapterRequired: true,
        noAdapterMaxSensorDiagonal: 0,
        wavelengthVisible: false,
        wavelengthNearInfrared: false,
        wavelengthShortWaveInfrared: false,
    }),
    objectiveCoreStopDiamCoefficient: (): DbObjectiveCoreStopDiamCoefficient => ({
        id: generateDbId(),
        objectiveCoreId: 0,
        coefficient: 0,
    }),
    objectiveAttachment: (): DbObjectiveAttachment => ({
        id: generateDbId(),
        name: '',
        focalLength: 0,
        stopSemiDiameter: 0,
        workingDistance: 0,
        wavelengthVisible: false,
        wavelengthNearInfrared: false,
        wavelengthShortWaveInfrared: false,
    }),
    singleShotCore: (): DbSingleShotCore => ({
        id: generateDbId(),
        productFamilyId: 0,
        name: '',
        focalLength: 0,
        stopSemiDiameter: 0,
        workingDistance: 0,
        maxSensorDiagonal: 0,
        wavelengthVisible: false,
        wavelengthNearInfrared: false,
        wavelengthShortWaveInfrared: false,
    }),
    singleShotObjective: (): DbSingleShotObjective => ({
        id: generateDbId(),
        productFamilyId: 0,
        name: '',
        focalLength: 0,
        stopSemiDiameter: 0,
        workingDistance: 0,
        wavelengthVisible: false,
        wavelengthNearInfrared: false,
        wavelengthShortWaveInfrared: false,
    }),
    machineVisionLens: (): DbMachineVisionLens => ({
        id: generateDbId(),
        productFamilyId: 0,
        partNumber: '',
        megapixels: 0,
        focalLength: 0,
        fNumberLow: 0,
        fNumberHigh: 0,
        maxSensorDiagonal: 0,
        minWorkingDistance: 0,
        pupilPosition: 0,
        resolvableFrequencyCenter: 0,
        resolvableFrequencyEdge: 0,
        wavelengthVisible: false,
        wavelengthNearInfrared: false,
        wavelengthShortWaveInfrared: false,
    }),
    zoomXtender: (): DbZoomXtender => ({
        id: generateDbId(),
        productFamilyId: 0,
        name: '',
        coreName: '',
        coreMagnificationLow: 0,
        coreMagnificationHigh: 0,
        workingDistanceLow: 0,
        workingDistanceHigh: 0,
        fitEquationId: 0,
        wavelengthVisible: false,
        wavelengthNearInfrared: false,
        wavelengthShortWaveInfrared: false,
    }),
    zoomXtenderMagnificationCoefficient: (): DbZoomXtenderMagnificationCoefficient => ({
        id: generateDbId(),
        zoomXtenderId: 0,
        coefficient: 0,
    }),
    zoomXtenderNaCoefficient: (): DbZoomXtenderNaCoefficient => ({
        id: generateDbId(),
        zoomXtenderId: 0,
        coefficient: 0,
    }),
};
