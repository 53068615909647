import { DbObjectiveCore } from '../db_types';

export const dbDataObjectiveCore: { [index: string]: DbObjectiveCore } = {
    // Zoom 6000
    '1': {
        id: 1,
        productFamilyId: 1,
        name: '6.5X UltraZoom Core',
        focalLengthLow: 71.0872921131711,
        focalLengthHigh: 413.1323684,
        adapterRequired: true,
        noAdapterMaxSensorDiagonal: 0,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },

    // 12x Zoom
    '2': {
        id: 2,
        productFamilyId: 2,
        name: '12X UltraZoom Core',
        focalLengthLow: 55.21739251,
        focalLengthHigh: 666.2404228,
        adapterRequired: true,
        noAdapterMaxSensorDiagonal: 0,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },

    // Resolv4K
    '3': {
        id: 3,
        productFamilyId: 3,
        name: 'Resolv4K Zoom Core',
        focalLengthLow: 64.06106406,
        focalLengthHigh: 447.9005669,
        adapterRequired: true,
        noAdapterMaxSensorDiagonal: 0,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: true,
    },
    '4': {
        id: 4,
        productFamilyId: 3,
        name: 'Resolv4K Fixed Core',
        focalLengthLow: 160,
        focalLengthHigh: 160,
        adapterRequired: true,
        noAdapterMaxSensorDiagonal: 0,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: true,
    },

    // Precise Eye
    '5': {
        id: 5,
        productFamilyId: 4,
        name: 'Precise Eye Ultra Core',
        focalLengthLow: 177.0229,
        focalLengthHigh: 177.0229,
        adapterRequired: true,
        noAdapterMaxSensorDiagonal: 0,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },

    // Modular Tube Lens
    '6': {
        id: 6,
        productFamilyId: 6,
        name: '0.5X Tube Lens',
        focalLengthLow: 100,
        focalLengthHigh: 100,
        adapterRequired: false,
        noAdapterMaxSensorDiagonal: 11,
        wavelengthVisible: true,
        wavelengthNearInfrared: false,
        wavelengthShortWaveInfrared: false,
    },
    '7': {
        id: 7,
        productFamilyId: 6,
        name: '1X Tube Lens',
        focalLengthLow: 200,
        focalLengthHigh: 200,
        adapterRequired: false,
        noAdapterMaxSensorDiagonal: 23,
        wavelengthVisible: true,
        wavelengthNearInfrared: false,
        wavelengthShortWaveInfrared: false,
    },
    '8': {
        id: 8,
        productFamilyId: 6,
        name: '1X Tube Lens, Large Aperture',
        focalLengthLow: 200,
        focalLengthHigh: 200,
        adapterRequired: false,
        noAdapterMaxSensorDiagonal: 23,
        wavelengthVisible: true,
        wavelengthNearInfrared: false,
        wavelengthShortWaveInfrared: false,
    },
    '9': {
        id: 9,
        productFamilyId: 6,
        name: '2X Tube Lens',
        focalLengthLow: 400,
        focalLengthHigh: 400,
        adapterRequired: false,
        noAdapterMaxSensorDiagonal: 33,
        wavelengthVisible: true,
        wavelengthNearInfrared: false,
        wavelengthShortWaveInfrared: false,
    },
};
