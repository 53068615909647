import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../util';
import { cssScope } from './css_scope';

type Props = {
    value: any;
    valueToString: (value: any) => string;
    stringToValue: (s: string) => any;
    valueEqual: (value1: any, value2: any) => boolean;
    placeholder?: string;
    autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters';
    onChange: (value: any) => void;
};

const c = scopedClasses(cssScope);

export const TypedStringInput = mobxReactLite.observer(function TypedStringInput(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const cachedValue = React.useRef();
    const cachedDisplay = React.useRef('');

    const onChange = React.useCallback(
        (e) => {
            const newDisplay = e.currentTarget.value;
            const newValue = propsRef.current.stringToValue(newDisplay);

            cachedValue.current = newValue;
            cachedDisplay.current = newDisplay;

            propsRef.current.onChange(newValue);

            forceUpdate();
        },
        [],
    );

    const onBlur = React.useCallback(
        () => {
            cachedValue.current = undefined;
            forceUpdate();
        },
        [],
    );

    let display = props.valueToString(props.value);

    if (cachedValue.current !== undefined) {
        if (props.valueEqual(props.value, cachedValue.current)) {
            display = cachedDisplay.current;
        } else {
            cachedValue.current = undefined;
        }
    }

    return h('input', {
        className: c('root'),
        type: 'text',
        value: display,
        placeholder: props.placeholder,
        autoCapitalize: props.autoCapitalize,
        onChange: onChange,
        onBlur: onBlur,
    });
});
