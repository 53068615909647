import { fileExtension } from './util';
import { api } from './api';
import { Store } from './store';

export function uploadFile(blob: Blob, originalFileName: string, store: Store) {
    let extension = '';
    if (blob.type === 'image/jpeg') {
        extension = 'jpg';
    } else if (blob.type === 'image/png') {
        extension = 'png';
    } else {
        extension = fileExtension(originalFileName)
    }

    let filePath = '';

    return api('/api/file-upload-url', store, false, null, {
        extension: extension,
        contentType: blob.type,
    }).then((response) => {
        filePath = response.filePath;

        return fetch(response.uploadUrl, {
            method: 'PUT',
            body: blob,
            headers: {
                'Content-Type': blob.type,
                'Cache-Control': 'public, max-age=31536000',
            },
        });
    }).then(() => Promise.resolve(filePath));
}
