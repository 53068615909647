import * as React from 'react';
import * as mobx from 'mobx';
import * as mobxReactLite from 'mobx-react-lite';
import { api } from '../../../api';
import { Link } from '../../link';
import { routeToUrl } from '../../../route_util';
import { h, scopedClasses, getHomeUrl, addSnackbarItem } from '../../../util';
import { Form } from '../../form';
import { Button } from '../../button/button';
import { cssScope } from './css_scope';
import { store } from '../../../store';
import { FieldLabel } from '../../field_label/field_label';
import { createTwoWayBinding } from '../../two_way_binding';
import { EmailInput } from '../../email_input';

const c = scopedClasses(cssScope);

export const SendPasswordReset = mobxReactLite.observer(function SendPasswordReset() {
    const state = mobxReactLite.useLocalObservable(() => {
        return {
            submitting: false,
            success: false,
            email: '',
        };
    });

    const validateForm = React.useCallback(
        (): string[] => {
            const validationErrors = [];

            if (state.email === '') {
                validationErrors.push('The "Email" field is required');
            }

            return validationErrors;
        },
        [],
    );

    const onSubmit = React.useCallback(
        () => {
            if (state.submitting) return;

            const validationErrors = validateForm();
            if (validationErrors.length !== 0) {
                mobx.runInAction(() => {
                    for (const validationError of validationErrors) {
                        addSnackbarItem(store, 'error', validationError);
                    }
                });
                return;
            }


            mobx.runInAction(() => {
                state.submitting = true;
            });

            api('/api/send-password-reset', store, false, null, {
                email: state.email,
            }).then((response) => {
                if (response.status === 'wrong_email') {
                    addSnackbarItem(store, 'error', 'The email you entered is not associated with any account');
                    mobx.runInAction(() => {
                        state.submitting = false;
                    });
                } else {
                    mobx.runInAction(() => {
                        state.submitting = false;
                        state.success = true;
                        state.email = '';
                    });
                }

            }).catch((err) => {
                console.error(err);

                mobx.runInAction(() => {
                    state.submitting = false;
                });
            });
        },
        [],
    );

    const logoUrl = store.user !== null ? getHomeUrl() : routeToUrl({ id: '/login' });

    return h('div', { className: c('root') },
        h('div', { className: c('content') },
            h(Link, { className: c('logo'), url: logoUrl },
            ),

            (state.success ?
                h(React.Fragment, {},
                    h('div', { className: c('title') }, 'Reset Sent'),

                    h('div', { className: c('description') }, 'An email has been sent to your address. Click the link in the email to reset your password.'),

                    h(Link,
                        { className: c('login-link'), url: routeToUrl({ id: '/login' }) },
                        'Login',
                    ),
                ) :
                h(React.Fragment, {},
                    h('div', { className: c('title') }, 'Reset Password'),

                    h('div', { className: c('description') }, 'Enter your account email address and a link will be sent to that address to reset your password.'),

                    h(Form, { className: c('form'), onSubmit: onSubmit },
                        h('div', { className: c('fields') },
                            h('div', { className: c('field') },
                                h(FieldLabel, { label: 'Email' }),
                                createTwoWayBinding({
                                    observable: state,
                                    property: 'email',
                                    childType: EmailInput,
                                    childProps: {},
                                }),
                            ),
                        ),

                        h('div', { className: c('button') },
                            h(Button, {
                                title: 'Send',
                                style: 'blue',
                                loading: state.submitting,
                                onClick: onSubmit,
                            }),
                        ),
                    ),
                )
            ),
        ),
    );
});
