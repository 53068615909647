import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../../../util';
import { cssScope } from './css_scope';

type Props = {
    head: string[];
    rows: string[][];
};

const c = scopedClasses(cssScope);

export const QualityAssuranceTable = mobxReactLite.observer(function QualityAssuranceTable(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    return h('div', { className: c('root') },
        h('div', { className: c('head') },
            props.head.map((s, i) => h('div', { className: c('head-cell'), key: i }, s)),
        ),
        props.rows.map((row, i) => h('div', { className: c('row'), key: i },
            row.map((s, i) => h('div', { className: c('row-cell'), key: i }, s)),
        )),
    );
});
