import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../util';
import { cssScope } from './css_scope';
import { ChevronRightIcon } from '../icons/chevron_right';

type Props = {
    page: number;
    itemsPerPage: number;
    totalItems: number;
    onPageChange: (page: number) => void;
};

const c = scopedClasses(cssScope);

export const WizardPagination = mobxReactLite.observer(function WizardPagination(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const isPreviousEnabled = React.useCallback(
        () => {
            return propsRef.current.page > 0;
        },
        [],
    );

    const isNextEnabled = React.useCallback(
        () => {
            return (propsRef.current.page + 1) * propsRef.current.itemsPerPage < propsRef.current.totalItems;
        },
        [],
    );

    const onPreviousClick = React.useCallback(
        () => {
            if (!isPreviousEnabled()) return;
            propsRef.current.onPageChange(propsRef.current.page - 1);
        },
        [],
    );

    const onNextClick = React.useCallback(
        () => {
            if (!isNextEnabled()) return;
            propsRef.current.onPageChange(propsRef.current.page + 1);
        },
        [],
    );

    const showingStart = props.page * props.itemsPerPage + 1;
    const showingEnd = Math.min(showingStart + props.itemsPerPage - 1, props.totalItems);

    return h('div', { className: c('root') },
        h('div',
            {
                className: c('previous', 'enabled-' + isPreviousEnabled()),
                onClick: onPreviousClick,
            },
            h('div', { className: c('previous-icon') },
                h(ChevronRightIcon, { size: 24 }),
            ),
        ),
        h('div',
            {
                className: c('next', 'enabled-' + isNextEnabled()),
                onClick: onNextClick,
            },
            h('div', { className: c('next-icon') },
                h(ChevronRightIcon, { size: 24 }),
            ),
        ),
        h('div', { className: c('current') },
            `Showing ${showingStart}–${showingEnd} of ${props.totalItems}`,
        ),
    );
});
