import * as mobx from 'mobx';
import { createDbTables } from './db_create';
import { parseUrl } from './route_util';
import { isObject } from './util';
import { WizardRoute, WizardStore } from './wizard_types';

export const wizardStore: WizardStore = mobx.observable({
    ...getCameraFormState(),
    ...getLensFormState(),
    cameraResolutionMax: 0,
    cameraResolutionRangeSliderMax: 0,
    wizardRoute: getInitialWizardRoute(),
    tables: createDbTables(),
    selectedCameraId: null,
    selectedLensSolution: null,
});

function getInitialWizardRoute(): WizardRoute {
    const url = location.pathname + location.search;
    const parsedUrl = parseUrl(url);

    if (parsedUrl.id === '/' && isObject(parsedUrl.params) && parsedUrl.params.startingStep === 'camera_listing') {
        return 'camera_listing';
    }

    return 'home';
}

function getCameraFormState() {
    return {
        cameraResolutionMin: 0,
        cameraFrameRate: 0,
        cameraInterface: '',
        cameraColorMono: '',
        cameraNearInfrared: null,
        cameraSensorModelId: null,
        cameraSensorFormatId: null,
        cameraPixelSizeMin: 0,
        cameraPixelSizeMax: 0,
        cameraAutofocus: false,
        cameraAutofocusFocalLength: '',
        cameraShutterType: '',
        cameraHousing: 'enclosed',
        cameraMountId: null,
        cameraTrigger: true,
        cameraBitDepth: 0,
        cameraSort: 'resolution_descending',
    } as const;
}

function getLensFormState() {
    return {
        lensCameraMake: '',
        lensPixelinkCameraSensorFormatId: null,
        lensCommonCameraSensorFormatId: null,
        lensCameraId: null,
        lensPixelSize: 0,
        lensResolutionWidth: 0,
        lensResolutionHeight: 0,
        lensObjectWidth: 0,
        lensObjectHeight: 0,
        lensFeatureSize: 0,
        lensMaxObjectWidth: 0,
        lensMaxObjectHeight: 0,
        lensMaxFeatureSize: 0,
        lensWorkingDistance: 0,
        lensType: 'all',
        lensWavelength: 'visible',
        lensProductFamilyFilter: null,
        lensSort: 'recommended',
        lensMountId: null,
        lensNeedFeatureCoaxialIllumination: false,
        lensNeedFeatureFineFocus: false,
        lensNeedFeatureMotorization: false,
        lensNeedFeatureApertureControl: false,
        lensNeedFeatureDetentedZoom: false,
        lensNeedFeatureRingLight: false,
    } as const;
}

export function resetWizardCameraForm() {
    mobx.runInAction(() => {
        Object.assign(wizardStore, getCameraFormState());
        wizardStore.cameraResolutionMax = wizardStore.cameraResolutionRangeSliderMax;
    });
}

export function resetWizardLensForm() {
    mobx.runInAction(() => {
        Object.assign(wizardStore, getLensFormState());
    });
}

export function resetWizardStore() {
    mobx.runInAction(() => {
        resetWizardCameraForm();
        resetWizardLensForm();
        wizardStore.selectedCameraId = null;
        wizardStore.selectedLensSolution = null;
    });
}
