import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../../../util';
import { cssScope } from './css_scope';
import * as controller from './controller';
import { Button } from '../../../button/button';

type Props = {};

const c = scopedClasses(cssScope);

export const BottomBar = mobxReactLite.observer(function BottomBar(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    return h('div', { className: c('buttons') },
        h('div', { className: c('buttons-left') },
        ),
        h('div', { className: c('buttons-right') },
            h(Button, {
                title: 'Save',
                style: 'blue',
                loading: controller.state.submitting,
                onClick: controller.onSubmit,
            }),
        ),
    );
});
