import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../../../util';
import { cssScope } from './css_scope';
import { Form } from '../../../form';
import { FieldLabel } from '../../../field_label/field_label';
import { createTwoWayBinding } from '../../../two_way_binding';
import { EmailInput } from '../../../email_input';
import { PasswordInput } from '../../../password_input/password_input';
import * as controller from './controller';

type Props = {};

const c = scopedClasses(cssScope);

export const ChangePassword = mobxReactLite.observer(function ChangePassword(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    return h('div', { className: c('root') },
        h('div', { className: c('page-title') }, 'Change Password'),
        h('div', { className: c('content') },
            h(Form, { className: c('form'), onSubmit: controller.onSubmit },
                h('div', { className: c('form-fields') },
                    h('div', { className: c('form-field') },
                        h(FieldLabel, { label: 'Email' }),
                        createTwoWayBinding({
                            observable: controller.state,
                            property: 'email',
                            childType: EmailInput,
                            childProps: {},
                        }),
                    ),

                    h('div', { className: c('form-field') },
                        h(FieldLabel, { label: 'New Password' }),
                        createTwoWayBinding({
                            observable: controller.state,
                            property: 'newPassword',
                            childType: PasswordInput,
                            childProps: {},
                        }),
                    ),

                    h('div', { className: c('form-field') },
                        h(FieldLabel, { label: 'Confirm New Password' }),
                        createTwoWayBinding({
                            observable: controller.state,
                            property: 'newPasswordConfirm',
                            childType: PasswordInput,
                            childProps: {},
                        }),
                    ),
                ),
            ),
        ),
    );
});
