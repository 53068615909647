import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h } from '../util';
import { TypedStringInput } from './typed_string_input/typed_string_input';

type Props = {
    value: number;
    fractionDigits: number;
    onChange: (value: number) => void;
    nonNegative?: boolean;
    emptyZero?: boolean;
    placeholder?: string;
};

function valueEqual(value1: number, value2: number): boolean {
    return value1 === value2;
}

export const NumberInput = mobxReactLite.observer(function NumberInput(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const numberToString = React.useCallback(
        (n: number): string => {
            if (propsRef.current.emptyZero === true && n === 0) return '';
            return n.toLocaleString('en-US', { maximumFractionDigits: propsRef.current.fractionDigits });
        },
        [],
    );

    const stringToNumber = React.useCallback(
        (s: string): number => {
            if (s === '') return 0;

            s = s.replace(/[^0-9.-]/g, '');

            let float = parseFloat(s);

            if (isNaN(float)) return 0;

            if (propsRef.current.nonNegative === true) {
                float = Math.abs(float);
            }

            if (propsRef.current.fractionDigits === 0) {
                return Math.round(float);
            } else {
                const fractionDigitPower = Math.pow(10, propsRef.current.fractionDigits);
                return Math.round(float * fractionDigitPower) / fractionDigitPower;
            }
        },
        [],
    );

    return h(TypedStringInput, {
        value: props.value,
        valueToString: numberToString,
        stringToValue: stringToNumber,
        valueEqual: valueEqual,
        onChange: props.onChange,
        placeholder: props.placeholder,
    });
});
