import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses, setRoute } from '../../../util';
import { cssScope } from './css_scope';
import { routeToUrl } from '../../../route_util';
import { api } from '../../../api';
import { store } from '../../../store';

type Props = {
    onClose: () => void;
};

const c = scopedClasses(cssScope);

export const AccountMenu = mobxReactLite.observer(function AccountMenu(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const onDocumentClick = React.useCallback(
        () => {
            propsRef.current.onClose();
        },
        [],
    );

    const onLogoutClick = React.useCallback(
        () => {
            api('/api/logout', store, true, null, {}).then(() => {
                setRoute(store, routeToUrl({
                    id: '/login',
                }));
            });
        },
        [],
    );

    React.useEffect(
        () => {
            document.addEventListener('click', onDocumentClick);
            return () => {
                document.removeEventListener('click', onDocumentClick);
            };
        },
        [],
    );

    return h('div', { className: c('account-menu') },
        h('div', { className: c('account-menu-item'), onClick: onLogoutClick },
            'Log Out',
        ),
    );
});
