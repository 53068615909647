import { DbStandardCore } from '../db_types';

export const dbDataStandardCore: { [index: string]: DbStandardCore } = {
    // Zoom 6000
    '1': {
        id: 1,
        productFamilyId: 1,
        name: '6.5X Zoom Core',
        magnificationLow: 0.7,
        magnificationHigh: 4.5,
        attachmentRequired: false,
        noAttachmentWorkingDistance: 92,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },

    // 12x Zoom
    '2': {
        id: 2,
        productFamilyId: 2,
        name: '12X Zoom Core',
        magnificationLow: 0.58,
        magnificationHigh: 7,
        attachmentRequired: false,
        noAttachmentWorkingDistance: 86,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },

    // Resolv4K
    '3': {
        id: 3,
        productFamilyId: 3,
        name: 'Resolv4K Zoom Core',
        magnificationLow: 0.64,
        magnificationHigh: 4.5,
        attachmentRequired: true,
        noAttachmentWorkingDistance: 0,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: true,
    },
    '4': {
        id: 4,
        productFamilyId: 3,
        name: 'Resolv4K Fixed Core',
        magnificationLow: 1.6,
        magnificationHigh: 1.6,
        attachmentRequired: true,
        noAttachmentWorkingDistance: 0,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: true,
    },

    // Precise Eye
    '5': {
        id: 5,
        productFamilyId: 4,
        name: 'Precise Eye Core',
        magnificationLow: 1.8,
        magnificationHigh: 1.8,
        attachmentRequired: false,
        noAttachmentWorkingDistance: 92,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
};
