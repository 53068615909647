import { CameraColorMono, CameraHousing, CameraInterface, CameraShutterType } from './db_types';

export const dbOptions = {
    all: {
        boolean: new Map<boolean | null, string>([
            [null, 'All'],
            [true, 'Yes'],
            [false, 'No'],
        ]),
    },
    camera: {
        interface: new Map<CameraInterface, string>([
            ['', 'All'],
            ['10_gig_e', '10 GigE'],
            ['usb_3', 'USB 3.0'],
        ]),
        colorMono: new Map<CameraColorMono, string>([
            ['', 'All'],
            ['color', 'Color'],
            ['mono', 'Mono'],
        ]),
        shutterType: new Map<CameraShutterType, string>([
            ['', 'All'],
            ['global', 'Global'],
            ['rolling', 'Rolling'],
        ]),
        housing: new Map<CameraHousing, string>([
            ['', 'All'],
            ['enclosed', 'Enclosed'],
            ['board_level', 'Board Level'],
        ]),
        bitDepth: new Map<number, string>([
            [0, 'All'],
            [8, '8-bit'],
            [10, '10-bit'],
            [12, '12-bit'],
        ]),
    },
};
