import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, setRoute } from '../util';
import { store } from '../store';

type Props = {
    url: string;
    className?: string;
    children?: React.ReactNode;
};

export const Link = mobxReactLite.observer(function Link(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const onClick = React.useCallback(
        (e) => {
            e.preventDefault();
            setRoute(store, propsRef.current.url);
        },
        [],
    );

    return h('a',
        {
            className: props.className,
            href: props.url,
            onClick: onClick,
        },
        props.children,
    );
});
