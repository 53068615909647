import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../../../util';
import { cssScope } from './css_scope';
import { DbTableTypes } from '../../../../db_types';
import { AdminBreadcrumb } from '../../../../admin_breadcrumb_types';
import * as controller from './controller';
import { Button } from '../../../button/button';

type Props = {
    tableName: keyof DbTableTypes;
    rowId: number | null;
    hiddenFilterFieldId?: string;
    hiddenFilterValue?: number;
    breadcrumbs?: AdminBreadcrumb[];
};

const c = scopedClasses(cssScope);

export const BottomBar = mobxReactLite.observer(function BottomBar(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    if (!controller.state.loaded) return null;

    return h('div', { className: c('buttons') },
        h('div', { className: c('buttons-left') },
            (props.rowId !== null ?
                h(Button, {
                    title: 'Delete',
                    style: 'red',
                    loading: false,
                    onClick: controller.onDeleteOpen,
                }) : null
            ),
        ),
        h('div', { className: c('buttons-right') },
            h(Button, {
                title: 'Save',
                style: 'blue',
                loading: controller.state.submitting,
                onClick: controller.onSubmit,
            }),
        ),
    );
});
