import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { formatFloat, h } from '../../../util';
import { DbTables } from '../../../db_types';
import { QualityAssuranceTable } from './quality_assurance_table/quality_assurance_table';
import { wavelengthsToString } from './quality_assurance_util';

type Props = {
    tables: DbTables;
};

export const DataSingleShotCore = mobxReactLite.observer(function DataSingleShotCore(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const items = Object.values(props.tables.singleShotCore).sort((a, b) => {
        if (a.productFamilyId < b.productFamilyId) return -1;
        if (a.productFamilyId > b.productFamilyId) return 1;

        return a.focalLength - b.focalLength;
    });

    return h(QualityAssuranceTable, {
        head: [
            'Product Family',
            'Name',
            'Focal Length',
            'Stop Semi Diameter',
            'Working Distance',
            'Max Sensor Diagonal',
            'Wavelengths',
        ],
        rows: items.map((item) => ([
            props.tables.productFamily[item.productFamilyId].name,
            item.name,
            formatFloat(item.focalLength),
            formatFloat(item.stopSemiDiameter),
            formatFloat(item.workingDistance),
            formatFloat(item.maxSensorDiagonal),
            wavelengthsToString(item),
        ])),
    });
});
