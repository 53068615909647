import { DbSingleShotCore } from '../db_types';

export const dbDataSingleShotCore: { [index: string]: DbSingleShotCore } = {
    '1': {
        id: 1,
        productFamilyId: 5,
        name: '160mm Tube Lens',
        focalLength: 160,
        stopSemiDiameter: 14.88,
        workingDistance: 50,
        maxSensorDiagonal: 23,
        wavelengthVisible: true,
        wavelengthNearInfrared: false,
        wavelengthShortWaveInfrared: false,
    },
    '2': {
        id: 2,
        productFamilyId: 5,
        name: '110mm Tube Lens',
        focalLength: 110,
        stopSemiDiameter: 14.63,
        workingDistance: 39,
        maxSensorDiagonal: 16,
        wavelengthVisible: true,
        wavelengthNearInfrared: false,
        wavelengthShortWaveInfrared: false,
    },
};
