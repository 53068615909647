import * as React from 'react';
import * as mobx from 'mobx';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../util';
import { VisibilityOffIcon } from '../icons/visibility_off';
import { VisibilityIcon } from '../icons/visibility';
import { cssScope } from './css_scope';

type Props = {
    value: string;
    onChange: (value: string) => void;
};

const c = scopedClasses(cssScope);

export const PasswordInput = mobxReactLite.observer(function PasswordInput(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const state = mobxReactLite.useLocalObservable(() => {
        return {
            visible: false,
        };
    });

    const onChange = React.useCallback(
        (e) => {
            propsRef.current.onChange(e.currentTarget.value);
        },
        [],
    );

    const onVisibilityClick = React.useCallback(
        () => {
            mobx.runInAction(() => {
                state.visible = !state.visible;
            });
        },
        [],
    );

    return h('div', { className: c('root') },
        h('input', {
            className: c('input'),
            type: state.visible ? 'text' : 'password',
            value: props.value,
            onChange: onChange,
        }),
        h('div', { className: c('icon'), onClick: onVisibilityClick },
            (state.visible ?
                h(VisibilityOffIcon, { size: 24 }) :
                h(VisibilityIcon, { size: 24 })
            ),
        ),
    );
});
