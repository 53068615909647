import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as mobx from 'mobx';
import { Root } from './components/root';
import { assertNotNull, getAdminUrl } from './util';
import { routeFromUrl, routeToUrl } from './route_util';
import { publicRouteIds } from './routes';
import { store } from './store';
import { DbUser } from './db_types';
import { InitialData } from './initial_data_types';
import { onAdminUrlChange } from './components/pages/admin/admin_url_change';

function initialUrl(user: DbUser | null): string {
    const originalUrl = location.pathname + location.search;
    let url = originalUrl;
    if (url === '/admin' || url === '/admin/') {
        url = getAdminUrl();
    }

    if (routeFromUrl(url).id === '/404') {
        url = routeToUrl({ id: '/404' });
    }

    if (publicRouteIds[routeFromUrl(url).id] === undefined && user === null) {
        url = routeToUrl({ id: '/login' });
    }

    if (url !== originalUrl) {
        history.replaceState(null, '', url);
    }

    return url;
}

function getDealerId(): string {
    const searchParams = new URLSearchParams(location.search);
    const dealerId = searchParams.get('dealerId');
    return dealerId === null ? '' : dealerId;
}

function init() {
    const initialData = JSON.parse(assertNotNull(assertNotNull(
        document.getElementById('initial-data')
    ).textContent)) as InitialData;

    mobx.runInAction(() => {
        store.storageUrlPrefix = initialData.storageUrlPrefix;
        store.imageLogo = initialData.imageLogo;
        store.imageLogoBlack = initialData.imageLogoBlack;
        store.imageNavitarLogo = initialData.imageNavitarLogo;
        store.imageLensDiagram = initialData.imageLensDiagram;
        store.adminSettings = initialData.adminSettings;
        store.user = initialData.user;
        store.url = initialUrl(initialData.user);
        store.dealerId = getDealerId();
    });

    const root = React.createElement(Root, {});
    ReactDOM.render(root, document.getElementById('react-root'));

    window.addEventListener('popstate', () => {
        mobx.runInAction(() => {
            store.url = location.pathname + location.search;
        });
    });

    onAdminUrlChange(store.url);
    mobx.reaction(() => store.url, onAdminUrlChange);
}

init();
