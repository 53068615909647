import * as React from 'react';
import * as mobx from 'mobx';
import * as mobxReactLite from 'mobx-react-lite';
import { h, shallowEqual } from '../util';

type LooseProps = {
    observable: any;
    property: any;
    childType: React.FunctionComponent<any>;
    childProps: any;
};

type StrictProps<
    A extends object,
    B extends keyof A,
    C extends {
        value: A[B];
        onChange: (value: A[B]) => void;
    },
> = {
    observable: A;
    property: B;
    childType: React.FunctionComponent<C>;
    childProps: Omit<C, 'value' | 'onChange'>;
};

function propsEqual(a: any, b: any) {
    return (
        a.observable === b.observable &&
        a.property === b.property &&
        a.childType === b.childType &&
        shallowEqual(a.childProps, b.childProps)
    );
}

const TwoWayBinding = React.memo(function TwoWayBinding(props: LooseProps) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const onChange = React.useCallback(
        (value) => {
            mobx.runInAction(() => {
                propsRef.current.observable[propsRef.current.property] = value;
            });
        },
        [],
    );

    return h(mobxReactLite.Observer, {},
        () => h(props.childType, {
            ...props.childProps,
            value: props.observable[props.property],
            onChange: onChange,
        }),
    );
}, propsEqual);

export function createTwoWayBinding<
    A extends object,
    B extends keyof A,
    C extends {
        value: A[B];
        onChange: (value: A[B]) => void;
    },
>(props: StrictProps<A, B, C>) {
    return h(TwoWayBinding, { ...props });
};
