import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../../../util';
import { cssScope } from './css_scope';
import { DbTables } from '../../../../db_types';
import { QualityAssuranceTable } from '../quality_assurance_table/quality_assurance_table';
import { displayFieldOfViewRange, displayRangeFloat, getFieldOfViewRange, getSolutionMagnification, getSolutionNumericalAperture, getSolutionPartNames, getSolutions } from '../../../../wizard_util';
import { FieldLabel } from '../../../field_label/field_label';
import { createTwoWayBinding } from '../../../two_way_binding';
import { Select } from '../../../select/select';
import { NumberInput } from '../../../number_input';

type Props = {
    tables: DbTables;
};

const c = scopedClasses(cssScope);

export const Solutions = mobxReactLite.observer(function Solutions(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const state = mobxReactLite.useLocalObservable(() => {
        return {
            productFamilyId: null as number | null,
            sensorWidth: 0,
            sensorHeight: 0,
            workingDistance: 0,
        };
    });

    const sortedProductFamilies = Object.values(props.tables.productFamily).sort((a, b) => a.order - b.order);
    const productFamilyOptions = new Map<number | null, string>();
    productFamilyOptions.set(null, '');
    for (const productFamily of sortedProductFamilies) {
        productFamilyOptions.set(productFamily.id, productFamily.name);
    }

    return h('div', { className: c('root') },
        h('div', { className: c('form-fields') },
            h('div', { className: c('form-field') },
                h(FieldLabel, { label: 'Product Family' }),
                createTwoWayBinding({
                    observable: state,
                    property: 'productFamilyId',
                    childType: Select,
                    childProps: {
                        options: productFamilyOptions,
                    },
                }),
            ),

            h('div', { className: c('form-field') },
                h(FieldLabel, { label: 'Sensor Width' }),
                createTwoWayBinding({
                    observable: state,
                    property: 'sensorWidth',
                    childType: NumberInput,
                    childProps: {
                        fractionDigits: 2,
                        nonNegative: true,
                        emptyZero: true,
                    },
                }),
            ),

            h('div', { className: c('form-field') },
                h(FieldLabel, { label: 'Sensor Height' }),
                createTwoWayBinding({
                    observable: state,
                    property: 'sensorHeight',
                    childType: NumberInput,
                    childProps: {
                        fractionDigits: 2,
                        nonNegative: true,
                        emptyZero: true,
                    },
                }),
            ),

            h('div', { className: c('form-field') },
                h(FieldLabel, { label: 'Working Distance' }),
                createTwoWayBinding({
                    observable: state,
                    property: 'workingDistance',
                    childType: NumberInput,
                    childProps: {
                        fractionDigits: 2,
                        nonNegative: true,
                        emptyZero: true,
                    },
                }),
            ),
        ),

        (state.productFamilyId !== null && state.sensorWidth > 0 && state.sensorHeight > 0 && state.workingDistance > 0 ?
            (() => {
                const solutions = getSolutions(props.tables, state.productFamilyId, () => true);

                return h('div', { className: c('table') },
                    h(QualityAssuranceTable, {
                        head: [
                            'Product Family',
                            'Parts',
                            'Magnification',
                            'Field Of View',
                            'Numerical Aperture',
                        ],
                        rows: solutions.map((solution) => {
                            const magnificationRange = getSolutionMagnification(solution);
                            return [
                                solution.productFamily.name,
                                getSolutionPartNames(solution),
                                displayRangeFloat(magnificationRange),
                                displayFieldOfViewRange(getFieldOfViewRange(state.sensorWidth, state.sensorHeight, magnificationRange)),
                                displayRangeFloat(getSolutionNumericalAperture(solution, state.workingDistance)),
                            ];
                        }),
                    }),
                );
            })() : null
        ),
    );
});
