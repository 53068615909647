import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { Login } from './pages/login/login';
import { routeFromUrl } from '../route_util';
import { SendPasswordReset } from './pages/send_password_reset/send_password_reset';
import { ResetPassword } from './pages/reset_password/reset_password';
import { Error404 } from './pages/error_404/error_404';
import { Wizard } from './pages/wizard/wizard';
import { QualityAssurance } from './pages/quality_assurance/quality_assurance';
import { ContentImport } from './pages/content_import/content_import';
import { Admin } from './pages/admin/admin';
import { h, assertUnreachable } from '../util';
import { Snackbar } from './snackbar/snackbar';
import { store } from '../store';

function getScreen() {
    const route = routeFromUrl(store.url);

    switch (route.id) {
        case '/': return h(Wizard, {});
        case '/quality-assurance/6fe89101d6be452ba9acb05890f869bd': return h(QualityAssurance, {});
        case '/content-import/b06e6f0f421f44379cae18ac07380860': return h(ContentImport, {});
        case '/login': return h(Login, { key: store.url });
        case '/send-password-reset': return h(SendPasswordReset, { key: store.url });
        case '/reset-password': return h(ResetPassword, { ...route.params, key: store.url });
        case '/404': return h(Error404, { key: store.url });
        case '/admin/listing': return h(Admin, {});
        case '/admin/detail': return h(Admin, {});
        case '/admin/documentation': return h(Admin, {});
        case '/admin/change-password': return h(Admin, {});
        default: return assertUnreachable(route);
    }
}

export const Root = mobxReactLite.observer(function Root() {
    return h(React.Fragment, {},
        getScreen(),
        h(Snackbar, {}),
    );
});
