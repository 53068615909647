import { routeFromUrl } from '../../../route_util';
import * as listingController from './listing/controller';
import * as detailController from './detail/controller';
import * as changePasswordController from './change_password/controller';

export function onAdminUrlChange(url: string) {
    const route = routeFromUrl(url);

    // Even if we're changing from /admin/listing to another /admin/listing we want to unmount and remount
    listingController.onUnmount();
    detailController.onUnmount();
    changePasswordController.onUnmount();

    if (route.id === '/admin/listing') {
        listingController.onMount(route.params);
    } else if (route.id === '/admin/detail') {
        detailController.onMount(route.params);
    } else if (route.id === '/admin/change-password') {
        changePasswordController.onMount();
    }
}
