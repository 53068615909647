import { DbCommonSensor } from '../db_types';

export const dbDataCommonSensor: { [index: string]: DbCommonSensor } = {
    '1': {
        id: 1,
        name: '1/3"',
        width: 4.8,
        height: 3.6,
    },
    '2': {
        id: 2,
        name: '1/2.5"',
        width: 5.8,
        height: 4.3,
    },
    '3': {
        id: 3,
        name: '1/2"',
        width: 6.4,
        height: 4.8,
    },
    '4': {
        id: 4,
        name: '1/1.8"',
        width: 7.2,
        height: 5.4,
    },
    '5': {
        id: 5,
        name: '2/3"',
        width: 8.8,
        height: 6.6,
    },
    '6': {
        id: 6,
        name: '1"',
        width: 12.8,
        height: 9.6,
    },
    '7': {
        id: 7,
        name: '1.1"',
        width: 14.1,
        height: 10.6,
    },
    '8': {
        id: 8,
        name: '4/3"',
        width: 17.3,
        height: 13,
    },
    '9': {
        id: 9,
        name: 'APS-C',
        width: 22.3,
        height: 16.7,
    },
    '10': {
        id: 10,
        name: '35mm',
        width: 36,
        height: 24,
    },
};
