import { DbAdapter } from '../db_types';

// maxSensorDiagonal comes from the largest sensor listed in the catalog and using the old code to find the
// diagonal for that sensor size. The diagonal in mm's doesn't match the inches name of the sensor for legacy
// reasons. We can also calculate this by looking at the Pixlink camera finder for this sensor size
// and calculating (horizontal_resolution * pixel_size) / 1000 to get the width and height in mm and then using
// the pythagorean theorem to get the diagonal in mm.
//
// 1/3" = 6
// 1/2" = 8
// 2/3" = 11
// 1" = 16
// 1.1" = 17.5
// 4/3" = 22.5
// 32mm = 32
// 35mm = 43

export const dbDataAdapter: { [index: string]: DbAdapter } = {
    // Zoom 6000
    '1': {
        id: 1,
        productFamilyId: 1,
        name: '0.5X Adapter',
        magnification: 0.5,
        warnSensorDiagonalStandard: 0,
        warnSensorDiagonalObjective: 0,
        maxSensorDiagonalStandard: 11,
        maxSensorDiagonalObjective: 0,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '2': {
        id: 2,
        productFamilyId: 1,
        name: '0.67X Adapter',
        magnification: 0.67,
        warnSensorDiagonalStandard: 11.1,
        warnSensorDiagonalObjective: 0,
        maxSensorDiagonalStandard: 16,
        maxSensorDiagonalObjective: 0,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '3': {
        id: 3,
        productFamilyId: 1,
        name: '1X Adapter',
        magnification: 1,
        warnSensorDiagonalStandard: 11.1,
        warnSensorDiagonalObjective: 6.1,
        maxSensorDiagonalStandard: 16,
        maxSensorDiagonalObjective: 11,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '4': {
        id: 4,
        productFamilyId: 1,
        name: '1.33X Adapter',
        magnification: 1.33,
        warnSensorDiagonalStandard: 11.1,
        warnSensorDiagonalObjective: 0,
        maxSensorDiagonalStandard: 16,
        maxSensorDiagonalObjective: 11,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '5': {
        id: 5,
        productFamilyId: 1,
        name: '2X Adapter',
        magnification: 2,
        warnSensorDiagonalStandard: 11.1,
        warnSensorDiagonalObjective: 0,
        maxSensorDiagonalStandard: 16,
        maxSensorDiagonalObjective: 11,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '6': {
        id: 6,
        productFamilyId: 1,
        name: '3.3X Adapter',
        magnification: 3.3,
        warnSensorDiagonalStandard: 11.1,
        warnSensorDiagonalObjective: 0,
        maxSensorDiagonalStandard: 16,
        maxSensorDiagonalObjective: 11,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },

    // 12x Zoom
    '7': {
        id: 7,
        productFamilyId: 2,
        name: '0.5X Adapter',
        magnification: 0.5,
        warnSensorDiagonalStandard: 0,
        warnSensorDiagonalObjective: 0,
        maxSensorDiagonalStandard: 6,
        maxSensorDiagonalObjective: 0,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '8': {
        id: 8,
        productFamilyId: 2,
        name: '0.67X Adapter',
        magnification: 0.67,
        warnSensorDiagonalStandard: 8.1,
        warnSensorDiagonalObjective: 0,
        maxSensorDiagonalStandard: 11,
        maxSensorDiagonalObjective: 0,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '9': {
        id: 9,
        productFamilyId: 2,
        name: '1X Adapter',
        magnification: 1,
        warnSensorDiagonalStandard: 0,
        warnSensorDiagonalObjective: 0.1,
        maxSensorDiagonalStandard: 11,
        maxSensorDiagonalObjective: 11,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '10': {
        id: 10,
        productFamilyId: 2,
        name: '1.33X Adapter',
        magnification: 1.33,
        warnSensorDiagonalStandard: 0,
        warnSensorDiagonalObjective: 0.1,
        maxSensorDiagonalStandard: 11,
        maxSensorDiagonalObjective: 11,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '11': {
        id: 11,
        productFamilyId: 2,
        name: '2X Adapter',
        magnification: 2,
        warnSensorDiagonalStandard: 0,
        warnSensorDiagonalObjective: 8.1,
        maxSensorDiagonalStandard: 11,
        maxSensorDiagonalObjective: 11,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '12': {
        id: 12,
        productFamilyId: 2,
        name: '3.3X Adapter',
        magnification: 3.3,
        warnSensorDiagonalStandard: 0,
        warnSensorDiagonalObjective: 8.1,
        maxSensorDiagonalStandard: 11,
        maxSensorDiagonalObjective: 11,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },

    // Resolv4K
    '13': {
        id: 13,
        productFamilyId: 3,
        name: '0.6875X Adapter',
        magnification: 0.6875,
        warnSensorDiagonalStandard: 0,
        warnSensorDiagonalObjective: 0,
        maxSensorDiagonalStandard: 11,
        maxSensorDiagonalObjective: 11,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: true,
    },
    '14': {
        id: 14,
        productFamilyId: 3,
        name: '1X Adapter',
        magnification: 1,
        warnSensorDiagonalStandard: 16.1,
        warnSensorDiagonalObjective: 16.1,
        maxSensorDiagonalStandard: 17.5,
        maxSensorDiagonalObjective: 17.5,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: true,
    },
    '15': {
        id: 15,
        productFamilyId: 3,
        name: '1.375X Adapter',
        magnification: 1.375,
        warnSensorDiagonalStandard: 0,
        warnSensorDiagonalObjective: 0,
        maxSensorDiagonalStandard: 22.5,
        maxSensorDiagonalObjective: 22.5,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: true,
    },
    '16': {
        id: 16,
        productFamilyId: 3,
        name: '2X Adapter',
        magnification: 2,
        warnSensorDiagonalStandard: 0,
        warnSensorDiagonalObjective: 0,
        maxSensorDiagonalStandard: 32,
        maxSensorDiagonalObjective: 32,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: true,
    },

    // Precise Eye
    '17': {
        id: 17,
        productFamilyId: 4,
        name: '0.5X Adapter',
        magnification: 0.5,
        warnSensorDiagonalStandard: 0,
        warnSensorDiagonalObjective: 0,
        maxSensorDiagonalStandard: 11,
        maxSensorDiagonalObjective: 8,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '18': {
        id: 18,
        productFamilyId: 4,
        name: '0.67X Adapter',
        magnification: 0.67,
        warnSensorDiagonalStandard: 0,
        warnSensorDiagonalObjective: 0,
        maxSensorDiagonalStandard: 11,
        maxSensorDiagonalObjective: 8,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '19': {
        id: 19,
        productFamilyId: 4,
        name: '1X Adapter',
        magnification: 1,
        warnSensorDiagonalStandard: 0,
        warnSensorDiagonalObjective: 0,
        maxSensorDiagonalStandard: 11,
        maxSensorDiagonalObjective: 11,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '20': {
        id: 20,
        productFamilyId: 4,
        name: '1.33X Adapter',
        magnification: 1.33,
        warnSensorDiagonalStandard: 0,
        warnSensorDiagonalObjective: 0,
        maxSensorDiagonalStandard: 11,
        maxSensorDiagonalObjective: 11,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '21': {
        id: 21,
        productFamilyId: 4,
        name: '2X Adapter',
        magnification: 2,
        warnSensorDiagonalStandard: 0,
        warnSensorDiagonalObjective: 0,
        maxSensorDiagonalStandard: 11,
        maxSensorDiagonalObjective: 11,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
};
