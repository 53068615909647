import * as React from 'react';
import * as mobx from 'mobx';
import * as mobxReactLite from 'mobx-react-lite';
import { defaultValueIfUndefined, formatFloat, formatInt, h, scopedClasses } from '../../../../../util';
import { cssScope } from './css_scope';
import { wizardStore } from '../../../../../wizard_store';
import { createRange, displayDiagonalFieldOfViewRange, displayFieldOfViewRange, displayRangeFloat, displayResolveLimit, getCameraSolutionDetails, getMountOptions, getSensorInfo, getSolutionDepthOfField, getSolutionDetails, getSolutionNumericalAperture, getSolutionPartNames, getSolutionWorkingNumericalAperture, getWorkingDepthOfField, negativeOneToInfinity, solutionIsZoom, swapRange } from '../../../../../wizard_util';
import { cameraMakeOptions, lensTypeOptions, wavelengthOptions } from '../../../../../wizard_options';
import { LoadingSpinner } from '../../../../loading_spinner/loading_spinner';
import { store } from '../../../../../store';

type Props = {};

const formUrl = 'https://go.navitar.com/l/540462/2022-01-17/3zstnhr';
const c = scopedClasses(cssScope);

function getHiddenData(): string {
    const cameraLines = getCameraHiddenData();
    const lensLines = getLensHiddenData();
    const routing = lensLines.length > 0 ? 'lens' : 'camera';

    return [
        ...cameraLines,
        ...(cameraLines.length > 0 ? ['', ''] : []),
        ...lensLines,
        '',
        '',
        'Contact Routing: ' + routing,
    ].join('\n');
}

function getCameraHiddenData(): string[] {
    if (wizardStore.selectedCameraId === null) return [];

    return [
        'Camera Specs:',
        '',
        ...getCameraSolutionDetails().map((row) => row.label + ': ' + row.value),
    ];
}

function getLensHiddenData(): string[] {
    if (wizardStore.selectedLensSolution === null) return [];

    const solution = wizardStore.selectedLensSolution;
    const solutionDetails = getSolutionDetails(solution);
    const sensorInfo = getSensorInfo();
    const wavelengthString = defaultValueIfUndefined(wavelengthOptions.get(wizardStore.lensWavelength), '');

    const workingNumericalApertureRange = createRange(
        getSolutionWorkingNumericalAperture(solution, solutionDetails.magnification.working.low, wizardStore.lensWorkingDistance),
        getSolutionWorkingNumericalAperture(solution, solutionDetails.magnification.working.high, wizardStore.lensWorkingDistance),
    );

    const workingDepthOfFieldRange = createRange(
        getWorkingDepthOfField(workingNumericalApertureRange.low, wizardStore.lensWavelength),
        getWorkingDepthOfField(workingNumericalApertureRange.high, wizardStore.lensWavelength),
    );

    let camera: string[] = [];
    if (wizardStore.lensCameraMake === 'pixelink') {
        if (wizardStore.lensCameraId !== null) {
            camera = [
                'Camera: ' + wizardStore.tables.camera[wizardStore.lensCameraId].partNumber,
            ];
        }
    } else if (wizardStore.lensCameraMake !== '') {
        const sensorInfo = getSensorInfo();
        camera = [
            'Camera Horizontal Resolution: ' + formatInt(sensorInfo.resolutionWidth),
            'Camera Vertical Resolution: ' + formatInt(sensorInfo.resolutionHeight),
            'Camera Pixel Size: ' + formatFloat(sensorInfo.pixelSize),
        ];
    }

    let fieldOfView = [];
    if (wizardStore.lensType === 'zoom') {
        fieldOfView = [
            'Min Object Width: ' + formatFloat(wizardStore.lensObjectWidth),
            'Min Object Height: ' + formatFloat(wizardStore.lensObjectHeight),
            'Min Feature Size: ' + formatFloat(wizardStore.lensFeatureSize),
            'Max Object Width: ' + formatFloat(wizardStore.lensMaxObjectWidth),
            'Max Object Height: ' + formatFloat(wizardStore.lensMaxObjectHeight),
            'Max Feature Size: ' + formatFloat(wizardStore.lensMaxFeatureSize),
        ];
    } else {
        fieldOfView = [
            'Object Width: ' + formatFloat(wizardStore.lensObjectWidth),
            'Object Height: ' + formatFloat(wizardStore.lensObjectHeight),
            'Feature Size: ' + formatFloat(wizardStore.lensFeatureSize),
        ];
    }

    const featuresNeeded = [];
    if (wizardStore.lensNeedFeatureCoaxialIllumination) {
        featuresNeeded.push('Coaxial Illumination');
    }
    if (wizardStore.lensNeedFeatureFineFocus) {
        featuresNeeded.push('Fine Focus');
    }
    if (wizardStore.lensNeedFeatureMotorization) {
        featuresNeeded.push('Motorization');
    }
    if (wizardStore.lensNeedFeatureApertureControl) {
        featuresNeeded.push('Aperture Control');
    }
    if (solutionIsZoom(solution) && wizardStore.lensNeedFeatureDetentedZoom) {
        featuresNeeded.push('Detented Zoom');
    }
    if (wizardStore.lensNeedFeatureRingLight) {
        featuresNeeded.push('Ring Light');
    }

    return [
        'Lens User Inputs:',
        '',
        'Camera Make: ' + defaultValueIfUndefined(cameraMakeOptions.get(wizardStore.lensCameraMake), ''),
        ...camera,
        'Lens Type: ' + defaultValueIfUndefined(lensTypeOptions.get(wizardStore.lensType), ''),
        ...fieldOfView,
        'Working Distance: ' + formatFloat(wizardStore.lensWorkingDistance),
        'Wavelength: ' + wavelengthString,
        'Camera Mount: ' + getMountOptions('').get(wizardStore.lensMountId),
        'Features Needed: ' + featuresNeeded.join(', '),
        '',
        '',
        'Lens Specs:',
        '',
        'Magnification: ' + displayRangeFloat(solutionDetails.magnification.spec),
        'H×V Field of View (mm): ' + displayFieldOfViewRange(solutionDetails.fieldOfView.spec),
        'Diagonal Field of View (mm): ' + displayDiagonalFieldOfViewRange(solutionDetails.fieldOfView.spec),
        'Working Distance (mm): ' + displayRangeFloat(solutionDetails.workingDistance.spec),
        'Lens Resolve Limit (μm): ' + displayResolveLimit(solutionDetails.resolveLimit.spec),
        ...(wizardStore.lensCameraMake === 'pixelink' ?
            [
                'H×V Number of Pixels: ' + formatInt(sensorInfo.resolutionWidth) + '×' + formatInt(sensorInfo.resolutionHeight),
            ] : []
        ),
        ...(solution.kind === 'machine_vision' ?
            [
                'F-Number: ' + displayRangeFloat(createRange(solution.lens.fNumberLow, negativeOneToInfinity(solution.lens.fNumberHigh))),
            ] : [
                'Depth of Field (mm): ' + displayRangeFloat(swapRange(getSolutionDepthOfField(solution, wizardStore.lensWavelength, wizardStore.lensWorkingDistance))),
                'Numerical Aperture: ' + displayRangeFloat(getSolutionNumericalAperture(solution, wizardStore.lensWorkingDistance)),
            ]
        ),
        'Wavelength: ' + wavelengthString,
        '',
        '',
        'Lens Working Values:',
        '',
        'Magnification: ' + displayRangeFloat(solutionDetails.magnification.working),
        'H×V Field of View (mm): ' + displayFieldOfViewRange(solutionDetails.fieldOfView.working),
        'Diagonal Field of View (mm): ' + displayDiagonalFieldOfViewRange(solutionDetails.fieldOfView.working),
        'Working Distance (mm): ' + formatFloat(solutionDetails.workingDistance.working),
        'Lens Resolve Limit (μm): ' + displayRangeFloat(swapRange(solutionDetails.resolveLimit.working)),
        'Camera Resolve Limit (μm): ' + displayRangeFloat(swapRange(createRange(
            sensorInfo.pixelSize / solutionDetails.magnification.working.low,
            sensorInfo.pixelSize / solutionDetails.magnification.working.high,
        ))),
        ...(solution.kind !== 'machine_vision' ?
            [
                'Depth of Field (mm): ' + displayRangeFloat(swapRange(workingDepthOfFieldRange)),
                'Numerical Aperture: ' + displayRangeFloat(workingNumericalApertureRange),
            ] : []
        ),
        'Wavelength: ' + wavelengthString,
        '',
        '',
        'Lens Wizard Result:',
        '',
        'Product Family: ' + solution.productFamily.name,
        getSolutionPartNames(solution),
    ];
}

export const PardotForm = mobxReactLite.observer(function PardotForm(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const rootRef = React.useRef<HTMLElement>();
    const iframeRef = React.useRef<HTMLIFrameElement>();

    const state = mobxReactLite.useLocalObservable(() => {
        return {
            iframeLoaded: false,
        };
    });

    const onIframeLoad = React.useCallback(
        () => {
            mobx.runInAction(() => {
                state.iframeLoaded = true;
            });

            if (iframeRef.current !== undefined) {
                iframeRef.current.style.removeProperty('visibility');
            }
        },
        [],
    );

    const onReceiveMessage = React.useCallback(
        (e) => {
            if (iframeRef.current === undefined || iframeRef.current.contentWindow !== e.source) return;

            if (e.data.type === 'submit_success') {
                // handle
            } else if (e.data.type === 'body_height') {
                iframeRef.current.style.height = e.data.height + 'px';
            }
        },
        [],
    );

    React.useEffect(
        () => {
            if (rootRef.current === undefined) return () => {};

            iframeRef.current = document.createElement('iframe');
            iframeRef.current.className = c('iframe');
            iframeRef.current.src = formUrl + '?OW_Hidden_Data=' + encodeURIComponent(getHiddenData()) + '&OW_Hidden_Dealer_ID=' + store.dealerId;
            iframeRef.current.style.width = '100%';
            iframeRef.current.style.height = '500px';
            iframeRef.current.style.visibility = 'hidden';
            iframeRef.current.addEventListener('load', onIframeLoad);
            rootRef.current.appendChild(iframeRef.current);

            window.addEventListener('message', onReceiveMessage);

            return () => {
                if (iframeRef.current !== undefined) {
                    iframeRef.current.removeEventListener('load', onIframeLoad);
                    iframeRef.current = undefined;
                }
                window.removeEventListener('message', onReceiveMessage);
            };
        },
        [],
    );

    return h('div', { className: c('root'), ref: rootRef },
        (!state.iframeLoaded ?
            h('div', { className: c('loader') },
                h(LoadingSpinner, { size: 30, color: '#3B3B3B' }),
            ) : null
        ),
    );
});
