import { DbObjectiveCoreStopDiamCoefficient } from '../db_types';

export const dbDataObjectiveCoreStopDiamCoefficient: { [index: string]: DbObjectiveCoreStopDiamCoefficient } = {
    // 6.5X UltraZoom Core
    '1': {
        id: 1,
        objectiveCoreId: 1,
        coefficient: -0.922,
    },
    '2': {
        id: 2,
        objectiveCoreId: 1,
        coefficient: 0.0644,
    },
    '3': {
        id: 3,
        objectiveCoreId: 1,
        coefficient: -0.000291,
    },
    '4': {
        id: 4,
        objectiveCoreId: 1,
        coefficient: 7.54e-7,
    },
    '5': {
        id: 5,
        objectiveCoreId: 1,
        coefficient: -7.55e-10,
    },

    // 12X UltraZoom Core
    '6': {
        id: 6,
        objectiveCoreId: 2,
        coefficient: 0.102,
    },
    '7': {
        id: 7,
        objectiveCoreId: 2,
        coefficient: 0.0351,
    },
    '8': {
        id: 8,
        objectiveCoreId: 2,
        coefficient: -5.57e-5,
    },
    '9': {
        id: 9,
        objectiveCoreId: 2,
        coefficient: 3.63e-8,
    },

    // Resolv4K Zoom Core
    '10': {
        id: 10,
        objectiveCoreId: 3,
        coefficient: -0.688,
    },
    '11': {
        id: 11,
        objectiveCoreId: 3,
        coefficient: 0.0734,
    },
    '12': {
        id: 12,
        objectiveCoreId: 3,
        coefficient: -1.85e-4,
    },
    '13': {
        id: 13,
        objectiveCoreId: 3,
        coefficient: 1.61e-7,
    },

    // Resolv4K Fixed Core
    '14': {
        id: 14,
        objectiveCoreId: 4,
        coefficient: 10,
    },

    // Precise Eye Ultra Core
    '15': {
        id: 15,
        objectiveCoreId: 5,
        coefficient: 7,
    },

    // 0.5X Modular Tube Lens
    '16': {
        id: 16,
        objectiveCoreId: 6,
        coefficient: 16.675,
    },

    // 1X Modular Tube Lens
    '17': {
        id: 17,
        objectiveCoreId: 7,
        coefficient: 14.605,
    },

    // 1X Modular Tube Lens, Large Aperture
    '18': {
        id: 18,
        objectiveCoreId: 8,
        coefficient: 11.505,
    },

    // 2X Modular Tube Lens
    '19': {
        id: 19,
        objectiveCoreId: 9,
        coefficient: 12.045,
    },
};
