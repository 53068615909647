import * as mobxReactLite from 'mobx-react-lite';
import { h } from '../../util';

type Props = {
    size: number;
};

export const ImagingSystemIcon = mobxReactLite.observer(function ImagingSystemIcon(props: Props) {
    return h('svg',
        {
            width: props.size.toString(),
            height: props.size.toString(),
            viewBox: '0 0 72 72',
            xmlns: 'http://www.w3.org/2000/svg',
        },
        h('path', {
            d: 'M3.99998 4.66666C3.99998 2.45752 5.79085 0.666664 7.99998 0.666664H27.3333C29.5425 0.666664 31.3333 2.45752 31.3333 4.66666V17.3333C31.3333 19.5425 29.5425 21.3333 27.3333 21.3333H19.6667V31C19.6667 32.2377 20.1584 33.4247 21.0335 34.2999C21.9087 35.175 23.0957 35.6667 24.3334 35.6667H47.6667C48.9044 35.6667 50.0913 35.175 50.9665 34.2999C51.8417 33.4247 52.3334 32.2377 52.3334 31V21.3333H44.6667C42.4576 21.3333 40.6667 19.5425 40.6667 17.3333V4.66666C40.6667 2.45752 42.4576 0.666664 44.6667 0.666664H64C66.2092 0.666664 68 2.45752 68 4.66666V17.3333C68 19.5425 66.2092 21.3333 64 21.3333H56.3334V31C56.3334 33.2986 55.4203 35.503 53.7949 37.1283C52.1696 38.7536 49.9652 39.6667 47.6667 39.6667H38V50.6667H45.6666C47.8758 50.6667 49.6666 52.4575 49.6666 54.6667V67.3333C49.6666 69.5425 47.8758 71.3333 45.6666 71.3333H26.3333C24.1241 71.3333 22.3333 69.5425 22.3333 67.3333V54.6667C22.3333 52.4575 24.1241 50.6667 26.3333 50.6667H34V39.6667H24.3334C22.0348 39.6667 19.8304 38.7536 18.2051 37.1283C16.5798 35.503 15.6667 33.2986 15.6667 31V21.3333H7.99998C5.79085 21.3333 3.99998 19.5425 3.99998 17.3333V4.66666ZM27.3333 4.66666L7.99998 4.66666V17.3333H27.3333V4.66666ZM26.3333 54.6667L26.3333 67.3333H45.6666V54.6667H26.3333ZM44.6667 17.3333V4.66666L64 4.66666V17.3333H44.6667Z',
            fillRule: 'evenodd',
        }),
    );
});
