import { DbStandardAttachment } from '../db_types';

// 6x and 12x and precise eye support visible and NIR
// Resolve supports SWIR

export const dbDataStandardAttachment: { [index: string]: DbStandardAttachment } = {
    // Zoom 6000
    '1': {
        id: 1,
        productFamilyId: 1,
        name: '0.25X Attachment',
        magnification: 0.25,
        workingDistance: 300,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '2': {
        id: 2,
        productFamilyId: 1,
        name: '0.5X Attachment',
        magnification: 0.5,
        workingDistance: 175,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '3': {
        id: 3,
        productFamilyId: 1,
        name: '0.75X Attachment',
        magnification: 0.75,
        workingDistance: 113,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '4': {
        id: 4,
        productFamilyId: 1,
        name: '1.5X Attachment',
        magnification: 1.5,
        workingDistance: 51,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '5': {
        id: 5,
        productFamilyId: 1,
        name: '2X Attachment',
        magnification: 2,
        workingDistance: 36,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },

    // 12x Zoom
    '6': {
        id: 6,
        productFamilyId: 2,
        name: '0.25X Attachment',
        magnification: 0.25,
        workingDistance: 341,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '7': {
        id: 7,
        productFamilyId: 2,
        name: '0.5X Attachment',
        magnification: 0.5,
        workingDistance: 165,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '8': {
        id: 8,
        productFamilyId: 2,
        name: '0.75X Attachment',
        magnification: 0.75,
        workingDistance: 108,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '9': {
        id: 9,
        productFamilyId: 2,
        name: '1.5X Attachment',
        magnification: 1.5,
        workingDistance: 50,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '10': {
        id: 10,
        productFamilyId: 2,
        name: '2X Attachment',
        magnification: 2,
        workingDistance: 37,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },

    // Resolv4K
    '11': {
        id: 11,
        productFamilyId: 3,
        name: '0.25X Attachment',
        magnification: 0.25,
        workingDistance: 359.5,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: true,
    },
    '12': {
        id: 12,
        productFamilyId: 3,
        name: '0.5X Attachment',
        magnification: 0.5,
        workingDistance: 173,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: true,
    },
    '13': {
        id: 13,
        productFamilyId: 3,
        name: '0.75X Attachment',
        magnification: 0.75,
        workingDistance: 110,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: true,
    },
    '14': {
        id: 14,
        productFamilyId: 3,
        name: '1X Attachment',
        magnification: 1,
        workingDistance: 90,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: true,
    },
    '15': {
        id: 15,
        productFamilyId: 3,
        name: '1.25X Attachment',
        magnification: 1.25,
        workingDistance: 72,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: true,
    },
    '16': {
        id: 16,
        productFamilyId: 3,
        name: '1.5X Attachment',
        magnification: 1.5,
        workingDistance: 46.5,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: true,
    },
    '17': {
        id: 17,
        productFamilyId: 3,
        name: '2X Attachment',
        magnification: 2,
        workingDistance: 32.3,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: true,
    },

    // Precise Eye
    '18': {
        id: 18,
        productFamilyId: 4,
        name: '0.25X Attachment',
        magnification: 0.25,
        workingDistance: 310,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '19': {
        id: 19,
        productFamilyId: 4,
        name: '0.5X Attachment',
        magnification: 0.5,
        workingDistance: 175,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '20': {
        id: 20,
        productFamilyId: 4,
        name: '0.75X Attachment',
        magnification: 0.75,
        workingDistance: 113,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '21': {
        id: 21,
        productFamilyId: 4,
        name: '1.5X Attachment',
        magnification: 1.5,
        workingDistance: 51,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '22': {
        id: 22,
        productFamilyId: 4,
        name: '2X Attachment',
        magnification: 2,
        workingDistance: 36,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
};
