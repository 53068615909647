import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../util';
import { CheckIcon } from '../icons/check';
import { cssScope } from './css_scope';

type Props = {
    value: boolean;
    label: string;
    onChange: (value: boolean) => void;
};

const c = scopedClasses(cssScope);

export const Checkbox = mobxReactLite.observer(function Checkbox(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const onClick = React.useCallback(
        () => {
            propsRef.current.onChange(!propsRef.current.value);
        },
        [],
    );

    return h('div', { className: c('root') },
        h('div', { className: c('inner'), onClick: onClick  },
            h('div', { className: c('icon') },
                (props.value ?
                    h('div', { className: c('checked-icon') },
                        h(CheckIcon, { size: 20 }),
                     ) :
                    h('div', { className: c('unchecked-icon') })
                ),
            ),
            h('div', { className: c('label') }, props.label),
        ),
    );
});
