import * as React from 'react';
import * as mobx from 'mobx';
import * as mobxReactLite from 'mobx-react-lite';
import { api } from '../../../api';
import { h, scopedClasses, addSnackbarItem, getHomeUrl } from '../../../util';
import { Link } from '../../link';
import { routeToUrl } from '../../../route_util';
import { Form } from '../../form';
import { Button } from '../../button/button';
import { cssScope } from './css_scope';
import { store } from '../../../store';

type Props = {
    token: string;
};

const c = scopedClasses(cssScope);

export const ResetPassword = mobxReactLite.observer(function ResetPassword(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const state = mobxReactLite.useLocalObservable(() => {
        return {
            submitting: false,
            success: false,
            fieldValues: {
                password: '',
                confirmPassword: '',
            },
        };
    });

    const validateForm = React.useCallback(
        (): string[] => {
            const validationErrors = [];
            const requiredFieldErrors = [];

            if (state.fieldValues.password === '') {
                requiredFieldErrors.push('New Password');
            }

            if (state.fieldValues.confirmPassword === '') {
                requiredFieldErrors.push('Confirm Password');
            }

            if (requiredFieldErrors.length !== 0) {
                validationErrors.push('The following fields are required: ' + requiredFieldErrors.join(', '));
            }

            if (state.fieldValues.password !== state.fieldValues.confirmPassword) {
                validationErrors.push('The two password fields must match');
            }

            return validationErrors;
        },
        [],
    );


    const onSubmit = React.useCallback(
        () => {
            if (state.submitting) return;

            const validationErrors = validateForm();
            if (validationErrors.length !== 0) {
                mobx.runInAction(() => {
                    for (const validationError of validationErrors) {
                        addSnackbarItem(store, 'error', validationError);
                    }
                });
                return;
            }

            mobx.runInAction(() => {
                state.submitting = true;
            });

            api('/api/reset-password', store, false, null, {
                    token: propsRef.current.token,
                    password: state.fieldValues.password,
            }).then((response) => {
                if (response.status === 'invalid_token') {
                    addSnackbarItem(store, 'error', 'The token you used is not valid. It may have expired or your user account may have been deleted.');
                    mobx.runInAction(() => {
                        state.submitting = false;
                    });
                } else {
                    mobx.runInAction(() => {
                        state.submitting = false;
                        state.success = true;
                        state.fieldValues.password = '';
                        state.fieldValues.confirmPassword = '';
                    });
                }
            }).catch((err) => {
                console.error(err);

                mobx.runInAction(() => {
                    state.submitting = false;
                });
            });
        },
        [],
    );

    const logoUrl = store.user !== null ? getHomeUrl() : routeToUrl({ id: '/login' });

    return h('div', { className: c('root') },
        h('div', { className: c('content') },
            h(Link, { className: c('logo'), url: logoUrl },
            ),

            (state.success ?
                h(React.Fragment, {},
                    h('div', { className: c('title') }, 'Password Reset'),

                    h('div', { className: c('description') }, 'Your password has been reset.'),

                    h(Link,
                        { className: c('login-link'), url: routeToUrl({ id: '/login' }) },
                        'Login',
                    ),
                ) :
                h(React.Fragment, {},
                    h('div', { className: c('title') }, 'Reset Password'),

                    h('div', { className: c('description') }, 'Choose a new password for your account.'),

                    h(Form, { className: c('form'), onSubmit: onSubmit },
                        h('div', { className: c('fields') },
                            // h('div', { className: c('field') },
                            //     h(FieldLabel, { label: 'New Password' }),
                            //     createTwoWayBinding({
                            //         observable: state.fieldValues,
                            //         property: 'password',
                            //         childType: PasswordInput,
                            //         childProps: {},
                            //     }),
                            // ),

                            // h('div', { className: c('field') },
                            //     h(FieldLabel, { label: 'Confirm Password' }),
                            //     createTwoWayBinding({
                            //         observable: state.fieldValues,
                            //         property: 'confirmPassword',
                            //         childType: PasswordInput,
                            //         childProps: {},
                            //     }),
                            // ),
                        ),

                        h('div', { className: c('button') },
                            h(Button, {
                                title: 'Reset',
                                style: 'blue',
                                loading: state.submitting,
                                onClick: onSubmit,
                            }),
                        ),
                    ),
                )
            ),
        ),
    );
});
