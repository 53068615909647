import { DbTables } from '../db_types';
import { dbDataCommonSensor } from './db_data_common_sensor';
import { dbDataProductFamily } from './db_data_product_family';
import { dbDataStandardCore } from './db_data_standard_core';
import { dbDataObjectiveCore } from './db_data_objective_core';
import { dbDataAdapter } from './db_data_adapter';
import { dbDataStandardAttachment } from './db_data_standard_attachment';
import { dbDataObjectiveAttachment } from './db_data_objective_attachment';
import { dbDataSingleShotCore } from './db_data_single_shot_core';
import { dbDataSingleShotObjective } from './db_data_single_shot_objective';
import { dbDataMachineVisionLens } from './db_data_machine_vision_lens';
import { dbDataZoomXtender } from './db_data_zoom_xtender';
import { dbDataZoomXtenderMagnificationCoefficient } from './db_data_zoom_xtender_magnification_coefficient';
import { dbDataZoomXtenderNaCoefficient } from './db_data_zoom_xtender_na_coefficient';
import { dbDataStandardCoreNaCoefficient } from './db_data_standard_core_na_coefficient';
import { dbDataObjectiveCoreStopDiamCoefficient } from './db_data_objective_core_stop_diam_coefficient';
import { dbDataCameraSensorFormat } from './db_data_camera_sensor_format';
import { dbDataCameraSensor } from './db_data_camera_sensor';
import { dbDataCamera } from './db_data_camera';
import { dbDataMount } from './db_data_mount';
import { dbDataCameraMount } from './db_data_camera_mount';
import { createDbTables } from '../db_create';

export const dbDataTables: DbTables = {
    ...createDbTables(),
    cameraSensorFormat: dbDataCameraSensorFormat,
    cameraSensor: dbDataCameraSensor,
    camera: dbDataCamera,
    mount: dbDataMount,
    cameraMount: dbDataCameraMount,
    commonSensor: dbDataCommonSensor,
    productFamily: dbDataProductFamily,
    adapter: dbDataAdapter,
    standardCore: dbDataStandardCore,
    standardCoreNaCoefficient: dbDataStandardCoreNaCoefficient,
    standardAttachment: dbDataStandardAttachment,
    objectiveCore: dbDataObjectiveCore,
    objectiveCoreStopDiamCoefficient: dbDataObjectiveCoreStopDiamCoefficient,
    objectiveAttachment: dbDataObjectiveAttachment,
    singleShotCore: dbDataSingleShotCore,
    singleShotObjective: dbDataSingleShotObjective,
    machineVisionLens: dbDataMachineVisionLens,
    zoomXtender: dbDataZoomXtender,
    zoomXtenderMagnificationCoefficient: dbDataZoomXtenderMagnificationCoefficient,
    zoomXtenderNaCoefficient: dbDataZoomXtenderNaCoefficient,
};
