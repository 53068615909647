import * as React from 'react';
import * as mobx from 'mobx';
import * as mobxReactLite from 'mobx-react-lite';
import { defaultValueIfUndefined, formatFloat, formatInt, h, scopedClasses } from '../../../../../util';
import { cssScope } from './css_scope';
import { Solution } from '../../../../../wizard_types';
import { createRange, displayDiagonalFieldOfViewRange, displayFieldOfViewRange, displayRangeFloat, displayResolveLimit, getSensorInfo, getSensorSize, getSolutionDepthOfField, getSolutionDetails, getSolutionNumericalAperture, getSolutionPartNames, getSolutionWorkingNumericalAperture, getWorkingDepthOfField, negativeOneToInfinity, showSolutionWarning, swapRange } from '../../../../../wizard_util';
import { wizardStore } from '../../../../../wizard_store';
import { wavelengthOptions } from '../../../../../wizard_options';
import { Tooltip } from '../../../../tooltip/tooltip';
import { WarningIcon } from '../../../../icons/warning';
import { DescriptionIcon } from '../../../../icons/description';

type Props = {
    solution: Solution;
};

const c = scopedClasses(cssScope);

export const SolutionDetails = mobxReactLite.observer(function SolutionDetails(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const state = mobxReactLite.useLocalObservable(() => {
        return {
            mobileTabId: 'spec',
        };
    });

    const onMobileTabClick = React.useCallback(
        (id: string) => {
            mobx.runInAction(() => {
                state.mobileTabId = id;
            });
        },
        [],
    );

    const sensorInfo = getSensorInfo();
    const sensorSize = getSensorSize();
    const solutionDetails = getSolutionDetails(props.solution);

    const workingNumericalApertureRange = createRange(
        getSolutionWorkingNumericalAperture(props.solution, solutionDetails.magnification.working.low, wizardStore.lensWorkingDistance),
        getSolutionWorkingNumericalAperture(props.solution, solutionDetails.magnification.working.high, wizardStore.lensWorkingDistance),
    );

    const workingDepthOfFieldRange = createRange(
        getWorkingDepthOfField(workingNumericalApertureRange.low, wizardStore.lensWavelength),
        getWorkingDepthOfField(workingNumericalApertureRange.high, wizardStore.lensWavelength),
    );

    const wavelengthString = defaultValueIfUndefined(wavelengthOptions.get(wizardStore.lensWavelength), '');

    const renderMobileTab = (id: string, title: string) => h('div',
        {
            className: c('mobile-tab', 'mobile-tab-active-' + (id === state.mobileTabId)),
            onClick: () => onMobileTabClick(id),
        },
        title,
    );

    const camera = wizardStore.selectedCameraId !== null ? wizardStore.tables.camera[wizardStore.selectedCameraId] : null;

    return h('div', { className: c('root') },
        h('div', { className: c('title') },
            'Current Selection',
        ),

        h('div', { className: c('product-page') },
            h('a', { className: c('product-page-link'), href: props.solution.productFamily.productPageUrl, target: '_blank' },
                h('div', { className: c('product-page-link-icon') },
                    h(DescriptionIcon, { size: 24 }),
                ),
                h('div', { className: c('product-page-link-text') },
                    props.solution.productFamily.name + ' Product Page',
                ),
            ),
        ),

        h('div', { className: c('mobile-tabs') },
            renderMobileTab('wizard_input', 'Wizard Input'),
            renderMobileTab('spec', 'Spec'),
            renderMobileTab('working_value', 'Working Value'),
        ),

        h('div', { className: c('rows', 'rows-mobile-active-tab-' + state.mobileTabId) },
            h('div', { className: c('row') },
                h('div', { className: c('cell') }),
                h('div', { className: c('cell', 'bold') },
                    'Wizard Input',
                ),
                h('div', { className: c('cell', 'bold') },
                    'Spec',
                ),
                h('div', { className: c('cell', 'bold') },
                    'Working Value',
                ),
            ),

            h('div', { className: c('row') },
                h('div', { className: c('cell', 'bold') },
                    'Product Family',
                ),
                h('div', { className: c('cell') }),
                h('div', { className: c('cell') },
                    props.solution.productFamily.name,
                ),
            ),

            h('div', { className: c('row') },
                h('div', { className: c('cell', 'bold') },
                    'Parts',
                ),
                h('div', { className: c('cell') }),
                h('div', { className: c('cell') },
                    (camera !== null ? camera.partNumber + ' Camera\n' : ''),
                    getSolutionPartNames(props.solution),
                ),
            ),

            h('div', { className: c('row') },
                h('div', { className: c('cell', 'bold') },
                    'Magnification',
                ),
                h('div', { className: c('cell') },
                    displayRangeFloat(solutionDetails.magnification.input),
                ),
                h('div', { className: c('cell') },
                    displayRangeFloat(solutionDetails.magnification.spec),
                ),
                h('div', { className: c('cell') },
                    displayRangeFloat(solutionDetails.magnification.working),
                ),
            ),

            h('div', { className: c('row') },
                h('div', { className: c('cell', 'bold') },
                    'H×V Field of View (mm)',
                ),
                h('div', { className: c('cell') },
                    displayFieldOfViewRange(solutionDetails.fieldOfView.input),
                ),
                h('div', { className: c('cell') },
                    displayFieldOfViewRange(solutionDetails.fieldOfView.spec),
                ),
                h('div', { className: c('cell', 'tooltip-cell') },
                    displayFieldOfViewRange(solutionDetails.fieldOfView.working),
                    (showSolutionWarning(props.solution, sensorSize.width, sensorSize.height) ?
                        h('div', { className: c('tooltip-cell-warning') },
                            h(Tooltip, {
                                content: 'Contact your Navitar sales representative for further guidance when selecting this option.',
                                icon: h(WarningIcon, { size: 18 }),
                            }),
                        ) : null
                    ),
                ),
            ),

            h('div', { className: c('row') },
                h('div', { className: c('cell', 'bold') },
                    'Diagonal Field of View (mm)',
                ),
                h('div', { className: c('cell') },
                    displayDiagonalFieldOfViewRange(solutionDetails.fieldOfView.input),
                ),
                h('div', { className: c('cell') },
                    displayDiagonalFieldOfViewRange(solutionDetails.fieldOfView.spec),
                ),
                h('div', { className: c('cell') },
                    displayDiagonalFieldOfViewRange(solutionDetails.fieldOfView.working),
                ),
            ),

            h('div', { className: c('row') },
                h('div', { className: c('cell', 'bold') },
                    'Working Distance (mm)',
                ),
                h('div', { className: c('cell') },
                    solutionDetails.workingDistance.input > 0 ? formatFloat(solutionDetails.workingDistance.input) : '',
                ),
                h('div', { className: c('cell') },
                    displayRangeFloat(solutionDetails.workingDistance.spec),
                ),
                h('div', { className: c('cell') },
                    formatFloat(solutionDetails.workingDistance.working),
                ),
            ),

            h('div', { className: c('row') },
                h('div', { className: c('cell', 'bold') },
                    'Lens Resolve Limit (μm)',
                ),
                h('div', { className: c('cell') },
                    displayRangeFloat(swapRange(solutionDetails.resolveLimit.input)),
                ),
                h('div', { className: c('cell') },
                    displayResolveLimit(solutionDetails.resolveLimit.spec),
                ),
                h('div', { className: c('cell') },
                    displayRangeFloat(swapRange(solutionDetails.resolveLimit.working)),
                ),
            ),

            h('div', { className: c('row') },
                h('div', { className: c('cell', 'bold') },
                    'Camera Resolve Limit (μm)',
                ),
                h('div', { className: c('cell') }),
                h('div', { className: c('cell') }),
                h('div', { className: c('cell') },
                    displayRangeFloat(swapRange(createRange(
                        sensorInfo.pixelSize / solutionDetails.magnification.working.low,
                        sensorInfo.pixelSize / solutionDetails.magnification.working.high,
                    ))),
                ),
            ),

            h('div', { className: c('row') },
                h('div', { className: c('cell', 'bold') },
                    'H×V Number of Pixels',
                ),
                (wizardStore.lensCameraMake === 'pixelink' ?
                    h('div', { className: c('cell') }) : null
                ),
                h('div', { className: c('cell') },
                    formatInt(sensorInfo.resolutionWidth),
                    '×',
                    formatInt(sensorInfo.resolutionHeight),
                ),
            ),

            h('div', { className: c('row') },
                h('div', { className: c('cell', 'bold') },
                    'Pixel Size (μm)',
                ),
                h('div', { className: c('cell') },
                    formatFloat(sensorInfo.pixelSize),
                ),
                h('div', { className: c('cell') }),
                h('div', { className: c('cell') }),
            ),

            (props.solution.kind !== 'machine_vision' ?
                h('div', { className: c('row') },
                    h('div', { className: c('cell', 'bold') },
                        'Depth of Field (mm)',
                    ),
                    h('div', { className: c('cell') }),
                    h('div', { className: c('cell') },
                        displayRangeFloat(swapRange(getSolutionDepthOfField(props.solution, wizardStore.lensWavelength, wizardStore.lensWorkingDistance))),
                    ),
                    h('div', { className: c('cell') },
                        displayRangeFloat(swapRange(workingDepthOfFieldRange)),
                    ),
                ) : null
            ),

            (props.solution.kind !== 'machine_vision' ?
                h('div', { className: c('row') },
                    h('div', { className: c('cell', 'bold') },
                        'Numerical Aperture',
                    ),
                    h('div', { className: c('cell') }),
                    h('div', { className: c('cell') },
                        displayRangeFloat(getSolutionNumericalAperture(props.solution, wizardStore.lensWorkingDistance)),
                    ),
                    h('div', { className: c('cell') },
                        displayRangeFloat(workingNumericalApertureRange),
                    ),
                ) : null
            ),

            (props.solution.kind === 'machine_vision' ?
                h('div', { className: c('row') },
                    h('div', { className: c('cell', 'bold') },
                        'F-Number',
                    ),
                    h('div', { className: c('cell') }),
                    h('div', { className: c('cell') },
                        displayRangeFloat(createRange(props.solution.lens.fNumberLow, negativeOneToInfinity(props.solution.lens.fNumberHigh))),
                    ),
                ) : null
            ),

            h('div', { className: c('row') },
                h('div', { className: c('cell', 'bold') },
                    'Wavelength',
                ),
                h('div', { className: c('cell') },
                    wavelengthString,
                ),
                h('div', { className: c('cell') },
                    wavelengthString,
                ),
                h('div', { className: c('cell') },
                    wavelengthString,
                ),
            ),
        ),
    );
});
