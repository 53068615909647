import * as React from 'react';
import * as mobx from 'mobx';
import * as mobxReactLite from 'mobx-react-lite';
import { formatFloat, h, scopedClasses } from '../../../../util';
import { cssScope } from './css_scope';
import { wizardStore } from '../../../../wizard_store';
import { displayFieldOfViewRange, displayRangeFloat, displayResolveLimit, getSolutionDetails, getSolutionPartNames, showSolutionWarning, solutionsEqual } from '../../../../wizard_util';
import { Solution } from '../../../../wizard_types';
import { WarningIcon } from '../../../icons/warning';
import { Tooltip } from '../../../tooltip/tooltip';
import { BackIcon } from '../../../icons/back';
import { navigateTo } from '../../../../wizard_navigator';

type Props = {
    solution: Solution;
    sensorWidth: number;
    sensorHeight: number;
};

const c = scopedClasses(cssScope);

function propsEqual(a: Props, b: Props) {
    return (
        a.sensorWidth === b.sensorWidth
        && a.sensorHeight === b.sensorHeight
        && solutionsEqual(a.solution, b.solution)
    );
}

export const SolutionRow = React.memo(mobxReactLite.observer(function SolutionRow(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const onClick = React.useCallback(
        () => {
            mobx.runInAction(() => {
                wizardStore.selectedLensSolution = propsRef.current.solution;
                navigateTo('lens_detail');
            });
        },
        [],
    );

    return h(mobxReactLite.Observer, {},
        () => {
            const solutionDetails = getSolutionDetails(props.solution);

            return h('div', { className: c('solution-row'), onClick: onClick },
                h('div', { className: c('solution-row-cell', 'solution-column-product-family') },
                    props.solution.productFamily.name,
                ),
                h('div', { className: c('solution-row-cell', 'solution-column-parts') },
                    getSolutionPartNames(props.solution),
                ),
                h('div', { className: c('solution-mobile-label') },
                    'Magnification',
                ),
                h('div', { className: c('solution-row-cell', 'solution-column-magnification') },
                    displayRangeFloat(solutionDetails.magnification.working),
                    (solutionDetails.magnification.spec.low !== solutionDetails.magnification.spec.high ?
                        ' (' + displayRangeFloat(solutionDetails.magnification.spec) + ')' : ''
                    ),
                ),
                h('div', { className: c('solution-mobile-label') },
                    'Field of View',
                ),
                h('div', { className: c('solution-row-cell', 'solution-column-field-of-view') },
                    h('span', {},
                        displayFieldOfViewRange(solutionDetails.fieldOfView.working),
                        h('span', { className: c('solution-mobile-units') }, 'mm'),
                    ),
                    (showSolutionWarning(props.solution, props.sensorWidth, props.sensorHeight) ?
                        h('div', { className: c('solution-warning') },
                            h(Tooltip, {
                                content: 'Contact your Navitar sales representative for further guidance when selecting this option.',
                                icon: h(WarningIcon, { size: 18 }),
                            }),
                        ) : null
                    ),
                ),
                h('div', { className: c('solution-mobile-label') },
                    'Working Distance',
                ),
                h('div', { className: c('solution-row-cell', 'solution-column-working-distance') },
                    formatFloat(solutionDetails.workingDistance.working),
                    h('span', { className: c('solution-mobile-units') }, 'mm'),
                ),
                h('div', { className: c('solution-mobile-label') },
                    'Lens Resolution',
                ),
                h('div', { className: c('solution-row-cell', 'solution-column-resolve-limit') },
                    displayResolveLimit(solutionDetails.resolveLimit.working),
                    h('span', { className: c('solution-mobile-units') }, 'μm'),
                ),
                h('div', { className: c('solution-row-cell', 'solution-column-select') },
                    h('div', { className: c('solution-select-button') },
                        'Select',
                    ),
                ),
                h('div', { className: c('solution-column-arrow') },
                    h(BackIcon, { size: 18 }),
                ),
            );
        },
    );
}), propsEqual);
