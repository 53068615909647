import * as React from 'react';
import * as mobx from 'mobx';
import * as mobxReactLite from 'mobx-react-lite';
import { addSnackbarItem, generateDbId, h, scopedClasses } from '../../../util';
import { cssScope } from './css_scope';
import { Button } from '../../button/button';
import { api } from '../../../api';
import { store } from '../../../store';
import { createDbTableIds, createDbTables } from '../../../db_create';
import { DbTableTypes, DbTables } from '../../../db_types';
import { dbDataTables } from '../../../db_data/db_data_tables';

type Props = {};

const c = scopedClasses(cssScope);

// const tableReferences: { [P in keyof DbTableTypes]?: { [index: string]: keyof DbTableTypes } } = {
//     cameraSensorFormat: {},
//     cameraSensor: {
//         cameraSensorFormatId: 'cameraSensorFormat',
//     },
//     camera: {
//         cameraSensorId: 'cameraSensor',
//     },
//     mount: {},
//     cameraMount: {
//         cameraId: 'camera',
//         mountId: 'mount',
//     },
//     commonSensor: {},
//     productFamily: {},
//     adapter: {
//         productFamilyId: 'productFamily',
//     },
//     standardCore: {
//         productFamilyId: 'productFamily',
//     },
//     standardCoreNaCoefficient: {
//         standardCoreId: 'standardCore',
//     },
//     standardAttachment: {
//         productFamilyId: 'productFamily',
//     },
//     objectiveCore: {
//         productFamilyId: 'productFamily',
//     },
//     objectiveCoreStopDiamCoefficient: {
//         objectiveCoreId: 'objectiveCore',
//     },
//     objectiveAttachment: {},
//     singleShotCore: {
//         productFamilyId: 'productFamily',
//     },
//     singleShotObjective: {
//         productFamilyId: 'productFamily',
//     },
//     machineVisionLens: {
//         productFamilyId: 'productFamily',
//     },
//     zoomXtender: {
//         productFamilyId: 'productFamily',
//     },
//     zoomXtenderMagnificationCoefficient: {
//         zoomXtenderId: 'zoomXtender',
//     },
//     zoomXtenderNaCoefficient: {
//         zoomXtenderId: 'zoomXtender',
//     },
// };

// function orderDeps(refs: { [index: string]: { [index: string]: string } }): string[] {
//     const rs: string[] = [];
//     const refsInRs = ([k, v]: [string, { [index: string]: string }]) => (
//         !rs.includes(k) && Object.values(v).every((x) => rs.includes(x))
//     );
//     while (true) {
//         const entries = Object.entries(refs);
//         if (rs.length === entries.length) return rs;

//         const r = entries.find(refsInRs);
//         if (r === undefined) {
//             return [];
//         } else {
//             rs.push(r[0]);
//         }
//     }
// }

function getNewCoefficientTables(
    oldTables: DbTables,
    newTables: DbTables,
    coefficientTable: keyof DbTableTypes,
    coreTable: keyof DbTableTypes,
    coreIdField: string,
) {
    const mapOldIdToName: { [index: string]: string } = {};
    for (const row of  Object.values(oldTables[coreTable])) {
        mapOldIdToName[row.id] = row.name;
    }

    const mapNameToNewId: { [index: string]: number } = {};
    for (const row of  Object.values(newTables[coreTable])) {
        mapNameToNewId[row.name] = row.id;
    }

    const editTablesList = [];

    for (const row of Object.values(oldTables[coefficientTable]).sort((a, b) => a.id - b.id)) {
        const newId = generateDbId();

        const newRow: { [index: string]: any } = {};
        for (const [k, v] of Object.entries(row)) {
            if (k === 'id') {
                newRow[k] = newId;
            } else if (k === coreIdField) {
                newRow[k] = mapNameToNewId[mapOldIdToName[v as any]];
            } else {
                newRow[k] = v;
            }
        }

        const editTables = createDbTables();
        editTables[coefficientTable][newRow.id] = newRow as any;
        editTablesList.push(editTables);
    }

    return editTablesList;
}

export const ContentImport = mobxReactLite.observer(function ContentImport(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const state = mobxReactLite.useLocalObservable(() => {
        return {
            tables: createDbTables(),
            tablesLoaded: false,
            importing: false,
            done: false,
        };
    });

    const onImport = React.useCallback(
        () => {
            if (!state.tablesLoaded || state.importing || state.done) return;

            mobx.runInAction(() => {
                state.importing = true;
            });

            const editTablesList = [
                ...getNewCoefficientTables(dbDataTables, state.tables, 'objectiveCoreStopDiamCoefficient', 'objectiveCore', 'objectiveCoreId'),
                ...getNewCoefficientTables(dbDataTables, state.tables, 'standardCoreNaCoefficient', 'standardCore', 'standardCoreId'),
                ...getNewCoefficientTables(dbDataTables, state.tables, 'zoomXtenderMagnificationCoefficient', 'zoomXtender', 'zoomXtenderId'),
                ...getNewCoefficientTables(dbDataTables, state.tables, 'zoomXtenderNaCoefficient', 'zoomXtender', 'zoomXtenderId'),
            ];

            console.log(editTablesList);

            let promise: Promise<any> = Promise.resolve();

            for (const editTables of editTablesList) {
                promise = promise.then(() => (
                    api('/api/edit-tables', store, false, null, {
                        permissionProof: createDbTableIds(),
                        editTables: editTables,
                        deleteIds: createDbTableIds(),
                    })
                ));
            }

            promise.then(() => {
                mobx.runInAction(() => {
                    state.importing = false;
                    state.done = true;
                    addSnackbarItem(store, 'success', 'Import successful');
                });
            });
        },
        [],
    );

    // Load query
    React.useEffect(
        () => {
            const abortController = new AbortController();

            api('/api/read/wizard', store, true, abortController.signal, {}).then((response) => {
                mobx.runInAction(() => {
                    state.tables = response.tables;
                    state.tablesLoaded = true;
                });
            });

            return () => {
                abortController.abort();
            };
        },
        [],
    );

    return h('div', { className: c('root') },
        (!state.done ?
            h(Button, {
                title: 'Import Coefficients',
                style: 'blue',
                loading: state.importing,
                onClick: onImport,
            }) : null
        ),
    );
});
