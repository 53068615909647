import { DbCameraSensor } from '../db_types';

// Autogenerated from spreadsheet import
export const dbDataCameraSensor: { [index: string]: DbCameraSensor } = {
    "7": {
        "frameRate": 141,
        "pixelSize": 4.5,
        "resolutionHeight": 1464,
        "name": "Sony IMX420",
        "bitDepth": 12,
        "id": 7,
        "cameraSensorFormatId": 7,
        "resolutionWidth": 1936
    },
    "20": {
        "frameRate": 14,
        "pixelSize": 2.2,
        "resolutionHeight": 1944,
        "name": "Onsemi Mono MT9P031",
        "bitDepth": 12,
        "id": 20,
        "cameraSensorFormatId": 5,
        "resolutionWidth": 2592
    },
    "27": {
        "frameRate": 43,
        "pixelSize": 2.74,
        "resolutionHeight": 4600,
        "name": "Sony IMX530",
        "bitDepth": 12,
        "id": 27,
        "cameraSensorFormatId": 9,
        "resolutionWidth": 5328
    },
    "1": {
        "frameRate": 23,
        "pixelSize": 3.45,
        "resolutionHeight": 3000,
        "name": "Sony IMX304",
        "bitDepth": 12,
        "id": 1,
        "cameraSensorFormatId": 7,
        "resolutionWidth": 4096
    },
    "24": {
        "frameRate": 27,
        "pixelSize": 4.5,
        "resolutionHeight": 2200,
        "name": "Sony IMX428",
        "bitDepth": 12,
        "id": 24,
        "cameraSensorFormatId": 7,
        "resolutionWidth": 3208
    },
    "4": {
        "frameRate": 14,
        "pixelSize": 1.25,
        "resolutionHeight": 3680,
        "name": "Onsemi AR1820",
        "bitDepth": 12,
        "id": 4,
        "cameraSensorFormatId": 4,
        "resolutionWidth": 4912
    },
    "15": {
        "frameRate": 167,
        "pixelSize": 5.86,
        "resolutionHeight": 1200,
        "name": "Sony IMX174",
        "bitDepth": 12,
        "id": 15,
        "cameraSensorFormatId": 8,
        "resolutionWidth": 1920
    },
    "21": {
        "frameRate": 21,
        "pixelSize": 2.74,
        "resolutionHeight": 4512,
        "name": "Sony IMX541",
        "bitDepth": 12,
        "id": 21,
        "cameraSensorFormatId": 7,
        "resolutionWidth": 4512
    },
    "13": {
        "frameRate": 87,
        "pixelSize": 4.8,
        "resolutionHeight": 1200,
        "name": "Onsemi Vita 2000",
        "bitDepth": 10,
        "id": 13,
        "cameraSensorFormatId": 3,
        "resolutionWidth": 1920
    },
    "22": {
        "frameRate": 17,
        "pixelSize": 2.74,
        "resolutionHeight": 4608,
        "name": "Sony IMX540",
        "bitDepth": 12,
        "id": 22,
        "cameraSensorFormatId": 9,
        "resolutionWidth": 5328
    },
    "6": {
        "frameRate": 170,
        "pixelSize": 5.5,
        "resolutionHeight": 1088,
        "name": "CMOSIS CMV2000",
        "bitDepth": 10,
        "id": 6,
        "cameraSensorFormatId": 3,
        "resolutionWidth": 2048
    },
    "25": {
        "frameRate": 32,
        "pixelSize": 3.5,
        "resolutionHeight": 2160,
        "name": "Sony IMX267",
        "bitDepth": 12,
        "id": 25,
        "cameraSensorFormatId": 6,
        "resolutionWidth": 4096
    },
    "17": {
        "frameRate": 80,
        "pixelSize": 3.45,
        "resolutionHeight": 2048,
        "name": "Sony IMX250MZR",
        "bitDepth": 12,
        "id": 17,
        "cameraSensorFormatId": 3,
        "resolutionWidth": 2448
    },
    "3": {
        "frameRate": 13,
        "pixelSize": 1.4,
        "resolutionHeight": 3288,
        "name": "Onsemi MT9F002",
        "bitDepth": 12,
        "id": 3,
        "cameraSensorFormatId": 4,
        "resolutionWidth": 4608
    },
    "12": {
        "frameRate": 212,
        "pixelSize": 4.8,
        "resolutionHeight": 1024,
        "name": "Onsemi Python 1300",
        "bitDepth": 10,
        "id": 12,
        "cameraSensorFormatId": 1,
        "resolutionWidth": 1280
    },
    "2": {
        "frameRate": 33,
        "pixelSize": 3.45,
        "resolutionHeight": 3000,
        "name": "Sony IMX253",
        "bitDepth": 12,
        "id": 2,
        "cameraSensorFormatId": 7,
        "resolutionWidth": 4096
    },
    "23": {
        "frameRate": 36,
        "pixelSize": 3.45,
        "resolutionHeight": 2048,
        "name": "Sony IMX264",
        "bitDepth": 12,
        "id": 23,
        "cameraSensorFormatId": 3,
        "resolutionWidth": 2448
    },
    "19": {
        "frameRate": 14,
        "pixelSize": 2.2,
        "resolutionHeight": 1944,
        "name": "Onsemi Color MT9P006",
        "bitDepth": 12,
        "id": 19,
        "cameraSensorFormatId": 5,
        "resolutionWidth": 2592
    },
    "11": {
        "frameRate": 45,
        "pixelSize": 3.45,
        "resolutionHeight": 2160,
        "name": "Sony IMX255",
        "bitDepth": 12,
        "id": 11,
        "cameraSensorFormatId": 6,
        "resolutionWidth": 4096
    },
    "9": {
        "frameRate": 75,
        "pixelSize": 4.8,
        "resolutionHeight": 2048,
        "name": "Onsemi Vita 5000",
        "bitDepth": 10,
        "id": 9,
        "cameraSensorFormatId": 6,
        "resolutionWidth": 2592
    },
    "5": {
        "frameRate": 151,
        "pixelSize": 4.8,
        "resolutionHeight": 1024,
        "name": "Onsemi Vita 1300",
        "bitDepth": 10,
        "id": 5,
        "cameraSensorFormatId": 1,
        "resolutionWidth": 1280
    },
    "14": {
        "frameRate": 5,
        "pixelSize": 3.5,
        "resolutionHeight": 3000,
        "name": "Onsemi IBIS4",
        "bitDepth": 10,
        "id": 14,
        "cameraSensorFormatId": 6,
        "resolutionWidth": 2208
    },
    "26": {
        "frameRate": 52,
        "pixelSize": 2.74,
        "resolutionHeight": 4512,
        "name": "Sony IMX531",
        "bitDepth": 12,
        "id": 26,
        "cameraSensorFormatId": 7,
        "resolutionWidth": 4512
    },
    "16": {
        "frameRate": 141,
        "pixelSize": 4.5,
        "resolutionHeight": 1464,
        "name": "Sony IMX421",
        "bitDepth": 12,
        "id": 16,
        "cameraSensorFormatId": 3,
        "resolutionWidth": 1936
    },
    "10": {
        "frameRate": 80,
        "pixelSize": 3.45,
        "resolutionHeight": 2048,
        "name": "Sony IMX250",
        "bitDepth": 12,
        "id": 10,
        "cameraSensorFormatId": 3,
        "resolutionWidth": 2448
    },
    "18": {
        "frameRate": 20,
        "pixelSize": 2.4,
        "resolutionHeight": 3648,
        "name": "Sony IMX183",
        "bitDepth": 12,
        "id": 18,
        "cameraSensorFormatId": 6,
        "resolutionWidth": 5472
    },
    "8": {
        "frameRate": 90,
        "pixelSize": 5.5,
        "resolutionHeight": 2048,
        "name": "CMOSIS CMV4000",
        "bitDepth": 10,
        "id": 8,
        "cameraSensorFormatId": 6,
        "resolutionWidth": 2048
    }
};
