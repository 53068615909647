import { assertNotNull } from './util';

type Dimensions = {
    height: number;
    width: number;
};

const resizer = (window as any).pica();

export function processImage(blob: Blob, resizeDimensions: Dimensions | null): Promise<Blob> {
    if (['image/jpeg', 'image/png'].indexOf(blob.type) < 0) return Promise.resolve(blob);

    return loadCanvas(blob).then((sourceCanvas) => {
        const alpha = blob.type === 'image/png' && canvasHasTransparency(sourceCanvas);

        const needsResize = (
            resizeDimensions !== null &&
            (sourceCanvas.width > resizeDimensions.width || sourceCanvas.height > resizeDimensions.height)
        );
        const needsTypeConversion = blob.type === 'image/png' && !alpha;

        if (!(needsResize || needsTypeConversion)) return Promise.resolve(blob);

        return Promise.resolve(sourceCanvas).then((canvas) => {
            if (!needsResize) return Promise.resolve(canvas);
            return resize(canvas, assertNotNull(resizeDimensions), alpha);
        }).then(canvas => {
            return resizer.toBlob(canvas, alpha ? 'image/png' : 'image/jpeg', 0.92);
        });
    });
}

function loadCanvas(blob: Blob): Promise<HTMLCanvasElement> {
    return loadImage(blob).then(img => {
        const canvas = document.createElement('canvas');
        const ctx = assertNotNull(canvas.getContext('2d'));
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        return canvas;
    });
}

function loadImage(blob: Blob): Promise<HTMLImageElement> {
    const blobUrl = URL.createObjectURL(blob);

    return new Promise<HTMLImageElement>(resolve => {
        const img = document.createElement('img');
        img.onload = () => {
            URL.revokeObjectURL(blobUrl);
            resolve(img);
        };
        img.src = blobUrl;
    });
}

function resize(from: HTMLCanvasElement, containDimensions: Dimensions, alpha: boolean): Promise<HTMLCanvasElement> {
    const toDimensions = calculateResizeDimensions({ width: from.width, height: from.height }, containDimensions);

    const to = document.createElement('canvas');
    to.width = toDimensions.width;
    to.height = toDimensions.height;

    return resizer.resize(from, to, { alpha: alpha });
}

function calculateResizeDimensions(imgDimensions: Dimensions, containDimensions: Dimensions): Dimensions {
    const aspectRatio = imgDimensions.width / imgDimensions.height;
    const containAspectRatio = containDimensions.width / containDimensions.height;

    if (aspectRatio > containAspectRatio) {
        // Width limitted
        return limitWidth(containDimensions.width, aspectRatio);
    } else {
        // Height limitted
        return limitHeight(containDimensions.height, aspectRatio);
    }
}

function limitWidth(width: number, aspectRatio: number): Dimensions {
    return {
        width: width,
        height: Math.round(width / aspectRatio),
    };
}

function limitHeight(height: number, aspectRatio: number): Dimensions {
    return {
        width: Math.round(height * aspectRatio),
        height: height,
    };
}

function canvasHasTransparency(canvas: HTMLCanvasElement): boolean {
    const imageData = assertNotNull(canvas.getContext('2d')).getImageData(0, 0, canvas.width, canvas.height).data;
    const imageDataLength = imageData.length;

    for (let i = 3; i < imageDataLength; i += 4) {
        if (imageData[i] !== 255) {
            return true;
        }
    }

    return false;
}
