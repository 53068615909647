import { DbProductFamily } from '../db_types';

export const dbDataProductFamily: { [index: string]: DbProductFamily } = {
    '1': {
        id: 1,
        name: 'Zoom 6000',
        order: 1,
        productPageUrl: 'https://navitar.com/products/imaging-optics/high-magnification-imaging/zoom-6000/',
    },
    '2': {
        id: 2,
        name: '12x Zoom',
        order: 2,
        productPageUrl: 'https://navitar.com/products/imaging-optics/high-magnification-imaging/12x-zoom/',
    },
    '3': {
        id: 3,
        name: 'Resolv4K',
        order: 3,
        productPageUrl: 'https://navitar.com/products/imaging-optics/resolv4k-zoom-system/',
    },
    '4': {
        id: 4,
        name: 'Precise Eye',
        order: 4,
        productPageUrl: 'https://navitar.com/products/imaging-optics/high-magnification-imaging/precise-eye-fixed/',
    },
    '5': {
        id: 5,
        name: 'SingleShot',
        order: 5,
        productPageUrl: 'https://navitar.com/products/imaging-optics/super-wide-angle-4k-imaging-lens-system-2/',
    },
    '6': {
        id: 6,
        name: 'Modular Tube Lens',
        order: 6,
        productPageUrl: 'https://navitar.com/products/imaging-optics/mtl-imaging-system/',
    },
    '7': {
        id: 7,
        name: 'Machine Vision',
        order: 7,
        productPageUrl: 'https://navitar.com/products/imaging-optics/low-magnification-video/',
    },
};
