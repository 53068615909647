import * as mobx from 'mobx';
import { store } from '../../../../store';
import { addSnackbarItem } from '../../../../util';
import { api } from '../../../../api';

export const state = mobx.observable({
    email: '',
    newPassword: '',
    newPasswordConfirm: '',
    submitting: false,
});

export function onMount() {
    mobx.runInAction(() => {
        state.email = '';
        state.newPassword = '';
        state.newPasswordConfirm = '';
        state.submitting = false;
    });
}

export function onUnmount() {
}

export function validateForm(): string[] {
    const validationErrors: string[] = [];
    const requiredFieldErrors: string[] = [];

    if (state.email.trim() === '') {
        requiredFieldErrors.push('Email');
    }

    if (state.newPassword === '') {
        requiredFieldErrors.push('New Password');
    }

    if (state.newPassword !== state.newPasswordConfirm) {
        validationErrors.push('"New Password" and "Confirm New Password" do not match');
    }

    if (requiredFieldErrors.length !== 0) {
        validationErrors.push('The following fields are required: ' + requiredFieldErrors.join(', '));
    }

    return validationErrors;
}

export function onSubmit() {
    if (state.submitting) return;

    const validationErrors = validateForm();
    if (validationErrors.length !== 0) {
        mobx.runInAction(() => {
            for (const validationError of validationErrors) {
                addSnackbarItem(store, 'error', validationError);
            }
        });
        return;
    }

    mobx.runInAction(() => {
        state.submitting = true;
    });

    api('/api/change-password', store, false, null, {
        email: state.email,
        newPassword: state.newPassword,
    }).then((response) => {
        if (response.status === 'success') {
            mobx.runInAction(() => {
                addSnackbarItem(store, 'success', `Password changed for user ${state.email}`);
                state.email = '';
                state.newPassword = '';
                state.newPasswordConfirm = '';
                state.submitting = false;
            });
        } else if (response.status === 'invalid_email') {
            mobx.runInAction(() => {
                state.submitting = false;
                addSnackbarItem(store, 'error', `No user exists with email ${state.email}`);
            });
        }
    });
}
