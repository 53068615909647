import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../util';
import { cssScope } from './css_scope';

type Props = {
    title: string;
    value: any;
    borderBottom: boolean;
    onClick: (value: any) => void;
};

const c = scopedClasses(cssScope);

export const Option = mobxReactLite.observer(function Option(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const onClick = React.useCallback(
        () => {
            propsRef.current.onClick(propsRef.current.value);
        },
        [],
    );

    return h('div', { className: c('option', 'border-bottom-' + props.borderBottom), onClick: onClick },
        props.title,
    );
});
