import * as React from 'react';
import * as mobx from 'mobx';
import * as mobxReactLite from 'mobx-react-lite';
import { h, pixelsToMegapixels, scopedClasses } from '../../../util';
import { cssScope } from './css_scope';
import { wizardStore } from '../../../wizard_store';
import { LensListing } from './lens_listing/lens_listing';
import { LensDetail } from './lens_detail/lens_detail';
import { Contact } from './contact/contact';
import { api } from '../../../api';
import { store } from '../../../store';
import { LoadingSpinner } from '../../loading_spinner/loading_spinner';
import { WizardHeader } from '../../wizard_header/wizard_header';
import { WizardFooter } from '../../wizard_footer/wizard_footer';
import { Home } from './home/home';
import { CameraListing } from './camera_listing/camera_listing';
import { CameraDetail } from './camera_detail/camera_detail';
import { DbTables } from '../../../db_types';

type Props = {};

const c = scopedClasses(cssScope);

function getResolutionRangeSliderMax(tables: DbTables): number {
    let maxResolution = 0;
    for (const sensor of Object.values(tables.cameraSensor)) {
        const resolution = sensor.resolutionWidth * sensor.resolutionHeight;
        if (resolution > maxResolution) {
            maxResolution = resolution;
        }
    }
    return Math.ceil(pixelsToMegapixels(maxResolution));
}

export const Wizard = mobxReactLite.observer(function Wizard(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const state = mobxReactLite.useLocalObservable(() => {
        return {
            loaded: false,
        };
    });

    // Load query
    React.useEffect(
        () => {
            const abortController = new AbortController();

            api('/api/read/wizard', store, true, abortController.signal, {}).then((response) => {
                mobx.runInAction(() => {
                    wizardStore.tables = response.tables;

                    const resolutionRangeSliderMax = getResolutionRangeSliderMax(wizardStore.tables);
                    wizardStore.cameraResolutionMax = resolutionRangeSliderMax;
                    wizardStore.cameraResolutionRangeSliderMax = resolutionRangeSliderMax;

                    state.loaded = true;
                });
            });

            return () => {
                abortController.abort();
            };
        },
        [],
    );

    // Scroll restoration
    React.useEffect(
        () => {
            history.scrollRestoration = 'manual';
            return () => {
                history.scrollRestoration = 'auto';
            };
        },
        [],
    );

    if (!state.loaded) {
        return h('div', { className: c('root') },
            h('div', { className: c('loading') },
                h(LoadingSpinner, { size: 40, color: '#3B3B3B' }),
            ),
        );
    }

    let child = null;
    if (wizardStore.wizardRoute === 'home') {
        child = h(Home, {});
    } else if (wizardStore.wizardRoute === 'camera_listing') {
        child = h(CameraListing, {});
    } else if (wizardStore.wizardRoute === 'camera_detail') {
        child = h(CameraDetail, {});
    } else if (wizardStore.wizardRoute === 'lens_listing') {
        child = h(LensListing, {});
    } else if (wizardStore.wizardRoute === 'lens_detail') {
        child = h(LensDetail, {});
    } else if (wizardStore.wizardRoute === 'contact') {
        child = h(Contact, {});
    }

    return h('div', { className: c('root') },
        h(WizardHeader, {}),
        child,
        store.dealerId === '' ? h(WizardFooter, {}) : null,
    );
});
