import * as mobxReactLite from 'mobx-react-lite';
import { h } from '../../util';

type Props = {
    size: number;
};

export const MenuIcon = mobxReactLite.observer(function MenuIcon(props: Props) {
    return h('svg',
        {
            width: props.size.toString(),
            height: props.size.toString(),
            viewBox: '0 0 48 48',
            xmlns: 'http://www.w3.org/2000/svg',
        },
        h('path', {
            d: 'M6 36v-3h36v3Zm0-10.5v-3h36v3ZM6 15v-3h36v3Z',
        }),
    );
});
