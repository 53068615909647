import { DbTables } from './db_types';
import { createDbTables } from './db_create';

export function sortById<A extends { id: number }>(rows: A[]): A[] {
    return rows.sort((a, b) => {
        if (a.id < 0) {
            if (b.id < 0) {
                return Math.abs(a.id) - Math.abs(b.id);
            } else {
                return 1;
            }
        } else {
            if (b.id < 0) {
                return -1;
            } else {
                return a.id - b.id;
            }
        }
    });
}

export function dbMergeTables(tablesListTyped: DbTables[]): DbTables {
    const tablesList: any = tablesListTyped;
    const mergedTables: any = createDbTables();

    for (const tables of tablesList) {
        for (const tableName of Object.keys(tables)) {
            const table = tables[tableName];
            for (const id of Object.keys(table)) {
                mergedTables[tableName][id] = table[id];
            }
        }
    }

    return mergedTables;
}

export function indexByForeignKey<T>(ts: T[], foreignKey: keyof T): { [index: string]: T[] } {
    const index: { [index: string]: T[] } = {};
    for (const t of ts) {
        const foreignKeyValue = (t as any)[foreignKey] as number;
        if (index[foreignKeyValue] === undefined) {
            index[foreignKeyValue] = [];
        }
        index[foreignKeyValue].push(t);
    }
    return index;
}
