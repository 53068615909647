import { DbObjectiveAttachment } from '../db_types';

export const dbDataObjectiveAttachment: { [index: string]: DbObjectiveAttachment } = {
    '1': {
        id: 1,
        name: 'Navitar 2X HR Objective, 0.08 NA',
        focalLength: 100,
        stopSemiDiameter: 8,
        workingDistance: 39,
        wavelengthVisible: true,
        wavelengthNearInfrared: false,
        wavelengthShortWaveInfrared: false,
    },
    '2': {
        id: 2,
        name: 'Navitar 4X HR Objective, 0.2 NA',
        focalLength: 50,
        stopSemiDiameter: 9.763435,
        workingDistance: 20,
        wavelengthVisible: true,
        wavelengthNearInfrared: false,
        wavelengthShortWaveInfrared: false,
    },
    '3': {
        id: 3,
        name: 'Navitar 6X HR Objective, 0.3 NA',
        focalLength: 33.333333333333333,
        stopSemiDiameter: 9.576583333,
        workingDistance: 25,
        wavelengthVisible: true,
        wavelengthNearInfrared: false,
        wavelengthShortWaveInfrared: false,
    },
    '4': {
        id: 4,
        name: 'Navitar 10X HR Objective, 0.4 NA',
        focalLength: 20,
        stopSemiDiameter: 7.41696,
        workingDistance: 10,
        wavelengthVisible: true,
        wavelengthNearInfrared: false,
        wavelengthShortWaveInfrared: false,
    },
    '5': {
        id: 5,
        name: 'Navitar 20X HR Objective, 0.53 NA',
        focalLength: 10,
        stopSemiDiameter: 5.296390335,
        workingDistance: 10,
        wavelengthVisible: true,
        wavelengthNearInfrared: false,
        wavelengthShortWaveInfrared: false,
    },
    '6': {
        id: 6,
        name: 'Mitutoyo 1X Objective, 0.025 NA',
        focalLength: 200,
        stopSemiDiameter: 5,
        workingDistance: 11,
        wavelengthVisible: true,
        wavelengthNearInfrared: false,
        wavelengthShortWaveInfrared: false,
    },
    '7': {
        id: 7,
        name: 'Mitutoyo 2X Objective, 0.055 NA',
        focalLength: 100,
        stopSemiDiameter: 11,
        workingDistance: 34,
        wavelengthVisible: true,
        wavelengthNearInfrared: false,
        wavelengthShortWaveInfrared: false,
    },
    '8': {
        id: 8,
        name: 'Mitutoyo 5X Objective, 0.14 NA',
        focalLength: 40,
        stopSemiDiameter: 5.6,
        workingDistance: 34,
        wavelengthVisible: true,
        wavelengthNearInfrared: false,
        wavelengthShortWaveInfrared: false,
    },
    '9': {
        id: 9,
        name: 'Mitutoyo 5X Objective, 0.14 NA',
        focalLength: 40,
        stopSemiDiameter: 5.6,
        workingDistance: 37.5,
        wavelengthVisible: false,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '10': {
        id: 10,
        name: 'Mitutoyo 10X Objective, 0.28 NA',
        focalLength: 20,
        stopSemiDiameter: 5.6,
        workingDistance: 33,
        wavelengthVisible: true,
        wavelengthNearInfrared: false,
        wavelengthShortWaveInfrared: false,
    },
    '11': {
        id: 11,
        name: 'Mitutoyo 10X Objective, 0.28 NA',
        focalLength: 20,
        stopSemiDiameter: 5.6,
        workingDistance: 30.5,
        wavelengthVisible: false,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '12': {
        id: 12,
        name: 'Mitutoyo 20X Objective, 0.42 NA',
        focalLength: 10,
        stopSemiDiameter: 4,
        workingDistance: 20,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '13': {
        id: 13,
        name: 'Mitutoyo 50X Objective, 0.55 NA',
        focalLength: 4,
        stopSemiDiameter: 1.68,
        workingDistance: 13,
        wavelengthVisible: true,
        wavelengthNearInfrared: false,
        wavelengthShortWaveInfrared: false,
    },
    '14': {
        id: 14,
        name: 'Mitutoyo 50X Objective, 0.55 NA',
        focalLength: 4,
        stopSemiDiameter: 1.68,
        workingDistance: 17,
        wavelengthVisible: false,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
};
