import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../util';
import { Button } from '../button/button';
import { Dialog } from '../dialog/dialog';
import { cssScope } from './css_scope';

type Props = {
    title: string;
    description: string;
    submitLabel: string;
    loading: boolean;
    onClose: () => void;
    onSubmit: () => void;
};

export const c = scopedClasses(cssScope);

export const ConfirmationDialog = mobxReactLite.observer(function ConfirmationDialog(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    React.useEffect(
        () => {
            const onKeyDown = (e: KeyboardEvent) => {
                if (e.key === 'Escape') {
                    propsRef.current.onClose();
                }
            };

            window.addEventListener('keydown', onKeyDown);

            return () => {
                window.removeEventListener('keydown', onKeyDown);
            }
        },
        [],
    );

    return h('div', { className: c('root') },
        h(Dialog, { maxWidth: 500, onClose: props.onClose },
            h('div', { className: c('content') },
                h('div', { className: c('title') }, props.title),
                h('div', { className: c('description') }, props.description),
                h('div', { className: c('buttons') },
                    h('div', { className: c('button') },
                        h(Button, {
                            title: 'Cancel',
                            style: 'transparent',
                            loading: false,
                            onClick: props.onClose,
                        }),
                    ),

                    h('div', { className: c('button') },
                        h(Button, {
                            title: props.submitLabel,
                            style: 'blue',
                            loading: props.loading,
                            onClick: props.onSubmit,
                        }),
                    ),
                ),
            ),
        ),
    );
});
