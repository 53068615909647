import * as React from 'react';
import * as luxon from 'luxon';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../util';
import { cssScope } from './css_scope';
import { store } from '../../store';
import { FooterPhoneIcon } from '../icons/footer_phone';
import { FooterEmailIcon } from '../icons/footer_email';

type Props = {};

const c = scopedClasses(cssScope);

export const WizardFooter = mobxReactLite.observer(function WizardFooter(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    return h('div', { className: c('root') },
        h('div', { className: c('top') },
            h('div', { className: c('top-cards') },
                h('a', { className: c('top-card'), href: 'tel:+15853594000', target: '_blank' },
                    h('div', { className: c('top-card-icon') },
                        h(FooterPhoneIcon, { size: 48 }),
                    ),
                    h('div', { className: c('top-card-text') },
                        h('div', { className: c('top-card-text-1') }, 'Call'),
                        h('div', { className: c('top-card-text-2') }, '1.585.359.4000'),
                    ),
                ),

                h('a', { className: c('top-card'), href: 'https://go.navitar.com/l/540462/2022-03-02/3zvmlxt', target: '_blank' },
                    h('div', { className: c('top-card-icon') },
                        h(FooterEmailIcon, { size: 40 }),
                    ),
                    h('div', { className: c('top-card-text') },
                        h('div', { className: c('top-card-text-1') }, 'Email'),
                        h('div', { className: c('top-card-text-2') }, 'Contact us'),
                    ),
                ),
            ),
        ),
        h('div', { className: c('bottom') },
            h('div', { className: c('bottom-logo') },
                h('img', { src: store.imageNavitarLogo, width: '126', height: '38' }),
            ),
            h('div', { className: c('bottom-links') },
                h('a', { className: c('bottom-link'), href: 'https://navitar.com/company/about/' },
                    'Company',
                ),
                h('a', { className: c('bottom-link'), href: 'https://navitar.com/products/' },
                    'Products',
                ),
                h('a', { className: c('bottom-link'), href: 'https://navitar.com/contact/' },
                    'Contact',
                ),
            ),
            h('div', { className: c('bottom-copyright') },
                '© ' + luxon.DateTime.local().year + ' Navitar, Inc. All rights reserved. ',
            ),
            h('a', { className: c('bottom-copyright'), href: 'https://www.ametek.com/privacy-policy/english', target: '_blank', style: { marginLeft: '5px' } },
                'Privacy Policy',
            ),
        ),
    );
});
