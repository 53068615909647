import * as React from 'react';
import * as mobx from 'mobx';
import * as mobxReactLite from 'mobx-react-lite';
import { assertUnreachable, defaultValueIfNaN, h, pixelsToMegapixels, scopedClasses } from '../../../../util';
import { cssScope } from './css_scope';
import { resetWizardCameraForm, wizardStore } from '../../../../wizard_store';
import { WizardTitleBar } from '../../../wizard_title_bar/wizard_title_bar';
import { CameraIcon } from '../../../icons/camera';
import { createTwoWayBinding } from '../../../two_way_binding';
import { NumberInput } from '../../../number_input';
import { RangeSlider } from '../../../range_slider/range_slider';
import { Select } from '../../../select/select';
import { dbOptions } from '../../../../db_options';
import { getCameraSensorFormatOptions, getMountOptions } from '../../../../wizard_util';
import { SolutionRow } from './solution_row';
import { FieldLabel } from '../../../field_label/field_label';
import { CameraSort } from '../../../../wizard_types';
import { WizardPagination } from '../../../wizard_pagination/wizard_pagination';
import { CameraColorMono, CameraHousing, CameraInterface, CameraShutterType, DbCamera } from '../../../../db_types';
import { ResetButton } from '../../../reset_button/reset_button';
import { StickyResults } from './sticky_results';

type Props = {};

const sortOptions = new Map<CameraSort, string>([
    ['resolution_ascending', 'Resolution (low first)'],
    ['resolution_descending', 'Resolution (high first)'],
    ['sensor_size_ascending', 'Sensor Size (low first)'],
    ['sensor_size_descending', 'Sensor Size (high first)'],
    ['pixel_size_ascending', 'Pixel Size (low first)'],
    ['pixel_size_descending', 'Pixel Size (high first)'],
    ['frame_rate_ascending', 'Frame Rate (low first)'],
    ['frame_rate_descending', 'Frame Rate (high first)'],
]);

const camerasPerPage = 100;

const c = scopedClasses(cssScope);

function getCameraMountSupportIndex(mountId: number): { [index: string]: true } {
    const result: { [index: string]: true } = {};
    for (const cameraMount of Object.values(wizardStore.tables.cameraMount)) {
        if (cameraMount.mountId === mountId) {
            result[cameraMount.cameraId] = true;
        }
    }
    return result;
}

function isValidCamera(cameraMountSupportIndex: { [index: string]: true }, camera: DbCamera): boolean {
    const sensor = wizardStore.tables.cameraSensor[camera.cameraSensorId];
    const resolution = sensor.resolutionWidth * sensor.resolutionHeight;
    const resolutionRoundMegapixels = Math.round(pixelsToMegapixels(resolution) * 10) / 10;

    return (
        (
            // Sensor properties match
            (wizardStore.cameraSensorModelId !== null && camera.cameraSensorId === wizardStore.cameraSensorModelId)
            || (
                wizardStore.cameraSensorModelId === null
                && (
                    resolutionRoundMegapixels >= wizardStore.cameraResolutionMin
                    && resolutionRoundMegapixels < wizardStore.cameraResolutionMax + 1
                    && (wizardStore.cameraFrameRate === 0 || sensor.frameRate >= wizardStore.cameraFrameRate)
                    && (wizardStore.cameraSensorFormatId === null || sensor.cameraSensorFormatId === wizardStore.cameraSensorFormatId)
                    && (wizardStore.cameraPixelSizeMin === 0 || sensor.pixelSize >= wizardStore.cameraPixelSizeMin)
                    && (wizardStore.cameraPixelSizeMax === 0 || sensor.pixelSize <= wizardStore.cameraPixelSizeMax)
                    && (wizardStore.cameraBitDepth === 0 || sensor.bitDepth === wizardStore.cameraBitDepth)
                )
            )
        )
        && (wizardStore.cameraInterface === '' || camera.interface === wizardStore.cameraInterface)
        && (wizardStore.cameraColorMono === '' || camera.colorMono === wizardStore.cameraColorMono)
        && (wizardStore.cameraNearInfrared === null || camera.nearInfrared === wizardStore.cameraNearInfrared)
        && (wizardStore.cameraAutofocus === null || camera.autofocus === wizardStore.cameraAutofocus)
        && (wizardStore.cameraAutofocusFocalLength === '' || camera.autofocusFocalLength === wizardStore.cameraAutofocusFocalLength)
        && (wizardStore.cameraShutterType === '' || camera.shutterType === wizardStore.cameraShutterType)
        && (wizardStore.cameraHousing === '' || camera.housing === wizardStore.cameraHousing)
        && (wizardStore.cameraMountId === null || cameraMountSupportIndex[camera.id] === true)
        && (wizardStore.cameraTrigger === null || wizardStore.cameraHousing === 'board_level' || camera.trigger === wizardStore.cameraTrigger)
    );
}

function sortCompareCamera(a: DbCamera, b: DbCamera): number {
    if (wizardStore.cameraSort === 'resolution_ascending') {
        return sortCompareResolution(a, b);
    } else if (wizardStore.cameraSort === 'resolution_descending') {
        return -1 * sortCompareResolution(a, b);
    } else if (wizardStore.cameraSort === 'sensor_size_ascending') {
        return sortCompareSensorSize(a, b);
    } else if (wizardStore.cameraSort === 'sensor_size_descending') {
        return -1 * sortCompareSensorSize(a, b);
    } else if (wizardStore.cameraSort === 'pixel_size_ascending') {
        return sortComparePixelSize(a, b);
    } else if (wizardStore.cameraSort === 'pixel_size_descending') {
        return -1 * sortComparePixelSize(a, b);
    } else if (wizardStore.cameraSort === 'frame_rate_ascending') {
        return sortCompareFrameRate(a, b);
    } else if (wizardStore.cameraSort === 'frame_rate_descending') {
        return -1 * sortCompareFrameRate(a, b);
    } else {
        assertUnreachable(wizardStore.cameraSort);
    }
}

function sortCompareResolution(a: DbCamera, b: DbCamera): number {
    const aSensor = wizardStore.tables.cameraSensor[a.cameraSensorId];
    const bSensor = wizardStore.tables.cameraSensor[b.cameraSensorId];
    return (aSensor.resolutionWidth * aSensor.resolutionHeight) - (bSensor.resolutionWidth * bSensor.resolutionHeight);
}

function sortCompareSensorSize(a: DbCamera, b: DbCamera): number {
    const aSensor = wizardStore.tables.cameraSensor[a.cameraSensorId];
    const bSensor = wizardStore.tables.cameraSensor[b.cameraSensorId];
    const aSensorFormat = wizardStore.tables.cameraSensorFormat[aSensor.cameraSensorFormatId];
    const bSensorFormat = wizardStore.tables.cameraSensorFormat[bSensor.cameraSensorFormatId];
    return aSensorFormat.order - bSensorFormat.order;
}

function sortComparePixelSize(a: DbCamera, b: DbCamera): number {
    const aSensor = wizardStore.tables.cameraSensor[a.cameraSensorId];
    const bSensor = wizardStore.tables.cameraSensor[b.cameraSensorId];
    return aSensor.pixelSize - bSensor.pixelSize;
}

function sortCompareFrameRate(a: DbCamera, b: DbCamera): number {
    const aSensor = wizardStore.tables.cameraSensor[a.cameraSensorId];
    const bSensor = wizardStore.tables.cameraSensor[b.cameraSensorId];
    return aSensor.frameRate - bSensor.frameRate;
}

export const CameraListing = mobxReactLite.observer(function CameraListing(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const tableSortRef = React.useRef<HTMLElement>(null);

    const state = mobxReactLite.useLocalObservable(() => {
        return {
            page: 0,
        };
    });

    const onBackClick = React.useCallback(
        () => {
            history.back();
        },
        [],
    );

    const onResolutionMinChange = React.useCallback(
        (value: number) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.cameraResolutionMin = value;
                wizardStore.cameraSensorModelId = null;
            });
        },
        [],
    );

    const onResolutionMaxChange = React.useCallback(
        (value: number) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.cameraResolutionMax = value;
                wizardStore.cameraSensorModelId = null;
            });
        },
        [],
    );

    const onFrameRateChange = React.useCallback(
        (value: number) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.cameraFrameRate = value;
                wizardStore.cameraSensorModelId = null;
            });
        },
        [],
    );

    const onSensorFormatIdChange = React.useCallback(
        (value: number | null) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.cameraSensorFormatId = value;
                wizardStore.cameraSensorModelId = null;
            });
        },
        [],
    );

    const onSensorModelIdChange = React.useCallback(
        (value: number | null) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.cameraSensorModelId = value;
                if (value !== null) {
                    wizardStore.cameraResolutionMin = 0;
                    wizardStore.cameraResolutionMax = wizardStore.cameraResolutionRangeSliderMax;
                    wizardStore.cameraFrameRate = 0;
                    wizardStore.cameraSensorFormatId = null;
                    wizardStore.cameraPixelSizeMin = 0;
                    wizardStore.cameraPixelSizeMax = 0;
                    wizardStore.cameraBitDepth = 0;
                }
            });
        },
        [],
    );

    const onColorMonoChange = React.useCallback(
        (value: CameraColorMono) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.cameraColorMono = value;
            });
        },
        [],
    );

    const onInterfaceChange = React.useCallback(
        (value: CameraInterface) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.cameraInterface = value;
            });
        },
        [],
    );

    const onNearInfraredChange = React.useCallback(
        (value: boolean) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.cameraNearInfrared = value;
            });
        },
        [],
    );

    const onPixelSizeMinChange = React.useCallback(
        (value: number) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.cameraPixelSizeMin = value;
                wizardStore.cameraSensorModelId = null;
            });
        },
        [],
    );

    const onPixelSizeMaxChange = React.useCallback(
        (value: number) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.cameraPixelSizeMax = value;
                wizardStore.cameraSensorModelId = null;
            });
        },
        [],
    );

    const onAutofocusChange = React.useCallback(
        (value: boolean) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.cameraAutofocus = value;
            });
        },
        [],
    );

    const onAutofocusFocalLengthChange = React.useCallback(
        (value: string) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.cameraAutofocusFocalLength = value;
            });
        },
        [],
    );

    const onShutterTypeChange = React.useCallback(
        (value: CameraShutterType) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.cameraShutterType = value;
            });
        },
        [],
    );

    const onHousingChange = React.useCallback(
        (value: CameraHousing) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.cameraHousing = value;
            });
        },
        [],
    );

    const onMountIdChange = React.useCallback(
        (value: number | null) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.cameraMountId = value;
            });
        },
        [],
    );

    const onTriggerChange = React.useCallback(
        (value: boolean) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.cameraTrigger = value;
            });
        },
        [],
    );

    const onBitDepthChange = React.useCallback(
        (value: number) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.cameraBitDepth = value;
                wizardStore.cameraSensorModelId = null;
            });
        },
        [],
    );

    const getTableAbsoluteTop = React.useCallback(
        (): number => {
            if (tableSortRef.current === null) return 0;

            const rect = tableSortRef.current.getBoundingClientRect();
            const absoluteTop = window.scrollY + rect.top;

            return Math.max(absoluteTop - 40, 0);
        },
        [],
    );

    const paginationScroll = React.useCallback(
        () => {
            window.scrollTo({
                top: getTableAbsoluteTop(),
                left: 0,
                behavior: 'smooth',
            });
        },
        [],
    );

    const onPageChange = React.useCallback(
        (page: number) => {
            mobx.runInAction(() => {
                state.page = page;
            });
            paginationScroll();
        },
        [],
    );

    const onResetClick = React.useCallback(
        () => {
            resetWizardCameraForm();
        },
        [],
    );

    const cameraMountSupportIndex = React.useMemo(
        () => {
            if (wizardStore.cameraMountId === null) return {};
            return getCameraMountSupportIndex(wizardStore.cameraMountId);
        },
        [wizardStore.cameraMountId],
    );

    const mountOptions = React.useMemo(
        () => {
            const usedMountIds: { [index: string]: true } = {};
            for (const cameraMount of Object.values(wizardStore.tables.cameraMount)) {
                usedMountIds[cameraMount.mountId] = true;
            }

            const options = getMountOptions('All');

            const filteredOptions = new Map<number | null, string>();
            for (const [key, value] of options) {
                if (key === null || usedMountIds[key] === true) {
                    filteredOptions.set(key, value);
                }
            }
            return filteredOptions;
        },
        [wizardStore.tables],
    );

    const sortedCameraSensors = Object.values(wizardStore.tables.cameraSensor).sort((a, b) => {
        if (a.name.startsWith('Sony') && !b.name.startsWith('Sony')) return -1;
        if (!a.name.startsWith('Sony') && b.name.startsWith('Sony')) return 1;
        return a.name.localeCompare(b.name)
    });
    const cameraSensorOptions = new Map<number | null, string>();
    cameraSensorOptions.set(null, 'All');
    for (const sensor of sortedCameraSensors) {
        cameraSensorOptions.set(sensor.id, sensor.name);
    }

    const allCameras = Object.values(wizardStore.tables.camera);

    const autofocusFocalLengthSet = new Set<string>();
    for (const camera of allCameras) {
        if (camera.autofocusFocalLength === '') continue;
        autofocusFocalLengthSet.add(camera.autofocusFocalLength);
    }
    const autofocusFocalLengthArray = Array.from(autofocusFocalLengthSet).sort((a, b) => {
        return defaultValueIfNaN(parseFloat(a), Infinity) - defaultValueIfNaN(parseFloat(b), Infinity);
    });
    const autofocusFocalLengthOptions = new Map<string, string>();
    autofocusFocalLengthOptions.set('', 'All');
    for (const x of autofocusFocalLengthArray) {
        autofocusFocalLengthOptions.set(x, x + 'mm');
    }

    const filteredCameras = allCameras.filter((camera) => isValidCamera(cameraMountSupportIndex, camera));
    const cameras = filteredCameras
        .sort(sortCompareCamera)
        .slice(state.page * camerasPerPage, (state.page + 1) * camerasPerPage);

    return h('div', { className: c('root') },
        h(WizardTitleBar, {
            backText: 'Home',
            backOnClick: onBackClick,
            titleText: 'Pixelink Camera Selector Guide',
            titleIcon: h(CameraIcon, { size: 44 }),
            bottomBorder: false,
        }),

        h('div', { className: c('form') },
            h('div', { className: c('fields') },
                h('div', { className: c('field-group') },
                    h('div', { className: c('field') },
                        h('div', { className: c('field-label') }, 'Resolution (MP)'),
                        h('div', { className: c('resolution-input') },
                            h(NumberInput, {
                                value: wizardStore.cameraResolutionMin,
                                onChange: onResolutionMinChange,
                                fractionDigits: 0,
                                nonNegative: true,
                                emptyZero: false,
                            }),
                            h(RangeSlider, {
                                minValue: wizardStore.cameraResolutionMin,
                                maxValue: wizardStore.cameraResolutionMax,
                                minBound: 0,
                                maxBound: wizardStore.cameraResolutionRangeSliderMax,
                                onChangeMinValue: onResolutionMinChange,
                                onChangeMaxValue: onResolutionMaxChange,
                            }),
                            h(NumberInput, {
                                value: wizardStore.cameraResolutionMax,
                                onChange: onResolutionMaxChange,
                                fractionDigits: 0,
                                nonNegative: true,
                                emptyZero: false,
                            }),
                        ),
                    ),
                ),

                h('div', { className: c('field-group') },
                    h('div', { className: c('field') },
                        h('div', { className: c('field-label') }, 'Min Frame Rate'),
                        h(NumberInput, {
                            value: wizardStore.cameraFrameRate,
                            onChange: onFrameRateChange,
                            fractionDigits: 0,
                            nonNegative: true,
                            emptyZero: true,
                        }),
                    ),

                    h('div', { className: c('field') },
                        h('div', { className: c('field-label') }, 'Sensor Size'),
                        h(Select, {
                            value: wizardStore.cameraSensorFormatId,
                            onChange: onSensorFormatIdChange,
                            options: getCameraSensorFormatOptions('All'),
                        }),
                    ),
                ),

                h('div', { className: c('field-group') },
                    h('div', { className: c('field') },
                        h('div', { className: c('field-label') }, 'Sensor Model'),
                        h(Select, {
                            value: wizardStore.cameraSensorModelId,
                            onChange: onSensorModelIdChange,
                            options: cameraSensorOptions,
                        }),
                    ),

                    h('div', { className: c('field') },
                        h('div', { className: c('field-label') }, 'Color/Mono'),
                        h(Select, {
                            value: wizardStore.cameraColorMono,
                            onChange: onColorMonoChange,
                            options: dbOptions.camera.colorMono,
                        }),
                    ),
                ),

                h('div', { className: c('field-group') },
                    h('div', { className: c('field') },
                        h('div', { className: c('field-label') }, 'Interface'),
                        h(Select, {
                            value: wizardStore.cameraInterface,
                            onChange: onInterfaceChange,
                            options: dbOptions.camera.interface,
                        }),
                    ),

                    h('div', { className: c('field') },
                        h('div', { className: c('field-label') }, 'NIR'),
                        h(Select, {
                            value: wizardStore.cameraNearInfrared,
                            onChange: onNearInfraredChange,
                            options: dbOptions.all.boolean,
                        }),
                    ),
                ),

                h('div', { className: c('field-group') },
                    h('div', { className: c('field') },
                        h('div', { className: c('field-label') }, 'Min Pixel Size'),
                        h(NumberInput, {
                            value: wizardStore.cameraPixelSizeMin,
                            onChange: onPixelSizeMinChange,
                            fractionDigits: 2,
                            nonNegative: true,
                            emptyZero: true,
                        }),
                    ),

                    h('div', { className: c('field') },
                        h('div', { className: c('field-label') }, 'Max Pixel Size'),
                        h(NumberInput, {
                            value: wizardStore.cameraPixelSizeMax,
                            onChange: onPixelSizeMaxChange,
                            fractionDigits: 2,
                            nonNegative: true,
                            emptyZero: true,
                        }),
                    ),
                ),

                h('div', { className: c('field-group') },
                    h('div', { className: c('field') },
                        h('div', { className: c('field-label') }, 'Autofocus'),
                        h(Select, {
                            value: wizardStore.cameraAutofocus,
                            onChange: onAutofocusChange,
                            options: dbOptions.all.boolean,
                        }),
                    ),

                    h('div', { className: c('field') },
                        h('div', { className: c('field-label') }, 'Autofocus Focal Length'),
                        h(Select, {
                            value: wizardStore.cameraAutofocusFocalLength,
                            onChange: onAutofocusFocalLengthChange,
                            options: autofocusFocalLengthOptions,
                        }),
                    ),
                ),

                h('div', { className: c('field-group') },
                    h('div', { className: c('field') },
                        h('div', { className: c('field-label') }, 'Shutter Type'),
                        h(Select, {
                            value: wizardStore.cameraShutterType,
                            onChange: onShutterTypeChange,
                            options: dbOptions.camera.shutterType,
                        }),
                    ),

                    h('div', { className: c('field') },
                        h('div', { className: c('field-label') }, 'Housing'),
                        h(Select, {
                            value: wizardStore.cameraHousing,
                            onChange: onHousingChange,
                            options: dbOptions.camera.housing,
                        }),
                    ),
                ),

                h('div', { className: c('field-group') },
                    h('div', { className: c('field') },
                        h('div', { className: c('field-label') }, 'Lens Mount'),
                        h(Select, {
                            value: wizardStore.cameraMountId,
                            onChange: onMountIdChange,
                            options: mountOptions,
                        }),
                    ),

                    h('div', { className: c('field') },
                        h('div', { className: c('field-label') }, 'Hardware Trigger'),
                        h(Select, {
                            value: wizardStore.cameraTrigger,
                            onChange: onTriggerChange,
                            options: dbOptions.all.boolean,
                        }),
                    ),

                    h('div', { className: c('field') },
                        h('div', { className: c('field-label') }, 'Bit Depth'),
                        h(Select, {
                            value: wizardStore.cameraBitDepth,
                            onChange: onBitDepthChange,
                            options: dbOptions.camera.bitDepth,
                        }),
                    ),
                ),
            ),

            h('div', { className: c('form-reset-button') },
                h(ResetButton, {
                    title: 'Reset',
                    onClick: onResetClick,
                }),
            ),
        ),

        h('div', { className: c('table-sort'), ref: tableSortRef },
            h(FieldLabel, { label: 'Sort Results', bold: true }),
            createTwoWayBinding({
                observable: wizardStore,
                property: 'cameraSort',
                childType: Select,
                childProps: {
                    options: sortOptions,
                },
            }),
        ),

        (cameras.length > 0 ?
            h('div', { className: c('solution-table') },
                h('div', { className: c('solution-head') },
                    h('div', { className: c('solution-head-cell', 'solution-column-model-name') }, 'Model Name'),
                    h('div', { className: c('solution-head-cell', 'solution-column-resolution') }, 'Resolution'),
                    h('div', { className: c('solution-head-cell', 'solution-column-resolution') }, 'Sensor Size'),
                    h('div', { className: c('solution-head-cell', 'solution-column-pixel-size') }, 'Pixel Size'),
                    h('div', { className: c('solution-head-cell', 'solution-column-frame-rate') }, 'Frame Rate'),
                    h('div', { className: c('solution-head-cell', 'solution-column-color-mono') }, 'Color/Mono'),
                    h('div', { className: c('solution-head-cell', 'solution-column-interface') }, 'Interface'),
                    h('div', { className: c('solution-head-cell', 'solution-column-sensor-model') }, 'Sensor Model'),
                    h('div', { className: c('solution-head-cell', 'solution-column-select') }),
                ),
                cameras.map((camera) => (
                    h(SolutionRow, {
                        cameraId: camera.id,
                        key: camera.id,
                    })
                )),
                h('div', { className: c('pagination') },
                    h(WizardPagination, {
                        page: state.page,
                        itemsPerPage: camerasPerPage,
                        totalItems: filteredCameras.length,
                        onPageChange: onPageChange,
                    }),
                ),
            ) :
            h('div', { className: c('no-solutions') },
                h('div', { className: c('no-solutions-title') },
                    'No cameras match your entries.',
                ),
                h('div', { className: c('no-solutions-description') },
                    'Please ',
                    h('a', { href: 'https://go.navitar.com/l/540462/2022-03-02/3zvmlxt', target: '_blank' },
                        'contact us',
                    ),
                    ' for custom or additional solutions.',
                ),
            )
        ),

        h(StickyResults, {
            filteredCameras: filteredCameras,
            getTableAbsoluteTop: getTableAbsoluteTop,
        }),
    );
});
