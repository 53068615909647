import * as mobxReactLite from 'mobx-react-lite';
import { h } from '../../util';

type Props = {
    size: number;
};

export const ErrorIcon = mobxReactLite.observer(function ErrorIcon(props: Props) {
    return h('svg',
        {
            width: props.size.toString(),
            height: props.size.toString(),
            viewBox: '0 0 18 18',
            xmlns: 'http://www.w3.org/2000/svg',
        },
        h('path', {
            d: 'M0 9C0 4.02944 4.02944 0 9 0C13.9682 0.00578607 17.9942 4.03184 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9ZM13.322 12.7864C13.3907 12.5242 13.3125 12.2453 13.1175 12.057L10.1925 9.13276C10.1573 9.09771 10.1375 9.05007 10.1375 9.00038C10.1375 8.95069 10.1573 8.90306 10.1925 8.86801L13.1175 5.94301C13.3202 5.75667 13.4046 5.47422 13.3372 5.20723C13.2698 4.94025 13.0615 4.73166 12.7946 4.66388C12.5277 4.59611 12.2451 4.68003 12.0585 4.88251L9.1335 7.80751C9.09832 7.84277 9.05056 7.86259 9.00075 7.86259C8.95094 7.86259 8.90318 7.84277 8.868 7.80751L5.943 4.88251C5.65036 4.58924 5.17539 4.58874 4.88213 4.88138C4.58886 5.17402 4.58836 5.64899 4.881 5.94226L7.806 8.86726C7.84122 8.90231 7.86102 8.94994 7.86102 8.99963C7.86102 9.04932 7.84122 9.09696 7.806 9.13201L4.881 12.057C4.58821 12.3499 4.58821 12.8246 4.881 13.1175C5.17588 13.4054 5.64663 13.4054 5.9415 13.1175L8.8665 10.1925C8.9401 10.1199 9.0584 10.1199 9.132 10.1925L12.057 13.1175C12.2453 13.3125 12.5242 13.3907 12.7864 13.322C13.0486 13.2534 13.2534 13.0486 13.322 12.7864Z',
        }),
    );
});
