import * as mobxReactLite from 'mobx-react-lite';
import { h } from '../../util';

type Props = {
    size: number;
};

export const FooterEmailIcon = mobxReactLite.observer(function FooterEmailIcon(props: Props) {
    return h('svg',
        {
            width: props.size.toString(),
            height: props.size.toString(),
            viewBox: '0 0 40 40',
            xmlns: 'http://www.w3.org/2000/svg',
        },
        h('path', {
            d: 'M36 4H4C1.8 4 0.02 5.8 0.02 8L0 32C0 34.2 1.8 36 4 36H36C38.2 36 40 34.2 40 32V8C40 5.8 38.2 4 36 4ZM36 12L20 22L4 12V8L20 18L36 8V12Z',
        }),
    );
});
