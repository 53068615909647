import * as mobx from 'mobx';
import { AdminBreadcrumb } from '../../../../admin_breadcrumb_types';
import { adminSettingsEnumOptions, adminSettingsUniqueNameMultiEllipsis } from '../../../../admin_util';
import { api } from '../../../../api';
import { createDbTables } from '../../../../db_create';
import { DbTableTypes } from '../../../../db_types';
import { store } from '../../../../store';
import { h, timestampToString } from '../../../../util';
import { MultiEllipsis } from '../../../multi_ellipsis/multi_ellipsis';
import { EnumFilter } from './enum_filter';
import { ForeignKeyFilter } from './foreign_key_filter';
import { StringFilter } from './string_filter';

type RouteParams = {
    tableName: keyof DbTableTypes;
    hiddenFilterFieldId?: string;
    hiddenFilterValue?: number;
    breadcrumbs?: AdminBreadcrumb[];
};

// Also edit on server (search: ede43e6f1d6a425386cf605ebe503ca4)
export const itemsPerPage = 100;

export const state = mobx.observable({
    staticLoaded: false,
    dynamicTables: createDbTables(),
    dynamicLoaded: false,
    dynamicLoading: false,
    pageId: 0,
    pagePreviousLoading: false,
    pageNextLoading: false,
    filters: {} as { [index: string]: any },
});

// Static tables aren't paginated so they can get large and therefore instead of keeping
// them in state, which mobx makes observable, we keep them in a ref, which is fine
// because they don't change.
let staticTables = createDbTables();

let routeParams: RouteParams = { tableName: 'user' };
let staticAbortController = new AbortController();
let dynamicAbortController = new AbortController();

export const afterPagination = createEvent();

export function onMount(newRouteParams: RouteParams) {
    routeParams = newRouteParams;
    staticTables = createDbTables();
    staticAbortController.abort();
    staticAbortController = new AbortController();
    dynamicAbortController.abort();
    dynamicAbortController = new AbortController();

    const tableSettings = getTableSettings();

    const filters: { [index: string]: any } = {};
    for (const fieldId of getListingFilterFieldIds()) {
        const fieldSettings = tableSettings.fields[fieldId];

        if (fieldSettings.fieldType === 'foreignKey') {
            filters[fieldId] = null;
        } else if (fieldSettings.fieldType === 'enum') {
            filters[fieldId] = fieldSettings.enumEmptyValue;
        } else if (fieldSettings.fieldType === 'string' || fieldSettings.fieldType === 'email') {
            filters[fieldId] = '';
        }
    }

    mobx.runInAction(() => {
        state.staticLoaded = false;
        state.dynamicTables = createDbTables();
        state.dynamicLoaded = false;
        state.dynamicLoading = false;
        state.pageId = 0;
        state.pagePreviousLoading = false;
        state.pageNextLoading = false;
        state.filters = filters;
    });

    api('/api/read/admin/listing/static', store, true, staticAbortController.signal, {
        tableName: routeParams.tableName,
        hiddenFilterFieldId: routeParams.hiddenFilterFieldId !== undefined ? routeParams.hiddenFilterFieldId : '',
    }).then((response) => {
        staticTables = response.tables;
        mobx.runInAction(() => {
            state.staticLoaded = true;
        });
    });

    fetchDynamic();
}

export function onUnmount() {
    staticAbortController.abort();
    dynamicAbortController.abort();
}

export function getTableSettings() {
    return store.adminSettings[routeParams.tableName];
}

export function getListingFilterFieldIds(): string[] {
    let result = getTableSettings().listingFilterFieldIds;
    if (routeParams.hiddenFilterFieldId !== undefined) {
        result = result.filter((fieldId) => fieldId !== routeParams.hiddenFilterFieldId);
    }
    return result;
};

export function getListingFieldIds(): string[] {
    let result = getTableSettings().listingFieldIds;
    if (routeParams.hiddenFilterFieldId !== undefined) {
        result = result.filter((fieldId) => fieldId !== routeParams.hiddenFilterFieldId);
    }
    return result;
}

export function getRows(): DbTableTypes[keyof DbTableTypes][] {
    return Object.values(state.dynamicTables[routeParams.tableName]);
}

export function getSortedRows() {
    const tableSettings = getTableSettings();
    const rows = getRows();
    rows.sort((a: any, b: any): number => {
        for (const orderBy of tableSettings.listingOrderBy) {
            let less = -1;
            let greater = 1;
            if (orderBy.descending) {
                less = 1;
                greater = -1;
            }

            if (a[orderBy.fieldId] < b[orderBy.fieldId]) return less;
            if (a[orderBy.fieldId] > b[orderBy.fieldId]) return greater;
        }

        return 0;
    });
    return rows;
}

export function getFilterElement(fieldId: string): React.ReactNode {
    const fieldSettings = getTableSettings().fields[fieldId];

    if (fieldSettings.fieldType === 'foreignKey') {
        if (fieldSettings.foreignKeyTable === '') return null;

        return h(ForeignKeyFilter, {
            label: fieldSettings.name,
            fieldId: fieldId,
            tables: staticTables,
            tableName: fieldSettings.foreignKeyTable,
            emptyValue: null,
            emptyTitle: 'All',
            value: state.filters[fieldId],
            onChange: onFilterChange,
        });
    } else if (fieldSettings.fieldType === 'enum') {
        return h(EnumFilter, {
            label: fieldSettings.name,
            fieldId: fieldId,
            options: adminSettingsEnumOptions(fieldSettings, 'All'),
            value: state.filters[fieldId],
            onChange: onFilterChange,
        });
    } else if (fieldSettings.fieldType === 'string' || fieldSettings.fieldType === 'email') {
        return h(StringFilter, {
            label: fieldSettings.name,
            fieldId: fieldId,
            onChange: onFilterChange,
        });
    } else {
        return null;
    }
}

export function getFieldElement(row: any, fieldId: string): React.ReactNode {
    const fieldSettings = getTableSettings().fields[fieldId];
    if (fieldSettings.fieldType === 'foreignKey') {
        if (fieldSettings.foreignKeyTable === '') return '';

        const items = adminSettingsUniqueNameMultiEllipsis(
            store.adminSettings,
            staticTables,
            fieldSettings.foreignKeyTable,
            row[fieldId],
        );
        return h(MultiEllipsis, {
            items: items,
        });
    } else if (fieldSettings.fieldType === 'enum') {
        let result = '';
        for (const choice of fieldSettings.enumChoices) {
            if (choice.value === row[fieldId]) {
                result = choice.name;
            }
        }
        return result;
    } else if (fieldSettings.fieldType === 'number') {
        return row[fieldId].toLocaleString('en-US', { maximumFractionDigits: fieldSettings.numberFractionDigits });
    } else if (fieldSettings.fieldType === 'timestamp') {
        return timestampToString(row[fieldId]);
    } else {
        return row[fieldId];
    }
}

export function onFilterChange(fieldId: string, value: any) {
    mobx.runInAction(() => {
        state.filters[fieldId] = value;
        state.pageId = 0;
        state.pagePreviousLoading = false;
        state.pageNextLoading = false;
    });

    fetchDynamic();
}

export function fetchDynamic() {
    dynamicAbortController.abort();
    dynamicAbortController = new AbortController();

    mobx.runInAction(() => {
        state.dynamicLoading = true;
    });

    const tableSettings = getTableSettings();

    const filters: { [index: string]: any } = {};
    for (const fieldId of Object.keys(state.filters)) {
        const filterValue = state.filters[fieldId];
        const fieldSettings = tableSettings.fields[fieldId];

        if (fieldSettings.fieldType === 'foreignKey') {
            if (filterValue !== null) {
                filters[fieldId] = filterValue;
            }
        } else if (fieldSettings.fieldType === 'enum') {
            if (filterValue !== fieldSettings.enumEmptyValue) {
                filters[fieldId] = filterValue;
            }
        } else if (fieldSettings.fieldType === 'string' || fieldSettings.fieldType === 'email') {
            if (filterValue !== '') {
                filters[fieldId] = filterValue;
            }
        }
    }

    return api('/api/read/admin/listing/dynamic', store, true, dynamicAbortController.signal, {
        tableName: routeParams.tableName,
        pageId: state.pageId,
        filters: filters,
        hiddenFilterFieldId: routeParams.hiddenFilterFieldId !== undefined ? routeParams.hiddenFilterFieldId : '',
        hiddenFilterValue: routeParams.hiddenFilterValue !== undefined ? routeParams.hiddenFilterValue : 0,
    }).then((response) => {
        mobx.runInAction(() => {
            state.dynamicTables = response.tables;
            state.dynamicLoaded = true;
            state.dynamicLoading = false;
            state.pagePreviousLoading = false;
            state.pageNextLoading = false;
        });
    });
}

export function onPaginationPrevious() {
    if (state.dynamicLoading || state.pageId === 0) return;

    mobx.runInAction(() => {
        state.pageId -= 1;
        state.pagePreviousLoading = true;
    });

    fetchDynamic().then(afterPagination.publish);
}

export function onPaginationNext() {
    if (state.dynamicLoading) return;

    const rows = getRows();

    if (rows.length < itemsPerPage) return;

    mobx.runInAction(() => {
        state.pageId += 1;
        state.pageNextLoading = true;
    });

    fetchDynamic().then(afterPagination.publish);
}

export function createEvent() {
    let subscribers: (() => void)[] = [];
    return {
        subscribe: (f: () => void) => {
            subscribers.push(f);
            return () => {
                subscribers = subscribers.filter((x) => x !== f);
            };
        },
        publish: () => {
            for (const subscriber of subscribers) {
                subscriber();
            }
        },
    };
}
