import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../util';
import { cssScope } from './css_scope';

export type Item = {
    type: 'fixed' | 'ellipsis';
    text: string;
};

type Props = {
    items: Item[];
};

const c = scopedClasses(cssScope);

export const MultiEllipsis = mobxReactLite.observer(function MultiEllipsis(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    return h('div', { className: c('root') },
        props.items.map((item, i) => h('div', { className: c('item', 'type-' + item.type), key: i },
            item.text,
        )),
    );
});
