import * as React from 'react';
import * as mobx from 'mobx';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../../util';
import { cssScope } from './css_scope';
import { DataAdapter } from './data_adapter';
import { DataStandardCore } from './data_standard_core';
import { DataAttachment } from './data_attachment';
import { DataObjectiveCore } from './data_objective_core';
import { DataObjective } from './data_objective';
import { DataSingleShotCore } from './data_single_shot_core';
import { DataSingleShotObjective } from './data_single_shot_objective';
import { DataZoomXtender } from './data_zoom_xtender';
import { DataMachineVisionLens } from './data_machine_vision_lens';
import { ZoomXtenderFit } from './zoom_xtender_fit/zoom_xtender_fit';
import { Solutions } from './solutions/solutions';
import { DbTables } from '../../../db_types';
import { Tab } from './tab';
import { api } from '../../../api';
import { createDbTables } from '../../../db_create';
import { store } from '../../../store';
import { FieldLabel } from '../../field_label/field_label';
import { createTwoWayBinding } from '../../two_way_binding';
import { Select } from '../../select/select';

type Props = {};

const c = scopedClasses(cssScope);

type Tab = {
    name: string;
    component: React.FunctionComponent<{ tables: DbTables }>,
};

const tabs: Tab[] = [
    {
        name: 'Solutions',
        component: Solutions,
    },
    {
        name: 'Zoom Xtender Fit',
        component: ZoomXtenderFit,
    },
    {
        name: 'Data: Adapter',
        component: DataAdapter,
    },
    {
        name: 'Data: Standard Core',
        component: DataStandardCore,
    },
    {
        name: 'Data: Attachment',
        component: DataAttachment,
    },
    {
        name: 'Data: Objective Core',
        component: DataObjectiveCore,
    },
    {
        name: 'Data: Objective',
        component: DataObjective,
    },
    {
        name: 'Data: SingleShot Core',
        component: DataSingleShotCore,
    },
    {
        name: 'Data: SingleShot Objective',
        component: DataSingleShotObjective,
    },
    {
        name: 'Data: Zoom Xtender',
        component: DataZoomXtender,
    },
    {
        name: 'Data: Machine Vision Lens',
        component: DataMachineVisionLens,
    },
];

export const QualityAssurance = mobxReactLite.observer(function QualityAssurance(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const state = mobxReactLite.useLocalObservable(() => {
        return {
            tabName: 'Solutions',
            tables: createDbTables(),
            loaded: false,
        };
    });

    const getChild = React.useCallback(
        (tabName: string) => {
            for (const tab of tabs) {
                if (tab.name === tabName) {
                    return h(tab.component, { tables: state.tables });
                }
            }
            return null;
        },
        [],
    );

    // Load query
    React.useEffect(
        () => {
            const abortController = new AbortController();

            api('/api/read/wizard', store, true, abortController.signal, {}).then((response) => {
                mobx.runInAction(() => {
                    state.tables = response.tables;
                    state.loaded = true;
                });
            });

            return () => {
                abortController.abort();
            };
        },
        [],
    );

    if (!state.loaded) return null;

    const tabOptions = new Map<string, string>();
    for (const tab of tabs) {
        tabOptions.set(tab.name, tab.name);
    }

    return h('div', { className: c('root') },
        h('div', { className: c('tab-select') },
            h('div', { className: c('tab-select-inner') },
                h(FieldLabel, { label: 'Tab' }),
                createTwoWayBinding({
                    observable: state,
                    property: 'tabName',
                    childType: Select,
                    childProps: {
                        options: tabOptions,
                    },
                }),
            ),
        ),
        h('div', { className: c('child') },
            getChild(state.tabName),
        ),
    );
});
