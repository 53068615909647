import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../util';
import { cssScope } from './css_scope';

type Props = {
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters';
    onBlur?: () => void;
};

const c = scopedClasses(cssScope);

export const TextInput = mobxReactLite.observer(function TextInput(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const onChange = React.useCallback(
        (e) => {
            propsRef.current.onChange(e.currentTarget.value);
        },
        [],
    );

    return h('input', {
        className: c('root'),
        type: 'text',
        value: props.value,
        onChange: onChange,
        placeholder: props.placeholder,
        autoCapitalize: props.autoCapitalize,
        onBlur: props.onBlur,
    });
});
