import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../util';
import { Message } from './message';
import { cssScope } from './css_scope';
import { store } from '../../store';

const c = scopedClasses(cssScope);

export const Snackbar = mobxReactLite.observer(function Snackbar() {
    return h('div', { className: c('root') },
        h('div', { className: c('messages') },
            store.snackBarMessages.map((message) => h(Message, { message: message, key: message.id })),
        ),
    );
});
