import * as React from 'react';
import * as mobx from 'mobx';
import * as mobxReactLite from 'mobx-react-lite';
import { assertUnreachable, defaultValueIfUndefined, formatFloat, h, scopedClasses } from '../../../../util';
import { cssScope } from './css_scope';
import { createTwoWayBinding } from '../../../two_way_binding';
import { NumberInput } from '../../../number_input';
import { Select } from '../../../select/select';
import { Solution, LensSort, CameraMake, LensType, Wavelength } from '../../../../wizard_types';
import { resetWizardStore, wizardStore } from '../../../../wizard_store';
import { SolutionRow } from './solution_row';
import { displayDiagonalFieldOfViewRange, displayFieldOfViewRange, displayRangeFloat, formatMegapixelResolution, getCameraSensorFormatOptions, getDiagonal, getFieldOfViewRange, getSensorSize, getSolutionDetails, getSolutions, getTargetMagnification, getTargetMagnificationRange, isValidSolution } from '../../../../wizard_util';
import { cameraMakeOptions, lensTypeOptions, wavelengthOptions } from '../../../../wizard_options';
import { DbCamera } from '../../../../db_types';
import { store } from '../../../../store';
import { FieldLabel } from '../../../field_label/field_label';
import { WizardPagination } from '../../../wizard_pagination/wizard_pagination';
import { WizardTitleBar } from '../../../wizard_title_bar/wizard_title_bar';
import { LensIcon } from '../../../icons/lens';
import { dbOptions } from '../../../../db_options';
import { navigateTo } from '../../../../wizard_navigator';

type Props = {};

const c = scopedClasses(cssScope);

const sortOptions = new Map<LensSort, string>([
    ['recommended', 'Recommended'],
    ['magnification_ascending', 'Magnification (low first)'],
    ['magnification_descending', 'Magnification (high first)'],
    ['working_distance_ascending', 'Working Distance (low first)'],
    ['working_distance_descending', 'Working Distance (high first)'],
    ['resolve_limit_ascending', 'Resolve Limit (low first)'],
    ['resolve_limit_descending', 'Resolve Limit (high first)'],
]);

const solutionsPerPage = 100;

type ComputedData = {
    solutions: Solution[];
    sensorSize: {
        width: number;
        height: number;
    };
    targetMagnification: number;
    maxTargetMagnification: number;
};

const computedData = mobx.computed((): ComputedData => {
    const sensorSize = getSensorSize();
    const targetMagnification = getTargetMagnification(
        sensorSize.width,
        sensorSize.height,
        wizardStore.lensObjectWidth,
        wizardStore.lensObjectHeight,
        wizardStore.lensCameraMake,
    );
    const maxTargetMagnification = getTargetMagnification(
        sensorSize.width,
        sensorSize.height,
        wizardStore.lensMaxObjectWidth,
        wizardStore.lensMaxObjectHeight,
        wizardStore.lensCameraMake,
    );
    const solutions = getSolutions(
        wizardStore.tables,
        wizardStore.lensProductFamilyFilter,
        (solution: Solution) => isValidSolution(
            solution,
            sensorSize.width,
            sensorSize.height,
            targetMagnification,
            maxTargetMagnification,
            wizardStore.lensWorkingDistance,
            wizardStore.lensFeatureSize,
            wizardStore.lensMaxFeatureSize,
            wizardStore.lensType,
            wizardStore.lensWavelength,
        ),
    );
    solutions.sort(getCompareFunction());
    return {
        solutions: solutions,
        sensorSize: sensorSize,
        targetMagnification: targetMagnification,
        maxTargetMagnification: maxTargetMagnification,
    };
});

function getSolutionKey(solution: Solution): string {
    if (solution.kind === 'standard') {
        return JSON.stringify([
            solution.kind,
            solution.productFamily.id,
            solution.adapter.id,
            solution.standardCore.id,
            solution.standardAttachment !== null ? solution.standardAttachment.id : 0,
        ]);
    } else if (solution.kind === 'objective') {
        return JSON.stringify([
            solution.kind,
            solution.productFamily.id,
            solution.adapter !== null ? solution.adapter.id : 0,
            solution.objectiveCore.id,
            solution.objectiveAttachment.id,
        ]);
    } else if (solution.kind === 'single_shot') {
        return JSON.stringify([
            solution.kind,
            solution.productFamily.id,
            solution.objectiveCore.id,
            solution.objectiveAttachment.id,
        ]);
    } else if (solution.kind === 'zoom_xtender') {
        return JSON.stringify([
            solution.kind,
            solution.productFamily.id,
            solution.adapter.id,
            solution.zoomXtender.id,
        ]);
    } else if (solution.kind === 'machine_vision') {
        return JSON.stringify([
            solution.kind,
            solution.productFamily.id,
            solution.lens.id,
        ]);
    } else {
        assertUnreachable(solution);
    }
}

function compareSolutionsRecommended(a: Solution, b: Solution): number {
    const aSolutionDetails = getSolutionDetails(a);
    const bSolutionDetails = getSolutionDetails(b);

    if (aSolutionDetails.magnification.working.high < bSolutionDetails.magnification.working.high) return 1;
    if (aSolutionDetails.magnification.working.high > bSolutionDetails.magnification.working.high) return -1;

    return aSolutionDetails.resolveLimit.working.low - bSolutionDetails.resolveLimit.working.low;
}

function compareSolutionsResolveLimit(a: Solution, b: Solution): number {
    const aSolutionDetails = getSolutionDetails(a);
    const bSolutionDetails = getSolutionDetails(b);
    return aSolutionDetails.resolveLimit.working.low - bSolutionDetails.resolveLimit.working.low;
}

function compareSolutionsMagnification(a: Solution, b: Solution): number {
    const aSolutionDetails = getSolutionDetails(a);
    const bSolutionDetails = getSolutionDetails(b);
    return aSolutionDetails.magnification.working.high - bSolutionDetails.magnification.working.high;
}

function compareSolutionsWorkingDistance(a: Solution, b: Solution): number {
    const aSolutionDetails = getSolutionDetails(a);
    const bSolutionDetails = getSolutionDetails(b);
    return aSolutionDetails.workingDistance.working - bSolutionDetails.workingDistance.working;
}

function getCameraDisplayString(camera: DbCamera): string {
    const sensor = wizardStore.tables.cameraSensor[camera.cameraSensorId];
    return (
        camera.partNumber
        + ', ' + formatMegapixelResolution(sensor.resolutionWidth, sensor.resolutionHeight)
        + ' ' + defaultValueIfUndefined(dbOptions.camera.colorMono.get(camera.colorMono), '')
        + ' ' + formatFloat(sensor.pixelSize) + 'μm pixel size'
    );
}

function getCameraOptions(cameraSensorFormatId: number | null): Map<number | null, string> {
    const options = new Map<number | null, string>();
    options.set(null, '');

    if (cameraSensorFormatId === null) {
        return options;
    }

    const cameras = Object.values(wizardStore.tables.camera).filter((camera) => {
        const sensor = wizardStore.tables.cameraSensor[camera.cameraSensorId];
        return sensor.cameraSensorFormatId === cameraSensorFormatId && !camera.autofocus;
    });
    cameras.sort((a, b) => a.partNumber.localeCompare(b.partNumber));

    for (const camera of cameras) {
        options.set(camera.id, getCameraDisplayString(camera));
    }

    return options;
}

function getCompareFunction(): (a: Solution, b: Solution) => number {
    if (wizardStore.lensSort === 'recommended') {
        return compareSolutionsRecommended;
    } else if (wizardStore.lensSort === 'magnification_ascending') {
        return (a, b) => compareSolutionsMagnification(a, b);
    } else if (wizardStore.lensSort === 'magnification_descending') {
        return (a, b) => -1 * compareSolutionsMagnification(a, b);
    } else if (wizardStore.lensSort === 'working_distance_ascending') {
        return (a, b) => compareSolutionsWorkingDistance(a, b);
    } else if (wizardStore.lensSort === 'working_distance_descending') {
        return (a, b) => -1 * compareSolutionsWorkingDistance(a, b);
    } else if (wizardStore.lensSort === 'resolve_limit_ascending') {
        return (a, b) => compareSolutionsResolveLimit(a, b);
    } else if (wizardStore.lensSort === 'resolve_limit_descending') {
        return (a, b) => -1 * compareSolutionsResolveLimit(a, b);
    } else {
        assertUnreachable(wizardStore.lensSort);
    }
}

export const LensListing = mobxReactLite.observer(function LensListing(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const tableFiltersRef = React.useRef<HTMLElement>();

    const state = mobxReactLite.useLocalObservable(() => {
        return {
            page: 0,
        };
    });

    const onBackClick = React.useCallback(
        () => {
            history.back();
        },
        [],
    );

    const onCameraWorkflowClick = React.useCallback(
        () => {
            mobx.runInAction(() => {
                resetWizardStore();
                navigateTo('camera_listing');
            });
        },
        [],
    );

    const onCameraMakeChange = React.useCallback(
        (value: CameraMake) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.lensCameraMake = value;
            });
        },
        [],
    );

    const onPixelinkSensorFormatChange = React.useCallback(
        (value: number | null) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.lensPixelinkCameraSensorFormatId = value;
                wizardStore.lensCameraId = null;
            });
        },
        [],
    );

    const onCameraIdChange = React.useCallback(
        (value: number | null) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.lensCameraId = value;
            });
        },
        [],
    );

    const onCommonCameraSensorFormatIdChange = React.useCallback(
        (value: number | null) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.lensCommonCameraSensorFormatId = value;
            });
        },
        [],
    );

    const onPixelSizeChange = React.useCallback(
        (value: number) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.lensPixelSize = value;
            });
        },
        [],
    );

    const onResolutionWidthChange = React.useCallback(
        (value: number) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.lensResolutionWidth = value;
            });
        },
        [],
    );

    const onResolutionHeightChange = React.useCallback(
        (value: number) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.lensResolutionHeight = value;
            });
        },
        [],
    );

    const onLensTypeChange = React.useCallback(
        (value: LensType) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.lensType = value;
            });
        },
        [],
    );

    const onObjectWidthChange = React.useCallback(
        (value: number) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.lensObjectWidth = value;
            });
        },
        [],
    );

    const onObjectHeightChange = React.useCallback(
        (value: number) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.lensObjectHeight = value;
            });
        },
        [],
    );

    const onFeatureSizeChange = React.useCallback(
        (value: number) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.lensFeatureSize = value;
            });
        },
        [],
    );

    const onMaxObjectWidthChange = React.useCallback(
        (value: number) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.lensMaxObjectWidth = value;
            });
        },
        [],
    );

    const onMaxObjectHeightChange = React.useCallback(
        (value: number) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.lensMaxObjectHeight = value;
            });
        },
        [],
    );

    const onMaxFeatureSizeChange = React.useCallback(
        (value: number) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.lensMaxFeatureSize = value;
            });
        },
        [],
    );

    const onWorkingDistanceChange = React.useCallback(
        (value: number) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.lensWorkingDistance = value;
            });
        },
        [],
    );

    const onWavelengthChange = React.useCallback(
        (value: Wavelength) => {
            mobx.runInAction(() => {
                state.page = 0;
                wizardStore.lensWavelength = value;
            });
        },
        [],
    );

    const getTableAbsoluteTop = React.useCallback(
        (): number => {
            if (tableFiltersRef.current === undefined) return 0;

            const rect = tableFiltersRef.current.getBoundingClientRect();
            const absoluteTop = window.scrollY + rect.top;

            return Math.max(absoluteTop - 40, 0);
        },
        [],
    );

    const paginationScroll = React.useCallback(
        () => {
            window.scrollTo({
                top: getTableAbsoluteTop(),
                left: 0,
                behavior: 'smooth',
            });
        },
        [],
    );

    const onPageChange = React.useCallback(
        (page: number) => {
            mobx.runInAction(() => {
                state.page = page;
            });
            paginationScroll();
        },
        [],
    );

    const currentComputedData = computedData.get();

    const sortedCommonSensors = Object.values(wizardStore.tables.commonSensor).sort((a, b) => (a.width * a.height) - (b.width * b.height));
    const commonSensorFormatOptions = new Map<number | null, string>();
    commonSensorFormatOptions.set(null, '');
    for (const sensor of sortedCommonSensors) {
        commonSensorFormatOptions.set(sensor.id, sensor.name);
    }

    const sortedProductFamilies = Object.values(wizardStore.tables.productFamily).sort((a, b) => a.order - b.order);
    const productFamilyOptions = new Map<number | null, string>();
    productFamilyOptions.set(null, 'All');
    for (const productFamily of sortedProductFamilies) {
        productFamilyOptions.set(productFamily.id, productFamily.name);
    }

    const solutionRows: React.ReactElement[] = [];
    const startI = state.page * solutionsPerPage;
    const endI = Math.min(startI + solutionsPerPage, currentComputedData.solutions.length);
    for (let i = startI; i < endI; ++i) {
        const solution = currentComputedData.solutions[i];
        solutionRows.push(
            h(SolutionRow, {
                solution: solution,
                sensorWidth: currentComputedData.sensorSize.width,
                sensorHeight: currentComputedData.sensorSize.height,
                key: getSolutionKey(solution),
            })
        );
    }

    const sensorSize = getSensorSize();
    const targetMagnificationRange = getTargetMagnificationRange(
        currentComputedData.targetMagnification,
        currentComputedData.maxTargetMagnification,
        wizardStore.lensType,
    );

    const maybeSmallLabel = (label: string, required: boolean) => (
        wizardStore.lensType === 'zoom' ?
            h('div', { className: c('input-small-label', required ? 'input-label-required' : '') }, label ) :
            h(FieldLabel, { label: label, bold: true, required: required })
    );

    return h('div', { className: c('root') },
        h(WizardTitleBar, {
            backText: wizardStore.selectedCameraId === null ? 'Home' : 'Camera Selection',
            backOnClick: onBackClick,
            titleText: 'Lens Selector Guide',
            titleIcon: h(LensIcon, { size: 38 }),
            bottomBorder: true,
        }),

        h('div', { className: c('form-section') },
            h('div', { className: c('form-section-form') },
                h('div', { className: c('inputs') },
                    h('div', { className: c('input-group') },
                        h('div', { className: c('input-group-row') },
                            h('div', { className: c('input') },
                                h(FieldLabel, { label: 'Camera Selection', bold: true, required: true }),
                                h(Select, {
                                    value: wizardStore.lensCameraMake,
                                    onChange: onCameraMakeChange,
                                    options: cameraMakeOptions,
                                }),
                            ),
                        ),
                    ),

                    (wizardStore.lensCameraMake === 'pixelink' ?
                        h(React.Fragment, {},
                            h('div', { className: c('input-group') },
                                h('div', { className: c('input-group-row') },
                                    h('div', { className: c('input') },
                                        h(FieldLabel, { label: 'Camera Sensor Format', bold: true, required: true }),
                                        h(Select, {
                                            value: wizardStore.lensPixelinkCameraSensorFormatId,
                                            options: getCameraSensorFormatOptions(''),
                                            onChange: onPixelinkSensorFormatChange,
                                        }),
                                    ),

                                    h('div', { className: c('input') },
                                        h(FieldLabel, { label: 'Camera', bold: true, required: true }),
                                        h(Select, {
                                            value: wizardStore.lensCameraId,
                                            onChange: onCameraIdChange,
                                            options: getCameraOptions(wizardStore.lensPixelinkCameraSensorFormatId),
                                        }),
                                    ),
                                ),
                            ),

                            (wizardStore.lensCameraId === null ?
                                h('div', { className: c('input-group') },
                                    h('div', { className: c('input-group-row') },
                                        h('div', { className: c('input') },
                                            h('div', { className: c('camera-workflow-description') },
                                                'If you would like more camera filtering options, start with our ',
                                                h('span', { className: c('camera-workflow-link'), onClick: onCameraWorkflowClick },
                                                    'Camera Selection Guide',
                                                ),
                                                '.',
                                            ),
                                        ),
                                    ),
                                ) : null
                            ),
                        ) : null
                    ),

                    (wizardStore.lensCameraMake === 'common_size' ?
                        h('div', { className: c('input-group') },
                            h('div', { className: c('input-group-row') },
                                h('div', { className: c('input') },
                                    h(FieldLabel, { label: 'Camera Sensor Format', bold: true, required: true }),
                                    h(Select, {
                                        value: wizardStore.lensCommonCameraSensorFormatId,
                                        onChange: onCommonCameraSensorFormatIdChange,
                                        options: commonSensorFormatOptions,
                                    }),
                                ),

                                h('div', { className: c('input') },
                                    h(FieldLabel, { label: 'Pixel Size (μm)', bold: true, required: true }),
                                    h(NumberInput, {
                                        value: wizardStore.lensPixelSize,
                                        onChange: onPixelSizeChange,
                                        fractionDigits: 2,
                                        nonNegative: true,
                                        emptyZero: true,
                                    }),
                                ),
                            ),
                        ) : null
                    ),

                    (wizardStore.lensCameraMake === 'custom_size' || wizardStore.lensCameraMake === 'custom_line' ?
                        h('div', { className: c('input-group') },
                            h('div', { className: c('input-group-row') },
                                h('div', { className: c('input') },
                                    h(FieldLabel, { label: 'Horizontal (pixels)', bold: true }),
                                    h(NumberInput, {
                                        value: wizardStore.lensResolutionWidth,
                                        onChange: onResolutionWidthChange,
                                        fractionDigits: 2,
                                        nonNegative: true,
                                        emptyZero: true,
                                    }),
                                ),

                                (wizardStore.lensCameraMake !== 'custom_line' ?
                                    h('div', { className: c('input') },
                                        h(FieldLabel, { label: 'Vertical (pixels)', bold: true }),
                                        h(NumberInput, {
                                            value: wizardStore.lensResolutionHeight,
                                            onChange: onResolutionHeightChange,
                                            fractionDigits: 2,
                                            nonNegative: true,
                                            emptyZero: true,
                                        }),
                                    ) : null
                                ),

                                h('div', { className: c('input') },
                                    h(FieldLabel, { label: 'Pixel Size (μm)', bold: true }),
                                    h(NumberInput, {
                                        value: wizardStore.lensPixelSize,
                                        onChange: onPixelSizeChange,
                                        fractionDigits: 2,
                                        nonNegative: true,
                                        emptyZero: true,
                                    }),
                                ),
                            ),
                        ) : null
                    ),

                    h('div', { className: c('input-group') },
                        h('div', { className: c('input') },
                            h(FieldLabel, { label: 'Lens Type', bold: true }),
                            h(Select, {
                                value: wizardStore.lensType,
                                onChange: onLensTypeChange,
                                options: lensTypeOptions,
                            }),
                        ),
                    ),

                    h('div', { className: c('input-group') },
                        (wizardStore.lensType === 'zoom' ?
                            h(FieldLabel, { label: 'Min Field of View (high magnification)', bold: true }) : null
                        ),
                        h('div', { className: c('input-group-row') },
                            h('div', { className: c('input') },
                                maybeSmallLabel('Object Horizontal (mm)', true),
                                h(NumberInput, {
                                    value: wizardStore.lensObjectWidth,
                                    onChange: onObjectWidthChange,
                                    fractionDigits: 2,
                                    nonNegative: true,
                                    emptyZero: true,
                                }),
                            ),

                            (wizardStore.lensCameraMake !== 'custom_line' ?
                                h('div', { className: c('input') },
                                    maybeSmallLabel('Object Vertical (mm)', true),
                                    h(NumberInput, {
                                        value: wizardStore.lensObjectHeight,
                                        onChange: onObjectHeightChange,
                                        fractionDigits: 2,
                                        nonNegative: true,
                                        emptyZero: true,
                                    }),
                                ) : null
                            ),

                            h('div', { className: c('input') },
                                maybeSmallLabel('Feature Size (μm)', false),
                                h(NumberInput, {
                                    value: wizardStore.lensFeatureSize,
                                    onChange: onFeatureSizeChange,
                                    fractionDigits: 2,
                                    nonNegative: true,
                                    emptyZero: true,
                                }),
                            ),
                        ),
                    ),

                    (wizardStore.lensType === 'zoom' ?
                        h('div', { className: c('input-group') },
                            h(FieldLabel, { label: 'Max Field of View (low magnification)', bold: true }),
                            h('div', { className: c('input-group-row') },
                                h('div', { className: c('input') },
                                    maybeSmallLabel('Object Width (mm)', false),
                                    h(NumberInput, {
                                        value: wizardStore.lensMaxObjectWidth,
                                        onChange: onMaxObjectWidthChange,
                                        fractionDigits: 2,
                                        nonNegative: true,
                                        emptyZero: true,
                                    }),
                                ),

                                (wizardStore.lensCameraMake !== 'custom_line' ?
                                    h('div', { className: c('input') },
                                        maybeSmallLabel('Object Height (mm)', false),
                                        h(NumberInput, {
                                            value: wizardStore.lensMaxObjectHeight,
                                            onChange: onMaxObjectHeightChange,
                                            fractionDigits: 2,
                                            nonNegative: true,
                                            emptyZero: true,
                                        }),
                                    ) : null
                                ),

                                h('div', { className: c('input') },
                                    maybeSmallLabel('Feature Size (μm)', false),
                                    h(NumberInput, {
                                        value: wizardStore.lensMaxFeatureSize,
                                        onChange: onMaxFeatureSizeChange,
                                        fractionDigits: 2,
                                        nonNegative: true,
                                        emptyZero: true,
                                    }),
                                ),
                            ),
                        ) : null
                    ),

                    h('div', { className: c('input-group') },
                        h('div', { className: c('input') },
                            h(FieldLabel, { label: 'Working Distance (mm)', bold: true }),
                            h(NumberInput, {
                                value: wizardStore.lensWorkingDistance,
                                onChange: onWorkingDistanceChange,
                                fractionDigits: 2,
                                nonNegative: true,
                                emptyZero: true,
                            }),
                        ),
                    ),

                    h('div', { className: c('input-group') },
                        h('div', { className: c('input') },
                            h(FieldLabel, { label: 'Wavelength', bold: true }),
                            h(Select, {
                                value: wizardStore.lensWavelength,
                                onChange: onWavelengthChange,
                                options: wavelengthOptions,
                            }),
                        ),
                    ),
                ),

                (currentComputedData.targetMagnification > 0 ?
                    h('div', { className: c('targets') },
                        h('div', { className: c('target') },
                            h('span', { className: c('target-label') },
                                'Target Magnification: ',
                            ),
                            h('span', { className: c('target-value') },
                                displayRangeFloat(targetMagnificationRange),
                                'x',
                            ),
                        ),

                        h('div', { className: c('target') },
                            h('span', { className: c('target-label') },
                                'Target Camera Input (mm): ',
                            ),
                            h('span', { className: c('target-value') },
                                formatFloat(sensorSize.width) + ' H',
                                ' × ',
                                formatFloat(sensorSize.height) + ' V',
                                ' (' + formatFloat(getDiagonal(sensorSize.width, sensorSize.height)) + ' DIAG)',
                            ),
                        ),

                        h('div', { className: c('target') },
                            h('span', { className: c('target-label') },
                                'Target H×V Field of View (mm): ',
                            ),
                            h('span', { className: c('target-value') },
                                displayFieldOfViewRange(getFieldOfViewRange(sensorSize.width, sensorSize.height, targetMagnificationRange)),
                            ),
                        ),

                        h('div', { className: c('target') },
                            h('span', { className: c('target-label') },
                                'Target Diagonal Field of View (mm): ',
                            ),
                            h('span', { className: c('target-value') },
                                displayDiagonalFieldOfViewRange(getFieldOfViewRange(sensorSize.width, sensorSize.height, targetMagnificationRange)),
                            ),
                        ),
                    ) : null
                ),
            ),

            h('div', { className: c('form-section-diagram') },
                h('img', { src: store.imageLensDiagram, width: '386', height: '523' }),
            ),
        ),

        h('div', { className: c('table-filters'), ref: tableFiltersRef },
            h('div', { className: c('input-group') },
                h('div', { className: c('input-group-row') },
                    h('div', { className: c('input') },
                        h(FieldLabel, { label: 'Product Family', bold: true }),
                        createTwoWayBinding({
                            observable: wizardStore,
                            property: 'lensProductFamilyFilter',
                            childType: Select,
                            childProps: {
                                options: productFamilyOptions,
                            },
                        }),
                    ),

                    h('div', { className: c('input') },
                        h(FieldLabel, { label: 'Sort Results', bold: true }),
                        createTwoWayBinding({
                            observable: wizardStore,
                            property: 'lensSort',
                            childType: Select,
                            childProps: {
                                options: sortOptions,
                            },
                        }),
                    ),
                ),
            ),
        ),

        (currentComputedData.targetMagnification > 0 && currentComputedData.solutions.length > 0 ?
            h(React.Fragment, {},
                h('div', { className: c('solution-head') },
                    h('div', { className: c('solution-head-cell', 'solution-column-product-family') }, 'Product Family'),
                    h('div', { className: c('solution-head-cell', 'solution-column-parts') }, 'Parts'),
                    h('div', { className: c('solution-head-cell', 'solution-column-magnification') }, 'Magnification'),
                    h('div', { className: c('solution-head-cell', 'solution-column-field-of-view') }, 'Field of View (mm)'),
                    h('div', { className: c('solution-head-cell', 'solution-column-working-distance') }, 'Working Distance (mm)'),
                    h('div', { className: c('solution-head-cell', 'solution-column-resolve-limit') }, 'Lens Resolution (μm)'),
                    h('div', { className: c('solution-head-cell', 'solution-column-select') }),
                ),
                solutionRows,
                h('div', { className: c('pagination') },
                    h(WizardPagination, {
                        page: state.page,
                        itemsPerPage: solutionsPerPage,
                        totalItems: currentComputedData.solutions.length,
                        onPageChange: onPageChange,
                    }),
                ),
            ) :
            h('div', { className: c('no-solutions') },
                h('div', { className: c('no-solutions-title') },
                    'No lenses match your entries.',
                ),
                h('div', { className: c('no-solutions-description') },
                    'Please ',
                    h('a', { href: 'https://go.navitar.com/l/540462/2022-03-02/3zvmlxt', target: '_blank' },
                        'contact us',
                    ),
                    ' for custom or additional solutions.',
                ),
            )
        ),
    );
});
