import * as mobxReactLite from 'mobx-react-lite';
import { h, stringToTimestamp, timestampToString } from '../util';
import { TypedStringInput } from './typed_string_input/typed_string_input';

type Props = {
    value: string | null;
    onChange: (value: string | null) => void;
};

function valueEqual(value1: string, value2: string): boolean {
    return value1 === value2;
}

export const TimestampInput = mobxReactLite.observer(function TimestampInput(props: Props) {
    return h(TypedStringInput, {
        value: props.value,
        valueToString: timestampToString,
        stringToValue: stringToTimestamp,
        valueEqual: valueEqual,
        onChange: props.onChange,
        placeholder: 'MM/DD/YYYY   hh:mm',
    });
});
