import { DbMount } from '../db_types';

export const dbDataMount: { [index: string]: DbMount } = {
    '1': {
        id: 1,
        name: 'C-Mount',
        order: 1,
    },
    '2': {
        id: 2,
        name: 'CS-Mount',
        order: 2,
    },
    '3': {
        id: 3,
        name: 'ENG-Mount',
        order: 3,
    },
    '4': {
        id: 4,
        name: 'F-Mount',
        order: 4,
    },
    '5': {
        id: 5,
        name: 'M42-Thread',
        order: 5,
    },
    '6': {
        id: 6,
        name: 'M72-Thread',
        order: 6,
    },
    '7': {
        id: 7,
        name: 'SLR-Mount',
        order: 7,
    },
    '8': {
        id: 8,
        name: 'T-Mount',
        order: 8,
    },
    '9': {
        id: 9,
        name: 'TFL-Mount',
        order: 9,
    },
    '10': {
        id: 10,
        name: 'S-Mount',
        order: 10,
    },
};
