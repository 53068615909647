import { DbZoomXtender } from '../db_types';

export const dbDataZoomXtender: { [index: string]: DbZoomXtender } = {
    // Zoom 6000
    '1': {
        id: 1,
        productFamilyId: 1,
        name: 'ZoomXtender (1-50011)',
        coreName: '6.5X Zoom Core',
        coreMagnificationLow: 0.7,
        coreMagnificationHigh: 4.5,
        workingDistanceLow: 125,
        workingDistanceHigh: 760,
        fitEquationId: 1,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '2': {
        id: 2,
        productFamilyId: 1,
        name: 'ZoomXtender (1-6044)',
        coreName: '6.5X Zoom Core',
        coreMagnificationLow: 0.7,
        coreMagnificationHigh: 4.5,
        workingDistanceLow: 280,
        workingDistanceHigh: 4500,
        fitEquationId: 2,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },

    // 12x Zoom
    '3': {
        id: 3,
        productFamilyId: 2,
        name: 'ZoomXtender (3 spacers)',
        coreName: '12X Zoom Core',
        coreMagnificationLow: 0.58,
        coreMagnificationHigh: 7,
        workingDistanceLow: 120,
        workingDistanceHigh: 400,
        fitEquationId: 3,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '4': {
        id: 4,
        productFamilyId: 2,
        name: 'ZoomXtender (2 spacers)',
        coreName: '12X Zoom Core',
        coreMagnificationLow: 0.58,
        coreMagnificationHigh: 7,
        workingDistanceLow: 260,
        workingDistanceHigh: 2450,
        fitEquationId: 4,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
    '5': {
        id: 5,
        productFamilyId: 2,
        name: 'ZoomXtender (1 spacer)',
        coreName: '12X Zoom Core',
        coreMagnificationLow: 0.58,
        coreMagnificationHigh: 7,
        workingDistanceLow: 800,
        workingDistanceHigh: -1,
        fitEquationId: 5,
        wavelengthVisible: true,
        wavelengthNearInfrared: true,
        wavelengthShortWaveInfrared: false,
    },
};
