import * as mobx from 'mobx';
import { WizardRoute } from './wizard_types';
import { resetWizardStore, wizardStore } from './wizard_store';
import { store } from './store';

function onPopState() {
    if (location.pathname !== '/') return;
    onBack();
}

export function navigateTo(wizardRoute: WizardRoute) {
    mobx.runInAction(() => {
        wizardStore.wizardRoute = wizardRoute;
    });

    let dealerParam = '';
    if (store.dealerId !== '') {
        dealerParam = '?dealerId=' + encodeURIComponent(store.dealerId);
    }

    history.pushState(null, '', '/' + dealerParam);
    window.scrollTo(0, 0);
}

function onBack() {
    if (wizardStore.wizardRoute === 'home') return;

    if (wizardStore.wizardRoute === 'camera_listing') {
        mobx.runInAction(() => {
            resetWizardStore();
            wizardStore.wizardRoute = 'home';
        });
    } else if (wizardStore.wizardRoute === 'camera_detail') {
        mobx.runInAction(() => {
            wizardStore.selectedCameraId = null;
            wizardStore.wizardRoute = 'camera_listing';
        });
    } else if (wizardStore.wizardRoute === 'lens_listing') {
        mobx.runInAction(() => {
            wizardStore.selectedLensSolution = null;
            if (wizardStore.selectedCameraId === null) {
                resetWizardStore();
                wizardStore.wizardRoute = 'home';
            } else {
                wizardStore.wizardRoute = 'camera_detail';
            }
        });
    } else if (wizardStore.wizardRoute === 'lens_detail') {
        mobx.runInAction(() => {
            wizardStore.selectedLensSolution = null;
            wizardStore.wizardRoute = 'lens_listing';
        });
    } else if (wizardStore.wizardRoute === 'contact') {
        mobx.runInAction(() => {
            if (wizardStore.selectedLensSolution === null) {
                wizardStore.wizardRoute = 'camera_detail';
            } else {
                wizardStore.wizardRoute = 'lens_detail';
            }
        });
    }

    window.scrollTo(0, 0);
}

window.addEventListener('popstate', onPopState);
