import * as mobxReactLite from 'mobx-react-lite';
import { h } from '../../util';

type Props = {
    size: number;
};

export const VisibilityIcon = mobxReactLite.observer(function VisibilityIcon(props: Props) {
    return h('svg',
        {
            width: props.size.toString(),
            height: props.size.toString(),
            viewBox: '0 0 24 24',
            xmlns: 'http://www.w3.org/2000/svg',
        },
        h('path', {
            d: 'M12 4C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 12.5c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z',
        }),
    );
});
