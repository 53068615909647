import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses, getHomeUrl } from '../../util';
import { Link } from '../link';
import { routeToUrl } from '../../route_util';
import { cssScope } from './css_scope';
import { store } from '../../store';

type Props = {
    showHomeLink: boolean;
};

const c = scopedClasses(cssScope);

export const Error404Display = mobxReactLite.observer(function Error404Display(props: Props) {
    React.useEffect(
        () => {
            const element = document.createElement('meta');
            element.setAttribute('name', 'robots');
            element.setAttribute('content', 'noindex');
            document.head.appendChild(element);
            return () => {
                document.head.removeChild(element);
            };
        },
        [],
    );

    const logoUrl = store.user !== null ? getHomeUrl() : routeToUrl({ id: '/login' });

    return h('div', { className: c('root') },
        h('div', { className: c('content') },
            h(Link, { className: c('logo'), url: logoUrl },
            ),
            h('div', { className: c('supertitle') }, '404'),
            h('div', { className: c('title') }, 'Page Not Found'),
            h('div', { className: c('description') },
                'We are sorry, the page you are looking for does not exist. You may have mistyped the address, or the page may have moved.',
            ),

            (props.showHomeLink ?
                h(Link, { className: c('link'), url: logoUrl },
                    'Go home'
                ) : null
            ),
        ),
    );
});
