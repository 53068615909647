import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../util';
import { cssScope } from './css_scope';

type Props = {
    maxWidth: number;
    onClose: () => void;
    children?: React.ReactNode;
};

export const c = scopedClasses(cssScope);

export const Dialog = mobxReactLite.observer(function Dialog(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    React.useEffect(
        () => {
            const onKeyDown = (e: KeyboardEvent) => {
                if (e.key === 'Escape') {
                    propsRef.current.onClose();
                }
            };

            window.addEventListener('keydown', onKeyDown);

            return () => {
                window.removeEventListener('keydown', onKeyDown);
            }
        },
        [],
    );

    return h('div', { className: c('root') },
        h('div', { className: c('overlay'), onClick: props.onClose }),
        h('div', { className: c('content'), style: { maxWidth: props.maxWidth + 'px' } },
            props.children,
        ),
    );
});
