import { CameraMake, LensType, Wavelength } from './wizard_types';

export const cameraMakeOptions = new Map<CameraMake, string>([
    ['', ''],
    ['pixelink', 'Pixelink'],
    ['common_size', 'Common Sensor Size'],
    ['custom_size', 'H × V Sensor'],
    ['custom_line', 'Line Scan'],
]);

export const lensTypeOptions = new Map<LensType, string>([
    ['all', 'All'],
    ['zoom', 'Zoom'],
    ['fixed', 'Fixed'],
]);

export const wavelengthOptions = new Map<Wavelength, string>([
    ['visible', 'Visible'],
    ['near_infrared', 'Near Infrared'],
    ['short_wave_infrared', 'Short-Wave Infrared'],
]);
