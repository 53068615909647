import { DbCameraMount } from '../db_types';

// Autogenerated from spreadsheet import
export const dbDataCameraMount: { [index: string]: DbCameraMount } = {
    "558": {
        "cameraId": 277,
        "mountId": 2,
        "id": 558
    },
    "453": {
        "cameraId": 208,
        "mountId": 2,
        "id": 453
    },
    "584": {
        "cameraId": 291,
        "mountId": 2,
        "id": 584
    },
    "487": {
        "cameraId": 226,
        "mountId": 1,
        "id": 487
    },
    "637": {
        "cameraId": 312,
        "mountId": 2,
        "id": 637
    },
    "519": {
        "cameraId": 256,
        "mountId": 1,
        "id": 519
    },
    "357": {
        "cameraId": 166,
        "mountId": 2,
        "id": 357
    },
    "716": {
        "cameraId": 356,
        "mountId": 1,
        "id": 716
    },
    "275": {
        "cameraId": 135,
        "mountId": 1,
        "id": 275
    },
    "530": {
        "cameraId": 267,
        "mountId": 10,
        "id": 530
    },
    "389": {
        "cameraId": 177,
        "mountId": 10,
        "id": 389
    },
    "586": {
        "cameraId": 292,
        "mountId": 10,
        "id": 586
    },
    "410": {
        "cameraId": 186,
        "mountId": 1,
        "id": 410
    },
    "433": {
        "cameraId": 199,
        "mountId": 1,
        "id": 433
    },
    "521": {
        "cameraId": 258,
        "mountId": 1,
        "id": 521
    },
    "451": {
        "cameraId": 207,
        "mountId": 10,
        "id": 451
    },
    "291": {
        "cameraId": 142,
        "mountId": 1,
        "id": 291
    },
    "443": {
        "cameraId": 203,
        "mountId": 10,
        "id": 443
    },
    "249": {
        "cameraId": 123,
        "mountId": 1,
        "id": 249
    },
    "638": {
        "cameraId": 313,
        "mountId": 1,
        "id": 638
    },
    "299": {
        "cameraId": 144,
        "mountId": 2,
        "id": 299
    },
    "121": {
        "cameraId": 63,
        "mountId": 1,
        "id": 121
    },
    "734": {
        "cameraId": 365,
        "mountId": 1,
        "id": 734
    },
    "287": {
        "cameraId": 140,
        "mountId": 2,
        "id": 287
    },
    "65": {
        "cameraId": 40,
        "mountId": 10,
        "id": 65
    },
    "702": {
        "cameraId": 349,
        "mountId": 1,
        "id": 702
    },
    "70": {
        "cameraId": 42,
        "mountId": 1,
        "id": 70
    },
    "218": {
        "cameraId": 112,
        "mountId": 2,
        "id": 218
    },
    "648": {
        "cameraId": 316,
        "mountId": 10,
        "id": 648
    },
    "62": {
        "cameraId": 39,
        "mountId": 2,
        "id": 62
    },
    "74": {
        "cameraId": 43,
        "mountId": 10,
        "id": 74
    },
    "475": {
        "cameraId": 220,
        "mountId": 1,
        "id": 475
    },
    "497": {
        "cameraId": 234,
        "mountId": 1,
        "id": 497
    },
    "580": {
        "cameraId": 289,
        "mountId": 10,
        "id": 580
    },
    "164": {
        "cameraId": 90,
        "mountId": 10,
        "id": 164
    },
    "282": {
        "cameraId": 138,
        "mountId": 2,
        "id": 282
    },
    "273": {
        "cameraId": 134,
        "mountId": 1,
        "id": 273
    },
    "186": {
        "cameraId": 102,
        "mountId": 1,
        "id": 186
    },
    "430": {
        "cameraId": 197,
        "mountId": 2,
        "id": 430
    },
    "641": {
        "cameraId": 314,
        "mountId": 1,
        "id": 641
    },
    "529": {
        "cameraId": 266,
        "mountId": 10,
        "id": 529
    },
    "370": {
        "cameraId": 171,
        "mountId": 1,
        "id": 370
    },
    "233": {
        "cameraId": 117,
        "mountId": 2,
        "id": 233
    },
    "298": {
        "cameraId": 144,
        "mountId": 10,
        "id": 298
    },
    "188": {
        "cameraId": 102,
        "mountId": 2,
        "id": 188
    },
    "240": {
        "cameraId": 120,
        "mountId": 1,
        "id": 240
    },
    "110": {
        "cameraId": 59,
        "mountId": 10,
        "id": 110
    },
    "130": {
        "cameraId": 66,
        "mountId": 1,
        "id": 130
    },
    "620": {
        "cameraId": 307,
        "mountId": 1,
        "id": 620
    },
    "311": {
        "cameraId": 148,
        "mountId": 2,
        "id": 311
    },
    "128": {
        "cameraId": 65,
        "mountId": 10,
        "id": 128
    },
    "399": {
        "cameraId": 181,
        "mountId": 10,
        "id": 399
    },
    "377": {
        "cameraId": 173,
        "mountId": 10,
        "id": 377
    },
    "468": {
        "cameraId": 216,
        "mountId": 2,
        "id": 468
    },
    "259": {
        "cameraId": 127,
        "mountId": 1,
        "id": 259
    },
    "210": {
        "cameraId": 110,
        "mountId": 1,
        "id": 210
    },
    "229": {
        "cameraId": 116,
        "mountId": 10,
        "id": 229
    },
    "153": {
        "cameraId": 79,
        "mountId": 10,
        "id": 153
    },
    "621": {
        "cameraId": 307,
        "mountId": 10,
        "id": 621
    },
    "213": {
        "cameraId": 111,
        "mountId": 1,
        "id": 213
    },
    "670": {
        "cameraId": 329,
        "mountId": 1,
        "id": 670
    },
    "343": {
        "cameraId": 159,
        "mountId": 10,
        "id": 343
    },
    "472": {
        "cameraId": 218,
        "mountId": 2,
        "id": 472
    },
    "7": {
        "cameraId": 7,
        "mountId": 1,
        "id": 7
    },
    "59": {
        "cameraId": 38,
        "mountId": 10,
        "id": 59
    },
    "473": {
        "cameraId": 219,
        "mountId": 1,
        "id": 473
    },
    "86": {
        "cameraId": 47,
        "mountId": 10,
        "id": 86
    },
    "756": {
        "cameraId": 376,
        "mountId": 1,
        "id": 756
    },
    "613": {
        "cameraId": 301,
        "mountId": 10,
        "id": 613
    },
    "491": {
        "cameraId": 228,
        "mountId": 1,
        "id": 491
    },
    "154": {
        "cameraId": 80,
        "mountId": 1,
        "id": 154
    },
    "20": {
        "cameraId": 20,
        "mountId": 1,
        "id": 20
    },
    "224": {
        "cameraId": 114,
        "mountId": 2,
        "id": 224
    },
    "355": {
        "cameraId": 166,
        "mountId": 1,
        "id": 355
    },
    "592": {
        "cameraId": 294,
        "mountId": 10,
        "id": 592
    },
    "610": {
        "cameraId": 300,
        "mountId": 10,
        "id": 610
    },
    "571": {
        "cameraId": 282,
        "mountId": 1,
        "id": 571
    },
    "466": {
        "cameraId": 215,
        "mountId": 2,
        "id": 466
    },
    "72": {
        "cameraId": 42,
        "mountId": 10,
        "id": 72
    },
    "454": {
        "cameraId": 209,
        "mountId": 1,
        "id": 454
    },
    "463": {
        "cameraId": 214,
        "mountId": 2,
        "id": 463
    },
    "58": {
        "cameraId": 38,
        "mountId": 1,
        "id": 58
    },
    "205": {
        "cameraId": 108,
        "mountId": 10,
        "id": 205
    },
    "555": {
        "cameraId": 276,
        "mountId": 2,
        "id": 555
    },
    "552": {
        "cameraId": 275,
        "mountId": 2,
        "id": 552
    },
    "60": {
        "cameraId": 38,
        "mountId": 2,
        "id": 60
    },
    "459": {
        "cameraId": 212,
        "mountId": 10,
        "id": 459
    },
    "175": {
        "cameraId": 96,
        "mountId": 2,
        "id": 175
    },
    "322": {
        "cameraId": 152,
        "mountId": 10,
        "id": 322
    },
    "510": {
        "cameraId": 247,
        "mountId": 1,
        "id": 510
    },
    "662": {
        "cameraId": 325,
        "mountId": 1,
        "id": 662
    },
    "27": {
        "cameraId": 25,
        "mountId": 1,
        "id": 27
    },
    "352": {
        "cameraId": 165,
        "mountId": 1,
        "id": 352
    },
    "493": {
        "cameraId": 230,
        "mountId": 1,
        "id": 493
    },
    "416": {
        "cameraId": 189,
        "mountId": 1,
        "id": 416
    },
    "694": {
        "cameraId": 345,
        "mountId": 2,
        "id": 694
    },
    "1": {
        "cameraId": 1,
        "mountId": 1,
        "id": 1
    },
    "631": {
        "cameraId": 310,
        "mountId": 2,
        "id": 631
    },
    "69": {
        "cameraId": 41,
        "mountId": 2,
        "id": 69
    },
    "101": {
        "cameraId": 55,
        "mountId": 10,
        "id": 101
    },
    "24": {
        "cameraId": 24,
        "mountId": 1,
        "id": 24
    },
    "547": {
        "cameraId": 274,
        "mountId": 1,
        "id": 547
    },
    "102": {
        "cameraId": 56,
        "mountId": 10,
        "id": 102
    },
    "713": {
        "cameraId": 354,
        "mountId": 10,
        "id": 713
    },
    "385": {
        "cameraId": 176,
        "mountId": 1,
        "id": 385
    },
    "135": {
        "cameraId": 67,
        "mountId": 2,
        "id": 135
    },
    "397": {
        "cameraId": 180,
        "mountId": 2,
        "id": 397
    },
    "490": {
        "cameraId": 227,
        "mountId": 2,
        "id": 490
    },
    "752": {
        "cameraId": 374,
        "mountId": 1,
        "id": 752
    },
    "354": {
        "cameraId": 165,
        "mountId": 2,
        "id": 354
    },
    "360": {
        "cameraId": 167,
        "mountId": 2,
        "id": 360
    },
    "55": {
        "cameraId": 35,
        "mountId": 10,
        "id": 55
    },
    "568": {
        "cameraId": 281,
        "mountId": 1,
        "id": 568
    },
    "688": {
        "cameraId": 343,
        "mountId": 10,
        "id": 688
    },
    "269": {
        "cameraId": 132,
        "mountId": 1,
        "id": 269
    },
    "676": {
        "cameraId": 335,
        "mountId": 1,
        "id": 676
    },
    "448": {
        "cameraId": 206,
        "mountId": 1,
        "id": 448
    },
    "527": {
        "cameraId": 264,
        "mountId": 10,
        "id": 527
    },
    "206": {
        "cameraId": 108,
        "mountId": 2,
        "id": 206
    },
    "165": {
        "cameraId": 91,
        "mountId": 10,
        "id": 165
    },
    "715": {
        "cameraId": 355,
        "mountId": 10,
        "id": 715
    },
    "387": {
        "cameraId": 176,
        "mountId": 2,
        "id": 387
    },
    "652": {
        "cameraId": 319,
        "mountId": 10,
        "id": 652
    },
    "683": {
        "cameraId": 341,
        "mountId": 10,
        "id": 683
    },
    "85": {
        "cameraId": 47,
        "mountId": 1,
        "id": 85
    },
    "721": {
        "cameraId": 358,
        "mountId": 10,
        "id": 721
    },
    "615": {
        "cameraId": 302,
        "mountId": 2,
        "id": 615
    },
    "681": {
        "cameraId": 340,
        "mountId": 1,
        "id": 681
    },
    "225": {
        "cameraId": 115,
        "mountId": 1,
        "id": 225
    },
    "297": {
        "cameraId": 144,
        "mountId": 1,
        "id": 297
    },
    "39": {
        "cameraId": 29,
        "mountId": 1,
        "id": 39
    },
    "274": {
        "cameraId": 134,
        "mountId": 2,
        "id": 274
    },
    "88": {
        "cameraId": 48,
        "mountId": 1,
        "id": 88
    },
    "217": {
        "cameraId": 112,
        "mountId": 10,
        "id": 217
    },
    "46": {
        "cameraId": 31,
        "mountId": 10,
        "id": 46
    },
    "682": {
        "cameraId": 341,
        "mountId": 1,
        "id": 682
    },
    "508": {
        "cameraId": 245,
        "mountId": 10,
        "id": 508
    },
    "149": {
        "cameraId": 77,
        "mountId": 1,
        "id": 149
    },
    "415": {
        "cameraId": 188,
        "mountId": 10,
        "id": 415
    },
    "239": {
        "cameraId": 119,
        "mountId": 2,
        "id": 239
    },
    "478": {
        "cameraId": 221,
        "mountId": 2,
        "id": 478
    },
    "157": {
        "cameraId": 83,
        "mountId": 1,
        "id": 157
    },
    "345": {
        "cameraId": 160,
        "mountId": 10,
        "id": 345
    },
    "300": {
        "cameraId": 145,
        "mountId": 1,
        "id": 300
    },
    "743": {
        "cameraId": 369,
        "mountId": 9,
        "id": 743
    },
    "4": {
        "cameraId": 4,
        "mountId": 1,
        "id": 4
    },
    "550": {
        "cameraId": 275,
        "mountId": 1,
        "id": 550
    },
    "204": {
        "cameraId": 108,
        "mountId": 1,
        "id": 204
    },
    "470": {
        "cameraId": 217,
        "mountId": 2,
        "id": 470
    },
    "646": {
        "cameraId": 315,
        "mountId": 2,
        "id": 646
    },
    "77": {
        "cameraId": 44,
        "mountId": 10,
        "id": 77
    },
    "106": {
        "cameraId": 58,
        "mountId": 1,
        "id": 106
    },
    "197": {
        "cameraId": 105,
        "mountId": 2,
        "id": 197
    },
    "405": {
        "cameraId": 184,
        "mountId": 1,
        "id": 405
    },
    "726": {
        "cameraId": 361,
        "mountId": 1,
        "id": 726
    },
    "755": {
        "cameraId": 375,
        "mountId": 10,
        "id": 755
    },
    "518": {
        "cameraId": 255,
        "mountId": 1,
        "id": 518
    },
    "232": {
        "cameraId": 117,
        "mountId": 10,
        "id": 232
    },
    "260": {
        "cameraId": 127,
        "mountId": 2,
        "id": 260
    },
    "267": {
        "cameraId": 131,
        "mountId": 1,
        "id": 267
    },
    "119": {
        "cameraId": 62,
        "mountId": 10,
        "id": 119
    },
    "319": {
        "cameraId": 151,
        "mountId": 10,
        "id": 319
    },
    "534": {
        "cameraId": 269,
        "mountId": 2,
        "id": 534
    },
    "222": {
        "cameraId": 114,
        "mountId": 1,
        "id": 222
    },
    "603": {
        "cameraId": 298,
        "mountId": 1,
        "id": 603
    },
    "293": {
        "cameraId": 142,
        "mountId": 2,
        "id": 293
    },
    "95": {
        "cameraId": 53,
        "mountId": 1,
        "id": 95
    },
    "450": {
        "cameraId": 207,
        "mountId": 1,
        "id": 450
    },
    "329": {
        "cameraId": 154,
        "mountId": 2,
        "id": 329
    },
    "144": {
        "cameraId": 75,
        "mountId": 2,
        "id": 144
    },
    "504": {
        "cameraId": 241,
        "mountId": 10,
        "id": 504
    },
    "505": {
        "cameraId": 242,
        "mountId": 10,
        "id": 505
    },
    "723": {
        "cameraId": 359,
        "mountId": 10,
        "id": 723
    },
    "176": {
        "cameraId": 97,
        "mountId": 1,
        "id": 176
    },
    "471": {
        "cameraId": 218,
        "mountId": 1,
        "id": 471
    },
    "349": {
        "cameraId": 164,
        "mountId": 1,
        "id": 349
    },
    "512": {
        "cameraId": 249,
        "mountId": 1,
        "id": 512
    },
    "710": {
        "cameraId": 353,
        "mountId": 1,
        "id": 710
    },
    "192": {
        "cameraId": 104,
        "mountId": 1,
        "id": 192
    },
    "54": {
        "cameraId": 34,
        "mountId": 10,
        "id": 54
    },
    "92": {
        "cameraId": 50,
        "mountId": 10,
        "id": 92
    },
    "221": {
        "cameraId": 113,
        "mountId": 2,
        "id": 221
    },
    "141": {
        "cameraId": 73,
        "mountId": 10,
        "id": 141
    },
    "502": {
        "cameraId": 239,
        "mountId": 1,
        "id": 502
    },
    "464": {
        "cameraId": 215,
        "mountId": 1,
        "id": 464
    },
    "307": {
        "cameraId": 147,
        "mountId": 10,
        "id": 307
    },
    "290": {
        "cameraId": 141,
        "mountId": 2,
        "id": 290
    },
    "627": {
        "cameraId": 309,
        "mountId": 10,
        "id": 627
    },
    "517": {
        "cameraId": 254,
        "mountId": 1,
        "id": 517
    },
    "361": {
        "cameraId": 168,
        "mountId": 1,
        "id": 361
    },
    "264": {
        "cameraId": 129,
        "mountId": 2,
        "id": 264
    },
    "137": {
        "cameraId": 69,
        "mountId": 10,
        "id": 137
    },
    "356": {
        "cameraId": 166,
        "mountId": 10,
        "id": 356
    },
    "728": {
        "cameraId": 362,
        "mountId": 1,
        "id": 728
    },
    "678": {
        "cameraId": 337,
        "mountId": 1,
        "id": 678
    },
    "327": {
        "cameraId": 154,
        "mountId": 1,
        "id": 327
    },
    "234": {
        "cameraId": 118,
        "mountId": 1,
        "id": 234
    },
    "104": {
        "cameraId": 57,
        "mountId": 2,
        "id": 104
    },
    "353": {
        "cameraId": 165,
        "mountId": 10,
        "id": 353
    },
    "15": {
        "cameraId": 15,
        "mountId": 1,
        "id": 15
    },
    "48": {
        "cameraId": 32,
        "mountId": 1,
        "id": 48
    },
    "242": {
        "cameraId": 120,
        "mountId": 2,
        "id": 242
    },
    "50": {
        "cameraId": 32,
        "mountId": 2,
        "id": 50
    },
    "557": {
        "cameraId": 277,
        "mountId": 10,
        "id": 557
    },
    "251": {
        "cameraId": 123,
        "mountId": 2,
        "id": 251
    },
    "394": {
        "cameraId": 179,
        "mountId": 10,
        "id": 394
    },
    "116": {
        "cameraId": 61,
        "mountId": 2,
        "id": 116
    },
    "585": {
        "cameraId": 292,
        "mountId": 1,
        "id": 585
    },
    "583": {
        "cameraId": 291,
        "mountId": 10,
        "id": 583
    },
    "75": {
        "cameraId": 43,
        "mountId": 2,
        "id": 75
    },
    "437": {
        "cameraId": 200,
        "mountId": 2,
        "id": 437
    },
    "516": {
        "cameraId": 253,
        "mountId": 1,
        "id": 516
    },
    "687": {
        "cameraId": 342,
        "mountId": 2,
        "id": 687
    },
    "159": {
        "cameraId": 85,
        "mountId": 1,
        "id": 159
    },
    "709": {
        "cameraId": 352,
        "mountId": 10,
        "id": 709
    },
    "99": {
        "cameraId": 54,
        "mountId": 2,
        "id": 99
    },
    "540": {
        "cameraId": 271,
        "mountId": 2,
        "id": 540
    },
    "645": {
        "cameraId": 315,
        "mountId": 10,
        "id": 645
    },
    "749": {
        "cameraId": 372,
        "mountId": 9,
        "id": 749
    },
    "479": {
        "cameraId": 222,
        "mountId": 1,
        "id": 479
    },
    "630": {
        "cameraId": 310,
        "mountId": 10,
        "id": 630
    },
    "281": {
        "cameraId": 138,
        "mountId": 1,
        "id": 281
    },
    "402": {
        "cameraId": 182,
        "mountId": 10,
        "id": 402
    },
    "669": {
        "cameraId": 328,
        "mountId": 10,
        "id": 669
    },
    "740": {
        "cameraId": 368,
        "mountId": 1,
        "id": 740
    },
    "429": {
        "cameraId": 197,
        "mountId": 10,
        "id": 429
    },
    "309": {
        "cameraId": 148,
        "mountId": 1,
        "id": 309
    },
    "458": {
        "cameraId": 211,
        "mountId": 10,
        "id": 458
    },
    "21": {
        "cameraId": 21,
        "mountId": 1,
        "id": 21
    },
    "388": {
        "cameraId": 177,
        "mountId": 1,
        "id": 388
    },
    "495": {
        "cameraId": 232,
        "mountId": 1,
        "id": 495
    },
    "626": {
        "cameraId": 309,
        "mountId": 1,
        "id": 626
    },
    "31": {
        "cameraId": 26,
        "mountId": 10,
        "id": 31
    },
    "113": {
        "cameraId": 60,
        "mountId": 10,
        "id": 113
    },
    "32": {
        "cameraId": 26,
        "mountId": 2,
        "id": 32
    },
    "407": {
        "cameraId": 184,
        "mountId": 2,
        "id": 407
    },
    "398": {
        "cameraId": 181,
        "mountId": 1,
        "id": 398
    },
    "136": {
        "cameraId": 68,
        "mountId": 10,
        "id": 136
    },
    "691": {
        "cameraId": 344,
        "mountId": 2,
        "id": 691
    },
    "139": {
        "cameraId": 71,
        "mountId": 10,
        "id": 139
    },
    "506": {
        "cameraId": 243,
        "mountId": 10,
        "id": 506
    },
    "396": {
        "cameraId": 180,
        "mountId": 10,
        "id": 396
    },
    "460": {
        "cameraId": 213,
        "mountId": 10,
        "id": 460
    },
    "483": {
        "cameraId": 224,
        "mountId": 1,
        "id": 483
    },
    "589": {
        "cameraId": 293,
        "mountId": 10,
        "id": 589
    },
    "581": {
        "cameraId": 290,
        "mountId": 10,
        "id": 581
    },
    "174": {
        "cameraId": 96,
        "mountId": 1,
        "id": 174
    },
    "578": {
        "cameraId": 287,
        "mountId": 10,
        "id": 578
    },
    "331": {
        "cameraId": 155,
        "mountId": 10,
        "id": 331
    },
    "363": {
        "cameraId": 168,
        "mountId": 2,
        "id": 363
    },
    "284": {
        "cameraId": 139,
        "mountId": 2,
        "id": 284
    },
    "208": {
        "cameraId": 109,
        "mountId": 10,
        "id": 208
    },
    "305": {
        "cameraId": 146,
        "mountId": 2,
        "id": 305
    },
    "708": {
        "cameraId": 352,
        "mountId": 1,
        "id": 708
    },
    "182": {
        "cameraId": 100,
        "mountId": 1,
        "id": 182
    },
    "256": {
        "cameraId": 125,
        "mountId": 2,
        "id": 256
    },
    "657": {
        "cameraId": 322,
        "mountId": 2,
        "id": 657
    },
    "514": {
        "cameraId": 251,
        "mountId": 1,
        "id": 514
    },
    "731": {
        "cameraId": 363,
        "mountId": 10,
        "id": 731
    },
    "619": {
        "cameraId": 306,
        "mountId": 10,
        "id": 619
    },
    "485": {
        "cameraId": 225,
        "mountId": 1,
        "id": 485
    },
    "214": {
        "cameraId": 111,
        "mountId": 10,
        "id": 214
    },
    "193": {
        "cameraId": 104,
        "mountId": 10,
        "id": 193
    },
    "685": {
        "cameraId": 342,
        "mountId": 1,
        "id": 685
    },
    "635": {
        "cameraId": 312,
        "mountId": 1,
        "id": 635
    },
    "442": {
        "cameraId": 203,
        "mountId": 1,
        "id": 442
    },
    "561": {
        "cameraId": 278,
        "mountId": 2,
        "id": 561
    },
    "656": {
        "cameraId": 321,
        "mountId": 2,
        "id": 656
    },
    "607": {
        "cameraId": 299,
        "mountId": 10,
        "id": 607
    },
    "241": {
        "cameraId": 120,
        "mountId": 10,
        "id": 241
    },
    "314": {
        "cameraId": 149,
        "mountId": 2,
        "id": 314
    },
    "226": {
        "cameraId": 115,
        "mountId": 10,
        "id": 226
    },
    "235": {
        "cameraId": 118,
        "mountId": 10,
        "id": 235
    },
    "672": {
        "cameraId": 331,
        "mountId": 1,
        "id": 672
    },
    "420": {
        "cameraId": 191,
        "mountId": 2,
        "id": 420
    },
    "418": {
        "cameraId": 190,
        "mountId": 1,
        "id": 418
    },
    "262": {
        "cameraId": 128,
        "mountId": 2,
        "id": 262
    },
    "263": {
        "cameraId": 129,
        "mountId": 1,
        "id": 263
    },
    "304": {
        "cameraId": 146,
        "mountId": 10,
        "id": 304
    },
    "401": {
        "cameraId": 182,
        "mountId": 1,
        "id": 401
    },
    "673": {
        "cameraId": 332,
        "mountId": 1,
        "id": 673
    },
    "40": {
        "cameraId": 29,
        "mountId": 10,
        "id": 40
    },
    "129": {
        "cameraId": 65,
        "mountId": 2,
        "id": 129
    },
    "600": {
        "cameraId": 297,
        "mountId": 1,
        "id": 600
    },
    "729": {
        "cameraId": 362,
        "mountId": 10,
        "id": 729
    },
    "467": {
        "cameraId": 216,
        "mountId": 1,
        "id": 467
    },
    "445": {
        "cameraId": 204,
        "mountId": 10,
        "id": 445
    },
    "317": {
        "cameraId": 150,
        "mountId": 2,
        "id": 317
    },
    "294": {
        "cameraId": 143,
        "mountId": 1,
        "id": 294
    },
    "91": {
        "cameraId": 49,
        "mountId": 10,
        "id": 91
    },
    "364": {
        "cameraId": 169,
        "mountId": 1,
        "id": 364
    },
    "515": {
        "cameraId": 252,
        "mountId": 1,
        "id": 515
    },
    "412": {
        "cameraId": 187,
        "mountId": 1,
        "id": 412
    },
    "553": {
        "cameraId": 276,
        "mountId": 1,
        "id": 553
    },
    "341": {
        "cameraId": 158,
        "mountId": 2,
        "id": 341
    },
    "117": {
        "cameraId": 61,
        "mountId": 10,
        "id": 117
    },
    "665": {
        "cameraId": 326,
        "mountId": 10,
        "id": 665
    },
    "523": {
        "cameraId": 260,
        "mountId": 1,
        "id": 523
    },
    "172": {
        "cameraId": 95,
        "mountId": 1,
        "id": 172
    },
    "601": {
        "cameraId": 297,
        "mountId": 10,
        "id": 601
    },
    "108": {
        "cameraId": 58,
        "mountId": 10,
        "id": 108
    },
    "156": {
        "cameraId": 82,
        "mountId": 1,
        "id": 156
    },
    "358": {
        "cameraId": 167,
        "mountId": 1,
        "id": 358
    },
    "308": {
        "cameraId": 147,
        "mountId": 2,
        "id": 308
    },
    "649": {
        "cameraId": 316,
        "mountId": 2,
        "id": 649
    },
    "531": {
        "cameraId": 268,
        "mountId": 1,
        "id": 531
    },
    "223": {
        "cameraId": 114,
        "mountId": 10,
        "id": 223
    },
    "419": {
        "cameraId": 190,
        "mountId": 10,
        "id": 419
    },
    "365": {
        "cameraId": 169,
        "mountId": 10,
        "id": 365
    },
    "181": {
        "cameraId": 99,
        "mountId": 2,
        "id": 181
    },
    "417": {
        "cameraId": 189,
        "mountId": 10,
        "id": 417
    },
    "278": {
        "cameraId": 136,
        "mountId": 2,
        "id": 278
    },
    "56": {
        "cameraId": 36,
        "mountId": 10,
        "id": 56
    },
    "33": {
        "cameraId": 27,
        "mountId": 1,
        "id": 33
    },
    "13": {
        "cameraId": 13,
        "mountId": 1,
        "id": 13
    },
    "22": {
        "cameraId": 22,
        "mountId": 1,
        "id": 22
    },
    "618": {
        "cameraId": 305,
        "mountId": 10,
        "id": 618
    },
    "380": {
        "cameraId": 174,
        "mountId": 10,
        "id": 380
    },
    "257": {
        "cameraId": 126,
        "mountId": 1,
        "id": 257
    },
    "338": {
        "cameraId": 157,
        "mountId": 2,
        "id": 338
    },
    "500": {
        "cameraId": 237,
        "mountId": 1,
        "id": 500
    },
    "168": {
        "cameraId": 93,
        "mountId": 2,
        "id": 168
    },
    "496": {
        "cameraId": 233,
        "mountId": 1,
        "id": 496
    },
    "347": {
        "cameraId": 162,
        "mountId": 10,
        "id": 347
    },
    "501": {
        "cameraId": 238,
        "mountId": 1,
        "id": 501
    },
    "596": {
        "cameraId": 295,
        "mountId": 2,
        "id": 596
    },
    "90": {
        "cameraId": 48,
        "mountId": 2,
        "id": 90
    },
    "237": {
        "cameraId": 119,
        "mountId": 1,
        "id": 237
    },
    "292": {
        "cameraId": 142,
        "mountId": 10,
        "id": 292
    },
    "109": {
        "cameraId": 59,
        "mountId": 1,
        "id": 109
    },
    "216": {
        "cameraId": 112,
        "mountId": 1,
        "id": 216
    },
    "191": {
        "cameraId": 103,
        "mountId": 2,
        "id": 191
    },
    "498": {
        "cameraId": 235,
        "mountId": 1,
        "id": 498
    },
    "375": {
        "cameraId": 172,
        "mountId": 2,
        "id": 375
    },
    "525": {
        "cameraId": 262,
        "mountId": 10,
        "id": 525
    },
    "367": {
        "cameraId": 170,
        "mountId": 1,
        "id": 367
    },
    "143": {
        "cameraId": 75,
        "mountId": 1,
        "id": 143
    },
    "742": {
        "cameraId": 369,
        "mountId": 1,
        "id": 742
    },
    "178": {
        "cameraId": 98,
        "mountId": 1,
        "id": 178
    },
    "640": {
        "cameraId": 313,
        "mountId": 2,
        "id": 640
    },
    "247": {
        "cameraId": 122,
        "mountId": 10,
        "id": 247
    },
    "328": {
        "cameraId": 154,
        "mountId": 10,
        "id": 328
    },
    "391": {
        "cameraId": 178,
        "mountId": 1,
        "id": 391
    },
    "167": {
        "cameraId": 93,
        "mountId": 1,
        "id": 167
    },
    "707": {
        "cameraId": 351,
        "mountId": 10,
        "id": 707
    },
    "36": {
        "cameraId": 28,
        "mountId": 1,
        "id": 36
    },
    "41": {
        "cameraId": 29,
        "mountId": 2,
        "id": 41
    },
    "474": {
        "cameraId": 219,
        "mountId": 2,
        "id": 474
    },
    "187": {
        "cameraId": 102,
        "mountId": 10,
        "id": 187
    },
    "551": {
        "cameraId": 275,
        "mountId": 10,
        "id": 551
    },
    "528": {
        "cameraId": 265,
        "mountId": 10,
        "id": 528
    },
    "599": {
        "cameraId": 296,
        "mountId": 2,
        "id": 599
    },
    "376": {
        "cameraId": 173,
        "mountId": 1,
        "id": 376
    },
    "195": {
        "cameraId": 105,
        "mountId": 1,
        "id": 195
    },
    "316": {
        "cameraId": 150,
        "mountId": 10,
        "id": 316
    },
    "668": {
        "cameraId": 328,
        "mountId": 1,
        "id": 668
    },
    "428": {
        "cameraId": 197,
        "mountId": 1,
        "id": 428
    },
    "303": {
        "cameraId": 146,
        "mountId": 1,
        "id": 303
    },
    "671": {
        "cameraId": 330,
        "mountId": 1,
        "id": 671
    },
    "368": {
        "cameraId": 170,
        "mountId": 10,
        "id": 368
    },
    "560": {
        "cameraId": 278,
        "mountId": 10,
        "id": 560
    },
    "565": {
        "cameraId": 280,
        "mountId": 1,
        "id": 565
    },
    "310": {
        "cameraId": 148,
        "mountId": 10,
        "id": 310
    },
    "366": {
        "cameraId": 169,
        "mountId": 2,
        "id": 366
    },
    "118": {
        "cameraId": 62,
        "mountId": 1,
        "id": 118
    },
    "522": {
        "cameraId": 259,
        "mountId": 1,
        "id": 522
    },
    "150": {
        "cameraId": 78,
        "mountId": 1,
        "id": 150
    },
    "313": {
        "cameraId": 149,
        "mountId": 10,
        "id": 313
    },
    "384": {
        "cameraId": 175,
        "mountId": 2,
        "id": 384
    },
    "567": {
        "cameraId": 280,
        "mountId": 2,
        "id": 567
    },
    "238": {
        "cameraId": 119,
        "mountId": 10,
        "id": 238
    },
    "196": {
        "cameraId": 105,
        "mountId": 10,
        "id": 196
    },
    "162": {
        "cameraId": 88,
        "mountId": 10,
        "id": 162
    },
    "393": {
        "cameraId": 178,
        "mountId": 2,
        "id": 393
    },
    "184": {
        "cameraId": 101,
        "mountId": 10,
        "id": 184
    },
    "219": {
        "cameraId": 113,
        "mountId": 1,
        "id": 219
    },
    "461": {
        "cameraId": 214,
        "mountId": 1,
        "id": 461
    },
    "89": {
        "cameraId": 48,
        "mountId": 10,
        "id": 89
    },
    "100": {
        "cameraId": 54,
        "mountId": 10,
        "id": 100
    },
    "426": {
        "cameraId": 196,
        "mountId": 10,
        "id": 426
    },
    "604": {
        "cameraId": 298,
        "mountId": 10,
        "id": 604
    },
    "477": {
        "cameraId": 221,
        "mountId": 1,
        "id": 477
    },
    "541": {
        "cameraId": 272,
        "mountId": 1,
        "id": 541
    },
    "351": {
        "cameraId": 164,
        "mountId": 2,
        "id": 351
    },
    "243": {
        "cameraId": 121,
        "mountId": 1,
        "id": 243
    },
    "131": {
        "cameraId": 66,
        "mountId": 10,
        "id": 131
    },
    "629": {
        "cameraId": 310,
        "mountId": 1,
        "id": 629
    },
    "122": {
        "cameraId": 63,
        "mountId": 10,
        "id": 122
    },
    "43": {
        "cameraId": 30,
        "mountId": 10,
        "id": 43
    },
    "231": {
        "cameraId": 117,
        "mountId": 1,
        "id": 231
    },
    "61": {
        "cameraId": 39,
        "mountId": 1,
        "id": 61
    },
    "654": {
        "cameraId": 321,
        "mountId": 1,
        "id": 654
    },
    "598": {
        "cameraId": 296,
        "mountId": 10,
        "id": 598
    },
    "413": {
        "cameraId": 187,
        "mountId": 10,
        "id": 413
    },
    "29": {
        "cameraId": 25,
        "mountId": 2,
        "id": 29
    },
    "151": {
        "cameraId": 79,
        "mountId": 1,
        "id": 151
    },
    "369": {
        "cameraId": 170,
        "mountId": 2,
        "id": 369
    },
    "348": {
        "cameraId": 163,
        "mountId": 10,
        "id": 348
    },
    "575": {
        "cameraId": 284,
        "mountId": 10,
        "id": 575
    },
    "693": {
        "cameraId": 345,
        "mountId": 10,
        "id": 693
    },
    "44": {
        "cameraId": 30,
        "mountId": 2,
        "id": 44
    },
    "739": {
        "cameraId": 367,
        "mountId": 10,
        "id": 739
    },
    "258": {
        "cameraId": 126,
        "mountId": 2,
        "id": 258
    },
    "250": {
        "cameraId": 123,
        "mountId": 10,
        "id": 250
    },
    "674": {
        "cameraId": 333,
        "mountId": 1,
        "id": 674
    },
    "539": {
        "cameraId": 271,
        "mountId": 10,
        "id": 539
    },
    "301": {
        "cameraId": 145,
        "mountId": 10,
        "id": 301
    },
    "424": {
        "cameraId": 195,
        "mountId": 10,
        "id": 424
    },
    "93": {
        "cameraId": 51,
        "mountId": 10,
        "id": 93
    },
    "6": {
        "cameraId": 6,
        "mountId": 1,
        "id": 6
    },
    "684": {
        "cameraId": 341,
        "mountId": 2,
        "id": 684
    },
    "573": {
        "cameraId": 282,
        "mountId": 2,
        "id": 573
    },
    "408": {
        "cameraId": 185,
        "mountId": 1,
        "id": 408
    },
    "563": {
        "cameraId": 279,
        "mountId": 10,
        "id": 563
    },
    "616": {
        "cameraId": 303,
        "mountId": 10,
        "id": 616
    },
    "111": {
        "cameraId": 59,
        "mountId": 2,
        "id": 111
    },
    "689": {
        "cameraId": 344,
        "mountId": 1,
        "id": 689
    },
    "28": {
        "cameraId": 25,
        "mountId": 10,
        "id": 28
    },
    "456": {
        "cameraId": 209,
        "mountId": 2,
        "id": 456
    },
    "374": {
        "cameraId": 172,
        "mountId": 10,
        "id": 374
    },
    "608": {
        "cameraId": 299,
        "mountId": 2,
        "id": 608
    },
    "737": {
        "cameraId": 366,
        "mountId": 10,
        "id": 737
    },
    "548": {
        "cameraId": 274,
        "mountId": 10,
        "id": 548
    },
    "538": {
        "cameraId": 271,
        "mountId": 1,
        "id": 538
    },
    "411": {
        "cameraId": 186,
        "mountId": 10,
        "id": 411
    },
    "134": {
        "cameraId": 67,
        "mountId": 10,
        "id": 134
    },
    "64": {
        "cameraId": 40,
        "mountId": 1,
        "id": 64
    },
    "623": {
        "cameraId": 308,
        "mountId": 1,
        "id": 623
    },
    "465": {
        "cameraId": 215,
        "mountId": 10,
        "id": 465
    },
    "334": {
        "cameraId": 156,
        "mountId": 10,
        "id": 334
    },
    "323": {
        "cameraId": 152,
        "mountId": 2,
        "id": 323
    },
    "189": {
        "cameraId": 103,
        "mountId": 1,
        "id": 189
    },
    "280": {
        "cameraId": 137,
        "mountId": 2,
        "id": 280
    },
    "198": {
        "cameraId": 106,
        "mountId": 1,
        "id": 198
    },
    "155": {
        "cameraId": 81,
        "mountId": 1,
        "id": 155
    },
    "295": {
        "cameraId": 143,
        "mountId": 10,
        "id": 295
    },
    "248": {
        "cameraId": 122,
        "mountId": 2,
        "id": 248
    },
    "587": {
        "cameraId": 292,
        "mountId": 2,
        "id": 587
    },
    "285": {
        "cameraId": 140,
        "mountId": 1,
        "id": 285
    },
    "507": {
        "cameraId": 244,
        "mountId": 10,
        "id": 507
    },
    "227": {
        "cameraId": 115,
        "mountId": 2,
        "id": 227
    },
    "724": {
        "cameraId": 360,
        "mountId": 1,
        "id": 724
    },
    "476": {
        "cameraId": 220,
        "mountId": 2,
        "id": 476
    },
    "494": {
        "cameraId": 231,
        "mountId": 1,
        "id": 494
    },
    "220": {
        "cameraId": 113,
        "mountId": 10,
        "id": 220
    },
    "103": {
        "cameraId": 57,
        "mountId": 1,
        "id": 103
    },
    "697": {
        "cameraId": 346,
        "mountId": 2,
        "id": 697
    },
    "611": {
        "cameraId": 300,
        "mountId": 2,
        "id": 611
    },
    "170": {
        "cameraId": 94,
        "mountId": 1,
        "id": 170
    },
    "51": {
        "cameraId": 33,
        "mountId": 1,
        "id": 51
    },
    "25": {
        "cameraId": 24,
        "mountId": 10,
        "id": 25
    },
    "261": {
        "cameraId": 128,
        "mountId": 1,
        "id": 261
    },
    "201": {
        "cameraId": 107,
        "mountId": 1,
        "id": 201
    },
    "590": {
        "cameraId": 293,
        "mountId": 2,
        "id": 590
    },
    "489": {
        "cameraId": 227,
        "mountId": 1,
        "id": 489
    },
    "166": {
        "cameraId": 92,
        "mountId": 10,
        "id": 166
    },
    "447": {
        "cameraId": 205,
        "mountId": 10,
        "id": 447
    },
    "34": {
        "cameraId": 27,
        "mountId": 10,
        "id": 34
    },
    "252": {
        "cameraId": 124,
        "mountId": 1,
        "id": 252
    },
    "325": {
        "cameraId": 153,
        "mountId": 10,
        "id": 325
    },
    "594": {
        "cameraId": 295,
        "mountId": 1,
        "id": 594
    },
    "436": {
        "cameraId": 200,
        "mountId": 10,
        "id": 436
    },
    "535": {
        "cameraId": 270,
        "mountId": 1,
        "id": 535
    },
    "146": {
        "cameraId": 76,
        "mountId": 1,
        "id": 146
    },
    "741": {
        "cameraId": 368,
        "mountId": 10,
        "id": 741
    },
    "228": {
        "cameraId": 116,
        "mountId": 1,
        "id": 228
    },
    "306": {
        "cameraId": 147,
        "mountId": 1,
        "id": 306
    },
    "125": {
        "cameraId": 64,
        "mountId": 10,
        "id": 125
    },
    "276": {
        "cameraId": 135,
        "mountId": 2,
        "id": 276
    },
    "340": {
        "cameraId": 158,
        "mountId": 10,
        "id": 340
    },
    "148": {
        "cameraId": 76,
        "mountId": 10,
        "id": 148
    },
    "482": {
        "cameraId": 223,
        "mountId": 2,
        "id": 482
    },
    "622": {
        "cameraId": 307,
        "mountId": 2,
        "id": 622
    },
    "588": {
        "cameraId": 293,
        "mountId": 1,
        "id": 588
    },
    "312": {
        "cameraId": 149,
        "mountId": 1,
        "id": 312
    },
    "606": {
        "cameraId": 299,
        "mountId": 1,
        "id": 606
    },
    "3": {
        "cameraId": 3,
        "mountId": 1,
        "id": 3
    },
    "520": {
        "cameraId": 257,
        "mountId": 1,
        "id": 520
    },
    "720": {
        "cameraId": 358,
        "mountId": 1,
        "id": 720
    },
    "286": {
        "cameraId": 140,
        "mountId": 10,
        "id": 286
    },
    "279": {
        "cameraId": 137,
        "mountId": 1,
        "id": 279
    },
    "536": {
        "cameraId": 270,
        "mountId": 10,
        "id": 536
    },
    "663": {
        "cameraId": 325,
        "mountId": 10,
        "id": 663
    },
    "12": {
        "cameraId": 12,
        "mountId": 1,
        "id": 12
    },
    "440": {
        "cameraId": 202,
        "mountId": 1,
        "id": 440
    },
    "332": {
        "cameraId": 155,
        "mountId": 2,
        "id": 332
    },
    "330": {
        "cameraId": 155,
        "mountId": 1,
        "id": 330
    },
    "382": {
        "cameraId": 175,
        "mountId": 1,
        "id": 382
    },
    "152": {
        "cameraId": 79,
        "mountId": 2,
        "id": 152
    },
    "544": {
        "cameraId": 273,
        "mountId": 1,
        "id": 544
    },
    "642": {
        "cameraId": 314,
        "mountId": 10,
        "id": 642
    },
    "435": {
        "cameraId": 200,
        "mountId": 1,
        "id": 435
    },
    "342": {
        "cameraId": 159,
        "mountId": 1,
        "id": 342
    },
    "703": {
        "cameraId": 349,
        "mountId": 10,
        "id": 703
    },
    "695": {
        "cameraId": 346,
        "mountId": 1,
        "id": 695
    },
    "2": {
        "cameraId": 2,
        "mountId": 1,
        "id": 2
    },
    "66": {
        "cameraId": 40,
        "mountId": 2,
        "id": 66
    },
    "484": {
        "cameraId": 224,
        "mountId": 2,
        "id": 484
    },
    "439": {
        "cameraId": 201,
        "mountId": 10,
        "id": 439
    },
    "236": {
        "cameraId": 118,
        "mountId": 2,
        "id": 236
    },
    "556": {
        "cameraId": 277,
        "mountId": 1,
        "id": 556
    },
    "373": {
        "cameraId": 172,
        "mountId": 1,
        "id": 373
    },
    "142": {
        "cameraId": 74,
        "mountId": 10,
        "id": 142
    },
    "359": {
        "cameraId": 167,
        "mountId": 10,
        "id": 359
    },
    "727": {
        "cameraId": 361,
        "mountId": 10,
        "id": 727
    },
    "371": {
        "cameraId": 171,
        "mountId": 10,
        "id": 371
    },
    "444": {
        "cameraId": 204,
        "mountId": 1,
        "id": 444
    },
    "570": {
        "cameraId": 281,
        "mountId": 2,
        "id": 570
    },
    "757": {
        "cameraId": 376,
        "mountId": 10,
        "id": 757
    },
    "107": {
        "cameraId": 58,
        "mountId": 2,
        "id": 107
    },
    "532": {
        "cameraId": 269,
        "mountId": 1,
        "id": 532
    },
    "23": {
        "cameraId": 23,
        "mountId": 1,
        "id": 23
    },
    "745": {
        "cameraId": 370,
        "mountId": 9,
        "id": 745
    },
    "719": {
        "cameraId": 357,
        "mountId": 10,
        "id": 719
    },
    "230": {
        "cameraId": 116,
        "mountId": 2,
        "id": 230
    },
    "625": {
        "cameraId": 308,
        "mountId": 2,
        "id": 625
    },
    "47": {
        "cameraId": 31,
        "mountId": 2,
        "id": 47
    },
    "526": {
        "cameraId": 263,
        "mountId": 10,
        "id": 526
    },
    "180": {
        "cameraId": 99,
        "mountId": 1,
        "id": 180
    },
    "537": {
        "cameraId": 270,
        "mountId": 2,
        "id": 537
    },
    "659": {
        "cameraId": 323,
        "mountId": 10,
        "id": 659
    },
    "158": {
        "cameraId": 84,
        "mountId": 1,
        "id": 158
    },
    "350": {
        "cameraId": 164,
        "mountId": 10,
        "id": 350
    },
    "35": {
        "cameraId": 27,
        "mountId": 2,
        "id": 35
    },
    "644": {
        "cameraId": 315,
        "mountId": 1,
        "id": 644
    },
    "127": {
        "cameraId": 65,
        "mountId": 1,
        "id": 127
    },
    "675": {
        "cameraId": 334,
        "mountId": 1,
        "id": 675
    },
    "383": {
        "cameraId": 175,
        "mountId": 10,
        "id": 383
    },
    "533": {
        "cameraId": 269,
        "mountId": 10,
        "id": 533
    },
    "302": {
        "cameraId": 145,
        "mountId": 2,
        "id": 302
    },
    "564": {
        "cameraId": 279,
        "mountId": 2,
        "id": 564
    },
    "701": {
        "cameraId": 348,
        "mountId": 2,
        "id": 701
    },
    "566": {
        "cameraId": 280,
        "mountId": 10,
        "id": 566
    },
    "82": {
        "cameraId": 46,
        "mountId": 1,
        "id": 82
    },
    "76": {
        "cameraId": 44,
        "mountId": 1,
        "id": 76
    },
    "735": {
        "cameraId": 365,
        "mountId": 10,
        "id": 735
    },
    "492": {
        "cameraId": 229,
        "mountId": 1,
        "id": 492
    },
    "718": {
        "cameraId": 357,
        "mountId": 1,
        "id": 718
    },
    "215": {
        "cameraId": 111,
        "mountId": 2,
        "id": 215
    },
    "97": {
        "cameraId": 53,
        "mountId": 10,
        "id": 97
    },
    "704": {
        "cameraId": 350,
        "mountId": 1,
        "id": 704
    },
    "277": {
        "cameraId": 136,
        "mountId": 1,
        "id": 277
    },
    "751": {
        "cameraId": 373,
        "mountId": 10,
        "id": 751
    },
    "19": {
        "cameraId": 19,
        "mountId": 1,
        "id": 19
    },
    "335": {
        "cameraId": 156,
        "mountId": 2,
        "id": 335
    },
    "597": {
        "cameraId": 296,
        "mountId": 1,
        "id": 597
    },
    "57": {
        "cameraId": 37,
        "mountId": 10,
        "id": 57
    },
    "609": {
        "cameraId": 300,
        "mountId": 1,
        "id": 609
    },
    "202": {
        "cameraId": 107,
        "mountId": 10,
        "id": 202
    },
    "68": {
        "cameraId": 41,
        "mountId": 10,
        "id": 68
    },
    "452": {
        "cameraId": 207,
        "mountId": 2,
        "id": 452
    },
    "200": {
        "cameraId": 106,
        "mountId": 2,
        "id": 200
    },
    "11": {
        "cameraId": 11,
        "mountId": 1,
        "id": 11
    },
    "115": {
        "cameraId": 61,
        "mountId": 1,
        "id": 115
    },
    "339": {
        "cameraId": 158,
        "mountId": 1,
        "id": 339
    },
    "431": {
        "cameraId": 198,
        "mountId": 1,
        "id": 431
    },
    "462": {
        "cameraId": 214,
        "mountId": 10,
        "id": 462
    },
    "337": {
        "cameraId": 157,
        "mountId": 10,
        "id": 337
    },
    "698": {
        "cameraId": 347,
        "mountId": 10,
        "id": 698
    },
    "255": {
        "cameraId": 125,
        "mountId": 1,
        "id": 255
    },
    "503": {
        "cameraId": 240,
        "mountId": 1,
        "id": 503
    },
    "546": {
        "cameraId": 273,
        "mountId": 2,
        "id": 546
    },
    "9": {
        "cameraId": 9,
        "mountId": 1,
        "id": 9
    },
    "706": {
        "cameraId": 351,
        "mountId": 1,
        "id": 706
    },
    "632": {
        "cameraId": 311,
        "mountId": 1,
        "id": 632
    },
    "457": {
        "cameraId": 210,
        "mountId": 10,
        "id": 457
    },
    "427": {
        "cameraId": 196,
        "mountId": 2,
        "id": 427
    },
    "145": {
        "cameraId": 75,
        "mountId": 10,
        "id": 145
    },
    "5": {
        "cameraId": 5,
        "mountId": 1,
        "id": 5
    },
    "733": {
        "cameraId": 364,
        "mountId": 10,
        "id": 733
    },
    "624": {
        "cameraId": 308,
        "mountId": 10,
        "id": 624
    },
    "244": {
        "cameraId": 121,
        "mountId": 10,
        "id": 244
    },
    "289": {
        "cameraId": 141,
        "mountId": 10,
        "id": 289
    },
    "112": {
        "cameraId": 60,
        "mountId": 1,
        "id": 112
    },
    "730": {
        "cameraId": 363,
        "mountId": 1,
        "id": 730
    },
    "699": {
        "cameraId": 348,
        "mountId": 1,
        "id": 699
    },
    "712": {
        "cameraId": 354,
        "mountId": 1,
        "id": 712
    },
    "414": {
        "cameraId": 188,
        "mountId": 1,
        "id": 414
    },
    "179": {
        "cameraId": 98,
        "mountId": 2,
        "id": 179
    },
    "344": {
        "cameraId": 159,
        "mountId": 2,
        "id": 344
    },
    "481": {
        "cameraId": 223,
        "mountId": 1,
        "id": 481
    },
    "732": {
        "cameraId": 364,
        "mountId": 1,
        "id": 732
    },
    "661": {
        "cameraId": 324,
        "mountId": 10,
        "id": 661
    },
    "245": {
        "cameraId": 121,
        "mountId": 2,
        "id": 245
    },
    "378": {
        "cameraId": 173,
        "mountId": 2,
        "id": 378
    },
    "658": {
        "cameraId": 323,
        "mountId": 1,
        "id": 658
    },
    "266": {
        "cameraId": 130,
        "mountId": 2,
        "id": 266
    },
    "324": {
        "cameraId": 153,
        "mountId": 1,
        "id": 324
    },
    "680": {
        "cameraId": 339,
        "mountId": 1,
        "id": 680
    },
    "446": {
        "cameraId": 205,
        "mountId": 1,
        "id": 446
    },
    "524": {
        "cameraId": 261,
        "mountId": 10,
        "id": 524
    },
    "254": {
        "cameraId": 124,
        "mountId": 2,
        "id": 254
    },
    "404": {
        "cameraId": 183,
        "mountId": 10,
        "id": 404
    },
    "617": {
        "cameraId": 304,
        "mountId": 10,
        "id": 617
    },
    "283": {
        "cameraId": 139,
        "mountId": 1,
        "id": 283
    },
    "513": {
        "cameraId": 250,
        "mountId": 1,
        "id": 513
    },
    "572": {
        "cameraId": 282,
        "mountId": 10,
        "id": 572
    },
    "705": {
        "cameraId": 350,
        "mountId": 10,
        "id": 705
    },
    "83": {
        "cameraId": 46,
        "mountId": 10,
        "id": 83
    },
    "634": {
        "cameraId": 311,
        "mountId": 2,
        "id": 634
    },
    "138": {
        "cameraId": 70,
        "mountId": 10,
        "id": 138
    },
    "346": {
        "cameraId": 161,
        "mountId": 10,
        "id": 346
    },
    "14": {
        "cameraId": 14,
        "mountId": 1,
        "id": 14
    },
    "455": {
        "cameraId": 209,
        "mountId": 10,
        "id": 455
    },
    "265": {
        "cameraId": 130,
        "mountId": 1,
        "id": 265
    },
    "449": {
        "cameraId": 206,
        "mountId": 10,
        "id": 449
    },
    "333": {
        "cameraId": 156,
        "mountId": 1,
        "id": 333
    },
    "650": {
        "cameraId": 317,
        "mountId": 10,
        "id": 650
    },
    "639": {
        "cameraId": 313,
        "mountId": 10,
        "id": 639
    },
    "569": {
        "cameraId": 281,
        "mountId": 10,
        "id": 569
    },
    "326": {
        "cameraId": 153,
        "mountId": 2,
        "id": 326
    },
    "746": {
        "cameraId": 371,
        "mountId": 1,
        "id": 746
    },
    "647": {
        "cameraId": 316,
        "mountId": 1,
        "id": 647
    },
    "45": {
        "cameraId": 31,
        "mountId": 1,
        "id": 45
    },
    "53": {
        "cameraId": 33,
        "mountId": 2,
        "id": 53
    },
    "559": {
        "cameraId": 278,
        "mountId": 1,
        "id": 559
    },
    "78": {
        "cameraId": 44,
        "mountId": 2,
        "id": 78
    },
    "562": {
        "cameraId": 279,
        "mountId": 1,
        "id": 562
    },
    "542": {
        "cameraId": 272,
        "mountId": 10,
        "id": 542
    },
    "664": {
        "cameraId": 326,
        "mountId": 1,
        "id": 664
    },
    "315": {
        "cameraId": 150,
        "mountId": 1,
        "id": 315
    },
    "480": {
        "cameraId": 222,
        "mountId": 2,
        "id": 480
    },
    "132": {
        "cameraId": 66,
        "mountId": 2,
        "id": 132
    },
    "753": {
        "cameraId": 374,
        "mountId": 10,
        "id": 753
    },
    "26": {
        "cameraId": 24,
        "mountId": 2,
        "id": 26
    },
    "123": {
        "cameraId": 63,
        "mountId": 2,
        "id": 123
    },
    "203": {
        "cameraId": 107,
        "mountId": 2,
        "id": 203
    },
    "667": {
        "cameraId": 327,
        "mountId": 10,
        "id": 667
    },
    "392": {
        "cameraId": 178,
        "mountId": 10,
        "id": 392
    },
    "577": {
        "cameraId": 286,
        "mountId": 10,
        "id": 577
    },
    "140": {
        "cameraId": 72,
        "mountId": 10,
        "id": 140
    },
    "321": {
        "cameraId": 152,
        "mountId": 1,
        "id": 321
    },
    "441": {
        "cameraId": 202,
        "mountId": 10,
        "id": 441
    },
    "700": {
        "cameraId": 348,
        "mountId": 10,
        "id": 700
    },
    "268": {
        "cameraId": 131,
        "mountId": 2,
        "id": 268
    },
    "16": {
        "cameraId": 16,
        "mountId": 1,
        "id": 16
    },
    "320": {
        "cameraId": 151,
        "mountId": 2,
        "id": 320
    },
    "133": {
        "cameraId": 67,
        "mountId": 1,
        "id": 133
    },
    "288": {
        "cameraId": 141,
        "mountId": 1,
        "id": 288
    },
    "381": {
        "cameraId": 174,
        "mountId": 2,
        "id": 381
    },
    "605": {
        "cameraId": 298,
        "mountId": 2,
        "id": 605
    },
    "163": {
        "cameraId": 89,
        "mountId": 10,
        "id": 163
    },
    "81": {
        "cameraId": 45,
        "mountId": 2,
        "id": 81
    },
    "120": {
        "cameraId": 62,
        "mountId": 2,
        "id": 120
    },
    "643": {
        "cameraId": 314,
        "mountId": 2,
        "id": 643
    },
    "79": {
        "cameraId": 45,
        "mountId": 1,
        "id": 79
    },
    "211": {
        "cameraId": 110,
        "mountId": 10,
        "id": 211
    },
    "38": {
        "cameraId": 28,
        "mountId": 2,
        "id": 38
    },
    "173": {
        "cameraId": 95,
        "mountId": 2,
        "id": 173
    },
    "126": {
        "cameraId": 64,
        "mountId": 2,
        "id": 126
    },
    "421": {
        "cameraId": 192,
        "mountId": 10,
        "id": 421
    },
    "593": {
        "cameraId": 294,
        "mountId": 2,
        "id": 593
    },
    "636": {
        "cameraId": 312,
        "mountId": 10,
        "id": 636
    },
    "98": {
        "cameraId": 54,
        "mountId": 1,
        "id": 98
    },
    "422": {
        "cameraId": 193,
        "mountId": 10,
        "id": 422
    },
    "423": {
        "cameraId": 194,
        "mountId": 10,
        "id": 423
    },
    "614": {
        "cameraId": 301,
        "mountId": 2,
        "id": 614
    },
    "582": {
        "cameraId": 291,
        "mountId": 1,
        "id": 582
    },
    "666": {
        "cameraId": 327,
        "mountId": 1,
        "id": 666
    },
    "554": {
        "cameraId": 276,
        "mountId": 10,
        "id": 554
    },
    "409": {
        "cameraId": 185,
        "mountId": 10,
        "id": 409
    },
    "574": {
        "cameraId": 283,
        "mountId": 10,
        "id": 574
    },
    "595": {
        "cameraId": 295,
        "mountId": 10,
        "id": 595
    },
    "124": {
        "cameraId": 64,
        "mountId": 1,
        "id": 124
    },
    "747": {
        "cameraId": 371,
        "mountId": 9,
        "id": 747
    },
    "171": {
        "cameraId": 94,
        "mountId": 2,
        "id": 171
    },
    "87": {
        "cameraId": 47,
        "mountId": 2,
        "id": 87
    },
    "169": {
        "cameraId": 93,
        "mountId": 10,
        "id": 169
    },
    "653": {
        "cameraId": 320,
        "mountId": 10,
        "id": 653
    },
    "679": {
        "cameraId": 338,
        "mountId": 1,
        "id": 679
    },
    "160": {
        "cameraId": 86,
        "mountId": 10,
        "id": 160
    },
    "30": {
        "cameraId": 26,
        "mountId": 1,
        "id": 30
    },
    "400": {
        "cameraId": 181,
        "mountId": 2,
        "id": 400
    },
    "696": {
        "cameraId": 346,
        "mountId": 10,
        "id": 696
    },
    "738": {
        "cameraId": 367,
        "mountId": 1,
        "id": 738
    },
    "509": {
        "cameraId": 246,
        "mountId": 10,
        "id": 509
    },
    "736": {
        "cameraId": 366,
        "mountId": 1,
        "id": 736
    },
    "207": {
        "cameraId": 109,
        "mountId": 1,
        "id": 207
    },
    "434": {
        "cameraId": 199,
        "mountId": 10,
        "id": 434
    },
    "690": {
        "cameraId": 344,
        "mountId": 10,
        "id": 690
    },
    "194": {
        "cameraId": 104,
        "mountId": 2,
        "id": 194
    },
    "511": {
        "cameraId": 248,
        "mountId": 1,
        "id": 511
    },
    "73": {
        "cameraId": 43,
        "mountId": 1,
        "id": 73
    },
    "486": {
        "cameraId": 225,
        "mountId": 2,
        "id": 486
    },
    "336": {
        "cameraId": 157,
        "mountId": 1,
        "id": 336
    },
    "96": {
        "cameraId": 53,
        "mountId": 2,
        "id": 96
    },
    "10": {
        "cameraId": 10,
        "mountId": 1,
        "id": 10
    },
    "660": {
        "cameraId": 324,
        "mountId": 1,
        "id": 660
    },
    "272": {
        "cameraId": 133,
        "mountId": 2,
        "id": 272
    },
    "499": {
        "cameraId": 236,
        "mountId": 1,
        "id": 499
    },
    "488": {
        "cameraId": 226,
        "mountId": 2,
        "id": 488
    },
    "386": {
        "cameraId": 176,
        "mountId": 10,
        "id": 386
    },
    "270": {
        "cameraId": 132,
        "mountId": 2,
        "id": 270
    },
    "576": {
        "cameraId": 285,
        "mountId": 10,
        "id": 576
    },
    "717": {
        "cameraId": 356,
        "mountId": 10,
        "id": 717
    },
    "543": {
        "cameraId": 272,
        "mountId": 2,
        "id": 543
    },
    "271": {
        "cameraId": 133,
        "mountId": 1,
        "id": 271
    },
    "18": {
        "cameraId": 18,
        "mountId": 1,
        "id": 18
    },
    "395": {
        "cameraId": 180,
        "mountId": 1,
        "id": 395
    },
    "403": {
        "cameraId": 183,
        "mountId": 1,
        "id": 403
    },
    "469": {
        "cameraId": 217,
        "mountId": 1,
        "id": 469
    },
    "105": {
        "cameraId": 57,
        "mountId": 10,
        "id": 105
    },
    "185": {
        "cameraId": 101,
        "mountId": 2,
        "id": 185
    },
    "52": {
        "cameraId": 33,
        "mountId": 10,
        "id": 52
    },
    "545": {
        "cameraId": 273,
        "mountId": 10,
        "id": 545
    },
    "633": {
        "cameraId": 311,
        "mountId": 10,
        "id": 633
    },
    "114": {
        "cameraId": 60,
        "mountId": 2,
        "id": 114
    },
    "253": {
        "cameraId": 124,
        "mountId": 10,
        "id": 253
    },
    "612": {
        "cameraId": 301,
        "mountId": 1,
        "id": 612
    },
    "628": {
        "cameraId": 309,
        "mountId": 2,
        "id": 628
    },
    "748": {
        "cameraId": 372,
        "mountId": 1,
        "id": 748
    },
    "209": {
        "cameraId": 109,
        "mountId": 2,
        "id": 209
    },
    "147": {
        "cameraId": 76,
        "mountId": 2,
        "id": 147
    },
    "655": {
        "cameraId": 321,
        "mountId": 10,
        "id": 655
    },
    "750": {
        "cameraId": 373,
        "mountId": 1,
        "id": 750
    },
    "425": {
        "cameraId": 196,
        "mountId": 1,
        "id": 425
    },
    "67": {
        "cameraId": 41,
        "mountId": 1,
        "id": 67
    },
    "296": {
        "cameraId": 143,
        "mountId": 2,
        "id": 296
    },
    "602": {
        "cameraId": 297,
        "mountId": 2,
        "id": 602
    },
    "318": {
        "cameraId": 151,
        "mountId": 1,
        "id": 318
    },
    "161": {
        "cameraId": 87,
        "mountId": 10,
        "id": 161
    },
    "651": {
        "cameraId": 318,
        "mountId": 10,
        "id": 651
    },
    "725": {
        "cameraId": 360,
        "mountId": 10,
        "id": 725
    },
    "372": {
        "cameraId": 171,
        "mountId": 2,
        "id": 372
    },
    "406": {
        "cameraId": 184,
        "mountId": 10,
        "id": 406
    },
    "438": {
        "cameraId": 201,
        "mountId": 1,
        "id": 438
    },
    "711": {
        "cameraId": 353,
        "mountId": 10,
        "id": 711
    },
    "71": {
        "cameraId": 42,
        "mountId": 2,
        "id": 71
    },
    "579": {
        "cameraId": 288,
        "mountId": 10,
        "id": 579
    },
    "722": {
        "cameraId": 359,
        "mountId": 1,
        "id": 722
    },
    "42": {
        "cameraId": 30,
        "mountId": 1,
        "id": 42
    },
    "80": {
        "cameraId": 45,
        "mountId": 10,
        "id": 80
    },
    "714": {
        "cameraId": 355,
        "mountId": 1,
        "id": 714
    },
    "677": {
        "cameraId": 336,
        "mountId": 1,
        "id": 677
    },
    "199": {
        "cameraId": 106,
        "mountId": 10,
        "id": 199
    },
    "754": {
        "cameraId": 375,
        "mountId": 1,
        "id": 754
    },
    "591": {
        "cameraId": 294,
        "mountId": 1,
        "id": 591
    },
    "744": {
        "cameraId": 370,
        "mountId": 1,
        "id": 744
    },
    "37": {
        "cameraId": 28,
        "mountId": 10,
        "id": 37
    },
    "183": {
        "cameraId": 101,
        "mountId": 1,
        "id": 183
    },
    "432": {
        "cameraId": 198,
        "mountId": 10,
        "id": 432
    },
    "379": {
        "cameraId": 174,
        "mountId": 1,
        "id": 379
    },
    "63": {
        "cameraId": 39,
        "mountId": 10,
        "id": 63
    },
    "212": {
        "cameraId": 110,
        "mountId": 2,
        "id": 212
    },
    "94": {
        "cameraId": 52,
        "mountId": 10,
        "id": 94
    },
    "362": {
        "cameraId": 168,
        "mountId": 10,
        "id": 362
    },
    "8": {
        "cameraId": 8,
        "mountId": 1,
        "id": 8
    },
    "686": {
        "cameraId": 342,
        "mountId": 10,
        "id": 686
    },
    "692": {
        "cameraId": 345,
        "mountId": 1,
        "id": 692
    },
    "246": {
        "cameraId": 122,
        "mountId": 1,
        "id": 246
    },
    "190": {
        "cameraId": 103,
        "mountId": 10,
        "id": 190
    },
    "549": {
        "cameraId": 274,
        "mountId": 2,
        "id": 549
    },
    "177": {
        "cameraId": 97,
        "mountId": 2,
        "id": 177
    },
    "49": {
        "cameraId": 32,
        "mountId": 10,
        "id": 49
    },
    "390": {
        "cameraId": 177,
        "mountId": 2,
        "id": 390
    },
    "84": {
        "cameraId": 46,
        "mountId": 2,
        "id": 84
    }
};
