import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { assertNotUndefined, h, scopedClasses } from '../../util';
import { cssScope } from './css_scope';
import { AdminBreadcrumb } from '../../admin_breadcrumb_types';
import { Link } from '../link';
import { BackIcon } from '../icons/back';
import { ChevronRightIcon } from '../icons/chevron_right';
import { getBreadcrumbUrl } from '../../admin_util';

type Props = {
    title: string;
    breadcrumbs: AdminBreadcrumb[] | undefined;
};

const c = scopedClasses(cssScope);

export const AdminHeader = mobxReactLite.observer(function AdminHeader(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    return h('div', { className: c('root') },
        (props.breadcrumbs !== undefined && props.breadcrumbs.length > 0 ?
            h(Link,{ className: c('back'), url: getBreadcrumbUrl(props.breadcrumbs, props.breadcrumbs.length - 1) },
                h(BackIcon, { size: 24 }),
            ) : null
        ),
        h('div', { className: c('right') },
            (props.breadcrumbs !== undefined ?
                h('div', { className: c('breadcrumbs') },
                    props.breadcrumbs.map((breadcrumb, i) => h(React.Fragment, { key: i },
                        (i !== 0 ?
                            h('div', { className: c('breadcrumb-sep') },
                                h(ChevronRightIcon, { size: 18 }),
                            ) : null
                        ),
                        h(Link, { className: c('breadcrumb'), url: getBreadcrumbUrl(assertNotUndefined(props.breadcrumbs), i) },
                            breadcrumb.name,
                        ),
                    )),
                ) : null
            ),
            h('div', { className: c('title') },
                props.title,
            ),
        ),
    );
});
