import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h } from '../../../../util';
import { ImageInput } from '../../../image_input/image_input';

type Props = {
    fieldId: string;
    value: string;
    onChange: (value: string) => void;
    onLoadStart: (fieldId: string, promise: Promise<void>) => void;
};

export const ImageField = mobxReactLite.observer(function ImageField(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const onLoadStart = React.useCallback(
        (promise: Promise<void>) => {
            propsRef.current.onLoadStart(propsRef.current.fieldId, promise);
        },
        [],
    );

    return h(ImageInput, {
        value: props.value,
        onChange: props.onChange,
        onLoadStart: onLoadStart,
    });
});
