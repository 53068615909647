import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { defaultValueIfUndefined, formatFloat, h } from '../../../util';
import { DbTables } from '../../../db_types';
import { QualityAssuranceTable } from './quality_assurance_table/quality_assurance_table';
import { booleanToString, wavelengthsToString } from './quality_assurance_util';
import { indexByForeignKey, sortById } from '../../../db_util';

type Props = {
    tables: DbTables;
};

export const DataObjectiveCore = mobxReactLite.observer(function DataObjectiveCore(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const items = Object.values(props.tables.objectiveCore).sort((a, b) => {
        if (a.productFamilyId < b.productFamilyId) return -1;
        if (a.productFamilyId > b.productFamilyId) return 1;

        if (a.focalLengthLow < b.focalLengthLow) return -1;
        if (a.focalLengthLow > b.focalLengthLow) return 1;

        return a.focalLengthHigh - b.focalLengthHigh;
    });

    const stopDiamCoefficientsByObjectiveCore = indexByForeignKey(
        sortById(Object.values(props.tables.objectiveCoreStopDiamCoefficient)),
        'objectiveCoreId',
    );

    return h(QualityAssuranceTable, {
        head: [
            'Product Family',
            'Name',
            'Focal Length Low',
            'Focal Length High',
            'Adapter Required',
            'No Adapter Max Sensor Diagonal',
            'Wavelengths',
            'Stop Semi-Diam Coefficients',
        ],
        rows: items.map((item) => ([
            props.tables.productFamily[item.productFamilyId].name,
            item.name,
            formatFloat(item.focalLengthLow),
            formatFloat(item.focalLengthHigh),
            booleanToString(item.adapterRequired),
            formatFloat(item.noAdapterMaxSensorDiagonal),
            wavelengthsToString(item),
            defaultValueIfUndefined(stopDiamCoefficientsByObjectiveCore[item.id], []).map((x) => x.coefficient.toString()).join(', '),
        ])),
    });
});
