import * as mobxReactLite from 'mobx-react-lite';
import { h } from '../../util';

type Props = {
    size: number;
};

export const CloseIcon = mobxReactLite.observer(function CloseIcon(props: Props) {
    return h('svg',
        {
            width: props.size.toString(),
            height: props.size.toString(),
            viewBox: '0 0 24 24',
            xmlns: 'http://www.w3.org/2000/svg',
        },
        h('path', {
            d: 'M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z',
        }),
    );
});
