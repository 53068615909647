import { dbOptions } from './db_options';
import { DbAdapter, DbTables, DbZoomXtender } from './db_types';
import { indexByForeignKey, sortById } from './db_util';
import { assertUnreachable, defaultValueIfUndefined, formatFloat, formatInt, mmToUm, pixelsToMegapixels, polynomial, toFixedMax, umToMm } from './util';
import { wizardSettings } from './wizard_settings';
import { wizardStore } from './wizard_store';
import { CameraMake, FieldOfView, FieldOfViewRange, SingleShotSolution, LensType, NumberRange, ObjectiveSolution, Solution, StandardSolution, Wavelength, MachineVisionSolution } from './wizard_types';

export function displayRangeFloat(range: NumberRange): string {
    if (range.low === range.high) {
        return formatFloat(range.low);
    }
    return formatFloat(range.low) + '–' + formatFloat(range.high);
}

export function displayRangeInt(range: NumberRange): string {
    if (range.low === range.high) {
        return formatInt(range.low);
    }
    return formatInt(range.low) + '–' + formatInt(range.high);
}

export function swapRange(range: NumberRange): NumberRange {
    return {
        low: range.high,
        high: range.low,
    };
}

export function createRange(low: number, high: number): NumberRange {
    return {
        low: Math.min(low, high),
        high: Math.max(low, high),
    };
}

export function isWithinRange(n: number, range: NumberRange): boolean {
    return n >= range.low && n <= range.high;
}

export function clampToRange(n: number, range: NumberRange): number {
    return clamp(n, range.low, range.high);
}

export function clamp(n: number, low: number, high: number): number {
    if (n < low) return low;
    if (n > high) return high;
    return n;
}

export function getFieldOfView(sensorWidth: number, sensorHeight: number, magnification: number): FieldOfView {
    if (magnification === 0) {
        return {
            x: Infinity,
            y: Infinity,
        };
    }

    const x = sensorWidth / magnification;
    const y = sensorHeight / magnification;

    return {
        x: Math.max(x, y),
        y: Math.min(x, y),
    };
}

export function getFieldOfViewRange(sensorWidth: number, sensorHeight: number, magnificationRange: NumberRange): FieldOfViewRange {
    // A lower magnification gives a higher field of view because you can see more
    return {
        low: getFieldOfView(sensorWidth, sensorHeight, magnificationRange.high),
        high: getFieldOfView(sensorWidth, sensorHeight, magnificationRange.low),
    };
}

export function displayFieldOfView(fieldOfView: FieldOfView): string {
    if (fieldOfView.x === Infinity && fieldOfView.y === Infinity) {
        return '∞';
    }

    return formatFloat(fieldOfView.x) + '×' + formatFloat(fieldOfView.y);
}

export function displayFieldOfViewRange(fieldOfViewRange: FieldOfViewRange): string {
    const lowString = displayFieldOfView(fieldOfViewRange.low);
    const highString = displayFieldOfView(fieldOfViewRange.high);

    if (fieldOfViewRange.low.x === fieldOfViewRange.high.x && fieldOfViewRange.low.y === fieldOfViewRange.high.y) {
        return lowString;
    }
    return lowString + ' – ' + highString;
}

export function getDiagonal(a: number, b: number): number {
    return Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));
}

export function displayDiagonalFieldOfView(fieldOfView: FieldOfView): string {
    return formatFloat(getDiagonal(fieldOfView.x, fieldOfView.y));
}

export function displayDiagonalFieldOfViewRange(fieldOfViewRange: FieldOfViewRange): string {
    const lowString = displayDiagonalFieldOfView(fieldOfViewRange.low);
    const highString = displayDiagonalFieldOfView(fieldOfViewRange.high);

    if (fieldOfViewRange.low.x === fieldOfViewRange.high.x && fieldOfViewRange.low.y === fieldOfViewRange.high.y) {
        return lowString;
    }
    return lowString + '–' + highString;
}

export function displayResolveLimit(range: NumberRange): string {
    if (range.low === Infinity && range.high === Infinity) return '';
    return displayRangeFloat(swapRange(range));
}

export function negativeOneToInfinity(n: number): number {
    if (n === -1) return Infinity;
    return n;
}

export function getSolutionMagnification(solution: Solution): NumberRange {
    if (solution.kind === 'standard') {
        const attachmentMagnification = solution.standardAttachment !== null ? solution.standardAttachment.magnification : 1;
        return createRange(
            solution.adapter.magnification * solution.standardCore.magnificationLow * attachmentMagnification,
            solution.adapter.magnification * solution.standardCore.magnificationHigh * attachmentMagnification,
        );
    } else if (solution.kind === 'objective') {
        const adapterMagnification = solution.adapter !== null ? solution.adapter.magnification : 1;
        return createRange(
            adapterMagnification * (solution.objectiveCore.focalLengthLow / solution.objectiveAttachment.focalLength),
            adapterMagnification * (solution.objectiveCore.focalLengthHigh / solution.objectiveAttachment.focalLength),
        );
    } else if (solution.kind === 'single_shot') {
        const magnification = solution.objectiveCore.focalLength / solution.objectiveAttachment.focalLength;
        return createRange(magnification, magnification);
    } else if (solution.kind === 'zoom_xtender') {
        // We only show zoom_xtender solutions if wizardStore.workingDistance > 0 so we can use that working
        // distance to calculate the magnification here
        return createRange(
            getZoomXtenderWorkingMagnification(
                solution.zoomXtender,
                solution.adapter,
                solution.zoomXtender.coreMagnificationLow,
                wizardStore.lensWorkingDistance,
            ),
            getZoomXtenderWorkingMagnification(
                solution.zoomXtender,
                solution.adapter,
                solution.zoomXtender.coreMagnificationHigh,
                wizardStore.lensWorkingDistance,
            ),
        );
    } else if (solution.kind === 'machine_vision') {
        return createRange(0, getMachineVisionWorkingMagnification(solution, solution.lens.minWorkingDistance));
    } else {
        assertUnreachable(solution);
    }
}

type ZoomXtenderFit6Coefficients = [
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
];

function zoomXtenderFit6(x: number, y: number, coefficients: ZoomXtenderFit6Coefficients): number {
    if (y < 1) return 0;
    return (
        coefficients[0] * x
        + coefficients[1] * y
        + coefficients[2] * Math.pow(x, 2)
        + coefficients[3] * Math.pow(y, 2)
        + coefficients[4] * x * y
        + coefficients[5] * Math.pow(x, 2) * y
        + coefficients[6] * x * Math.pow(y, 2)
        + coefficients[7] * Math.pow(x, 3)
        + coefficients[8] * Math.pow(y, 3)
        + coefficients[9] * Math.pow(x, 3) * y
        + coefficients[10] * x * Math.pow(y, 3)
        + coefficients[11] * x * Math.log(y)
        + coefficients[12]
    );
}

type ZoomXtenderFit12Coefficients = [
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
];

function zoomXtenderFit12(x: number, y: number, coefficients: ZoomXtenderFit12Coefficients): number {
    if (coefficients.length !== 20 || y < 1) return 0;
    return (
        coefficients[0] * x
        + coefficients[1] * y
        + coefficients[2] * Math.log(y)
        + coefficients[3] * Math.pow(x, 2)
        + coefficients[4] * Math.pow(y, 2)
        + coefficients[5] * x * y
        + coefficients[6] * x * Math.log(y)
        + coefficients[7] * x * Math.pow(y, 2)
        + coefficients[8] * Math.pow(x, 2) * y
        + coefficients[9] * Math.pow(x, 2) * Math.pow(y, 2)
        + coefficients[10] * Math.pow(x, 2) * Math.log(y)
        + coefficients[11] * Math.pow(x, 3)
        + coefficients[12] * Math.pow(x, 3) * y
        + coefficients[13] * Math.pow(x, 3) * Math.pow(y, 2)
        + coefficients[14] * Math.pow(x, 3) * Math.log(y)
        + coefficients[15] * Math.pow(x, 4)
        + coefficients[16] * Math.pow(x, 4) * Math.log(y)
        + coefficients[17] * Math.pow(x, 4) * y
        + coefficients[18] * Math.pow(x, 4) * Math.pow(y, 2)
        + coefficients[19]
    );
}

export function getZoomXtenderWorkingMagnificationOld(
    zoomXtender: DbZoomXtender,
    adapter: DbAdapter,
    coefficients: number[],
    workingDistance: number,
    coreZoom: number,
): number {
    const a = polynomial(coefficients, workingDistance);

    // Prevent divide by zero
    if (a === 0 || zoomXtender.coreMagnificationHigh === 0) return 0;

    return ((coreZoom / zoomXtender.coreMagnificationHigh) / a) * adapter.magnification;
}

export function getZoomXtenderWorkingMagnification(
    zoomXtender: DbZoomXtender,
    adapter: DbAdapter,
    coreZoom: number,
    workingDistance: number,
): number {
    let mainMag = 0;
    if (zoomXtender.fitEquationId === 1) {
        mainMag = zoomXtenderFit6(coreZoom, workingDistance, [
            1.74250016471407E+00,
            2.28745019435954E-05,
            4.33580037313330E-04,
            -1.04091487756643E-07,
            7.49845168117257E-04,
            -5.99338806139457E-07,
            -4.15852120420011E-07,
            -5.13964639083063E-05,
            8.93620303564495E-11,
            -2.54562324021766E-07,
            8.38490659674786E-11,
            -3.02788422186030E-01,
            1.25458362415636E-03,
        ]);
    } else if (zoomXtender.fitEquationId === 2) {
        mainMag = zoomXtenderFit6(coreZoom, workingDistance, [
            1.08954032046493E+00,
            -1.07786027280448E-06,
            8.51248656063535E-05,
            4.36520893334930E-10,
            1.55772934612641E-04,
            -2.06133229398032E-08,
            -2.88573882909511E-08,
            -5.18357454697756E-05,
            -4.83462894400176E-14,
            1.23007002916793E-08,
            2.36354344138276E-12,
            -1.67050898625458E-01,
            5.62670727774328E-04,
        ]);
    } else if (zoomXtender.fitEquationId === 3) {
        mainMag = zoomXtenderFit12(coreZoom, workingDistance, [
            8.87885444663281E-01,
            -1.09074051722128E-06,
            1.20838939529962E-04,
            -7.60556084663170E-03,
            1.15973859182597E-09,
            1.31986606445586E-04,
            -1.38398581821066E-01,
            -5.06993311152804E-10,
            -1.72925288700482E-05,
            1.82740955056457E-08,
            1.94895487290608E-03,
            3.11531251655188E-03,
            7.08683202952859E-06,
            -7.48949733127265E-09,
            -7.98450938316994E-04,
            -3.54370915349859E-05,
            9.16366358629730E-06,
            -8.35681872171253E-08,
            9.00334753047570E-11,
            -4.69710799238409E-04,
        ]);
    } else if (zoomXtender.fitEquationId === 4) {
        mainMag = zoomXtenderFit12(coreZoom, workingDistance, [
            7.63012721442660E-01,
            -6.25640306590581E-09,
            3.04418700235724E-06,
            -5.91076255684688E-04,
            1.59323436549997E-12,
            8.72786357945191E-05,
            -1.14183407302578E-01,
            -1.04096560005446E-08,
            -2.12474780655932E-07,
            4.55643530044765E-11,
            1.11302790402275E-04,
            2.42915027186543E-04,
            8.75555577129640E-08,
            -1.87758959501706E-11,
            -4.57480252116953E-05,
            -3.26406013934860E-06,
            6.26435700436015E-07,
            -1.35571705178897E-09,
            3.13488324154366E-13,
            -1.52418658519171E-05,
        ]);
    } else if (zoomXtender.fitEquationId === 5) {
        mainMag = zoomXtenderFit12(coreZoom, workingDistance, [
            3.87999920147220E-01,
            2.75646988133912E-09,
            -5.79988968608547E-06,
            1.73862799627203E-05,
            -1.25908269852846E-13,
            1.32353167425881E-05,
            -5.05704482689984E-02,
            -4.97139768603869E-10,
            -1.96116993055827E-09,
            1.04645139193112E-13,
            -3.80325543281833E-07,
            -1.29770296278029E-06,
            1.26629984904775E-09,
            -6.45294430769322E-14,
            -7.34843879492854E-07,
            1.83518065342807E-07,
            -2.07182125864347E-08,
            3.64619808174089E-12,
            -6.80992527861817E-17,
            3.90391760685532E-05,
        ]);
    }

    return mainMag * adapter.magnification;
}

export function getSolutionWorkingDistance(solution: Solution): NumberRange {
    if (solution.kind === 'standard') {
        if (solution.standardAttachment !== null) {
            return createRange(
                solution.standardAttachment.workingDistance,
                solution.standardAttachment.workingDistance,
            );
        } else {
            return createRange(
                solution.standardCore.noAttachmentWorkingDistance,
                solution.standardCore.noAttachmentWorkingDistance,
            );
        }
    } else if (solution.kind === 'objective' || solution.kind === 'single_shot') {
        return createRange(
            solution.objectiveAttachment.workingDistance,
            solution.objectiveAttachment.workingDistance,
        );
    } else if (solution.kind === 'zoom_xtender') {
        return createRange(
            solution.zoomXtender.workingDistanceLow,
            negativeOneToInfinity(solution.zoomXtender.workingDistanceHigh),
        );
    } else if (solution.kind === 'machine_vision') {
        return createRange(solution.lens.minWorkingDistance, Infinity);
    } else {
        assertUnreachable(solution);
    }
}

export function getMachineVisionWorkingWorkingDistance(solution: MachineVisionSolution, workingMagnification: number): number {
    return (solution.lens.focalLength / workingMagnification) - solution.lens.pupilPosition;
}

export function getMachineVisionWorkingMagnification(solution: MachineVisionSolution, workingDistance: number): number {
    const denominator = workingDistance + solution.lens.pupilPosition;
    if (denominator === 0) return Infinity;
    return solution.lens.focalLength / denominator;
}

export function getSolutionNumericalAperture(solution: Solution, workingDistance: number): NumberRange {
    if (solution.kind === 'standard') {
        return createRange(
            getStandardWorkingNumericalAperture(solution, solution.standardCore.magnificationLow),
            getStandardWorkingNumericalAperture(solution, solution.standardCore.magnificationHigh),
        );
    } else if (solution.kind === 'objective') {
        return createRange(
            getObjectiveWorkingNumericalAperture(solution, solution.objectiveCore.focalLengthLow),
            getObjectiveWorkingNumericalAperture(solution, solution.objectiveCore.focalLengthHigh),
        );
    } else if (solution.kind === 'single_shot') {
        const na = getSingleShotWorkingNumericalAperture(solution);
        return createRange(na, na);
    } else if (solution.kind === 'zoom_xtender') {
        return createRange(
            getZoomXtenderWorkingNumericalAperture(
                solution.zoomXtender,
                solution.zoomXtender.coreMagnificationLow,
                workingDistance,
            ),
            getZoomXtenderWorkingNumericalAperture(
                solution.zoomXtender,
                solution.zoomXtender.coreMagnificationHigh,
                workingDistance,
            ),
        );
    } else if (solution.kind === 'machine_vision') {
        // Numerical Aperture doesn't apply to these lenses
        return createRange(0, 0);
    } else {
        assertUnreachable(solution);
    }
}

export function getSolutionWorkingNumericalAperture(solution: Solution, workingMagnification: number, workingDistance: number): number {
    if (solution.kind === 'standard') {
        const attachmentMagnification = solution.standardAttachment !== null ? solution.standardAttachment.magnification : 1;
        const coreMagnification = workingMagnification / (solution.adapter.magnification * attachmentMagnification);
        return getStandardWorkingNumericalAperture(solution, coreMagnification);
    } else if (solution.kind === 'objective') {
        const adapterMagnification = solution.adapter !== null ? solution.adapter.magnification : 1;
        const coreFocalLength = (workingMagnification / adapterMagnification) * solution.objectiveAttachment.focalLength;
        return getObjectiveWorkingNumericalAperture(solution, coreFocalLength);
    } else if (solution.kind === 'single_shot') {
        return getSingleShotWorkingNumericalAperture(solution);
    } else if (solution.kind === 'zoom_xtender') {
        if (workingDistance === 0) return 0;
        const a = polynomial(solution.magnificationCoefficients, workingDistance);
        const coreZoom = (workingMagnification / solution.adapter.magnification) * a * solution.zoomXtender.coreMagnificationHigh;
        return getZoomXtenderWorkingNumericalAperture(solution.zoomXtender, coreZoom, workingDistance);
    } else if (solution.kind === 'machine_vision') {
        // Numerical Aperture doesn't apply to these lenses
        return 0;
    } else {
        assertUnreachable(solution);
    }
}

export function getStandardWorkingNumericalAperture(solution: StandardSolution, coreMagnification: number): number {
    const attachmentMagnification = solution.standardAttachment !== null ? solution.standardAttachment.magnification : 1;
    const coreNa = polynomial(solution.naCoefficients, coreMagnification);
    return coreNa * attachmentMagnification;
}

export function getObjectiveWorkingNumericalAperture(solution: ObjectiveSolution, coreFocalLength: number): number {
    const coreStopSemiDiameter = polynomial(solution.stopSemiDiameterCoefficients, coreFocalLength);
    return Math.min(coreStopSemiDiameter, solution.objectiveAttachment.stopSemiDiameter) / solution.objectiveAttachment.focalLength;
}

export function getSingleShotWorkingNumericalAperture(solution: SingleShotSolution): number {
    return Math.min(solution.objectiveCore.stopSemiDiameter, solution.objectiveAttachment.stopSemiDiameter) / solution.objectiveAttachment.focalLength;
}

export function getZoomXtenderWorkingNumericalApertureOld(coefficients: number[], workingDistance: number, coreZoom: number): number {
    // Prevent divide by zero
    if (workingDistance === 0) return 0;

    const a = polynomial(coefficients, coreZoom);
    return a / workingDistance;
}

export function getZoomXtenderWorkingNumericalAperture(zoomXtender: DbZoomXtender, coreZoom: number, workingDistance: number): number {
    let result = 0;
    if (zoomXtender.fitEquationId === 1) {
        result = zoomXtenderFit6(coreZoom, workingDistance, [
            4.11786085445542E-02,
            -2.52679337046429E-05,
            -2.53774792788677E-03,
            3.95256888979401E-08,
            2.12272881266694E-05,
            2.25723554884955E-06,
            -2.59544251594177E-08,
            2.33272155094137E-04,
            -1.82808325157505E-11,
            -2.07106134037167E-07,
            1.04891177855845E-11,
            -6.49926248960015E-03,
            4.62226654550071E-03,
        ]);
    } else if (zoomXtender.fitEquationId === 2) {
        result = zoomXtenderFit6(coreZoom, workingDistance, [
            2.43769670165070E-02,
            -3.68247598897260E-06,
            -9.94921075408171E-04,
            1.50039596539229E-09,
            4.01793276779901E-06,
            2.46821693855413E-07,
            -1.13275263249937E-09,
            9.15121493692921E-05,
            -1.70061066569060E-13,
            -2.27057733594979E-08,
            1.09350385957223E-13,
            -3.51067756302560E-03,
            2.06938506970764E-03,
        ]);
    } else if (zoomXtender.fitEquationId === 3) {
        result = zoomXtenderFit12(coreZoom, workingDistance, [
            3.73276678145044E-02,
            -9.42622517833304E-08,
            3.35230455367207E-04,
            -8.04780533646381E-03,
            -2.51835951699238E-10,
            4.68007068046685E-06,
            -5.77509626742932E-03,
            9.01647815848681E-10,
            -9.92263526375040E-07,
            -1.91232107311557E-10,
            1.24387605120832E-03,
            9.73733966218637E-04,
            8.04225200698012E-08,
            6.38754173930055E-11,
            -1.48562624533556E-04,
            -4.59424764686322E-05,
            6.89679803252493E-06,
            -1.49229742260019E-09,
            -5.36666514198463E-12,
            -2.22874024879056E-03,
        ]);
    } else if (zoomXtender.fitEquationId === 4) {
        result = zoomXtenderFit12(coreZoom, workingDistance, [
            3.25322069181437E-02,
            -2.46634909142560E-07,
            3.13518455076501E-04,
            -7.05170000002743E-03,
            2.98781789373466E-11,
            3.73308651771234E-06,
            -4.87078246672920E-03,
            -4.46050646766891E-10,
            -8.15154168168644E-07,
            9.80341352037430E-11,
            1.05690250540340E-03,
            8.71254792486671E-04,
            1.01008627814814E-07,
            -1.21764993132619E-11,
            -1.30652239460561E-04,
            -4.20975329266113E-05,
            6.31474821539885E-06,
            -4.88505437436258E-09,
            5.89512905022857E-13,
            -2.08338145786471E-03,
        ]);
    } else if (zoomXtender.fitEquationId === 5) {
        result = zoomXtenderFit12(coreZoom, workingDistance, [
            1.64845578298440E-02,
            -3.22791935382709E-08,
            1.29919295472143E-04,
            -3.54824108700320E-03,
            1.14772413920666E-12,
            5.60485018723279E-07,
            -2.14795786693892E-03,
            -2.09814857620475E-11,
            -1.20200231461133E-07,
            4.48454625187356E-12,
            4.62193814858963E-04,
            4.35657922817377E-04,
            1.46892993408699E-08,
            -5.46446841031486E-13,
            -5.67178703648320E-05,
            -2.09291346965494E-05,
            2.72312595204470E-06,
            -7.02400606952704E-10,
            2.60703001969056E-14,
            -1.00236137436054E-03,
        ]);
    }

    return result;
}

export function getSolutionResolveLimit(solution: Solution, wavelengthString: Wavelength, workingDistance: number): NumberRange {
    const na = getSolutionNumericalAperture(solution, workingDistance);
    if (solution.kind === 'machine_vision') {
        if (solution.lens.resolvableFrequencyCenter === 0) return createRange(Infinity, Infinity);
        const magAtMinWorkingDistance = getMachineVisionWorkingMagnification(solution, solution.lens.minWorkingDistance);
        const result = getMachineVisionWorkingResolveLimit(solution.lens.resolvableFrequencyCenter, magAtMinWorkingDistance);
        return createRange(result, result);
    } else {
        return createRange(
            getWorkingResolveLimit(na.low, wavelengthString),
            getWorkingResolveLimit(na.high, wavelengthString),
        );
    }
}

export function getWorkingResolveLimit(numericalAperture: number, wavelengthString: Wavelength): number {
    if (numericalAperture === 0) return 0;
    const wavelength = getWavelengthNumber(wavelengthString);
    return (0.61 * wavelength) / numericalAperture;
}

export function getMachineVisionWorkingResolveLimit(resolvableFrequencyCenter: number, magnification: number): number {
    if (resolvableFrequencyCenter === 0) return Infinity;
    return mmToUm(1 / (2 * magnification * resolvableFrequencyCenter));
}

export function getSolutionDepthOfField(solution: Solution, wavelengthString: Wavelength, workingDistance: number): NumberRange {
    const na = getSolutionNumericalAperture(solution, workingDistance);
    return createRange(
        getWorkingDepthOfField(na.low, wavelengthString),
        getWorkingDepthOfField(na.high, wavelengthString),
    );
}

export function getWorkingDepthOfField(numericalAperture: number, wavelengthString: Wavelength): number {
    if (numericalAperture === 0) return 0;
    const wavelength = getWavelengthNumber(wavelengthString);
    const wavelengthMm = umToMm(wavelength);
    return wavelengthMm / Math.pow(numericalAperture, 2);
}

export function getWavelengthNumber(wavelength: Wavelength): number {
    if (wavelength === 'near_infrared') return wizardSettings.wavelengthNearInfrared;
    if (wavelength === 'short_wave_infrared') return wizardSettings.wavelengthShortWaveInfrared;
    return wizardSettings.wavelengthVisible;
}

function getSensorLength(pixelSize: number, resolution: number): number {
    return umToMm(pixelSize) * resolution;
}

export function getSensorInfo(): { pixelSize: number; resolutionWidth: number; resolutionHeight: number } {
    if (wizardStore.lensCameraMake === 'custom_size') {
        return {
            pixelSize: wizardStore.lensPixelSize,
            resolutionWidth: wizardStore.lensResolutionWidth,
            resolutionHeight: wizardStore.lensResolutionHeight,
        };
    } else if (wizardStore.lensCameraMake === 'custom_line') {
        return {
            pixelSize: wizardStore.lensPixelSize,
            resolutionWidth: wizardStore.lensResolutionWidth,
            resolutionHeight: 1,
        };
    } else if (wizardStore.lensCameraMake === 'pixelink') {
        if (wizardStore.lensCameraId !== null) {
            const camera = wizardStore.tables.camera[wizardStore.lensCameraId];
            const sensor = wizardStore.tables.cameraSensor[camera.cameraSensorId];
            return {
                pixelSize: sensor.pixelSize,
                resolutionWidth: sensor.resolutionWidth,
                resolutionHeight: sensor.resolutionHeight,
            };
        }
    } else if (wizardStore.lensCameraMake === 'common_size') {
        if (wizardStore.lensCommonCameraSensorFormatId !== null && wizardStore.lensPixelSize > 0) {
            const sensor = wizardStore.tables.commonSensor[wizardStore.lensCommonCameraSensorFormatId];
            const pixelSizeMm = umToMm(wizardStore.lensPixelSize);
            return {
                pixelSize: wizardStore.lensPixelSize,
                resolutionWidth: sensor.width / pixelSizeMm,
                resolutionHeight: sensor.height / pixelSizeMm,
            };
        }
    }

    return {
        pixelSize: 0,
        resolutionWidth: 0,
        resolutionHeight: 0,
    };
}

export function getSensorSize(): { width: number; height: number } {
    const sensorInfo = getSensorInfo();
    return {
        width: getSensorLength(sensorInfo.pixelSize, sensorInfo.resolutionWidth),
        height: getSensorLength(sensorInfo.pixelSize, sensorInfo.resolutionHeight),
    };
}

export function solutionIsZoom(solution: Solution): boolean {
    if (solution.kind === 'machine_vision') return false;
    const solutionMagnification = getSolutionMagnification(solution);
    return solutionMagnification.low !== solutionMagnification.high;
}

export function getMountOptions(blankName: string): Map<number | null, string> {
    const result = new Map<number | null, string>();
    result.set(null, blankName);
    for (const mount of Object.values(wizardStore.tables.mount).sort((a, b) => a.order - b.order)) {
        result.set(mount.id, mount.name);
    }
    return result;
}

export function getSolutionPartNames(solution: Solution): string {
    if (solution.kind === 'standard') {
        return [
            solution.adapter.name,
            solution.standardCore.name,
            ...(solution.standardAttachment !== null ? [solution.standardAttachment.name] : []),
        ].join('\n');
    } else if (solution.kind === 'objective') {
        return [
            ...(solution.adapter !== null ? [solution.adapter.name] : []),
            solution.objectiveCore.name,
            solution.objectiveAttachment.name,
        ].join('\n');
    } else if (solution.kind === 'single_shot') {
        return [
            solution.objectiveCore.name,
            solution.objectiveAttachment.name,
        ].join('\n');
    } else if (solution.kind === 'zoom_xtender') {
        return [
            solution.adapter.name,
            solution.zoomXtender.coreName,
            solution.zoomXtender.name,
        ].join('\n');
    } else if (solution.kind === 'machine_vision') {
        return [
            solution.lens.partNumber,
            (
                formatFloat(solution.lens.focalLength)
                + 'mm Focal Length, f/'
                + formatFloat(solution.lens.fNumberLow)
                + '–'
                + formatFloat(negativeOneToInfinity(solution.lens.fNumberHigh))
                + (solution.lens.megapixels !== 0 ?
                    (
                        ', '
                        + formatFloat(solution.lens.megapixels)
                        + 'MP'
                    ) : ''
                )
            ),
        ].join('\n');
    } else {
        return assertUnreachable(solution);
    }
}

function getSolutionMaxSensorSize(solution: Solution): number {
    if (solution.kind === 'standard') {
        return solution.adapter.maxSensorDiagonalStandard;
    } else if (solution.kind === 'objective') {
        if (solution.adapter !== null) {
            return solution.adapter.maxSensorDiagonalObjective;
        } else {
            return solution.objectiveCore.noAdapterMaxSensorDiagonal;
        }
    } else if (solution.kind === 'single_shot') {
        return solution.objectiveCore.maxSensorDiagonal;
    } else if (solution.kind === 'zoom_xtender') {
        return solution.adapter.maxSensorDiagonalStandard;
    } else if (solution.kind === 'machine_vision') {
        return solution.lens.maxSensorDiagonal;
    } else {
        assertUnreachable(solution);
    }
}

function calculateDiagonal(x: number, y: number): number {
    return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
}

function isValidSolutionMaxSensorSize(solution: Solution, sensorWidth: number, sensorHeight: number): boolean {
    const sensorDiagonal = calculateDiagonal(sensorWidth, sensorHeight);
    return sensorDiagonal <= getSolutionMaxSensorSize(solution) * (1 + wizardSettings.maxSensorDiagonalTolerance);
}

function isValidSolutionMagnification(
    solution: Solution,
    targetMagnification: number,
    maxTargetMagnification: number,
    lensType: LensType,
    workingDistance: number,
    solutionDetails: SolutionDetails,
): boolean {
    if (solution.kind === 'machine_vision') {
        // Machine Vision lenses are fixed so we don't need to worry about maxTargetMagnification
        if (workingDistance === 0) {
            // Machine Vision lenses have an infinite field of view if the working distance is unconstrained
            // so they will work for any target magnification
            return solutionDetails.magnification.working.low >= targetMagnification * (1 - wizardSettings.magnificationTolerance);
        } else {
            return (
                solutionDetails.magnification.working.low <= targetMagnification + wizardSettings.epsilon
                && solutionDetails.magnification.working.low >= targetMagnification * (1 - wizardSettings.magnificationTolerance)
            );
        }
    } else {
        if (lensType === 'zoom') {
            return (
                solutionDetails.magnification.working.low <= targetMagnification + wizardSettings.epsilon
                && solutionDetails.magnification.working.high >= targetMagnification * (1 - wizardSettings.magnificationTolerance)
                && (
                    maxTargetMagnification <= 0
                    || (
                        solutionDetails.magnification.working.low <= maxTargetMagnification + wizardSettings.epsilon
                        && solutionDetails.magnification.working.high >= maxTargetMagnification * (1 - wizardSettings.magnificationTolerance)
                    )
                )
            );
        } else {
            return (
                solutionDetails.magnification.working.low <= targetMagnification + wizardSettings.epsilon
                && solutionDetails.magnification.working.low >= targetMagnification * (1 - wizardSettings.magnificationTolerance)
            );
        }
    }
}

function isValidSolutionWorkingDistance(solution: Solution, targetWorkingDistance: number, solutionDetails: SolutionDetails): boolean {
    if (targetWorkingDistance === 0) {
        if (solution.kind === 'zoom_xtender') {
            return false;
        } else {
            return true;
        }
    }

    return (
        solutionDetails.workingDistance.working >= targetWorkingDistance * (1 - wizardSettings.workingDistanceTolerance)
        && solutionDetails.workingDistance.working <= targetWorkingDistance * (1 + wizardSettings.workingDistanceTolerance)
    );
}

function isValidSolutionFeatureSize(targetFeatureSize: number, maxTargetFeatureSize: number, solutionDetails: SolutionDetails): boolean {
    if (targetFeatureSize > 0) {
        if (solutionDetails.resolveLimit.working.low > targetFeatureSize) {
            return false;
        }
    }

    if (maxTargetFeatureSize > 0) {
        if (solutionDetails.resolveLimit.working.high > maxTargetFeatureSize) {
            return false;
        }
    }

    return true;
}

function isValidSolutionLensType(solution: Solution, lensType: LensType): boolean {
    if (lensType === 'all') return true;

    const zoom = solutionIsZoom(solution);

    return lensType === 'zoom' && zoom || lensType === 'fixed' && !zoom;
}

function isValidSolutionWavelength(solution: Solution, wavelength: Wavelength): boolean {
    if (solution.kind === 'standard') {
        if (wavelength === 'visible') {
            return (
                solution.adapter.wavelengthVisible
                && solution.standardCore.wavelengthVisible
                && (solution.standardAttachment === null || solution.standardAttachment.wavelengthVisible)
            );
        } else if (wavelength === 'near_infrared') {
            return (
                solution.adapter.wavelengthNearInfrared
                && solution.standardCore.wavelengthNearInfrared
                && (solution.standardAttachment === null || solution.standardAttachment.wavelengthNearInfrared)
            );
        } else if (wavelength === 'short_wave_infrared') {
            return (
                solution.adapter.wavelengthShortWaveInfrared
                && solution.standardCore.wavelengthShortWaveInfrared
                && (solution.standardAttachment === null || solution.standardAttachment.wavelengthShortWaveInfrared)
            );
        } else {
            assertUnreachable(wavelength);
        }
    } else if (solution.kind === 'objective') {
        if (wavelength === 'visible') {
            return (
                (solution.adapter === null || solution.adapter.wavelengthVisible)
                && solution.objectiveCore.wavelengthVisible
                && solution.objectiveAttachment.wavelengthVisible
            );
        } else if (wavelength === 'near_infrared') {
            return (
                (solution.adapter === null || solution.adapter.wavelengthNearInfrared)
                && solution.objectiveCore.wavelengthNearInfrared
                && solution.objectiveAttachment.wavelengthNearInfrared
            );
        } else if (wavelength === 'short_wave_infrared') {
            return (
                (solution.adapter === null || solution.adapter.wavelengthShortWaveInfrared)
                && solution.objectiveCore.wavelengthShortWaveInfrared
                && solution.objectiveAttachment.wavelengthShortWaveInfrared
            );
        } else {
            assertUnreachable(wavelength);
        }
    } else if (solution.kind === 'single_shot') {
        if (wavelength === 'visible') {
            return (
                solution.objectiveCore.wavelengthVisible
                && solution.objectiveAttachment.wavelengthVisible
            );
        } else if (wavelength === 'near_infrared') {
            return (
                solution.objectiveCore.wavelengthNearInfrared
                && solution.objectiveAttachment.wavelengthNearInfrared
            );
        } else if (wavelength === 'short_wave_infrared') {
            return (
                solution.objectiveCore.wavelengthShortWaveInfrared
                && solution.objectiveAttachment.wavelengthShortWaveInfrared
            );
        } else {
            assertUnreachable(wavelength);
        }
    } else if (solution.kind === 'zoom_xtender') {
        if (wavelength === 'visible') {
            return (
                solution.adapter.wavelengthVisible
                && solution.zoomXtender.wavelengthVisible
            );
        } else if (wavelength === 'near_infrared') {
            return (
                solution.adapter.wavelengthNearInfrared
                && solution.zoomXtender.wavelengthNearInfrared
            );
        } else if (wavelength === 'short_wave_infrared') {
            return (
                solution.adapter.wavelengthShortWaveInfrared
                && solution.zoomXtender.wavelengthShortWaveInfrared
            );
        } else {
            assertUnreachable(wavelength);
        }
    } else if (solution.kind === 'machine_vision') {
        if (wavelength === 'visible') {
            return solution.lens.wavelengthVisible;
        } else if (wavelength === 'near_infrared') {
            return solution.lens.wavelengthNearInfrared;
        } else if (wavelength === 'short_wave_infrared') {
            return solution.lens.wavelengthShortWaveInfrared;
        } else {
            assertUnreachable(wavelength);
        }
    } else {
        assertUnreachable(solution);
    }
}

export function isValidSolution(
    solution: Solution,
    sensorWidth: number,
    sensorHeight: number,
    targetMagnification: number,
    maxTargetMagnification: number,
    targetWorkingDistance: number,
    targetFeatureSize: number,
    maxTargetFeatureSize: number,
    lensType: LensType,
    wavelength: Wavelength,
): boolean {
    const solutionDetails = getSolutionDetails(solution);

    return (
        isValidSolutionMaxSensorSize(solution, sensorWidth, sensorHeight)
        && isValidSolutionMagnification(solution, targetMagnification, maxTargetMagnification, lensType, targetWorkingDistance, solutionDetails)
        && isValidSolutionWorkingDistance(solution, targetWorkingDistance, solutionDetails)
        && isValidSolutionFeatureSize(targetFeatureSize, maxTargetFeatureSize, solutionDetails)
        && isValidSolutionLensType(solution, lensType)
        && isValidSolutionWavelength(solution, wavelength)
    );
}

export function getSolutions(
    tables: DbTables,
    productFamilyId: number | null,
    isValidSolutionFn: (solution: Solution) => boolean,
): Solution[] {
    const adaptersByProductFamily = indexByForeignKey(Object.values(tables.adapter), 'productFamilyId');
    const standardCoresByProductFamily = indexByForeignKey(Object.values(tables.standardCore), 'productFamilyId');
    const standardAttachmentsByProductFamily = indexByForeignKey(Object.values(tables.standardAttachment), 'productFamilyId');
    const objectiveCoresByProductFamily = indexByForeignKey(Object.values(tables.objectiveCore), 'productFamilyId');
    const singleShotCoresByProductFamily = indexByForeignKey(Object.values(tables.singleShotCore), 'productFamilyId');
    const singleShotObjectivesByProductFamily = indexByForeignKey(Object.values(tables.singleShotObjective), 'productFamilyId');
    const zoomXtendersByProductFamily = indexByForeignKey(Object.values(tables.zoomXtender), 'productFamilyId');
    const machineVisionLensesByProductFamily = indexByForeignKey(Object.values(tables.machineVisionLens), 'productFamilyId');
    const naCoefficientsByStandardCore = indexByForeignKey(
        sortById(Object.values(tables.standardCoreNaCoefficient)),
        'standardCoreId',
    );
    const stopDiamCoefficientsByObjectiveCore = indexByForeignKey(
        sortById(Object.values(tables.objectiveCoreStopDiamCoefficient)),
        'objectiveCoreId',
    );
    const magCoefficientsByZoomXtender = indexByForeignKey(
        sortById(Object.values(tables.zoomXtenderMagnificationCoefficient)),
        'zoomXtenderId',
    );
    const naCoefficientsByZoomXtender = indexByForeignKey(
        sortById(Object.values(tables.zoomXtenderNaCoefficient)),
        'zoomXtenderId',
    );

    const solutions: Solution[] = [];

    const maybePushSolution = (solution: Solution) => {
        if (isValidSolutionFn(solution)) {
            solutions.push(solution);
        }
    };

    for (const productFamily of Object.values(tables.productFamily)) {
        if (productFamilyId !== null && productFamily.id !== productFamilyId) continue;

        for (const standardCore of defaultValueIfUndefined(standardCoresByProductFamily[productFamily.id], [])) {
            const naCoefficients = defaultValueIfUndefined(naCoefficientsByStandardCore[standardCore.id], []).map((x) => x.coefficient);

            for (const adapter of defaultValueIfUndefined(adaptersByProductFamily[productFamily.id], [])) {
                for (const standardAttachment of defaultValueIfUndefined(standardAttachmentsByProductFamily[productFamily.id], [])) {
                    maybePushSolution({
                        kind: 'standard',
                        productFamily: productFamily,
                        adapter: adapter,
                        standardCore: standardCore,
                        standardAttachment: standardAttachment,
                        naCoefficients: naCoefficients,
                    });
                }

                // No attachment
                if (!standardCore.attachmentRequired) {
                    maybePushSolution({
                        kind: 'standard',
                        productFamily: productFamily,
                        adapter: adapter,
                        standardCore: standardCore,
                        standardAttachment: null,
                        naCoefficients: naCoefficients,
                    });
                }
            }
        }

        for (const objectiveCore of defaultValueIfUndefined(objectiveCoresByProductFamily[productFamily.id], [])) {
            const stopSemiDiameterCoefficients = defaultValueIfUndefined(stopDiamCoefficientsByObjectiveCore[objectiveCore.id], []).map((x) => x.coefficient);

            for (const objectiveAttachment of Object.values(tables.objectiveAttachment)) {
                for (const adapter of defaultValueIfUndefined(adaptersByProductFamily[productFamily.id], [])) {
                    maybePushSolution({
                        kind: 'objective',
                        productFamily: productFamily,
                        adapter: adapter,
                        objectiveCore: objectiveCore,
                        objectiveAttachment: objectiveAttachment,
                        stopSemiDiameterCoefficients: stopSemiDiameterCoefficients,
                    });
                }

                // No adapter
                if (!objectiveCore.adapterRequired) {
                    maybePushSolution({
                        kind: 'objective',
                        productFamily: productFamily,
                        adapter: null,
                        objectiveCore: objectiveCore,
                        objectiveAttachment: objectiveAttachment,
                        stopSemiDiameterCoefficients: stopSemiDiameterCoefficients,
                    });
                }
            }
        }

        for (const singleShotCore of defaultValueIfUndefined(singleShotCoresByProductFamily[productFamily.id], [])) {
            for (const singleShotObjective of defaultValueIfUndefined(singleShotObjectivesByProductFamily[productFamily.id], [])) {
                maybePushSolution({
                    kind: 'single_shot',
                    productFamily: productFamily,
                    objectiveCore: singleShotCore,
                    objectiveAttachment: singleShotObjective,
                });
            }
        }

        for (const zoomXtender of defaultValueIfUndefined(zoomXtendersByProductFamily[productFamily.id], [])) {
            const magCoefficients = defaultValueIfUndefined(magCoefficientsByZoomXtender[zoomXtender.id], []).map((x) => x.coefficient);
            const naCoefficients = defaultValueIfUndefined(naCoefficientsByZoomXtender[zoomXtender.id], []).map((x) => x.coefficient);

            for (const adapter of defaultValueIfUndefined(adaptersByProductFamily[productFamily.id], [])) {
                maybePushSolution({
                    kind: 'zoom_xtender',
                    productFamily: productFamily,
                    zoomXtender: zoomXtender,
                    adapter: adapter,
                    magnificationCoefficients: magCoefficients,
                    naCoefficients: naCoefficients,
                });
            }
        }

        for (const lens of defaultValueIfUndefined(machineVisionLensesByProductFamily[productFamily.id], [])) {
            maybePushSolution({
                kind: 'machine_vision',
                productFamily: productFamily,
                lens: lens,
            });
        }
    }

    return solutions;
}

export function solutionsEqual(a: Solution, b: Solution): boolean {
    if (a.kind === 'standard') {
        if (b.kind === 'standard') {
            return (
                a.productFamily === b.productFamily
                && a.adapter === b.adapter
                && a.standardCore === b.standardCore
                && a.standardAttachment === b.standardAttachment
            );
        } else {
            return false;
        }
    } else if (a.kind === 'objective') {
        if (b.kind === 'objective') {
            return (
                a.productFamily === b.productFamily
                && a.adapter === b.adapter
                && a.objectiveCore === b.objectiveCore
                && a.objectiveAttachment === b.objectiveAttachment
            );
        } else {
            return false;
        }
    } else if (a.kind === 'single_shot') {
        if (b.kind === 'single_shot') {
            return (
                a.productFamily === b.productFamily
                && a.objectiveCore === b.objectiveCore
                && a.objectiveAttachment === b.objectiveAttachment
            );
        } else {
            return false;
        }
    } else if (a.kind === 'zoom_xtender') {
        if (b.kind === 'zoom_xtender') {
            return (
                a.productFamily === b.productFamily
                && a.adapter === b.adapter
                && a.zoomXtender === b.zoomXtender
            );
        } else {
            return false;
        }
    } else if (a.kind === 'machine_vision') {
        if (b.kind === 'machine_vision') {
            return (
                a.productFamily === b.productFamily
                && a.lens === b.lens
            );
        } else {
            return false;
        }
    } else {
        assertUnreachable(a);
    }
}

export function getTargetMagnification(
    sensorWidth: number,
    sensorHeight: number,
    objectWidth: number,
    objectHeight: number,
    cameraMake: CameraMake,
): number {
    if (cameraMake === 'custom_line') {
        if (sensorWidth <= 0 || objectWidth <= 0) return 0;
        return sensorWidth / objectWidth;
    } else {
        if (sensorWidth <= 0 || sensorHeight <= 0 || objectWidth <= 0 || objectHeight <= 0) return 0;

        const sMax = Math.max(sensorWidth, sensorHeight);
        const sMin = Math.min(sensorWidth, sensorHeight);

        const oMax = Math.max(objectWidth, objectHeight);
        const oMin = Math.min(objectWidth, objectHeight);

        const maxMag = sMax / oMax;
        const minMag = sMin / oMin;

        return Math.min(maxMag, minMag);
    }
}

export function getTargetMagnificationRange(targetMagnification: number, maxTargetMagnification: number, lensType: LensType): NumberRange {
    return (lensType === 'zoom' && maxTargetMagnification > 0 ?
        createRange(targetMagnification, maxTargetMagnification) :
        createRange(targetMagnification, targetMagnification)
    );
}

export type SolutionDetails = ReturnType<typeof getSolutionDetails>;

export function getSolutionDetails(solution: Solution) {
    const sensorSize = getSensorSize();

    const specMagnificationRange = getSolutionMagnification(solution);
    const wizardInputMagnificationRange = getTargetMagnificationRange(
        getTargetMagnification(
            sensorSize.width,
            sensorSize.height,
            wizardStore.lensObjectWidth,
            wizardStore.lensObjectHeight,
            wizardStore.lensCameraMake,
        ),
        getTargetMagnification(
            sensorSize.width,
            sensorSize.height,
            wizardStore.lensMaxObjectWidth,
            wizardStore.lensMaxObjectHeight,
            wizardStore.lensCameraMake,
        ),
        wizardStore.lensType,
    );
    const workingMagnificationRange = createRange(
        clampToRange(wizardInputMagnificationRange.low, specMagnificationRange),
        clampToRange(wizardInputMagnificationRange.high, specMagnificationRange),
    );

    const wizardInputFieldOfViewLow = {
        x: wizardStore.lensObjectWidth,
        y: wizardStore.lensObjectHeight,
    };
    const wizardInputFieldOfViewHigh = {
        x: wizardStore.lensMaxObjectWidth,
        y: wizardStore.lensMaxObjectHeight,
    };
    const wizardInputFieldOfViewRange = (wizardStore.lensMaxObjectWidth === 0 || wizardStore.lensMaxObjectHeight === 0 ?
        { low: wizardInputFieldOfViewLow, high: wizardInputFieldOfViewLow } :
        { low: wizardInputFieldOfViewLow, high: wizardInputFieldOfViewHigh }
    );

    const specWorkingDistanceRange = getSolutionWorkingDistance(solution);

    let workingWorkingDistance = 0;
    if (solution.kind === 'zoom_xtender') {
        // We only show zoom_xtender solutions if wizardStore.workingDistance > 0 so we can use that working distance here
        // That's also the working distance we used to calculate whether this solution can reach the target magnification
        workingWorkingDistance = clampToRange(wizardStore.lensWorkingDistance, specWorkingDistanceRange);
    } else if (solution.kind === 'machine_vision') {
        // Machine vision lenses are fixed, so workingMagnificationRange will just be a single number
        workingWorkingDistance = getMachineVisionWorkingWorkingDistance(solution, workingMagnificationRange.low);
    } else {
        // For these solution kinds the working distance can't be a range
        workingWorkingDistance = specWorkingDistanceRange.low;
    }

    const workingNumericalApertureRange = createRange(
        getSolutionWorkingNumericalAperture(solution, workingMagnificationRange.low, wizardStore.lensWorkingDistance),
        getSolutionWorkingNumericalAperture(solution, workingMagnificationRange.high, wizardStore.lensWorkingDistance),
    );
    let workingResolveLimitRange = createRange(0, 0);
    if (solution.kind === 'machine_vision') {
        workingResolveLimitRange = createRange(
            getMachineVisionWorkingResolveLimit(solution.lens.resolvableFrequencyCenter, workingMagnificationRange.low),
            getMachineVisionWorkingResolveLimit(solution.lens.resolvableFrequencyCenter, workingMagnificationRange.high),
        );
    } else {
        workingResolveLimitRange = createRange(
            getWorkingResolveLimit(workingNumericalApertureRange.low, wizardStore.lensWavelength),
            getWorkingResolveLimit(workingNumericalApertureRange.high, wizardStore.lensWavelength),
        );
    }

    const wizardInputResolveLimitRange = (wizardStore.lensType === 'zoom' && wizardStore.lensMaxFeatureSize > 0 ?
        createRange(wizardStore.lensFeatureSize, wizardStore.lensMaxFeatureSize) :
        createRange(wizardStore.lensFeatureSize, wizardStore.lensFeatureSize)
    );

    return {
        magnification: {
            spec: specMagnificationRange,
            working: workingMagnificationRange,
            input: wizardInputMagnificationRange,
        },
        fieldOfView: {
            spec: getFieldOfViewRange(sensorSize.width, sensorSize.height, specMagnificationRange),
            working: getFieldOfViewRange(sensorSize.width, sensorSize.height, workingMagnificationRange),
            input: wizardInputFieldOfViewRange,
        },
        workingDistance: {
            spec: specWorkingDistanceRange,
            working: workingWorkingDistance,
            input: wizardStore.lensWorkingDistance,
        },
        resolveLimit: {
            spec: getSolutionResolveLimit(solution, wizardStore.lensWavelength, wizardStore.lensWorkingDistance),
            working: workingResolveLimitRange,
            input: wizardInputResolveLimitRange,
        },
    };
}

export function showSolutionWarning(solution: Solution, sensorWidth: number, sensorHeight: number): boolean {
    const sensorDiagonal = calculateDiagonal(sensorWidth, sensorHeight);

    if (solution.kind === 'standard' || solution.kind === 'zoom_xtender') {
        return (
            solution.adapter.warnSensorDiagonalStandard !== 0
            && sensorDiagonal >= solution.adapter.warnSensorDiagonalStandard
        );
    } else if (solution.kind === 'objective') {
        return (
            solution.adapter !== null
            && solution.adapter.warnSensorDiagonalObjective !== 0
            && sensorDiagonal >= solution.adapter.warnSensorDiagonalObjective
        );
    } else {
        return false;
    }
}

export function getCameraSensorFormatOptions(blankName: string): Map<number | null, string> {
    const result = new Map<number | null, string>();
    result.set(null, blankName);
    for (const format of Object.values(wizardStore.tables.cameraSensorFormat).sort((a, b) => a.order - b.order)) {
        result.set(format.id, format.name);
    }
    return result;
}

export function formatMegapixelResolution(resolutionWidth: number, resolutionHeight: number): string {
    return toFixedMax(pixelsToMegapixels(resolutionWidth * resolutionHeight), 1) + 'MP';
}

export function getCameraSolutionDetails(): { label: string; value: string }[] {
    if (wizardStore.selectedCameraId === null) return [];

    const camera = wizardStore.tables.camera[wizardStore.selectedCameraId];
    const sensor = wizardStore.tables.cameraSensor[camera.cameraSensorId];
    const sensorFormat = wizardStore.tables.cameraSensorFormat[sensor.cameraSensorFormatId];
    const mounts = Object.values(wizardStore.tables.cameraMount)
        .filter((x) => x.cameraId === camera.id)
        .map((x) => wizardStore.tables.mount[x.mountId])
        .sort((a, b) => a.order - b.order);

    return [
        {
            label: 'Model Name',
            value: camera.partNumber,
        },
        {
            label: 'Resolution',
            value: formatMegapixelResolution(sensor.resolutionWidth, sensor.resolutionHeight),
        },
        {
            label: 'Sensor Size',
            value: sensorFormat.name,
        },
        {
            label: 'Pixel Size',
            value: formatFloat(sensor.pixelSize) + 'μm',
        },
        {
            label: 'Frame Rate',
            value: sensor.frameRate + 'fps',
        },
        {
            label: 'Color/Mono',
            value: defaultValueIfUndefined(dbOptions.camera.colorMono.get(camera.colorMono), ''),
        },
        {
            label: 'Interface',
            value: defaultValueIfUndefined(dbOptions.camera.interface.get(camera.interface), ''),
        },
        {
            label: 'Sensor Model',
            value: sensor.name,
        },
        {
            label: 'NIR',
            value: camera.nearInfrared ? 'Yes' : 'No',
        },
        {
            label: 'Autofocus',
            value: camera.autofocus ? 'Yes' : 'No',
        },
        {
            label: 'Autofocus Focal Length',
            value: camera.autofocusFocalLength + 'mm',
        },
        {
            label: 'Shutter Type',
            value: defaultValueIfUndefined(dbOptions.camera.shutterType.get(camera.shutterType), ''),
        },
        {
            label: 'Housing',
            value: defaultValueIfUndefined(dbOptions.camera.housing.get(camera.housing), ''),
        },
        {
            label: 'Lens Mounts',
            value: mounts.map((x) => x.name).join(', '),
        },
        {
            label: 'Hardware Trigger',
            value: camera.trigger ? 'Yes' : 'No',
        },
        {
            label: 'Bit Depth',
            value: defaultValueIfUndefined(dbOptions.camera.bitDepth.get(sensor.bitDepth), ''),
        },
    ]
}
