import { AdminBreadcrumb } from './admin_breadcrumb_types';
import { AdminSettings, AdminSettingsField } from './admin_settings_types';
import { DbTables, DbTableTypes } from './db_types';
import { routeToUrl } from './route_util';
import { Item as MultiEllipsisItem } from './components/multi_ellipsis/multi_ellipsis';

export function adminSettingsUniqueName(
    adminSettings: AdminSettings,
    tables: DbTables,
    tableName: keyof DbTableTypes,
    rowId: number,
): string {
    let result = '';
    const tableSettings = adminSettings[tableName];

    for (const template of tableSettings.uniqueNameTemplate) {
        if (template.fieldId !== '') {
            const fieldSettings = tableSettings.fields[template.fieldId];
            const fieldValue = (tables[tableName][rowId] as any)[template.fieldId];

            if (fieldSettings.fieldType === 'foreignKey') {
                if (fieldSettings.foreignKeyTable !== '') {
                    result += adminSettingsUniqueName(adminSettings, tables, fieldSettings.foreignKeyTable, fieldValue);
                }
            } else {
                result += fieldValue;
            }
        } else {
            result += template.text;
        }
    }

    return result;
}

export function adminSettingsUniqueNameMultiEllipsis(
    adminSettings: AdminSettings,
    tables: DbTables,
    tableName: keyof DbTableTypes,
    rowId: number,
): MultiEllipsisItem[] {
    const result: MultiEllipsisItem[] = [];
    const tableSettings = adminSettings[tableName];

    for (const template of tableSettings.uniqueNameTemplate) {
        if (template.fieldId !== '') {
            const fieldSettings = tableSettings.fields[template.fieldId];
            const fieldValue = (tables[tableName][rowId] as any)[template.fieldId];

            if (fieldSettings.fieldType === 'foreignKey') {
                if (fieldSettings.foreignKeyTable !== '') {
                    const subResult = adminSettingsUniqueNameMultiEllipsis(adminSettings, tables, fieldSettings.foreignKeyTable, fieldValue);
                    for (const item of subResult) {
                        result.push(item);
                    }
                }
            } else {
                result.push({
                    type: 'ellipsis',
                    text: fieldValue,
                });
            }
        } else {
            result.push({
                type: 'fixed',
                text: template.text,
            });
        }
    }

    return result;
}

export function adminSettingsEnumOptions(fieldSettings: AdminSettingsField, emptyName: string): Map<any, string> {
    const options = new Map<any, string>();
    options.set(fieldSettings.enumEmptyValue, emptyName);
    for (const choice of fieldSettings.enumChoices) {
        options.set(choice.value, choice.name);
    }
    return options;
}

export function pushBreadcrumb(breadcrumbs: AdminBreadcrumb[] | undefined, breadcrumb: AdminBreadcrumb): AdminBreadcrumb[] {
    if (breadcrumbs === undefined) return [breadcrumb];
    return breadcrumbs.concat([breadcrumb]);
}

export function getBreadcrumbName(adminSettings: AdminSettings, tableName: keyof DbTableTypes, row: any): string {
    let result = '';
    const tableSettings = adminSettings[tableName];

    for (const template of tableSettings.breadcrumbNameTemplate) {
        if (template.fieldId !== '') {
            result += row[template.fieldId];
        } else {
            result += template.text;
        }
    }

    return result;
}

export function getBreadcrumbUrl(breadcrumbs: AdminBreadcrumb[], index: number): string {
    const newParams: any = { ...breadcrumbs[index].route.params };
    const newBreadcrumbs = breadcrumbs.slice(0, index);
    if (newBreadcrumbs.length > 0) {
        newParams.breadcrumbs = newBreadcrumbs;
    }
    return routeToUrl({
        id: breadcrumbs[index].route.id,
        params: newParams,
    });
}

type Relation = {
    table: keyof DbTableTypes;
    fieldId: string;
    name: string;
};

export function adminSettingsReverseRelations(adminSettings: AdminSettings, tableName: keyof DbTableTypes): Relation[] {
    const relations: Relation[] = [];

    for (const t of Object.keys(adminSettings)) {
        const tableSettings = adminSettings[t as keyof DbTableTypes];
        for (const fieldId of Object.keys(tableSettings.fields)) {
            const fieldSettings = tableSettings.fields[fieldId];
            if (
                fieldSettings.fieldType === 'foreignKey'
                && fieldSettings.foreignKeyTable === tableName
                && fieldSettings.foreignKeyReverseName !== ''
            ) {
                relations.push({
                    table: t as keyof DbTableTypes,
                    fieldId: fieldId,
                    name: fieldSettings.foreignKeyReverseName,
                });
            }
        }
    }

    relations.sort((a, b) => a.name.localeCompare(b.name));
    return relations;
}
