import { DbCameraSensorFormat } from '../db_types';

// Autogenerated from spreadsheet import
export const dbDataCameraSensorFormat: { [index: string]: DbCameraSensorFormat } = {
    "5": {
        "id": 5,
        "name": "1\/2.5\"",
        "order": 2
    },
    "4": {
        "id": 4,
        "name": "1\/2.3\"",
        "order": 3
    },
    "1": {
        "id": 1,
        "name": "1\/2\"",
        "order": 4
    },
    "3": {
        "id": 3,
        "name": "2\/3\"",
        "order": 5
    },
    "8": {
        "id": 8,
        "name": "1\/1.2\"",
        "order": 6
    },
    "6": {
        "id": 6,
        "name": "1\"",
        "order": 7
    },
    "7": {
        "id": 7,
        "name": "1.1\"",
        "order": 8
    },
    "9": {
        "id": 9,
        "name": "1.2\"",
        "order": 9
    }
};
