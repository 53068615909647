import * as React from 'react';
import * as mobx from 'mobx';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../util';
import { cssScope } from './css_scope';
import { processImage } from '../../process_image';
import { CloseIcon } from '../icons/close';
import { uploadFile } from '../../file_upload_util';
import { FileUploadButton } from '../file_upload_button';
import { store } from '../../store';

type Props = {
    value: string;
    onChange: (value: string) => void;
    onLoadStart: (promise: Promise<void>) => void;
};

const c = scopedClasses(cssScope);

const processDimensions = {
    width: 1920,
    height: 1920,
};

export const ImageInput = mobxReactLite.observer(function ImageInput(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const state = mobxReactLite.useLocalObservable(() => {
        return {
            uploading: false,
        };
    });

    const onFilesSelected = React.useCallback(
        (files: FileList) => {
            mobx.runInAction(() => {
                state.uploading = true;
            });

            const file = files[0];

            const promise = processImage(file, processDimensions).then((processedBlob) => {
                return uploadFile(processedBlob, file.name, store);
            }).then((value) => {
                mobx.runInAction(() => {
                    state.uploading = false;
                });
                propsRef.current.onChange(value);
            });

            propsRef.current.onLoadStart(promise);
        },
        [],
    );

    const onDeleteClick = React.useCallback(
        () => {
            propsRef.current.onChange('');
        },
        [],
    );

    return h('div', { className: c('root') },
        (props.value !== '' ?
            h('div',
                {
                    className: c('preview'),
                    style: { backgroundImage: 'url(' + store.storageUrlPrefix + props.value + ')' },
                },
                h('div', { className: c('delete-button'), onClick: onDeleteClick },
                    h('div', { className: c('delete-circle') },
                        h(CloseIcon, { size: 24 }),
                    ),
                ),
            ) :
            h('div', { className: c('preview') },
                h(FileUploadButton, {
                    label: 'Choose Image',
                    multiple: false,
                    uploading: state.uploading,
                    onFilesSelected: onFilesSelected,
                }),
            )
        ),
    );
});
