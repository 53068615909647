import { DbCamera } from '../db_types';

// Autogenerated from spreadsheet import
export const dbDataCamera: { [index: string]: DbCamera } = {
    "1": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 1,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/m12b-cyl_datasheet.pdf?v=1650477752",
        "housing": "enclosed",
        "id": 1,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "M12BC-CYL",
        "shutterType": "global",
        "trigger": false
    },
    "2": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 1,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/m12b-cyl_datasheet.pdf?v=1650477752",
        "housing": "enclosed",
        "id": 2,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "M12BM-CYL",
        "shutterType": "global",
        "trigger": false
    },
    "3": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 2,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/m12-cyl_datasheet.pdf?v=1650477068",
        "housing": "enclosed",
        "id": 3,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "M12C-CYL",
        "shutterType": "global",
        "trigger": false
    },
    "4": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 2,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/m12-cyl_datasheet.pdf?v=1650477068",
        "housing": "enclosed",
        "id": 4,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "M12M-CYL",
        "shutterType": "global",
        "trigger": false
    },
    "5": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 3,
        "colorMono": "color",
        "datasheet": "",
        "housing": "enclosed",
        "id": 5,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "M15C-CYL",
        "shutterType": "rolling",
        "trigger": false
    },
    "6": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 4,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/m18-cyl_datasheet.pdf?v=1650477068",
        "housing": "enclosed",
        "id": 6,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "M18C-CYL",
        "shutterType": "rolling",
        "trigger": false
    },
    "7": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 5,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/m1-cyl_datasheet.pdf?v=1650477066",
        "housing": "enclosed",
        "id": 7,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "M1C-CYL",
        "shutterType": "global",
        "trigger": false
    },
    "8": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 5,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/m1-cyl_datasheet.pdf?v=1650477066",
        "housing": "enclosed",
        "id": 8,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "M1M-CYL",
        "shutterType": "global",
        "trigger": false
    },
    "9": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 6,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/m2-cyl_datasheet.pdf?v=1650477067",
        "housing": "enclosed",
        "id": 9,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "M2C-CYL",
        "shutterType": "global",
        "trigger": false
    },
    "10": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 6,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/m2-cyl_datasheet.pdf?v=1650477067",
        "housing": "enclosed",
        "id": 10,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "M2M-CYL",
        "shutterType": "global",
        "trigger": false
    },
    "11": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 7,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/m3-cyl_datasheet.pdf?v=1",
        "housing": "enclosed",
        "id": 11,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "M3C-CYL",
        "shutterType": "global",
        "trigger": false
    },
    "12": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 7,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/m3-cyl_datasheet.pdf?v=1",
        "housing": "enclosed",
        "id": 12,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "M3M-CYL",
        "shutterType": "global",
        "trigger": false
    },
    "13": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 8,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/m4-cyl_datasheet.pdf?v=1650477067",
        "housing": "enclosed",
        "id": 13,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "M4C-CYL",
        "shutterType": "global",
        "trigger": false
    },
    "14": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 8,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/m4-cyl_datasheet.pdf?v=1650477067",
        "housing": "enclosed",
        "id": 14,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "M4M-CYL",
        "shutterType": "global",
        "trigger": false
    },
    "15": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 9,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/m5-cyl_datasheet.pdf?v=1650477067",
        "housing": "enclosed",
        "id": 15,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "M5C-CYL",
        "shutterType": "global",
        "trigger": false
    },
    "16": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 9,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/m5-cyl_datasheet.pdf?v=1650477067",
        "housing": "enclosed",
        "id": 16,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "M5M-CYL",
        "shutterType": "global",
        "trigger": false
    },
    "18": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 10,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/m5d-cyl_datasheet.pdf?v=1650477067",
        "housing": "enclosed",
        "id": 18,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "M5DC-CYL",
        "shutterType": "global",
        "trigger": false
    },
    "19": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 10,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/m5d-cyl_datasheet.pdf?v=1650477067",
        "housing": "enclosed",
        "id": 19,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "M5DM-CYL",
        "shutterType": "global",
        "trigger": false
    },
    "20": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 7,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/m7-cyl_datasheet.pdf?v=1",
        "housing": "enclosed",
        "id": 20,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "M7C-CYL",
        "shutterType": "global",
        "trigger": false
    },
    "21": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 7,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/m7-cyl_datasheet.pdf?v=1",
        "housing": "enclosed",
        "id": 21,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "M7M-CYL",
        "shutterType": "global",
        "trigger": false
    },
    "22": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 11,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/m9-cyl_datasheet.pdf?v=1650477067",
        "housing": "enclosed",
        "id": 22,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "M9C-CYL",
        "shutterType": "global",
        "trigger": false
    },
    "23": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 11,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/m9-cyl_datasheet.pdf?v=1650477067",
        "housing": "enclosed",
        "id": 23,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "M9M-CYL",
        "shutterType": "global",
        "trigger": false
    },
    "24": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 5,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721_datasheet.pdf?v=1650298822",
        "housing": "enclosed",
        "id": 24,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721CU",
        "shutterType": "global",
        "trigger": false
    },
    "25": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 5,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721_datasheet.pdf?v=1650298822",
        "housing": "enclosed",
        "id": 25,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721CU-AF16",
        "shutterType": "global",
        "trigger": true
    },
    "26": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 5,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721_datasheet.pdf?v=1650298822",
        "housing": "enclosed",
        "id": 26,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721CU-AF25",
        "shutterType": "global",
        "trigger": true
    },
    "27": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 5,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 27,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721CU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "28": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 5,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 28,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721CU-BL-AF16",
        "shutterType": "global",
        "trigger": true
    },
    "29": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 5,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 29,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721CU-BL-AF25",
        "shutterType": "global",
        "trigger": true
    },
    "30": {
        "autofocus": true,
        "autofocusFocalLength": "12",
        "cameraSensorId": 5,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 30,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721CU-BL-AFE12",
        "shutterType": "global",
        "trigger": true
    },
    "31": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 5,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 31,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721CU-BL-AFE16",
        "shutterType": "global",
        "trigger": true
    },
    "32": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 5,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 32,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721CU-BL-AFE25",
        "shutterType": "global",
        "trigger": true
    },
    "33": {
        "autofocus": true,
        "autofocusFocalLength": "35",
        "cameraSensorId": 5,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 33,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721CU-BL-AFE35",
        "shutterType": "global",
        "trigger": true
    },
    "34": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 5,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 34,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721CU-S-BL",
        "shutterType": "global",
        "trigger": false
    },
    "35": {
        "autofocus": true,
        "autofocusFocalLength": "2.6",
        "cameraSensorId": 5,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 35,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721CU-S-BL-AF2.6",
        "shutterType": "global",
        "trigger": true
    },
    "36": {
        "autofocus": true,
        "autofocusFocalLength": "7.5",
        "cameraSensorId": 5,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 36,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721CU-S-BL-AF7.5",
        "shutterType": "global",
        "trigger": true
    },
    "37": {
        "autofocus": true,
        "autofocusFocalLength": "9.6",
        "cameraSensorId": 5,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 37,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721CU-S-BL-AF9.6",
        "shutterType": "global",
        "trigger": true
    },
    "38": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 5,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721_datasheet.pdf?v=1650298822",
        "housing": "enclosed",
        "id": 38,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721CU-T",
        "shutterType": "global",
        "trigger": true
    },
    "39": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 5,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721_datasheet.pdf?v=1650298822",
        "housing": "enclosed",
        "id": 39,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721MU",
        "shutterType": "global",
        "trigger": false
    },
    "40": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 5,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721_datasheet.pdf?v=1650298822",
        "housing": "enclosed",
        "id": 40,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721MU-AF16",
        "shutterType": "global",
        "trigger": true
    },
    "41": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 5,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721_datasheet.pdf?v=1650298822",
        "housing": "enclosed",
        "id": 41,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721MU-AF25",
        "shutterType": "global",
        "trigger": true
    },
    "42": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 5,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 42,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721MU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "43": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 5,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 43,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721MU-BL-AF16",
        "shutterType": "global",
        "trigger": true
    },
    "44": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 5,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 44,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721MU-BL-AF25",
        "shutterType": "global",
        "trigger": true
    },
    "45": {
        "autofocus": true,
        "autofocusFocalLength": "12",
        "cameraSensorId": 5,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 45,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721MU-BL-AFE12",
        "shutterType": "global",
        "trigger": true
    },
    "46": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 5,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 46,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721MU-BL-AFE16",
        "shutterType": "global",
        "trigger": true
    },
    "47": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 5,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 47,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721MU-BL-AFE25",
        "shutterType": "global",
        "trigger": true
    },
    "48": {
        "autofocus": true,
        "autofocusFocalLength": "35",
        "cameraSensorId": 5,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 48,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721MU-BL-AFE35",
        "shutterType": "global",
        "trigger": true
    },
    "49": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 5,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 49,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721MU-S-BL",
        "shutterType": "global",
        "trigger": false
    },
    "50": {
        "autofocus": true,
        "autofocusFocalLength": "2.6",
        "cameraSensorId": 5,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 50,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721MU-S-BL-AF2.6",
        "shutterType": "global",
        "trigger": true
    },
    "51": {
        "autofocus": true,
        "autofocusFocalLength": "7.5",
        "cameraSensorId": 5,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 51,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721MU-S-BL-AF7.5",
        "shutterType": "global",
        "trigger": true
    },
    "52": {
        "autofocus": true,
        "autofocusFocalLength": "9.6",
        "cameraSensorId": 5,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 52,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721MU-S-BL-AF9.6",
        "shutterType": "global",
        "trigger": true
    },
    "53": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 5,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721_datasheet.pdf?v=1650298822",
        "housing": "enclosed",
        "id": 53,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721MU-T",
        "shutterType": "global",
        "trigger": true
    },
    "54": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 12,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721p_datasheet.pdf?v=1650298822",
        "housing": "enclosed",
        "id": 54,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721PMU",
        "shutterType": "global",
        "trigger": false
    },
    "55": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 12,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721p_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 55,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721PMU-S-BL",
        "shutterType": "global",
        "trigger": false
    },
    "56": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 12,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721p_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 56,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721PMU-S-BL",
        "shutterType": "global",
        "trigger": false
    },
    "57": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 12,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d721p_datasheet.pdf?v=1650298822",
        "housing": "enclosed",
        "id": 57,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D721PMU-T",
        "shutterType": "global",
        "trigger": true
    },
    "58": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 13,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 58,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722CU",
        "shutterType": "global",
        "trigger": false
    },
    "59": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 13,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 59,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722CU-AF16",
        "shutterType": "global",
        "trigger": true
    },
    "60": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 13,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 60,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722CU-AF25",
        "shutterType": "global",
        "trigger": true
    },
    "61": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 13,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 61,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722CU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "62": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 13,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 62,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722CU-BL-AF16",
        "shutterType": "global",
        "trigger": true
    },
    "63": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 13,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 63,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722CU-BL-AF25",
        "shutterType": "global",
        "trigger": true
    },
    "64": {
        "autofocus": true,
        "autofocusFocalLength": "12",
        "cameraSensorId": 13,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 64,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722CU-BL-AFE12",
        "shutterType": "global",
        "trigger": true
    },
    "65": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 13,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 65,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722CU-BL-AFE16",
        "shutterType": "global",
        "trigger": true
    },
    "66": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 13,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 66,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722CU-BL-AFE25",
        "shutterType": "global",
        "trigger": true
    },
    "67": {
        "autofocus": true,
        "autofocusFocalLength": "35",
        "cameraSensorId": 13,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 67,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722CU-BL-AFE35",
        "shutterType": "global",
        "trigger": true
    },
    "68": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 13,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 68,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722CU-S-BL",
        "shutterType": "global",
        "trigger": false
    },
    "69": {
        "autofocus": true,
        "autofocusFocalLength": "2.6",
        "cameraSensorId": 13,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 69,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722CU-S-BL-AF2.6",
        "shutterType": "global",
        "trigger": true
    },
    "70": {
        "autofocus": true,
        "autofocusFocalLength": "2.6",
        "cameraSensorId": 13,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 70,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722CU-S-BL-AF2.6",
        "shutterType": "global",
        "trigger": true
    },
    "71": {
        "autofocus": true,
        "autofocusFocalLength": "7.5",
        "cameraSensorId": 13,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 71,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722CU-S-BL-AF7.5",
        "shutterType": "global",
        "trigger": true
    },
    "72": {
        "autofocus": true,
        "autofocusFocalLength": "7.5",
        "cameraSensorId": 13,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 72,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722CU-S-BL-AF7.5",
        "shutterType": "global",
        "trigger": true
    },
    "73": {
        "autofocus": true,
        "autofocusFocalLength": "9.6",
        "cameraSensorId": 13,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 73,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722CU-S-BL-AF9.6",
        "shutterType": "global",
        "trigger": true
    },
    "74": {
        "autofocus": true,
        "autofocusFocalLength": "9.6",
        "cameraSensorId": 13,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 74,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722CU-S-BL-AF9.6",
        "shutterType": "global",
        "trigger": true
    },
    "75": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 13,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 75,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722CU-T",
        "shutterType": "global",
        "trigger": true
    },
    "76": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 13,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 76,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722MU",
        "shutterType": "global",
        "trigger": false
    },
    "77": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 13,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 77,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722MU-AF16",
        "shutterType": "global",
        "trigger": true
    },
    "78": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 13,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 78,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722MU-AF25",
        "shutterType": "global",
        "trigger": true
    },
    "79": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 13,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 79,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722MU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "80": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 13,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 80,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722MU-BL-AF16",
        "shutterType": "global",
        "trigger": true
    },
    "81": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 13,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 81,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722MU-BL-AF25",
        "shutterType": "global",
        "trigger": true
    },
    "82": {
        "autofocus": true,
        "autofocusFocalLength": "12",
        "cameraSensorId": 13,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 82,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722MU-BL-AFE12",
        "shutterType": "global",
        "trigger": true
    },
    "83": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 13,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 83,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722MU-BL-AFE16",
        "shutterType": "global",
        "trigger": true
    },
    "84": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 13,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 84,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722MU-BL-AFE25",
        "shutterType": "global",
        "trigger": true
    },
    "85": {
        "autofocus": true,
        "autofocusFocalLength": "35",
        "cameraSensorId": 13,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 85,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722MU-BL-AFE35",
        "shutterType": "global",
        "trigger": true
    },
    "86": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 13,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 86,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722MU-S-BL",
        "shutterType": "global",
        "trigger": false
    },
    "87": {
        "autofocus": true,
        "autofocusFocalLength": "2.6",
        "cameraSensorId": 13,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 87,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722MU-S-BL-AF2.6",
        "shutterType": "global",
        "trigger": true
    },
    "88": {
        "autofocus": true,
        "autofocusFocalLength": "2.6",
        "cameraSensorId": 13,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 88,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722MU-S-BL-AF2.6",
        "shutterType": "global",
        "trigger": true
    },
    "89": {
        "autofocus": true,
        "autofocusFocalLength": "7.5",
        "cameraSensorId": 13,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 89,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722MU-S-BL-AF7.5",
        "shutterType": "global",
        "trigger": true
    },
    "90": {
        "autofocus": true,
        "autofocusFocalLength": "7.5",
        "cameraSensorId": 13,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 90,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722MU-S-BL-AF7.5",
        "shutterType": "global",
        "trigger": true
    },
    "91": {
        "autofocus": true,
        "autofocusFocalLength": "9.6",
        "cameraSensorId": 13,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 91,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722MU-S-BL-AF9.6",
        "shutterType": "global",
        "trigger": true
    },
    "92": {
        "autofocus": true,
        "autofocusFocalLength": "9.6",
        "cameraSensorId": 13,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 92,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722MU-S-BL-AF9.6",
        "shutterType": "global",
        "trigger": true
    },
    "93": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 13,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d722_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 93,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D722MU-T",
        "shutterType": "global",
        "trigger": true
    },
    "94": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 9,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d725_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 94,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D725CU",
        "shutterType": "global",
        "trigger": false
    },
    "95": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 9,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d725_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 95,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D725CU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "96": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 9,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d725_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 96,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D725CU-T",
        "shutterType": "global",
        "trigger": true
    },
    "97": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 9,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d725_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 97,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D725MU",
        "shutterType": "global",
        "trigger": false
    },
    "98": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 9,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d725_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 98,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D725MU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "99": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 9,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d725_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 99,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D725MU-T",
        "shutterType": "global",
        "trigger": true
    },
    "100": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 14,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d726_datasheet.pdf?v=1650298826",
        "housing": "board_level",
        "id": 100,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D726MU-BL",
        "shutterType": "rolling",
        "trigger": false
    },
    "101": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 6,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d732_datasheet.pdf?v=1650298826",
        "housing": "enclosed",
        "id": 101,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D732CU",
        "shutterType": "global",
        "trigger": false
    },
    "102": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 6,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d732_datasheet.pdf?v=1650298826",
        "housing": "enclosed",
        "id": 102,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D732CU-AF25",
        "shutterType": "global",
        "trigger": true
    },
    "103": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 6,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d732_datasheet.pdf?v=1650298826",
        "housing": "board_level",
        "id": 103,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D732CU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "104": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 6,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d732_datasheet.pdf?v=1650298826",
        "housing": "board_level",
        "id": 104,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D732CU-BL-AF25",
        "shutterType": "global",
        "trigger": true
    },
    "105": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 6,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d732_datasheet.pdf?v=1650298826",
        "housing": "board_level",
        "id": 105,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D732CU-BL-AFE16",
        "shutterType": "global",
        "trigger": true
    },
    "106": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 6,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d732_datasheet.pdf?v=1650298826",
        "housing": "board_level",
        "id": 106,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D732CU-BL-AFE25",
        "shutterType": "global",
        "trigger": true
    },
    "107": {
        "autofocus": true,
        "autofocusFocalLength": "35",
        "cameraSensorId": 6,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d732_datasheet.pdf?v=1650298826",
        "housing": "board_level",
        "id": 107,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D732CU-BL-AFE35",
        "shutterType": "global",
        "trigger": true
    },
    "108": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 6,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d732_datasheet.pdf?v=1650298826",
        "housing": "enclosed",
        "id": 108,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D732CU-T",
        "shutterType": "global",
        "trigger": true
    },
    "109": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 6,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d732_datasheet.pdf?v=1650298826",
        "housing": "enclosed",
        "id": 109,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D732MU",
        "shutterType": "global",
        "trigger": false
    },
    "110": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 6,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d732_datasheet.pdf?v=1650298826",
        "housing": "enclosed",
        "id": 110,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D732MU-AF25",
        "shutterType": "global",
        "trigger": true
    },
    "111": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 6,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d732_datasheet.pdf?v=1650298826",
        "housing": "board_level",
        "id": 111,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D732MU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "112": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 6,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d732_datasheet.pdf?v=1650298826",
        "housing": "board_level",
        "id": 112,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D732MU-BL-AF25",
        "shutterType": "global",
        "trigger": true
    },
    "113": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 6,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d732_datasheet.pdf?v=1650298826",
        "housing": "board_level",
        "id": 113,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D732MU-BL-AFE16",
        "shutterType": "global",
        "trigger": true
    },
    "114": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 6,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d732_datasheet.pdf?v=1650298826",
        "housing": "board_level",
        "id": 114,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D732MU-BL-AFE25",
        "shutterType": "global",
        "trigger": true
    },
    "115": {
        "autofocus": true,
        "autofocusFocalLength": "35",
        "cameraSensorId": 6,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d732_datasheet.pdf?v=1650298826",
        "housing": "board_level",
        "id": 115,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D732MU-BL-AFE35",
        "shutterType": "global",
        "trigger": true
    },
    "116": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 6,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d732_datasheet.pdf?v=1650298826",
        "housing": "enclosed",
        "id": 116,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D732MU-NIR",
        "shutterType": "global",
        "trigger": false
    },
    "117": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 6,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d732_datasheet.pdf?v=1650298826",
        "housing": "enclosed",
        "id": 117,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D732MU-NIR-AF25",
        "shutterType": "global",
        "trigger": true
    },
    "118": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 6,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d732_datasheet.pdf?v=1650298826",
        "housing": "board_level",
        "id": 118,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D732MU-NIR-BL",
        "shutterType": "global",
        "trigger": false
    },
    "119": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 6,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d732_datasheet.pdf?v=1650298826",
        "housing": "board_level",
        "id": 119,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D732MU-NIR-BL-AF25",
        "shutterType": "global",
        "trigger": true
    },
    "120": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 6,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d732_datasheet.pdf?v=1650298826",
        "housing": "board_level",
        "id": 120,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D732MU-NIR-BL-AFE16",
        "shutterType": "global",
        "trigger": true
    },
    "121": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 6,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d732_datasheet.pdf?v=1650298826",
        "housing": "board_level",
        "id": 121,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D732MU-NIR-BL-AFE25",
        "shutterType": "global",
        "trigger": true
    },
    "122": {
        "autofocus": true,
        "autofocusFocalLength": "35",
        "cameraSensorId": 6,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d732_datasheet.pdf?v=1650298826",
        "housing": "board_level",
        "id": 122,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D732MU-NIR-BL-AFE35",
        "shutterType": "global",
        "trigger": true
    },
    "123": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 6,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d732_datasheet.pdf?v=1650298826",
        "housing": "enclosed",
        "id": 123,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D732MU-NIR-T",
        "shutterType": "global",
        "trigger": true
    },
    "124": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 6,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d732_datasheet.pdf?v=1650298826",
        "housing": "enclosed",
        "id": 124,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D732MU-T",
        "shutterType": "global",
        "trigger": true
    },
    "125": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 8,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d734_datasheet.pdf?v=1650298825",
        "housing": "enclosed",
        "id": 125,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D734CU",
        "shutterType": "global",
        "trigger": false
    },
    "126": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 8,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d734_datasheet.pdf?v=1650298825",
        "housing": "board_level",
        "id": 126,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D734CU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "127": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 8,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d734_datasheet.pdf?v=1650298825",
        "housing": "enclosed",
        "id": 127,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D734CU-T",
        "shutterType": "global",
        "trigger": true
    },
    "128": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 8,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d734_datasheet.pdf?v=1650298825",
        "housing": "enclosed",
        "id": 128,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D734MU",
        "shutterType": "global",
        "trigger": false
    },
    "129": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 8,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d734_datasheet.pdf?v=1650298825",
        "housing": "board_level",
        "id": 129,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D734MU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "130": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 8,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d734_datasheet.pdf?v=1650298825",
        "housing": "enclosed",
        "id": 130,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D734MU-NIR",
        "shutterType": "global",
        "trigger": false
    },
    "131": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 8,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d734_datasheet.pdf?v=1650298825",
        "housing": "board_level",
        "id": 131,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D734MU-NIR-BL",
        "shutterType": "global",
        "trigger": false
    },
    "132": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 8,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d734_datasheet.pdf?v=1650298825",
        "housing": "enclosed",
        "id": 132,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D734MU-NIR-T",
        "shutterType": "global",
        "trigger": true
    },
    "133": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 8,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d734_datasheet.pdf?v=1650298825",
        "housing": "enclosed",
        "id": 133,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D734MU-T",
        "shutterType": "global",
        "trigger": true
    },
    "134": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 2,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7512_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 134,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7512CU",
        "shutterType": "global",
        "trigger": false
    },
    "135": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 2,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7512_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 135,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7512CU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "136": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 2,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7512_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 136,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7512CU-T",
        "shutterType": "global",
        "trigger": true
    },
    "137": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 2,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7512_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 137,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7512MU",
        "shutterType": "global",
        "trigger": false
    },
    "138": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 2,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7512_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 138,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7512MU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "139": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 2,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7512_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 139,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7512MU-T",
        "shutterType": "global",
        "trigger": true
    },
    "140": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 15,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d752_datasheet.pdf?v=1650298825",
        "housing": "enclosed",
        "id": 140,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D752CU",
        "shutterType": "global",
        "trigger": false
    },
    "141": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 15,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d752_datasheet.pdf?v=1650298825",
        "housing": "board_level",
        "id": 141,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D752CU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "142": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 15,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d752_datasheet.pdf?v=1650298825",
        "housing": "enclosed",
        "id": 142,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D752CU-T",
        "shutterType": "global",
        "trigger": true
    },
    "143": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 15,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d752_datasheet.pdf?v=1650298825",
        "housing": "enclosed",
        "id": 143,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D752MU",
        "shutterType": "global",
        "trigger": false
    },
    "144": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 15,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d752_datasheet.pdf?v=1650298825",
        "housing": "board_level",
        "id": 144,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D752MU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "145": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 15,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d752_datasheet.pdf?v=1650298825",
        "housing": "enclosed",
        "id": 145,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D752MU-T",
        "shutterType": "global",
        "trigger": true
    },
    "146": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 16,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "enclosed",
        "id": 146,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D753CU",
        "shutterType": "global",
        "trigger": false
    },
    "147": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 16,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "enclosed",
        "id": 147,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D753CU-AF16",
        "shutterType": "global",
        "trigger": true
    },
    "148": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 16,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "enclosed",
        "id": 148,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D753CU-AF25",
        "shutterType": "global",
        "trigger": true
    },
    "149": {
        "autofocus": true,
        "autofocusFocalLength": "12",
        "cameraSensorId": 16,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "enclosed",
        "id": 149,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D753CU-AFE12",
        "shutterType": "global",
        "trigger": true
    },
    "150": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 16,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "enclosed",
        "id": 150,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D753CU-AFE16",
        "shutterType": "global",
        "trigger": true
    },
    "151": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 16,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "enclosed",
        "id": 151,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D753CU-AFE25",
        "shutterType": "global",
        "trigger": true
    },
    "152": {
        "autofocus": true,
        "autofocusFocalLength": "35",
        "cameraSensorId": 16,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "enclosed",
        "id": 152,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D753CU-AFE35",
        "shutterType": "global",
        "trigger": true
    },
    "153": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 16,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "board_level",
        "id": 153,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D753CU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "154": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 16,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "board_level",
        "id": 154,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D753CU-BL-AF16",
        "shutterType": "global",
        "trigger": true
    },
    "155": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 16,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "board_level",
        "id": 155,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D753CU-BL-AF25",
        "shutterType": "global",
        "trigger": true
    },
    "156": {
        "autofocus": true,
        "autofocusFocalLength": "12",
        "cameraSensorId": 16,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "board_level",
        "id": 156,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D753CU-BL-AFE12",
        "shutterType": "global",
        "trigger": true
    },
    "157": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 16,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "board_level",
        "id": 157,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D753CU-BL-AFE16",
        "shutterType": "global",
        "trigger": true
    },
    "158": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 16,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "board_level",
        "id": 158,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D753CU-BL-AFE25",
        "shutterType": "global",
        "trigger": true
    },
    "159": {
        "autofocus": true,
        "autofocusFocalLength": "35",
        "cameraSensorId": 16,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "board_level",
        "id": 159,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D753CU-BL-AFE35",
        "shutterType": "global",
        "trigger": true
    },
    "160": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 16,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "board_level",
        "id": 160,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D753CU-S-BL",
        "shutterType": "global",
        "trigger": false
    },
    "161": {
        "autofocus": true,
        "autofocusFocalLength": "2.6",
        "cameraSensorId": 16,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "board_level",
        "id": 161,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D753CU-S-BL-AF2.6",
        "shutterType": "global",
        "trigger": true
    },
    "162": {
        "autofocus": true,
        "autofocusFocalLength": "7.5",
        "cameraSensorId": 16,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "board_level",
        "id": 162,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D753CU-S-BL-AF7.5",
        "shutterType": "global",
        "trigger": true
    },
    "163": {
        "autofocus": true,
        "autofocusFocalLength": "9.6",
        "cameraSensorId": 16,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "board_level",
        "id": 163,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D753CU-S-BL-AF9.6",
        "shutterType": "global",
        "trigger": true
    },
    "164": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 16,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "enclosed",
        "id": 164,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D753CU-T",
        "shutterType": "global",
        "trigger": true
    },
    "165": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 16,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "enclosed",
        "id": 165,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D753MU",
        "shutterType": "global",
        "trigger": false
    },
    "166": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 16,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "enclosed",
        "id": 166,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D753MU-AF16",
        "shutterType": "global",
        "trigger": true
    },
    "167": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 16,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "enclosed",
        "id": 167,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D753MU-AF25",
        "shutterType": "global",
        "trigger": true
    },
    "168": {
        "autofocus": true,
        "autofocusFocalLength": "12",
        "cameraSensorId": 16,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "enclosed",
        "id": 168,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D753MU-AFE12",
        "shutterType": "global",
        "trigger": true
    },
    "169": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 16,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "enclosed",
        "id": 169,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D753MU-AFE16",
        "shutterType": "global",
        "trigger": true
    },
    "170": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 16,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "enclosed",
        "id": 170,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D753MU-AFE25",
        "shutterType": "global",
        "trigger": true
    },
    "171": {
        "autofocus": true,
        "autofocusFocalLength": "35",
        "cameraSensorId": 16,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "enclosed",
        "id": 171,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D753MU-AFE35",
        "shutterType": "global",
        "trigger": true
    },
    "172": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 16,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "board_level",
        "id": 172,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D753MU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "173": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 16,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "board_level",
        "id": 173,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D753MU-BL-AF16",
        "shutterType": "global",
        "trigger": true
    },
    "174": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 16,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "board_level",
        "id": 174,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D753MU-BL-AF25",
        "shutterType": "global",
        "trigger": true
    },
    "175": {
        "autofocus": true,
        "autofocusFocalLength": "12",
        "cameraSensorId": 16,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "board_level",
        "id": 175,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D753MU-BL-AFE12",
        "shutterType": "global",
        "trigger": true
    },
    "176": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 16,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "board_level",
        "id": 176,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D753MU-BL-AFE16",
        "shutterType": "global",
        "trigger": true
    },
    "177": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 16,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "board_level",
        "id": 177,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D753MU-BL-AFE25",
        "shutterType": "global",
        "trigger": true
    },
    "178": {
        "autofocus": true,
        "autofocusFocalLength": "35",
        "cameraSensorId": 16,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "board_level",
        "id": 178,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D753MU-BL-AFE35",
        "shutterType": "global",
        "trigger": true
    },
    "179": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 16,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "board_level",
        "id": 179,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D753MU-S-BL",
        "shutterType": "global",
        "trigger": false
    },
    "180": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 16,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d753_datasheet.pdf?v=1650298824",
        "housing": "enclosed",
        "id": 180,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D753MU-T",
        "shutterType": "global",
        "trigger": true
    },
    "181": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 10,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "enclosed",
        "id": 181,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755CU",
        "shutterType": "global",
        "trigger": false
    },
    "182": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 10,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "enclosed",
        "id": 182,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755CU-AF16",
        "shutterType": "global",
        "trigger": true
    },
    "183": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 10,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "enclosed",
        "id": 183,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755CU-AF25",
        "shutterType": "global",
        "trigger": true
    },
    "184": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 10,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 184,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755CU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "185": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 10,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 185,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755CU-BL-AF16",
        "shutterType": "global",
        "trigger": true
    },
    "186": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 10,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 186,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755CU-BL-AF25",
        "shutterType": "global",
        "trigger": true
    },
    "187": {
        "autofocus": true,
        "autofocusFocalLength": "12",
        "cameraSensorId": 10,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 187,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755CU-BL-AFE12",
        "shutterType": "global",
        "trigger": true
    },
    "188": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 10,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 188,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755CU-BL-AFE16",
        "shutterType": "global",
        "trigger": true
    },
    "189": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 10,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 189,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755CU-BL-AFE25",
        "shutterType": "global",
        "trigger": true
    },
    "190": {
        "autofocus": true,
        "autofocusFocalLength": "35",
        "cameraSensorId": 10,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 190,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755CU-BL-AFE35",
        "shutterType": "global",
        "trigger": true
    },
    "191": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 10,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 191,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755CU-CS-BL",
        "shutterType": "global",
        "trigger": false
    },
    "192": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 10,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 192,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755CU-S-BL",
        "shutterType": "global",
        "trigger": false
    },
    "193": {
        "autofocus": true,
        "autofocusFocalLength": "2.6",
        "cameraSensorId": 10,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 193,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755CU-S-BL-AF2.6",
        "shutterType": "global",
        "trigger": true
    },
    "194": {
        "autofocus": true,
        "autofocusFocalLength": "7.5",
        "cameraSensorId": 10,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 194,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755CU-S-BL-AF7.5",
        "shutterType": "global",
        "trigger": true
    },
    "195": {
        "autofocus": true,
        "autofocusFocalLength": "9.6",
        "cameraSensorId": 10,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 195,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755CU-S-BL-AF9.6",
        "shutterType": "global",
        "trigger": true
    },
    "196": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 10,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "enclosed",
        "id": 196,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755CU-T",
        "shutterType": "global",
        "trigger": true
    },
    "197": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 10,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "enclosed",
        "id": 197,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755MU",
        "shutterType": "global",
        "trigger": false
    },
    "198": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 10,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "enclosed",
        "id": 198,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755MU-AF16",
        "shutterType": "global",
        "trigger": true
    },
    "199": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 10,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "enclosed",
        "id": 199,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755MU-AF25",
        "shutterType": "global",
        "trigger": true
    },
    "200": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 10,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 200,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755MU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "201": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 10,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 201,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755MU-BL-AF16",
        "shutterType": "global",
        "trigger": true
    },
    "202": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 10,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 202,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755MU-BL-AF25",
        "shutterType": "global",
        "trigger": true
    },
    "203": {
        "autofocus": true,
        "autofocusFocalLength": "12",
        "cameraSensorId": 10,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 203,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755MU-BL-AFE12",
        "shutterType": "global",
        "trigger": true
    },
    "204": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 10,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 204,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755MU-BL-AFE16",
        "shutterType": "global",
        "trigger": true
    },
    "205": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 10,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 205,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755MU-BL-AFE25",
        "shutterType": "global",
        "trigger": true
    },
    "206": {
        "autofocus": true,
        "autofocusFocalLength": "35",
        "cameraSensorId": 10,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 206,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755MU-BL-AFE35",
        "shutterType": "global",
        "trigger": true
    },
    "207": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 17,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755-pol_datasheet.pdf?v=1650298821",
        "housing": "board_level",
        "id": 207,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755MU-BL-POL",
        "shutterType": "global",
        "trigger": false
    },
    "208": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 10,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 208,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755MU-CS-BL",
        "shutterType": "global",
        "trigger": false
    },
    "209": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 17,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755-pol_datasheet.pdf?v=1650298821",
        "housing": "enclosed",
        "id": 209,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755MU-POL",
        "shutterType": "global",
        "trigger": false
    },
    "210": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 10,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 210,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755MU-S-BL",
        "shutterType": "global",
        "trigger": false
    },
    "211": {
        "autofocus": true,
        "autofocusFocalLength": "2.6",
        "cameraSensorId": 10,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 211,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755MU-S-BL-AF2.6",
        "shutterType": "global",
        "trigger": true
    },
    "212": {
        "autofocus": true,
        "autofocusFocalLength": "7.5",
        "cameraSensorId": 10,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 212,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755MU-S-BL-AF7.5",
        "shutterType": "global",
        "trigger": true
    },
    "213": {
        "autofocus": true,
        "autofocusFocalLength": "9.6",
        "cameraSensorId": 10,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 213,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755MU-S-BL-AF9.6",
        "shutterType": "global",
        "trigger": true
    },
    "214": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 10,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755_datasheet.pdf?v=1650298822",
        "housing": "enclosed",
        "id": 214,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755MU-T",
        "shutterType": "global",
        "trigger": true
    },
    "215": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 17,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d755-pol_datasheet.pdf?v=1650298821",
        "housing": "enclosed",
        "id": 215,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D755MU-T-POL",
        "shutterType": "global",
        "trigger": true
    },
    "216": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 7,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d757_datasheet.pdf?v=1650298821",
        "housing": "enclosed",
        "id": 216,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D757CU",
        "shutterType": "global",
        "trigger": false
    },
    "217": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 7,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d757_datasheet.pdf?v=1650298821",
        "housing": "board_level",
        "id": 217,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D757CU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "218": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 7,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d757_datasheet.pdf?v=1650298821",
        "housing": "enclosed",
        "id": 218,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D757CU-T",
        "shutterType": "global",
        "trigger": true
    },
    "219": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 7,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d757_datasheet.pdf?v=1650298821",
        "housing": "enclosed",
        "id": 219,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D757MU",
        "shutterType": "global",
        "trigger": false
    },
    "220": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 7,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d757_datasheet.pdf?v=1650298821",
        "housing": "board_level",
        "id": 220,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D757MU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "221": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 7,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d757_datasheet.pdf?v=1650298821",
        "housing": "enclosed",
        "id": 221,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D757MU-T",
        "shutterType": "global",
        "trigger": true
    },
    "222": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 11,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d759_datasheet.pdf?v=1650298822",
        "housing": "enclosed",
        "id": 222,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D759CU",
        "shutterType": "global",
        "trigger": false
    },
    "223": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 11,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d759_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 223,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D759CU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "224": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 11,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d759_datasheet.pdf?v=1650298822",
        "housing": "enclosed",
        "id": 224,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D759CU-T",
        "shutterType": "global",
        "trigger": true
    },
    "225": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 11,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d759_datasheet.pdf?v=1650298822",
        "housing": "enclosed",
        "id": 225,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D759MU",
        "shutterType": "global",
        "trigger": false
    },
    "226": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 11,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d759_datasheet.pdf?v=1650298822",
        "housing": "board_level",
        "id": 226,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D759MU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "227": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 11,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d759_datasheet.pdf?v=1650298822",
        "housing": "enclosed",
        "id": 227,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D759MU-T",
        "shutterType": "global",
        "trigger": true
    },
    "228": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 18,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 228,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620CU",
        "shutterType": "rolling",
        "trigger": false
    },
    "229": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 18,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 229,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620CU-AF16",
        "shutterType": "rolling",
        "trigger": true
    },
    "230": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 18,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 230,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620CU-AF25",
        "shutterType": "rolling",
        "trigger": true
    },
    "231": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 18,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 231,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620CU-AFE16",
        "shutterType": "rolling",
        "trigger": true
    },
    "232": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 18,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 232,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620CU-AFE25",
        "shutterType": "rolling",
        "trigger": true
    },
    "233": {
        "autofocus": true,
        "autofocusFocalLength": "35",
        "cameraSensorId": 18,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 233,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620CU-AFE35",
        "shutterType": "rolling",
        "trigger": true
    },
    "234": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 18,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 234,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620CU-BL",
        "shutterType": "rolling",
        "trigger": false
    },
    "235": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 18,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 235,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620CU-BL-AF16",
        "shutterType": "rolling",
        "trigger": true
    },
    "236": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 18,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 236,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620CU-BL-AF25",
        "shutterType": "rolling",
        "trigger": true
    },
    "237": {
        "autofocus": true,
        "autofocusFocalLength": "12",
        "cameraSensorId": 18,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 237,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620CU-BL-AFE12",
        "shutterType": "rolling",
        "trigger": true
    },
    "238": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 18,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 238,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620CU-BL-AFE16",
        "shutterType": "rolling",
        "trigger": true
    },
    "239": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 18,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 239,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620CU-BL-AFE25",
        "shutterType": "rolling",
        "trigger": true
    },
    "240": {
        "autofocus": true,
        "autofocusFocalLength": "35",
        "cameraSensorId": 18,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 240,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620CU-BL-AFE35",
        "shutterType": "rolling",
        "trigger": true
    },
    "241": {
        "autofocus": true,
        "autofocusFocalLength": "2.6",
        "cameraSensorId": 18,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 241,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620CU-S-BL-AF2.6",
        "shutterType": "rolling",
        "trigger": true
    },
    "242": {
        "autofocus": true,
        "autofocusFocalLength": "7.5",
        "cameraSensorId": 18,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 242,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620CU-S-BL-AF7.5",
        "shutterType": "rolling",
        "trigger": true
    },
    "243": {
        "autofocus": true,
        "autofocusFocalLength": "9.6",
        "cameraSensorId": 18,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 243,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620CU-S-BL-AF9.6",
        "shutterType": "rolling",
        "trigger": true
    },
    "244": {
        "autofocus": true,
        "autofocusFocalLength": "12",
        "cameraSensorId": 18,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 244,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620CU-S-BL-AFE12",
        "shutterType": "rolling",
        "trigger": true
    },
    "245": {
        "autofocus": true,
        "autofocusFocalLength": "6",
        "cameraSensorId": 18,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 245,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620CU-S-BL-AFE6",
        "shutterType": "rolling",
        "trigger": true
    },
    "246": {
        "autofocus": true,
        "autofocusFocalLength": "8",
        "cameraSensorId": 18,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 246,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620CU-S-BL-AFE8",
        "shutterType": "rolling",
        "trigger": true
    },
    "247": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 18,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 247,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620CU-T",
        "shutterType": "rolling",
        "trigger": true
    },
    "248": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 18,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 248,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620MU",
        "shutterType": "rolling",
        "trigger": false
    },
    "249": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 18,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 249,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620MU-AF16",
        "shutterType": "rolling",
        "trigger": true
    },
    "250": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 18,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 250,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620MU-AF25",
        "shutterType": "rolling",
        "trigger": true
    },
    "251": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 18,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 251,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620MU-AFE16",
        "shutterType": "rolling",
        "trigger": true
    },
    "252": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 18,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 252,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620MU-AFE25",
        "shutterType": "rolling",
        "trigger": true
    },
    "253": {
        "autofocus": true,
        "autofocusFocalLength": "35",
        "cameraSensorId": 18,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 253,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620MU-AFE35",
        "shutterType": "rolling",
        "trigger": true
    },
    "254": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 18,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 254,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620MU-BL",
        "shutterType": "rolling",
        "trigger": false
    },
    "255": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 18,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 255,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620MU-BL-AF16",
        "shutterType": "rolling",
        "trigger": true
    },
    "256": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 18,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 256,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620MU-BL-AF25",
        "shutterType": "rolling",
        "trigger": true
    },
    "257": {
        "autofocus": true,
        "autofocusFocalLength": "12",
        "cameraSensorId": 18,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 257,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620MU-BL-AFE12",
        "shutterType": "rolling",
        "trigger": true
    },
    "258": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 18,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 258,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620MU-BL-AFE16",
        "shutterType": "rolling",
        "trigger": true
    },
    "259": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 18,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 259,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620MU-BL-AFE25",
        "shutterType": "rolling",
        "trigger": true
    },
    "260": {
        "autofocus": true,
        "autofocusFocalLength": "35",
        "cameraSensorId": 18,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 260,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620MU-BL-AFE35",
        "shutterType": "rolling",
        "trigger": true
    },
    "261": {
        "autofocus": true,
        "autofocusFocalLength": "2.6",
        "cameraSensorId": 18,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 261,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620MU-S-BL-AF2.6",
        "shutterType": "rolling",
        "trigger": true
    },
    "262": {
        "autofocus": true,
        "autofocusFocalLength": "7.5",
        "cameraSensorId": 18,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 262,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620MU-S-BL-AF7.5",
        "shutterType": "rolling",
        "trigger": true
    },
    "263": {
        "autofocus": true,
        "autofocusFocalLength": "9.6",
        "cameraSensorId": 18,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 263,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620MU-S-BL-AF9.6",
        "shutterType": "rolling",
        "trigger": true
    },
    "264": {
        "autofocus": true,
        "autofocusFocalLength": "12",
        "cameraSensorId": 18,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 264,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620MU-S-BL-AFE12",
        "shutterType": "rolling",
        "trigger": true
    },
    "265": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 18,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 265,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620MU-S-BL-AFE16",
        "shutterType": "rolling",
        "trigger": true
    },
    "266": {
        "autofocus": true,
        "autofocusFocalLength": "6",
        "cameraSensorId": 18,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 266,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620MU-S-BL-AFE6",
        "shutterType": "rolling",
        "trigger": true
    },
    "267": {
        "autofocus": true,
        "autofocusFocalLength": "8",
        "cameraSensorId": 18,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 267,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620MU-S-BL-AFE8",
        "shutterType": "rolling",
        "trigger": true
    },
    "268": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 18,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7620_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 268,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7620MU-T",
        "shutterType": "rolling",
        "trigger": true
    },
    "269": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 4,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7718_datasheet.pdf?v=1650298826",
        "housing": "enclosed",
        "id": 269,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7718CU",
        "shutterType": "rolling",
        "trigger": false
    },
    "270": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 4,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7718_datasheet.pdf?v=1650298826",
        "housing": "enclosed",
        "id": 270,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7718CU-AF16",
        "shutterType": "rolling",
        "trigger": true
    },
    "271": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 4,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7718_datasheet.pdf?v=1650298826",
        "housing": "enclosed",
        "id": 271,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7718CU-AF25",
        "shutterType": "rolling",
        "trigger": true
    },
    "272": {
        "autofocus": true,
        "autofocusFocalLength": "12",
        "cameraSensorId": 4,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7718_datasheet.pdf?v=1650298826",
        "housing": "enclosed",
        "id": 272,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7718CU-AFE12",
        "shutterType": "rolling",
        "trigger": true
    },
    "273": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 4,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7718_datasheet.pdf?v=1650298826",
        "housing": "enclosed",
        "id": 273,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7718CU-AFE16",
        "shutterType": "rolling",
        "trigger": true
    },
    "274": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 4,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7718_datasheet.pdf?v=1650298826",
        "housing": "enclosed",
        "id": 274,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7718CU-AFE25",
        "shutterType": "rolling",
        "trigger": true
    },
    "275": {
        "autofocus": true,
        "autofocusFocalLength": "35",
        "cameraSensorId": 4,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7718_datasheet.pdf?v=1650298826",
        "housing": "enclosed",
        "id": 275,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7718CU-AFE35",
        "shutterType": "rolling",
        "trigger": true
    },
    "276": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 4,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7718_datasheet.pdf?v=1650298826",
        "housing": "board_level",
        "id": 276,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7718CU-BL",
        "shutterType": "rolling",
        "trigger": false
    },
    "277": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 4,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7718_datasheet.pdf?v=1650298826",
        "housing": "board_level",
        "id": 277,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7718CU-BL-AF16",
        "shutterType": "rolling",
        "trigger": true
    },
    "278": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 4,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7718_datasheet.pdf?v=1650298826",
        "housing": "board_level",
        "id": 278,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7718CU-BL-AF25",
        "shutterType": "rolling",
        "trigger": true
    },
    "279": {
        "autofocus": true,
        "autofocusFocalLength": "12",
        "cameraSensorId": 4,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7718_datasheet.pdf?v=1650298826",
        "housing": "board_level",
        "id": 279,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7718CU-BL-AFE12",
        "shutterType": "rolling",
        "trigger": true
    },
    "280": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 4,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7718_datasheet.pdf?v=1650298826",
        "housing": "board_level",
        "id": 280,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7718CU-BL-AFE16",
        "shutterType": "rolling",
        "trigger": true
    },
    "281": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 4,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7718_datasheet.pdf?v=1650298826",
        "housing": "board_level",
        "id": 281,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7718CU-BL-AFE25",
        "shutterType": "rolling",
        "trigger": true
    },
    "282": {
        "autofocus": true,
        "autofocusFocalLength": "35",
        "cameraSensorId": 4,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7718_datasheet.pdf?v=1650298826",
        "housing": "board_level",
        "id": 282,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7718CU-BL-AFE35",
        "shutterType": "rolling",
        "trigger": true
    },
    "283": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 4,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7718_datasheet.pdf?v=1650298826",
        "housing": "board_level",
        "id": 283,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7718CU-S-BL",
        "shutterType": "rolling",
        "trigger": false
    },
    "284": {
        "autofocus": true,
        "autofocusFocalLength": "2.6",
        "cameraSensorId": 4,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7718_datasheet.pdf?v=1650298826",
        "housing": "board_level",
        "id": 284,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7718CU-S-BL-AF2.6",
        "shutterType": "rolling",
        "trigger": true
    },
    "285": {
        "autofocus": true,
        "autofocusFocalLength": "7.5",
        "cameraSensorId": 4,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7718_datasheet.pdf?v=1650298826",
        "housing": "board_level",
        "id": 285,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7718CU-S-BL-AF7.5",
        "shutterType": "rolling",
        "trigger": true
    },
    "286": {
        "autofocus": true,
        "autofocusFocalLength": "9.6",
        "cameraSensorId": 4,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7718_datasheet.pdf?v=1650298826",
        "housing": "board_level",
        "id": 286,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7718CU-S-BL-AF9.6",
        "shutterType": "rolling",
        "trigger": true
    },
    "287": {
        "autofocus": true,
        "autofocusFocalLength": "12",
        "cameraSensorId": 4,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7718_datasheet.pdf?v=1650298826",
        "housing": "board_level",
        "id": 287,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7718CU-S-BL-AFE12",
        "shutterType": "rolling",
        "trigger": true
    },
    "288": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 4,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7718_datasheet.pdf?v=1650298826",
        "housing": "board_level",
        "id": 288,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7718CU-S-BL-AFE16",
        "shutterType": "rolling",
        "trigger": true
    },
    "289": {
        "autofocus": true,
        "autofocusFocalLength": "6",
        "cameraSensorId": 4,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7718_datasheet.pdf?v=1650298826",
        "housing": "board_level",
        "id": 289,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7718CU-S-BL-AFE6",
        "shutterType": "rolling",
        "trigger": true
    },
    "290": {
        "autofocus": true,
        "autofocusFocalLength": "8",
        "cameraSensorId": 4,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7718_datasheet.pdf?v=1650298826",
        "housing": "board_level",
        "id": 290,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7718CU-S-BL-AFE8",
        "shutterType": "rolling",
        "trigger": true
    },
    "291": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 4,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7718_datasheet.pdf?v=1650298826",
        "housing": "enclosed",
        "id": 291,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7718CU-T",
        "shutterType": "rolling",
        "trigger": true
    },
    "292": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 19,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d775_datasheet.pdf?v=1650298823",
        "housing": "enclosed",
        "id": 292,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D775CU",
        "shutterType": "rolling",
        "trigger": false
    },
    "293": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 19,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d775_datasheet.pdf?v=1650298823",
        "housing": "enclosed",
        "id": 293,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D775CU-AF16",
        "shutterType": "rolling",
        "trigger": true
    },
    "294": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 19,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d775_datasheet.pdf?v=1650298823",
        "housing": "enclosed",
        "id": 294,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D775CU-AF25",
        "shutterType": "rolling",
        "trigger": true
    },
    "295": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 19,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d775_datasheet.pdf?v=1650298823",
        "housing": "board_level",
        "id": 295,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D775CU-BL",
        "shutterType": "rolling",
        "trigger": false
    },
    "296": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 19,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d775_datasheet.pdf?v=1650298823",
        "housing": "board_level",
        "id": 296,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D775CU-BL-AF16",
        "shutterType": "rolling",
        "trigger": true
    },
    "297": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 19,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d775_datasheet.pdf?v=1650298823",
        "housing": "board_level",
        "id": 297,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D775CU-BL-AF25",
        "shutterType": "rolling",
        "trigger": true
    },
    "298": {
        "autofocus": true,
        "autofocusFocalLength": "12",
        "cameraSensorId": 19,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d775_datasheet.pdf?v=1650298823",
        "housing": "board_level",
        "id": 298,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D775CU-BL-AFE12",
        "shutterType": "rolling",
        "trigger": true
    },
    "299": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 19,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d775_datasheet.pdf?v=1650298823",
        "housing": "board_level",
        "id": 299,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D775CU-BL-AFE16",
        "shutterType": "rolling",
        "trigger": true
    },
    "300": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 19,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d775_datasheet.pdf?v=1650298823",
        "housing": "board_level",
        "id": 300,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D775CU-BL-AFE25",
        "shutterType": "rolling",
        "trigger": true
    },
    "301": {
        "autofocus": true,
        "autofocusFocalLength": "35",
        "cameraSensorId": 19,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d775_datasheet.pdf?v=1650298823",
        "housing": "board_level",
        "id": 301,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D775CU-BL-AFE35",
        "shutterType": "rolling",
        "trigger": true
    },
    "302": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 19,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d775_datasheet.pdf?v=1650298823",
        "housing": "board_level",
        "id": 302,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D775CU-CS-BL",
        "shutterType": "rolling",
        "trigger": false
    },
    "303": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 19,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d775_datasheet.pdf?v=1650298823",
        "housing": "board_level",
        "id": 303,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D775CU-S-BL",
        "shutterType": "rolling",
        "trigger": false
    },
    "304": {
        "autofocus": true,
        "autofocusFocalLength": "2.6",
        "cameraSensorId": 19,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d775_datasheet.pdf?v=1650298823",
        "housing": "board_level",
        "id": 304,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D775CU-S-BL-AF2.6",
        "shutterType": "rolling",
        "trigger": true
    },
    "305": {
        "autofocus": true,
        "autofocusFocalLength": "7.5",
        "cameraSensorId": 19,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d775_datasheet.pdf?v=1650298823",
        "housing": "board_level",
        "id": 305,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D775CU-S-BL-AF7.5",
        "shutterType": "rolling",
        "trigger": true
    },
    "306": {
        "autofocus": true,
        "autofocusFocalLength": "9.6",
        "cameraSensorId": 19,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d775_datasheet.pdf?v=1650298823",
        "housing": "board_level",
        "id": 306,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D775CU-S-BL-AF9.6",
        "shutterType": "rolling",
        "trigger": true
    },
    "307": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 19,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d775_datasheet.pdf?v=1650298823",
        "housing": "enclosed",
        "id": 307,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D775CU-T",
        "shutterType": "rolling",
        "trigger": true
    },
    "308": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 20,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d775_datasheet.pdf?v=1650298823",
        "housing": "enclosed",
        "id": 308,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D775MU",
        "shutterType": "rolling",
        "trigger": false
    },
    "309": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 20,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d775_datasheet.pdf?v=1650298823",
        "housing": "enclosed",
        "id": 309,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D775MU-AF25",
        "shutterType": "rolling",
        "trigger": true
    },
    "310": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 20,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d775_datasheet.pdf?v=1650298823",
        "housing": "board_level",
        "id": 310,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D775MU-BL",
        "shutterType": "rolling",
        "trigger": false
    },
    "311": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 20,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d775_datasheet.pdf?v=1650298823",
        "housing": "board_level",
        "id": 311,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D775MU-BL-AF16",
        "shutterType": "rolling",
        "trigger": true
    },
    "312": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 20,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d775_datasheet.pdf?v=1650298823",
        "housing": "board_level",
        "id": 312,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D775MU-BL-AF25",
        "shutterType": "rolling",
        "trigger": true
    },
    "313": {
        "autofocus": true,
        "autofocusFocalLength": "12",
        "cameraSensorId": 20,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d775_datasheet.pdf?v=1650298823",
        "housing": "board_level",
        "id": 313,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D775MU-BL-AFE12",
        "shutterType": "rolling",
        "trigger": true
    },
    "314": {
        "autofocus": true,
        "autofocusFocalLength": "16",
        "cameraSensorId": 20,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d775_datasheet.pdf?v=1650298823",
        "housing": "board_level",
        "id": 314,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D775MU-BL-AFE16",
        "shutterType": "rolling",
        "trigger": true
    },
    "315": {
        "autofocus": true,
        "autofocusFocalLength": "25",
        "cameraSensorId": 20,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d775_datasheet.pdf?v=1650298823",
        "housing": "board_level",
        "id": 315,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D775MU-BL-AFE25",
        "shutterType": "rolling",
        "trigger": true
    },
    "316": {
        "autofocus": true,
        "autofocusFocalLength": "35",
        "cameraSensorId": 20,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d775_datasheet.pdf?v=1650298823",
        "housing": "board_level",
        "id": 316,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D775MU-BL-AFE35",
        "shutterType": "rolling",
        "trigger": true
    },
    "317": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 20,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d775_datasheet.pdf?v=1650298823",
        "housing": "board_level",
        "id": 317,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D775MU-S-BL",
        "shutterType": "rolling",
        "trigger": false
    },
    "318": {
        "autofocus": true,
        "autofocusFocalLength": "2.6",
        "cameraSensorId": 20,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d775_datasheet.pdf?v=1650298823",
        "housing": "board_level",
        "id": 318,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D775MU-S-BL-AF2.6",
        "shutterType": "rolling",
        "trigger": true
    },
    "319": {
        "autofocus": true,
        "autofocusFocalLength": "7.5",
        "cameraSensorId": 20,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d775_datasheet.pdf?v=1650298823",
        "housing": "board_level",
        "id": 319,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D775MU-S-BL-AF7.5",
        "shutterType": "rolling",
        "trigger": true
    },
    "320": {
        "autofocus": true,
        "autofocusFocalLength": "9.6",
        "cameraSensorId": 20,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d775_datasheet.pdf?v=1650298823",
        "housing": "board_level",
        "id": 320,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D775MU-S-BL-AF9.6",
        "shutterType": "rolling",
        "trigger": true
    },
    "321": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 20,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d775_datasheet.pdf?v=1650298823",
        "housing": "enclosed",
        "id": 321,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D775MU-T",
        "shutterType": "rolling",
        "trigger": true
    },
    "322": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 20,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d775_datasheet.pdf?v=1650298823",
        "housing": "board_level",
        "id": 322,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D775ZMU-CS-BL",
        "shutterType": "rolling",
        "trigger": false
    },
    "323": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 1,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7912_datasheet.pdf?v=1650298824",
        "housing": "enclosed",
        "id": 323,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7912CU",
        "shutterType": "global",
        "trigger": false
    },
    "324": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 1,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7912_datasheet.pdf?v=1650298824",
        "housing": "board_level",
        "id": 324,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7912CU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "325": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 1,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7912_datasheet.pdf?v=1650298824",
        "housing": "enclosed",
        "id": 325,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7912CU-T",
        "shutterType": "global",
        "trigger": true
    },
    "326": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 1,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7912_datasheet.pdf?v=1650298824",
        "housing": "enclosed",
        "id": 326,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7912MU",
        "shutterType": "global",
        "trigger": false
    },
    "327": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 1,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7912_datasheet.pdf?v=1650298824",
        "housing": "board_level",
        "id": 327,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7912MU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "328": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 1,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7912_datasheet.pdf?v=1650298824",
        "housing": "enclosed",
        "id": 328,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7912MU-T",
        "shutterType": "global",
        "trigger": true
    },
    "329": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 21,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7920_datasheet.pdf?v=1",
        "housing": "enclosed",
        "id": 329,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7920CU",
        "shutterType": "global",
        "trigger": false
    },
    "330": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 21,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7920_datasheet.pdf?v=1",
        "housing": "board_level",
        "id": 330,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7920CU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "331": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 21,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7920_datasheet.pdf?v=1",
        "housing": "enclosed",
        "id": 331,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7920CU-T",
        "shutterType": "global",
        "trigger": true
    },
    "332": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 21,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7920_datasheet.pdf?v=1",
        "housing": "enclosed",
        "id": 332,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7920MU",
        "shutterType": "global",
        "trigger": false
    },
    "333": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 21,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7920_datasheet.pdf?v=1",
        "housing": "board_level",
        "id": 333,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7920MU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "334": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 21,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d7920_datasheet.pdf?v=1",
        "housing": "enclosed",
        "id": 334,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7920MU-T",
        "shutterType": "global",
        "trigger": true
    },
    "335": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 22,
        "colorMono": "color",
        "datasheet": "",
        "housing": "enclosed",
        "id": 335,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7924CU",
        "shutterType": "global",
        "trigger": false
    },
    "336": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 22,
        "colorMono": "color",
        "datasheet": "",
        "housing": "board_level",
        "id": 336,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7924CU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "337": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 22,
        "colorMono": "color",
        "datasheet": "",
        "housing": "enclosed",
        "id": 337,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7924CU-T",
        "shutterType": "global",
        "trigger": true
    },
    "338": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 22,
        "colorMono": "mono",
        "datasheet": "",
        "housing": "enclosed",
        "id": 338,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7924MU",
        "shutterType": "global",
        "trigger": false
    },
    "339": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 22,
        "colorMono": "mono",
        "datasheet": "",
        "housing": "board_level",
        "id": 339,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7924MU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "340": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 22,
        "colorMono": "mono",
        "datasheet": "",
        "housing": "enclosed",
        "id": 340,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D7924MU-T",
        "shutterType": "global",
        "trigger": true
    },
    "341": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 23,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d795_datasheet.pdf?v=1650298823",
        "housing": "enclosed",
        "id": 341,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D795CU",
        "shutterType": "global",
        "trigger": false
    },
    "342": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 23,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d795_datasheet.pdf?v=1650298823",
        "housing": "board_level",
        "id": 342,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D795CU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "343": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 23,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d795_datasheet.pdf?v=1650298823",
        "housing": "board_level",
        "id": 343,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D795CU-S-BL",
        "shutterType": "global",
        "trigger": false
    },
    "344": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 23,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d795_datasheet.pdf?v=1650298823",
        "housing": "enclosed",
        "id": 344,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D795CU-T",
        "shutterType": "global",
        "trigger": true
    },
    "345": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 23,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d795_datasheet.pdf?v=1650298823",
        "housing": "enclosed",
        "id": 345,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D795MU",
        "shutterType": "global",
        "trigger": false
    },
    "346": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 23,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d795_datasheet.pdf?v=1650298823",
        "housing": "board_level",
        "id": 346,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D795MU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "347": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 23,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d795_datasheet.pdf?v=1650298823",
        "housing": "board_level",
        "id": 347,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D795MU-S-BL",
        "shutterType": "global",
        "trigger": false
    },
    "348": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 23,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d795_datasheet.pdf?v=1650298823",
        "housing": "enclosed",
        "id": 348,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D795MU-T",
        "shutterType": "global",
        "trigger": true
    },
    "349": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 24,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d797_datasheet.pdf?v=1650298821",
        "housing": "enclosed",
        "id": 349,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D797CU",
        "shutterType": "global",
        "trigger": false
    },
    "350": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 24,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d797_datasheet.pdf?v=1650298821",
        "housing": "board_level",
        "id": 350,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D797CU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "351": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 24,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d797_datasheet.pdf?v=1650298821",
        "housing": "enclosed",
        "id": 351,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D797CU-T",
        "shutterType": "global",
        "trigger": true
    },
    "352": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 24,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d797_datasheet.pdf?v=1650298821",
        "housing": "enclosed",
        "id": 352,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D797MU",
        "shutterType": "global",
        "trigger": false
    },
    "353": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 24,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d797_datasheet.pdf?v=1650298821",
        "housing": "board_level",
        "id": 353,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D797MU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "354": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 24,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d797_datasheet.pdf?v=1650298821",
        "housing": "enclosed",
        "id": 354,
        "interface": "usb_3",
        "nearInfrared": true,
        "partNumber": "PL-D797MU-T",
        "shutterType": "global",
        "trigger": true
    },
    "355": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 25,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d799_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 355,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D799CU",
        "shutterType": "global",
        "trigger": false
    },
    "356": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 25,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d799_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 356,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D799CU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "357": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 25,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-d799_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 357,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D799CU-T",
        "shutterType": "global",
        "trigger": true
    },
    "358": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 25,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d799_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 358,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D799MU",
        "shutterType": "global",
        "trigger": false
    },
    "359": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 25,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d799_datasheet.pdf?v=1650298820",
        "housing": "board_level",
        "id": 359,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D799MU-BL",
        "shutterType": "global",
        "trigger": false
    },
    "360": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 25,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-d799_datasheet.pdf?v=1650298820",
        "housing": "enclosed",
        "id": 360,
        "interface": "usb_3",
        "nearInfrared": false,
        "partNumber": "PL-D799MU-T",
        "shutterType": "global",
        "trigger": true
    },
    "361": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 2,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-x9512_datasheet.pdf?v=1650308182",
        "housing": "board_level",
        "id": 361,
        "interface": "10_gig_e",
        "nearInfrared": false,
        "partNumber": "PL-X9512CG-BL",
        "shutterType": "global",
        "trigger": false
    },
    "362": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 2,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-x9512_datasheet.pdf?v=1650308182",
        "housing": "enclosed",
        "id": 362,
        "interface": "10_gig_e",
        "nearInfrared": false,
        "partNumber": "PL-X9512CG-T",
        "shutterType": "global",
        "trigger": true
    },
    "363": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 2,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-x9512_datasheet.pdf?v=1650308182",
        "housing": "board_level",
        "id": 363,
        "interface": "10_gig_e",
        "nearInfrared": false,
        "partNumber": "PL-X9512MG-BL",
        "shutterType": "global",
        "trigger": false
    },
    "364": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 2,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-x9512_datasheet.pdf?v=1650308182",
        "housing": "enclosed",
        "id": 364,
        "interface": "10_gig_e",
        "nearInfrared": false,
        "partNumber": "PL-X9512MG-T",
        "shutterType": "global",
        "trigger": true
    },
    "365": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 26,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-x9520_datasheet.pdf?v=1650308182",
        "housing": "board_level",
        "id": 365,
        "interface": "10_gig_e",
        "nearInfrared": false,
        "partNumber": "PL-X9520CG-BL",
        "shutterType": "global",
        "trigger": false
    },
    "366": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 26,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-x9520_datasheet.pdf?v=1650308182",
        "housing": "enclosed",
        "id": 366,
        "interface": "10_gig_e",
        "nearInfrared": false,
        "partNumber": "PL-X9520CG-T",
        "shutterType": "global",
        "trigger": true
    },
    "367": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 26,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-x9520_datasheet.pdf?v=1650308182",
        "housing": "board_level",
        "id": 367,
        "interface": "10_gig_e",
        "nearInfrared": false,
        "partNumber": "PL-X9520MG-BL",
        "shutterType": "global",
        "trigger": false
    },
    "368": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 26,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-x9520_datasheet.pdf?v=1650308182",
        "housing": "enclosed",
        "id": 368,
        "interface": "10_gig_e",
        "nearInfrared": false,
        "partNumber": "PL-X9520MG-T",
        "shutterType": "global",
        "trigger": true
    },
    "369": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 27,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-x9524_datasheet.pdf?v=1650308182",
        "housing": "board_level",
        "id": 369,
        "interface": "10_gig_e",
        "nearInfrared": false,
        "partNumber": "PL-X9524CG-BL",
        "shutterType": "global",
        "trigger": false
    },
    "370": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 27,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-x9524_datasheet.pdf?v=1650308182",
        "housing": "enclosed",
        "id": 370,
        "interface": "10_gig_e",
        "nearInfrared": false,
        "partNumber": "PL-X9524CG-T",
        "shutterType": "global",
        "trigger": true
    },
    "371": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 27,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-x9524_datasheet.pdf?v=1650308182",
        "housing": "board_level",
        "id": 371,
        "interface": "10_gig_e",
        "nearInfrared": false,
        "partNumber": "PL-X9524MG-BL",
        "shutterType": "global",
        "trigger": false
    },
    "372": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 27,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-x9524_datasheet.pdf?v=1650308182",
        "housing": "enclosed",
        "id": 372,
        "interface": "10_gig_e",
        "nearInfrared": false,
        "partNumber": "PL-X9524MG-T",
        "shutterType": "global",
        "trigger": true
    },
    "373": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 7,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-x957_datasheet.pdf?v=1650308182",
        "housing": "board_level",
        "id": 373,
        "interface": "10_gig_e",
        "nearInfrared": false,
        "partNumber": "PL-X957CG-BL",
        "shutterType": "global",
        "trigger": false
    },
    "374": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 7,
        "colorMono": "color",
        "datasheet": "https://pixelink.com/media/uploads/pl-x957_datasheet.pdf?v=1650308182",
        "housing": "enclosed",
        "id": 374,
        "interface": "10_gig_e",
        "nearInfrared": false,
        "partNumber": "PL-X957CG-T",
        "shutterType": "global",
        "trigger": true
    },
    "375": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 7,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-x957_datasheet.pdf?v=1650308182",
        "housing": "board_level",
        "id": 375,
        "interface": "10_gig_e",
        "nearInfrared": false,
        "partNumber": "PL-X957MG-BL",
        "shutterType": "global",
        "trigger": false
    },
    "376": {
        "autofocus": false,
        "autofocusFocalLength": "",
        "cameraSensorId": 7,
        "colorMono": "mono",
        "datasheet": "https://pixelink.com/media/uploads/pl-x957_datasheet.pdf?v=1650308182",
        "housing": "enclosed",
        "id": 376,
        "interface": "10_gig_e",
        "nearInfrared": false,
        "partNumber": "PL-X957MG-T",
        "shutterType": "global",
        "trigger": true
    }
};
