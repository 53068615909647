import { DbStandardCoreNaCoefficient } from '../db_types';

export const dbDataStandardCoreNaCoefficient: { [index: string]: DbStandardCoreNaCoefficient } = {
    // 6.5X Zoom Core
    '1': {
        id: 1,
        standardCoreId: 1,
        coefficient: -0.004155811696,
    },
    '2': {
        id: 2,
        standardCoreId: 1,
        coefficient: 0.0513551877,
    },
    '3': {
        id: 3,
        standardCoreId: 1,
        coefficient: -0.017068176,
    },
    '4': {
        id: 4,
        standardCoreId: 1,
        coefficient: 0.003185042497,
    },
    '5': {
        id: 5,
        standardCoreId: 1,
        coefficient: -0.0002290124012,
    },

    // 12X Zoom Core
    '6': {
        id: 6,
        standardCoreId: 2,
        coefficient: -0.002769767093,
    },
    '7': {
        id: 7,
        standardCoreId: 2,
        coefficient: 0.04289616307,
    },
    '8': {
        id: 8,
        standardCoreId: 2,
        coefficient: -0.00952323647,
    },
    '9': {
        id: 9,
        standardCoreId: 2,
        coefficient: 0.001202246052,
    },
    '10': {
        id: 10,
        standardCoreId: 2,
        coefficient: -0.00005930959098,
    },

    // Resolv4K Zoom Core
    '11': {
        id: 11,
        standardCoreId: 3,
        coefficient: -0.01096648512,
    },
    '12': {
        id: 12,
        standardCoreId: 3,
        coefficient: 0.08345002244,
    },
    '13': {
        id: 13,
        standardCoreId: 3,
        coefficient: -0.02612068694,
    },
    '14': {
        id: 14,
        standardCoreId: 3,
        coefficient: 0.003803415826,
    },
    '15': {
        id: 15,
        standardCoreId: 3,
        coefficient: -0.0002143325169,
    },

    // Resolv4K Fixed Core
    '16': {
        id: 16,
        standardCoreId: 4,
        coefficient: 0.1,
    },

    // Precise Eye Core
    '17': {
        id: 17,
        standardCoreId: 5,
        coefficient: 0.07,
    },
};
