import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h } from '../util';

type Props = {
    onSubmit: () => void;
    className?: string;
    children?: React.ReactNode;
};

export const Form = mobxReactLite.observer(function Form(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const onSubmit = React.useCallback(
        (e) => {
            e.preventDefault();
            propsRef.current.onSubmit();
        },
        [],
    );

    return h('form', { className: props.className, onSubmit: onSubmit },
        // Form won't fire the submit event unless there's a submit button somewhere
        h('input', {
            type: 'submit',
            style: { display: 'none' },
        }),
        props.children,
    );
});
