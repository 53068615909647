import * as React from 'react';
import * as mobx from 'mobx';
import * as luxon from 'luxon';
import { Store } from './store';
import { routeToUrl } from './route_util';

export const h = React.createElement;

export function setRoute(store: Store, url: string) {
    mobx.runInAction(() => {
        store.url = url;
    });
    history.pushState(null, '', url);
}

export function setRouteScrollTop(store: Store, url: string) {
    setRoute(store, url);
    window.scrollTo(0, 0);
}

export function fetchPost(url: string, body: any) {
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'same-origin',
    });
}

export function assertNotNull<T>(obj: T | null) {
    if (obj === null) {
        throw new Error('Non-null assertion failed');
    } else {
        return obj;
    }
}

export function assertNotUndefined<T>(obj: T | undefined) {
    if (obj === undefined) {
        throw new Error('Non-undefined assertion failed');
    } else {
        return obj;
    }
}

export function loadingIndicatorIncrement(store: Store) {
    mobx.runInAction(() => {
        store.loadingIndicatorCount += 1;
    });
}

export function loadingIndicatorDecrement(store: Store) {
    mobx.runInAction(() => {
        store.loadingIndicatorCount -= 1;
    });
}

export function fileExtension(name: string) {
    const result = name.split('.').pop();

    if (result === undefined) {
        return '';
    } else {
        return result;
    }
}

export function generateUuid(): string {
    return crypto.getRandomValues(new Uint32Array(4)).join('-');
}

let generateDbIdNext = -1;

export function generateDbId(): number {
    const result = generateDbIdNext;
    generateDbIdNext -= 1;
    return result;
}

export function shallowEqual(a: any, b: any): boolean {
    if (Object.is(a, b)) return true;
    if (typeof a !== 'object' || a === null || typeof b !== 'object' || b === null) return false;

    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);

    if (aKeys.length !== bKeys.length) return false;

    for (const aKey of aKeys) {
        if (!b.hasOwnProperty(aKey) || !Object.is(a[aKey], b[aKey])) return false;
    }

    return true;
}

// Example: 1,234
const formatIntFormatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
export function formatInt(n: number): string {
    if (n === 0) return '0'; // Handle -0
    return formatIntFormatter.format(n);
}

// Example: 1,234.56
const formatFloatFormatter = new Intl.NumberFormat('en-US', { minimumSignificantDigits: 1, maximumSignificantDigits: 3 });
export function formatFloat(n: number): string {
    if (n === 0) return '0'; // Handle -0
    return formatFloatFormatter.format(n);
}

// Example: November 4, 2019
export function formatDate(d: Date): string {
    return d.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
}

// Example: 1:00 AM
export function formatTime(seconds: number): string {
    return new Date(
        2000, // Year
        0, // Month
        1, // Day
        0, // Hour
        0, // Minute
        seconds, // Second
    ).toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
    });
}


// Example: November 4, 2019, 1:00 PM
export function formatDateTime(d: Date): string {
    return d.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    });
}

// Example: (234) 567-8901
export function formatPhone(s: string): string {
    return '(' + s.substring(0, 3) + ') ' + s.substring(3, 6) + '-' + s.substring(6);
}

export function scopedClasses(scope: string) {
    return (...args: string[]) => {
        return scope + ' ' + args.join(' ');
    };
}

export function addSnackbarItem(store: Store, type: Store['snackBarMessages'][0]['type'], content: string) {
    const message = {
        id: generateUuid(),
        type: type,
        content: content,
    };

    mobx.runInAction(() => {
        store.snackBarMessages.push(message);
    });

    setTimeout(() => {
        mobx.runInAction(() => {
            store.snackBarMessages = store.snackBarMessages.filter((x) => {
                return x.id !== message.id;
            });
        });
    }, 7000);
}

export function getHomeUrl(): string {
    return routeToUrl({ id: '/' });
}

export function getAdminUrl(): string {
    return routeToUrl({
        id: '/admin/listing',
        params: {
            tableName: 'user',
        },
    });
}

export function assertUnreachable(x: never): never {
    throw new Error('Unreachable assertion failed');
}

export function noop(): void {
}

export function toFixedMax(n: number, digits: number): string {
    return n.toFixed(digits).replace(/\.?0+$/g, '');
}

export function downloadText(filename: string, text: string) {
    const a = document.createElement('a');
    a.setAttribute('download', filename);
    a.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    a.click();
}

export function csv(xss: string[][]) {
    return xss.map(csvRow).join('\n');
}

function csvRow(xs: string[]) {
    return xs.map(csvField).join(',');
}

function csvField(x: string) {
    return '"' + x.replace(/"/g, '""') + '"';
}

export function timestampToString(timestamp: string | null): string {
    if (timestamp === null) return '';

    const lux = luxon.DateTime.fromISO(timestamp);

    if (!lux.isValid) return '';

    return lux.toFormat('L/d/yyyy h:mm a');
}

export function stringToTimestamp(s: string): string | null {
    const m = s.match(/((\d\d?)\/(\d\d?)\/(\d\d(\d\d)?))?( +(\d\d?)(:(\d\d))? *(am|pm)?)?/i);

    if (m === null) return null;

    const monthInt = parseInt(m[2]);
    const dayInt = parseInt(m[3]);
    const yearString = m[4];
    const yearInt = parseInt(yearString);

    const hourInt = parseInt(m[7]);
    const minuteString = m[9];
    const minuteInt = parseInt(minuteString);
    const amString = m[10];

    let month = 0;
    let day = 0;
    let year = 0;
    if (!isNaN(monthInt) && !isNaN(dayInt) && !isNaN(yearInt)) {
        month = monthInt;
        day = dayInt;

        if (yearString.length === 2) {
            const modifiedYear = parseInt('20' + yearString);
            if (!isNaN(modifiedYear)) {
                year = modifiedYear;
            }
        } else {
            year = yearInt;
        }
    } else {
        if (isNaN(hourInt)) return null;

        const now = luxon.DateTime.local();

        month = now.month;
        day = now.day;
        year = now.year;
    }

    let hour = 0;
    if (!isNaN(hourInt)) {
        hour = hourInt;

        if (amString !== undefined && amString.toLowerCase() === 'pm') {
            if (hour !== 12) {
                hour += 12;
            }
        } else {
            if (hour === 12) {
                hour = 0;
            }
        }
    }

    let minute = 0;
    if (!isNaN(minuteInt)) {
        minute = minuteInt;
    }

    const lux = luxon.DateTime.local(year, month, day, hour, minute)

    if (!lux.isValid) return null;
    if (lux.year < 1000 || lux.year > 9999) return null;

    return lux.toISO();
}

export function defaultValueIfUndefined<T>(x: T | undefined, defaultValue: T): T {
    if (x === undefined) return defaultValue;
    return x;
}

export function defaultValueIfNaN(n: number, defaultValue: number): number {
    if (isNaN(n)) return defaultValue;
    return n;
}

export function umToMm(um: number): number {
    return um / 1000;
}

export function mmToUm(mm: number): number {
    return mm * 1000;
}

export function megapixelsToPixels(megapixels: number): number {
    return megapixels * 1e6;
}

export function pixelsToMegapixels(pixels: number): number {
    return pixels / 1e6;
}

export function delay(ms: number): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
};

export async function asyncForEach<T>(xs: T[], batchSize: number, delayMs: number, f: (xs: T[]) => Promise<any>) {
    for (let i = 0; i < xs.length; i += batchSize) {
        const batch = xs.slice(i, i + batchSize);

        if (i !== 0) {
            await delay(delayMs);
        }

        await f(batch);
    }
}

export function polynomial(coefficients: number[], x: number): number {
    let result = 0;
    for (let i = 0; i < coefficients.length; ++i) {
        result += coefficients[i] * Math.pow(x, i);
    }
    return result;
}

export function isObject(x: any): boolean {
    return typeof x === 'object' && x !== null && x !== undefined;
}
