import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../../../../util';
import { cssScope } from './css_scope';
import { BackIcon } from '../../../../icons/back';

type Props = {
    title: string;
    color: 'blue' | 'white';
    onClick: () => void;
};

const c = scopedClasses(cssScope);

export const ProceedButton = mobxReactLite.observer(function ProceedButton(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    return h('div', { className: c('root', 'color-' + props.color), onClick: props.onClick },
        h('div', { className: c('title') }, props.title),
        h('div', { className: c('icon') },
            h(BackIcon, { size: 24 }),
        ),
    );
});
