import * as mobxReactLite from 'mobx-react-lite';
import { assertUnreachable, h, scopedClasses } from '../../util';
import { cssScope } from './css_scope';
import { LoadingSpinner } from '../loading_spinner/loading_spinner';

type Props = {
    title: string;
    style: 'blue' | 'red' | 'transparent';
    fullWidth?: boolean;
    loading: boolean;
    onClick: () => void;
};

const c = scopedClasses(cssScope);

export const Button = mobxReactLite.observer(function Button(props: Props) {
    let loadingSpinnerColor = '';
    if (props.style === 'blue' || props.style === 'red') {
        loadingSpinnerColor = '#FFF';
    } else if (props.style === 'transparent') {
        loadingSpinnerColor = '#3D71D9';
    } else {
        assertUnreachable(props.style);
    }

    return h('div',
        {
            className: c(
                'root',
                'style-' + props.style,
                'full-width-' + (props.fullWidth === true),
                props.loading ? 'loading' : '',
            ),
            onClick: props.onClick,
        },
        h('div', { className: c('text') }, props.title),
        (props.loading ?
            h('div', { className: c('icon') },
                h(LoadingSpinner, {
                    size: 20,
                    color: loadingSpinnerColor,
                }),
            ) : null
        ),
    );
});
