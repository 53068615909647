import { setRoute, loadingIndicatorIncrement, loadingIndicatorDecrement, addSnackbarItem } from './util';
import { DbTables, DbTableIds, DbUser, DbTableIdMap } from './db_types';
import { routeToUrl } from './route_util';
import { Store } from './store';

export type ApiEditTablesResponse = {
    status: 'success';
    idMap: DbTableIdMap;
} | {
    status: 'constraint_violation';
    constraintName: string;
};

type ApiRequests = {
    '/api/login': {
        body: {
            email: string;
            password: string;
        };
        response: {
            status: 'success';
            user: DbUser;
        } | {
            status: 'wrong_credentials';
        };
    };
    '/api/logout': {
        body: {};
        response: {};
    };
    '/api/send-password-reset': {
        body: {
            email: string;
        };
        response: { status: 'wrong_email' | 'success' };
    };
    '/api/reset-password': {
        body: {
            token: string;
            password: string;
        };
        response: { status: 'invalid_token' | 'success' };
    };
    '/api/file-upload-url': {
        body: {
            extension: string;
            contentType: string;
        };
        response: {
            uploadUrl: string;
            filePath: string;
        };
    };
    '/api/edit-tables': {
        body: {
            permissionProof: DbTableIds;
            editTables: DbTables;
            deleteIds: DbTableIds;
        };
        response: ApiEditTablesResponse;
    };
    '/api/read/admin/listing/static': {
        body: {
            tableName: string;
            hiddenFilterFieldId: string;
        };
        response: {
            tables: DbTables;
        };
    };
    '/api/read/admin/listing/dynamic': {
        body: {
            tableName: string;
            pageId: number;
            filters: { [index: string]: number | null };
            hiddenFilterFieldId: string;
            hiddenFilterValue: number;
        };
        response: {
            tables: DbTables;
        };
    };
    '/api/read/admin/detail': {
        body: {
            tableName: string;
            rowId: number;
            hiddenFilterFieldId: string;
        };
        response: {
            tables: DbTables;
        };
    };
    '/api/read/wizard': {
        body: {};
        response: {
            tables: DbTables;
        };
    };
    '/api/change-password': {
        body: {
            email: string;
            newPassword: string;
        };
        response: {
            status: 'success' | 'invalid_email';
        };
    };
};

// Implementation
export function api<Url extends keyof ApiRequests, V extends ApiRequests[Url]>(
    url: Url,
    store: Store,
    showLoading: boolean,
    signal: AbortSignal | null,
    body: V['body'],
): Promise<V['response']> {
    if (showLoading) {
        loadingIndicatorIncrement(store);
    }

    return new Promise<any>((resolve, reject) => {
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'same-origin',
            signal: signal,
        }).then(response => {
            if (showLoading) {
                loadingIndicatorDecrement(store);
            }

            if (response.status === 401) {
                setRoute(store, routeToUrl({
                    id: '/login',
                }));
                reject();
                return;
            } else if (response.status === 400 || response.status === 500) {
                addSnackbarItem(store, 'error', 'An unknown server error occurred');
                reject();
                return;
            } else if (response.status === 403) {
                addSnackbarItem(store, 'error', 'You don\'t have permission to perform this action');
                reject();
                return;
            }

            response.json().then(responseJson => {
                resolve(responseJson);
            });
        }).catch((err: TypeError) => {
            if (showLoading) {
                loadingIndicatorDecrement(store);
            }

            if (err.name === 'AbortError') return;

            addSnackbarItem(store, 'error', 'An unknown network error occurred');
            console.error(err);
            reject(err);
        });
    });
}
