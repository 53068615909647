import * as React from 'react';
import * as mobx from 'mobx';
import * as mobxReactLite from 'mobx-react-lite';
import { assertNotNull, h, scopedClasses } from '../../../../util';
import { cssScope } from './css_scope';
import { SolutionDetails } from './solution_details/solution_details';
import { WizardTitleBar } from '../../../wizard_title_bar/wizard_title_bar';
import { wizardStore } from '../../../../wizard_store';
import { CameraIcon } from '../../../icons/camera';
import { ProceedButton } from '../lens_detail/proceed_button/proceed_button';
import { navigateTo } from '../../../../wizard_navigator';

type Props = {};

const c = scopedClasses(cssScope);

export const CameraDetail = mobxReactLite.observer(function CameraDetail(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const onBackClick = React.useCallback(
        () => {
            history.back();
        },
        [],
    );

    const onAddLensClick = React.useCallback(
        () => {
            mobx.runInAction(() => {
                if (wizardStore.selectedCameraId !== null) {
                    const camera = wizardStore.tables.camera[wizardStore.selectedCameraId];
                    const sensor = wizardStore.tables.cameraSensor[camera.cameraSensorId];

                    wizardStore.lensCameraMake = 'pixelink';
                    wizardStore.lensPixelinkCameraSensorFormatId = sensor.cameraSensorFormatId;
                    wizardStore.lensCameraId = wizardStore.selectedCameraId;
                }
                navigateTo('lens_listing');
            });
        },
        [],
    );

    const onFinalizeClick = React.useCallback(
        () => {
            navigateTo('contact');
        },
        [],
    );

    let isAutofocus = false;
    if (wizardStore.selectedCameraId !== null) {
        const camera = wizardStore.tables.camera[wizardStore.selectedCameraId];
        isAutofocus = camera.autofocus;
    }

    return h('div', { className: c('root') },
        h(WizardTitleBar, {
            backText: 'Adjust Camera Build',
            backOnClick: onBackClick,
            titleText: 'Pixelink Camera Selector Guide',
            titleIcon: h(CameraIcon, { size: 44 }),
            bottomBorder: true,
        }),

        h(SolutionDetails, {
            cameraId: assertNotNull(wizardStore.selectedCameraId),
        }),

        h('div', { className: c('proceed-buttons') },
            (!isAutofocus ?
                h('div', { className: c('proceed-button') },
                    h(ProceedButton, {
                        title: 'Add Lens',
                        color: 'white',
                        onClick: onAddLensClick,
                    }),
                ) : null
            ),

            h('div', { className: c('proceed-button') },
                h(ProceedButton, {
                    title: 'Finalize Selection',
                    color: 'blue',
                    onClick: onFinalizeClick,
                }),
            ),
        ),
    );
});
