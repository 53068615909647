import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { assertNotNull, h, scopedClasses } from '../../../../util';
import { cssScope } from './css_scope';
import { DbTableTypes } from '../../../../db_types';
import { Form } from '../../../form';
import { FieldLabel } from '../../../field_label/field_label';
import { routeToUrl } from '../../../../route_util';
import { ConfirmationDialog } from '../../../confirmation_dialog/confirmation_dialog';
import { Link } from '../../../link';
import { AdminBreadcrumb } from '../../../../admin_breadcrumb_types';
import { adminSettingsReverseRelations, getBreadcrumbName, pushBreadcrumb } from '../../../../admin_util';
import { AdminHeader } from '../../../admin_header/admin_header';
import { store } from '../../../../store';
import * as controller from './controller';

type Props = {
    tableName: keyof DbTableTypes;
    rowId: number | null;
    hiddenFilterFieldId?: string;
    hiddenFilterValue?: number;
    breadcrumbs?: AdminBreadcrumb[];
};

const c = scopedClasses(cssScope);

export const Detail = mobxReactLite.observer(function Detail(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    if (!controller.state.loaded) {
        return h('div', { className: c('root') });
    }

    const currentAdminSettings = store.adminSettings[props.tableName];
    const relations = adminSettingsReverseRelations(store.adminSettings, props.tableName);

    let detailFieldIds = currentAdminSettings.detailFieldIds;
    if (propsRef.current.hiddenFilterFieldId !== undefined) {
        detailFieldIds = detailFieldIds.filter((fieldId) => fieldId !== propsRef.current.hiddenFilterFieldId);
    }

    return h('div', { className: c('root') },
        h(AdminHeader, {
            title: (props.rowId === null ? 'Create' : 'Edit') + ' ' + currentAdminSettings.nameSingular,
            breadcrumbs: props.breadcrumbs,
        }),

        h('div', { className: c('content') },
            (relations.length > 0 && props.rowId !== null ?
                h('div', { className: c('relations-field') },
                    h(FieldLabel, { label: 'Related Data' }),
                    h('div', { className: c('relations') },
                        relations.map((relation, i) => h('div', { className: c('relation'), key: relation.table + '.' + relation.fieldId },
                            h(Link,
                                {
                                    className: c('relation-link'),
                                    url: routeToUrl({
                                        id: '/admin/listing',
                                        params: {
                                            tableName: relation.table,
                                            hiddenFilterFieldId: relation.fieldId,
                                            hiddenFilterValue: assertNotNull(props.rowId),
                                            breadcrumbs: pushBreadcrumb(props.breadcrumbs, {
                                                name: getBreadcrumbName(store.adminSettings, props.tableName, controller.state.row),
                                                route: {
                                                    id: '/admin/detail',
                                                    params: {
                                                        tableName: props.tableName,
                                                        rowId: props.rowId,
                                                        hiddenFilterFieldId: props.hiddenFilterFieldId,
                                                        hiddenFilterValue: props.hiddenFilterValue,
                                                    },
                                                },
                                            }),
                                        },
                                    }),
                                },
                                relation.name,
                            ),
                        )),
                    ),
                ) : null
            ),

            h(Form, { className: c('form'), onSubmit: controller.onSubmit },
                h('div', { className: c('form-fields') },
                    detailFieldIds.map((fieldId) => h('div', { className: c('form-field'), key: fieldId },
                        controller.createFieldElement(fieldId, currentAdminSettings.fields[fieldId]),
                    )),
                ),
            ),
        ),

        (controller.state.deleteDialog.open ?
            h(ConfirmationDialog, {
                title: 'Delete ' + currentAdminSettings.nameSingular + '?',
                description: currentAdminSettings.deleteDescription,
                submitLabel: 'Delete',
                loading: controller.state.deleteDialog.loading,
                onClose: controller.onDeleteClose,
                onSubmit: controller.onDeleteSubmit,
            }) :
            null
        ),
    );
});
