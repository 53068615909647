import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h } from '../util';
import { Button } from './button/button';

type Props = {
    label: string;
    multiple: boolean;
    uploading: boolean;
    onFilesSelected: (files: FileList) => void;
};

export const FileUploadButton = mobxReactLite.observer(function FileUploadButton(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const fileInputRef = React.useRef<HTMLElement>(null);

    const onUploadClick = React.useCallback(
        () => {
            if (fileInputRef.current === null) return;

            fileInputRef.current.click();
        },
        [],
    );

    const onFileInputChange = React.useCallback(
        () => {
            if (fileInputRef.current === null || propsRef.current.uploading) return;
            const fileInput = fileInputRef.current as HTMLInputElement;
            if (fileInput.files === null || fileInput.files.length === 0) return;

            propsRef.current.onFilesSelected(fileInput.files);

            fileInput.value = '';
        },
        [],
    );

    return h('div', {},
        h('input', {
            type: 'file',
            multiple: props.multiple,
            style: { display: 'none' },
            ref: fileInputRef,
            onChange: onFileInputChange,
        }),

        h(Button, {
            title: props.label,
            style: 'blue',
            loading: props.uploading,
            onClick: onUploadClick,
        }),
    );
});
