import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../util';
import { cssScope } from './css_scope';

type Props = {
    size: number;
    color: string;
};

const c = scopedClasses(cssScope);

export const LoadingSpinner = mobxReactLite.observer(function LoadingSpinner(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    return h('div', { className: c('root') },
        h('div', {
            className: c('icon'),
            style: {
                width: props.size + 'px',
                height: props.size + 'px',
                borderTopColor: props.color,
                borderRightColor: props.color,
            },
        }),
    );
});
