import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../../../../util';
import { cssScope } from './css_scope';
import { wizardStore } from '../../../../../wizard_store';
import { getCameraSolutionDetails } from '../../../../../wizard_util';
import { DescriptionIcon } from '../../../../icons/description';

type Props = {
    cameraId: number;
};

const c = scopedClasses(cssScope);

export const SolutionDetails = mobxReactLite.observer(function SolutionDetails(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const camera = wizardStore.tables.camera[props.cameraId];

    return h('div', { className: c('root') },
        h('div', { className: c('title') },
            'Current Selection',
        ),

        (camera.datasheet !== '' ?
            h('div', { className: c('datasheet') },
                h('a', { className: c('datasheet-link'), href: camera.datasheet, target: '_blank' },
                    h('div', { className: c('datasheet-link-icon') },
                        h(DescriptionIcon, { size: 24 }),
                    ),
                    h('div', { className: c('datasheet-link-text') }, 'Datasheet'),
                ),
            ) : null
        ),

        h('div', { className: c('rows') },
            getCameraSolutionDetails().map((row, i) => (
                h('div', { className: c('row'), key: i },
                    h('div', { className: c('cell', 'bold') },
                        row.label,
                    ),
                    h('div', { className: c('cell') },
                        row.value,
                    ),
                )
            )),
        ),
    );
});
