import * as React from 'react';
import * as mobx from 'mobx';
import { AdminBreadcrumb } from '../../../../admin_breadcrumb_types';
import { AdminSettingsField } from '../../../../admin_settings_types';
import { adminSettingsEnumOptions, getBreadcrumbUrl } from '../../../../admin_util';
import { api } from '../../../../api';
import { createDbTableIds, createDbTables, dbCreate } from '../../../../db_create';
import { DbTableTypes } from '../../../../db_types';
import { store } from '../../../../store';
import { addSnackbarItem, assertNotNull, h, setRoute } from '../../../../util';
import { FieldLabel } from '../../../field_label/field_label';
import { createTwoWayBinding } from '../../../two_way_binding';
import { EmailInput } from '../../../email_input';
import { TextInput } from '../../../text_input/text_input';
import { AdminForeignKeySelect } from '../../../admin_foreign_key_select/admin_foreign_key_select';
import { Select } from '../../../select/select';
import { NumberInput } from '../../../number_input';
import { ImageField } from './image_field';
import { TimestampInput } from '../../../timestamp_input';

type RouteParams = {
    tableName: keyof DbTableTypes;
    rowId: number | null;
    hiddenFilterFieldId?: string;
    hiddenFilterValue?: number;
    breadcrumbs?: AdminBreadcrumb[];
};

export const state = mobx.observable({
    row: null as any,
    loaded: false,
    submitting: false,
    deleteDialog: {
        open: false,
        loading: false,
    },
});

let routeParams: RouteParams = { tableName: 'user', rowId: null };
let tables = createDbTables();
let fileFieldPromises: { [index: string]: Promise<void> } = {};
let abortController = new AbortController();

export function onMount(newRouteParams: RouteParams) {
    routeParams = newRouteParams;
    tables = createDbTables();
    fileFieldPromises = {};
    abortController.abort();
    abortController = new AbortController()

    mobx.runInAction(() => {
        state.row = null;
        state.loaded = false;
        state.submitting = false;
        state.deleteDialog = {
            open: false,
            loading: false,
        };
    });

    if (routeParams.rowId === null) {
        api('/api/read/admin/detail', store, true, abortController.signal, {
            tableName: routeParams.tableName,
            rowId: 0,
            hiddenFilterFieldId: routeParams.hiddenFilterFieldId !== undefined ? routeParams.hiddenFilterFieldId : '',
        }).then((response) => {
            const row = dbCreate[routeParams.tableName]();

            if (routeParams.hiddenFilterFieldId !== undefined && routeParams.hiddenFilterValue !== undefined) {
                (row as any)[routeParams.hiddenFilterFieldId] = routeParams.hiddenFilterValue;
            }

            tables = response.tables;
            mobx.runInAction(() => {
                state.row = row;
                state.loaded = true;
            });
        });
    } else {
        api('/api/read/admin/detail', store, true, abortController.signal, {
            tableName: routeParams.tableName,
            rowId: routeParams.rowId,
            hiddenFilterFieldId: routeParams.hiddenFilterFieldId !== undefined ? routeParams.hiddenFilterFieldId : '',
        }).then((response) => {
            tables = response.tables;
            mobx.runInAction(() => {
                state.row = response.tables[routeParams.tableName][assertNotNull(routeParams.rowId)];
                state.loaded = true;
            });
        });
    }
}

export function onUnmount() {
    abortController.abort();
}

export function getBackUrl(): string {
    const breadcrumbs = routeParams.breadcrumbs;
    if (breadcrumbs === undefined || breadcrumbs.length === 0) return '';
    return getBreadcrumbUrl(breadcrumbs, breadcrumbs.length - 1);
}

export function createFieldElement(fieldId: string, configField: AdminSettingsField): React.ReactNode {
    const row = state.row;

    if (configField.fieldType === 'email') {
        return h(React.Fragment, {},
            h(FieldLabel, { label: configField.name }),
            createTwoWayBinding({
                observable: row,
                property: fieldId as any,
                childType: EmailInput,
                childProps: {},
            }),
        );
    } else if (configField.fieldType === 'string') {
        return h(React.Fragment, {},
            h(FieldLabel, { label: configField.name }),
            createTwoWayBinding({
                observable: row,
                property: fieldId as any,
                childType: TextInput,
                childProps: {},
            }),
        );
    } else if (configField.fieldType === 'foreignKey') {
        if (configField.foreignKeyTable === '') return null;

        return h(React.Fragment, {},
            h(FieldLabel, { label: configField.name }),
            createTwoWayBinding({
                observable: row,
                property: fieldId as any,
                childType: AdminForeignKeySelect,
                childProps: {
                    tables: tables,
                    tableName: configField.foreignKeyTable,
                    emptyValue: configField.required ? 0 : null,
                    emptyTitle: '',
                },
            }),
        );
    } else if (configField.fieldType === 'enum') {
        return h(React.Fragment, {},
            h(FieldLabel, { label: configField.name }),
            createTwoWayBinding({
                observable: row,
                property: fieldId as any,
                childType: Select,
                childProps: {
                    options: adminSettingsEnumOptions(configField, ''),
                },
            }),
        );
    } else if (configField.fieldType === 'number') {
        return h(React.Fragment, {},
            h(FieldLabel, { label: configField.name }),
            createTwoWayBinding({
                observable: row,
                property: fieldId as any,
                childType: NumberInput,
                childProps: {
                    fractionDigits: configField.numberFractionDigits,
                },
            }),
        );
    } else if (configField.fieldType === 'image') {
        return h(React.Fragment, {},
            h(FieldLabel, { label: configField.name }),
            createTwoWayBinding({
                observable: row,
                property: fieldId as any,
                childType: ImageField,
                childProps: {
                    fieldId: fieldId,
                    onLoadStart: onFileFieldLoadStart,
                },
            }),
        );
    } else if (configField.fieldType === 'timestamp') {
        return h(React.Fragment, {},
            h(FieldLabel, { label: configField.name }),
            createTwoWayBinding({
                observable: row,
                property: fieldId as any,
                childType: TimestampInput,
                childProps: {},
            }),
        );
    } else {
        return null;
    }
}

export function onFileFieldLoadStart(fieldId: string, promise: Promise<void>) {
    fileFieldPromises[fieldId] = promise;
}

export function validateForm(): string[] {
    const validationErrors = [];
    const requiredFieldErrors = [];
    const row = state.row;
    const currentAdminSettings = store.adminSettings[routeParams.tableName];

    for (const fieldId of currentAdminSettings.detailFieldIds) {
        const fieldConfig = currentAdminSettings.fields[fieldId];
        const fieldValue = (row as any)[fieldId];

        if (fieldConfig.fieldType === 'foreignKey') {
            if (fieldConfig.required && fieldValue === 0) {
                requiredFieldErrors.push(fieldConfig.name);
            }
        } else if (fieldConfig.fieldType === 'enum') {
            if (fieldConfig.required && fieldValue === fieldConfig.enumEmptyValue) {
                requiredFieldErrors.push(fieldConfig.name);
            }
        } else if (fieldConfig.fieldType === 'number') {
            // Numbers will never be blank
        } else if (fieldConfig.required && fieldValue === '') {
            requiredFieldErrors.push(fieldConfig.name);
        }

        if (fieldConfig.fieldType === 'email' && fieldValue !== '' && fieldValue.indexOf('@') < 0) {
            validationErrors.push(fieldConfig.name + ' must contain an @ symbol');
        }
    }

    if (requiredFieldErrors.length !== 0) {
        validationErrors.push('The following fields are required: ' + requiredFieldErrors.join(', '));
    }

    return validationErrors;
}

export function onSubmit() {
    if (!state.loaded || state.submitting) return;

    const validationErrors = validateForm();
    if (validationErrors.length !== 0) {
        mobx.runInAction(() => {
            for (const validationError of validationErrors) {
                addSnackbarItem(store, 'error', validationError);
            }
        });
        return;
    }

    mobx.runInAction(() => {
        state.submitting = true;
    });

    Promise.all(Object.values(fileFieldPromises)).then(() => {
        const permissionProof = createDbTableIds();
        const editTables = createDbTables();
        const deleteIds = createDbTableIds();

        const row = state.row;
        editTables[routeParams.tableName][row.id] = row;
        permissionProof[routeParams.tableName].push(row.id);

        api('/api/edit-tables', store, false, null, {
            permissionProof: permissionProof,
            editTables: editTables,
            deleteIds: deleteIds,
        }).then((response) => {
            if (response.status === 'success') {
                mobx.runInAction(() => {
                    addSnackbarItem(store, 'success', 'Saved changes');
                    setRoute(store, getBackUrl());
                });
            } else if (response.status === 'constraint_violation') {
                const tableSettings = store.adminSettings[routeParams.tableName];
                let errorMessage = tableSettings.constraintErrorMessagesSave[response.constraintName];
                if (errorMessage === undefined) {
                    errorMessage = 'Unknown constraint error';
                }

                mobx.runInAction(() => {
                    state.submitting = false;
                    addSnackbarItem(store, 'error', errorMessage);
                });
            }
        });
    });
}

export function onDeleteOpen() {
    if (!state.loaded || state.submitting) return;

    mobx.runInAction(() => {
        state.deleteDialog.open = true;
    });
}

export function onDeleteClose() {
    mobx.runInAction(() => {
        state.deleteDialog.open = false;
    });
}

export function onDeleteSubmit() {
    if (state.deleteDialog.loading) return;

    mobx.runInAction(() => {
        state.deleteDialog.loading = true;
    });

    const currentAdminSettings = store.adminSettings[routeParams.tableName];
    const row = state.row;
    const permissionProof = createDbTableIds();
    const deleteIds = createDbTableIds();

    deleteIds[routeParams.tableName].push(row.id);
    permissionProof[routeParams.tableName].push(row.id);

    api('/api/edit-tables', store, false, null, {
        permissionProof: permissionProof,
        editTables: createDbTables(),
        deleteIds: deleteIds,
    }).then((response) => {
        if (response.status === 'success') {
            mobx.runInAction(() => {
                addSnackbarItem(store, 'success', currentAdminSettings.nameSingular + ' deleted');
                setRoute(store, getBackUrl());
            });
        } else if (response.status === 'constraint_violation') {
            const tableSettings = store.adminSettings[routeParams.tableName];
            let errorMessage = tableSettings.constraintErrorMessagesDelete[response.constraintName];
            if (errorMessage === undefined) {
                errorMessage = 'Unknown constraint error';
            }

            mobx.runInAction(() => {
                state.deleteDialog.loading = false;
                addSnackbarItem(store, 'error', errorMessage);
            });
        }
    });
}
