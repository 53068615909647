import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../../../util';
import { cssScope } from './css_scope';
import { PardotForm } from './pardot_form/pardot_form';
import { SolutionDetails } from './solution_details/solution_details';
import { WizardTitleBar } from '../../../wizard_title_bar/wizard_title_bar';
import { wizardStore } from '../../../../wizard_store';
import { LensIcon } from '../../../icons/lens';

type Props = {};

const c = scopedClasses(cssScope);

export const Contact = mobxReactLite.observer(function Contact(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const onBackClick = React.useCallback(
        () => {
            history.back();
        },
        [],
    );

    const cameraOrLensTitles = [];
    if (wizardStore.selectedCameraId !== null) {
        cameraOrLensTitles.push('Camera');
    }
    if (wizardStore.selectedLensSolution !== null) {
        cameraOrLensTitles.push('Lens');
    }

    return h('div', { className: c('root') },
        h(WizardTitleBar, {
            backText: wizardStore.selectedLensSolution === null ? 'Camera Selection' : 'Lens Selection',
            backOnClick: onBackClick,
            titleText: 'Submit ' + cameraOrLensTitles.join('/') + ' Inquiry',
            titleIcon: h(LensIcon, { size: 38 }),
            bottomBorder: true,
        }),

        h('div', { className: c('form-and-solution-details') },
            h('div', { className: c('form') },
                h('div', { className: c('form-description') },
                    'A copy of your selections will be emailed to you.',
                ),

                h(PardotForm, {}),
            ),

            h('div', { className: c('solution-details') },
                h(SolutionDetails, {}),
            ),
        ),
    );
});
