import * as mobxReactLite from 'mobx-react-lite';
import { h } from '../../util';

type Props = {
    size: number;
};

export const WarningIcon = mobxReactLite.observer(function WarningIcon(props: Props) {
    return h('svg',
        {
            width: props.size.toString(),
            height: props.size.toString(),
            viewBox: '0 0 24 24',
            xmlns: 'http://www.w3.org/2000/svg',
        },
        h('path', {
            d: 'M1 21 12 2 23 21ZM11 15H13V10H11ZM12 18Q12.425 18 12.713 17.712Q13 17.425 13 17Q13 16.575 12.713 16.288Q12.425 16 12 16Q11.575 16 11.288 16.288Q11 16.575 11 17Q11 17.425 11.288 17.712Q11.575 18 12 18Z',
        }),
    );
});
