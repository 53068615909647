import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../util';
import { cssScope } from './css_scope';
import { Handle } from './handle';
import { clamp } from '../../wizard_util';

type Props = {
    minValue: number;
    maxValue: number;
    minBound: number;
    maxBound: number;
    onChangeMinValue: (minValue: number) => void;
    onChangeMaxValue: (maxValue: number) => void;
};

const c = scopedClasses(cssScope);

export const RangeSlider = mobxReactLite.observer(function RangeSlider(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    const handlesRef = React.useRef<HTMLDivElement>();

    const onChangeMinValue = React.useCallback(
        (minValue: number) => {
            propsRef.current.onChangeMinValue(clamp(Math.round(minValue), propsRef.current.minBound, propsRef.current.maxValue));
        },
        [],
    );
    const onChangeMaxValue = React.useCallback(
        (maxValue: number) => {
            propsRef.current.onChangeMaxValue(clamp(Math.round(maxValue), propsRef.current.minValue, propsRef.current.maxBound));
        },
        [],
    );

    const getRectWidth = React.useCallback(
        (): number => {
            if (handlesRef.current === undefined) return 1;
            return handlesRef.current.getBoundingClientRect().width;
        },
        [],
    );

    const fillLeftPercent = clamp((props.minValue - props.minBound) / (props.maxBound - props.minBound), 0, 1);
    const fillRightPercent = clamp((props.maxValue - props.minBound) / (props.maxBound - props.minBound), 0, 1);

    return h('div', { className: c('root') },
        h('div', { className: c('track') }),
        h('div', { className: c('handles'), ref: handlesRef },
            h('div', {
                className: c('fill'),
                style: {
                    left: 'calc(' + (fillLeftPercent * 100) + '% + 12px)',
                    right: 'calc(' + ((1 - fillRightPercent) * 100) + '% - 12px)',
                },
            }),
            h(Handle, {
                value: props.minValue,
                minBound: props.minBound,
                maxBound: props.maxBound,
                getRectWidth: getRectWidth,
                onChangeValue: onChangeMinValue,
            }),
            h(Handle, {
                value: props.maxValue,
                minBound: props.minBound,
                maxBound: props.maxBound,
                getRectWidth: getRectWidth,
                onChangeValue: onChangeMaxValue,
            }),
        ),
    );
});
