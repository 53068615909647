import { DbMachineVisionLens } from '../db_types';

// Generated from "Machine Vision Import" spreadsheet
export const dbDataMachineVisionLens: { [index: string]: DbMachineVisionLens } = {
    "1": {
        "id": 1,
        "productFamilyId": 7,
        "partNumber": "NMV-6M1.1",
        "megapixels": 24,
        "focalLength": 6.5,
        "fNumberLow": 2.5,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 17.64001134,
        "minWorkingDistance": 100,
        "pupilPosition": 17.25698179,
        "resolvableFrequencyCenter": 200,
        "resolvableFrequencyEdge": 100,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "2": {
        "id": 2,
        "productFamilyId": 7,
        "partNumber": "NMV-8M1.1",
        "megapixels": 24,
        "focalLength": 8.5,
        "fNumberLow": 2.5,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 17.64001134,
        "minWorkingDistance": 100,
        "pupilPosition": 10.79102715,
        "resolvableFrequencyCenter": 200,
        "resolvableFrequencyEdge": 100,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "3": {
        "id": 3,
        "productFamilyId": 7,
        "partNumber": "NMV-12M1.1",
        "megapixels": 24,
        "focalLength": 12,
        "fNumberLow": 1.8,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 17.64001134,
        "minWorkingDistance": 100,
        "pupilPosition": 14.6159504,
        "resolvableFrequencyCenter": 200,
        "resolvableFrequencyEdge": 100,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "4": {
        "id": 4,
        "productFamilyId": 7,
        "partNumber": "NMV-16M1.1",
        "megapixels": 24,
        "focalLength": 16,
        "fNumberLow": 1.8,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 17.64001134,
        "minWorkingDistance": 100,
        "pupilPosition": 15.98504776,
        "resolvableFrequencyCenter": 200,
        "resolvableFrequencyEdge": 100,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "5": {
        "id": 5,
        "productFamilyId": 7,
        "partNumber": "NMV-25M1.1",
        "megapixels": 24,
        "focalLength": 25,
        "fNumberLow": 1.8,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 17.64001134,
        "minWorkingDistance": 100,
        "pupilPosition": 13.34120425,
        "resolvableFrequencyCenter": 200,
        "resolvableFrequencyEdge": 100,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "6": {
        "id": 6,
        "productFamilyId": 7,
        "partNumber": "NMV-35M1.1",
        "megapixels": 24,
        "focalLength": 35,
        "fNumberLow": 1.8,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 17.64001134,
        "minWorkingDistance": 200,
        "pupilPosition": 8.26446281,
        "resolvableFrequencyCenter": 200,
        "resolvableFrequencyEdge": 100,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "7": {
        "id": 7,
        "productFamilyId": 7,
        "partNumber": "NMV-50M1.1",
        "megapixels": 24,
        "focalLength": 50,
        "fNumberLow": 1.8,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 17.64001134,
        "minWorkingDistance": 200,
        "pupilPosition": 8.380157329,
        "resolvableFrequencyCenter": 200,
        "resolvableFrequencyEdge": 100,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "8": {
        "id": 8,
        "productFamilyId": 7,
        "partNumber": "1-24719",
        "megapixels": 20,
        "focalLength": 8,
        "fNumberLow": 2.8,
        "fNumberHigh": 22,
        "maxSensorDiagonal": 23,
        "minWorkingDistance": 100,
        "pupilPosition": 3.739057546,
        "resolvableFrequencyCenter": 160,
        "resolvableFrequencyEdge": 80,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "9": {
        "id": 9,
        "productFamilyId": 7,
        "partNumber": "1-19910",
        "megapixels": 20,
        "focalLength": 12,
        "fNumberLow": 2,
        "fNumberHigh": 22,
        "maxSensorDiagonal": 23,
        "minWorkingDistance": 100,
        "pupilPosition": 18.09720082,
        "resolvableFrequencyCenter": 160,
        "resolvableFrequencyEdge": 80,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "10": {
        "id": 10,
        "productFamilyId": 7,
        "partNumber": "1-19911",
        "megapixels": 20,
        "focalLength": 16,
        "fNumberLow": 2,
        "fNumberHigh": 22,
        "maxSensorDiagonal": 23,
        "minWorkingDistance": 100,
        "pupilPosition": 16.95645709,
        "resolvableFrequencyCenter": 160,
        "resolvableFrequencyEdge": 80,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "11": {
        "id": 11,
        "productFamilyId": 7,
        "partNumber": "1-19912",
        "megapixels": 20,
        "focalLength": 25,
        "fNumberLow": 2,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 23,
        "minWorkingDistance": 150,
        "pupilPosition": 19.01999161,
        "resolvableFrequencyCenter": 160,
        "resolvableFrequencyEdge": 80,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "12": {
        "id": 12,
        "productFamilyId": 7,
        "partNumber": "1-19913",
        "megapixels": 20,
        "focalLength": 35,
        "fNumberLow": 2,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 23,
        "minWorkingDistance": 200,
        "pupilPosition": -9.116895215,
        "resolvableFrequencyCenter": 160,
        "resolvableFrequencyEdge": 80,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "13": {
        "id": 13,
        "productFamilyId": 7,
        "partNumber": "1-19914",
        "megapixels": 20,
        "focalLength": 50,
        "fNumberLow": 2,
        "fNumberHigh": 22,
        "maxSensorDiagonal": 23,
        "minWorkingDistance": 300,
        "pupilPosition": -27.08480642,
        "resolvableFrequencyCenter": 160,
        "resolvableFrequencyEdge": 80,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "14": {
        "id": 14,
        "productFamilyId": 7,
        "partNumber": "NMV-4M1",
        "megapixels": 5,
        "focalLength": 4.7,
        "fNumberLow": 2.4,
        "fNumberHigh": 11,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 100,
        "pupilPosition": 35.52488827,
        "resolvableFrequencyCenter": 100,
        "resolvableFrequencyEdge": 50,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "15": {
        "id": 15,
        "productFamilyId": 7,
        "partNumber": "NMV-6M1",
        "megapixels": 5,
        "focalLength": 6,
        "fNumberLow": 1.8,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 100,
        "pupilPosition": 24.00140166,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 80,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "16": {
        "id": 16,
        "productFamilyId": 7,
        "partNumber": "NMV-8M1",
        "megapixels": 5,
        "focalLength": 8,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 100,
        "pupilPosition": -8.020951302,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 80,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "17": {
        "id": 17,
        "productFamilyId": 7,
        "partNumber": "NMV-12M1",
        "megapixels": 5,
        "focalLength": 12.5,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 300,
        "pupilPosition": 0.2115664814,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 80,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "18": {
        "id": 18,
        "productFamilyId": 7,
        "partNumber": "NMV-16M1",
        "megapixels": 5,
        "focalLength": 16,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 300,
        "pupilPosition": -16.04181952,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 80,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "19": {
        "id": 19,
        "productFamilyId": 7,
        "partNumber": "NMV-25M1",
        "megapixels": 5,
        "focalLength": 25,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 300,
        "pupilPosition": -25,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 80,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "20": {
        "id": 20,
        "productFamilyId": 7,
        "partNumber": "NMV-35M1",
        "megapixels": 5,
        "focalLength": 35,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 300,
        "pupilPosition": -34.99349742,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 80,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "21": {
        "id": 21,
        "productFamilyId": 7,
        "partNumber": "NMV-50M1",
        "megapixels": 5,
        "focalLength": 50,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 500,
        "pupilPosition": -50,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 80,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "22": {
        "id": 22,
        "productFamilyId": 7,
        "partNumber": "NMV-75M1",
        "megapixels": 5,
        "focalLength": 75,
        "fNumberLow": 1.8,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 1000,
        "pupilPosition": -74.90235406,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 80,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "23": {
        "id": 23,
        "productFamilyId": 7,
        "partNumber": "1-19552",
        "megapixels": 10,
        "focalLength": 5,
        "fNumberLow": 1.8,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 100,
        "pupilPosition": 11.6470044,
        "resolvableFrequencyCenter": 200,
        "resolvableFrequencyEdge": 160,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "24": {
        "id": 24,
        "productFamilyId": 7,
        "partNumber": "1-19553",
        "megapixels": 10,
        "focalLength": 8.5,
        "fNumberLow": 1.8,
        "fNumberHigh": 22,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 100,
        "pupilPosition": 28.46561859,
        "resolvableFrequencyCenter": 200,
        "resolvableFrequencyEdge": 160,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "25": {
        "id": 25,
        "productFamilyId": 7,
        "partNumber": "1-19554",
        "megapixels": 10,
        "focalLength": 12,
        "fNumberLow": 1.8,
        "fNumberHigh": 11,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 100,
        "pupilPosition": 9.749204616,
        "resolvableFrequencyCenter": 200,
        "resolvableFrequencyEdge": 160,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "26": {
        "id": 26,
        "productFamilyId": 7,
        "partNumber": "1-19555",
        "megapixels": 10,
        "focalLength": 16,
        "fNumberLow": 1.8,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 100,
        "pupilPosition": 10.93918701,
        "resolvableFrequencyCenter": 200,
        "resolvableFrequencyEdge": 160,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "27": {
        "id": 27,
        "productFamilyId": 7,
        "partNumber": "1-19556",
        "megapixels": 10,
        "focalLength": 25,
        "fNumberLow": 1.8,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 100,
        "pupilPosition": 4.213993639,
        "resolvableFrequencyCenter": 200,
        "resolvableFrequencyEdge": 160,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "28": {
        "id": 28,
        "productFamilyId": 7,
        "partNumber": "1-19557",
        "megapixels": 10,
        "focalLength": 35,
        "fNumberLow": 2,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 100,
        "pupilPosition": -6.79943101,
        "resolvableFrequencyCenter": 200,
        "resolvableFrequencyEdge": 160,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "29": {
        "id": 29,
        "productFamilyId": 7,
        "partNumber": "1-19558",
        "megapixels": 10,
        "focalLength": 50,
        "fNumberLow": 2.8,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 100,
        "pupilPosition": 8.427947598,
        "resolvableFrequencyCenter": 200,
        "resolvableFrequencyEdge": 160,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "30": {
        "id": 30,
        "productFamilyId": 7,
        "partNumber": "1-24830",
        "megapixels": 5,
        "focalLength": 12.5,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 100,
        "pupilPosition": 15.48277983,
        "resolvableFrequencyCenter": 160,
        "resolvableFrequencyEdge": 125,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "31": {
        "id": 31,
        "productFamilyId": 7,
        "partNumber": "1-24831",
        "megapixels": 5,
        "focalLength": 16,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 100,
        "pupilPosition": 17.39390811,
        "resolvableFrequencyCenter": 160,
        "resolvableFrequencyEdge": 125,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "32": {
        "id": 32,
        "productFamilyId": 7,
        "partNumber": "1-24832",
        "megapixels": 5,
        "focalLength": 25,
        "fNumberLow": 1.6,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 100,
        "pupilPosition": -0.3314618873,
        "resolvableFrequencyCenter": 160,
        "resolvableFrequencyEdge": 125,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "33": {
        "id": 33,
        "productFamilyId": 7,
        "partNumber": "1-24833",
        "megapixels": 5,
        "focalLength": 35,
        "fNumberLow": 1.6,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 180,
        "pupilPosition": -12.49055653,
        "resolvableFrequencyCenter": 160,
        "resolvableFrequencyEdge": 125,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "34": {
        "id": 34,
        "productFamilyId": 7,
        "partNumber": "NMV-5M23",
        "megapixels": 2,
        "focalLength": 5,
        "fNumberLow": 2.8,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 100,
        "pupilPosition": 11.81818182,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 100,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "35": {
        "id": 35,
        "productFamilyId": 7,
        "partNumber": "NMV-8M23",
        "megapixels": 2,
        "focalLength": 8,
        "fNumberLow": 1.4,
        "fNumberHigh": -1,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 100,
        "pupilPosition": 6.651512999,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 100,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "36": {
        "id": 36,
        "productFamilyId": 7,
        "partNumber": "NMV-12M23",
        "megapixels": 2,
        "focalLength": 12,
        "fNumberLow": 1.4,
        "fNumberHigh": -1,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 150,
        "pupilPosition": 0,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 100,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "37": {
        "id": 37,
        "productFamilyId": 7,
        "partNumber": "NMV-16M23",
        "megapixels": 2,
        "focalLength": 16,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 200,
        "pupilPosition": 4.848197956,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 100,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "38": {
        "id": 38,
        "productFamilyId": 7,
        "partNumber": "NMV-25M23",
        "megapixels": 2,
        "focalLength": 25,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 200,
        "pupilPosition": 1.941276777,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 100,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "39": {
        "id": 39,
        "productFamilyId": 7,
        "partNumber": "NMV-35M23",
        "megapixels": 2,
        "focalLength": 35,
        "fNumberLow": 2,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 200,
        "pupilPosition": -9.820653379,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 100,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "40": {
        "id": 40,
        "productFamilyId": 7,
        "partNumber": "NMV-50M23",
        "megapixels": 2,
        "focalLength": 50,
        "fNumberLow": 2.8,
        "fNumberHigh": 22,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 200,
        "pupilPosition": -33.80730381,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 100,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "41": {
        "id": 41,
        "productFamilyId": 7,
        "partNumber": "NMV-75M23",
        "megapixels": 2,
        "focalLength": 75,
        "fNumberLow": 2.5,
        "fNumberHigh": 22,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 1200,
        "pupilPosition": -75,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 80,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "42": {
        "id": 42,
        "productFamilyId": 7,
        "partNumber": "NMV-100M23",
        "megapixels": 2,
        "focalLength": 100,
        "fNumberLow": 2.8,
        "fNumberHigh": 32,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 2000,
        "pupilPosition": -81.81818182,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 80,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "43": {
        "id": 43,
        "productFamilyId": 7,
        "partNumber": "NMV-6",
        "megapixels": 0,
        "focalLength": 6,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 100,
        "pupilPosition": 138.6966098,
        "resolvableFrequencyCenter": 100,
        "resolvableFrequencyEdge": 60,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "44": {
        "id": 44,
        "productFamilyId": 7,
        "partNumber": "NMV-8",
        "megapixels": 0,
        "focalLength": 8,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 100,
        "pupilPosition": 129.5034781,
        "resolvableFrequencyCenter": 100,
        "resolvableFrequencyEdge": 60,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "45": {
        "id": 45,
        "productFamilyId": 7,
        "partNumber": "NMV-12",
        "megapixels": 0,
        "focalLength": 12,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 100,
        "pupilPosition": 218.8051578,
        "resolvableFrequencyCenter": 100,
        "resolvableFrequencyEdge": 60,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "46": {
        "id": 46,
        "productFamilyId": 7,
        "partNumber": "NMV-16",
        "megapixels": 0,
        "focalLength": 16,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 200,
        "pupilPosition": 2.416984213,
        "resolvableFrequencyCenter": 100,
        "resolvableFrequencyEdge": 60,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "47": {
        "id": 47,
        "productFamilyId": 7,
        "partNumber": "NMV-25",
        "megapixels": 0,
        "focalLength": 25,
        "fNumberLow": 1.6,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 200,
        "pupilPosition": 137.5940329,
        "resolvableFrequencyCenter": 100,
        "resolvableFrequencyEdge": 60,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "48": {
        "id": 48,
        "productFamilyId": 7,
        "partNumber": "NMV-35",
        "megapixels": 0,
        "focalLength": 35,
        "fNumberLow": 1.6,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 300,
        "pupilPosition": 2.007342964,
        "resolvableFrequencyCenter": 100,
        "resolvableFrequencyEdge": 60,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "49": {
        "id": 49,
        "productFamilyId": 7,
        "partNumber": "NMV-50",
        "megapixels": 0,
        "focalLength": 50,
        "fNumberLow": 2,
        "fNumberHigh": 22,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 500,
        "pupilPosition": -16.10033802,
        "resolvableFrequencyCenter": 100,
        "resolvableFrequencyEdge": 60,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "50": {
        "id": 50,
        "productFamilyId": 7,
        "partNumber": "1-24431",
        "megapixels": 2,
        "focalLength": 3.5,
        "fNumberLow": 2.4,
        "fNumberHigh": 14,
        "maxSensorDiagonal": 9,
        "minWorkingDistance": 100,
        "pupilPosition": 10.54710313,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 100,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "51": {
        "id": 51,
        "productFamilyId": 7,
        "partNumber": "1-28108",
        "megapixels": 2,
        "focalLength": 6,
        "fNumberLow": 1.2,
        "fNumberHigh": -1,
        "maxSensorDiagonal": 8,
        "minWorkingDistance": 100,
        "pupilPosition": 14.15480427,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 100,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "52": {
        "id": 52,
        "productFamilyId": 7,
        "partNumber": "NMV-4WA",
        "megapixels": 0,
        "focalLength": 3.5,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 9,
        "minWorkingDistance": 200,
        "pupilPosition": 86.17165553,
        "resolvableFrequencyCenter": 100,
        "resolvableFrequencyEdge": 60,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "53": {
        "id": 53,
        "productFamilyId": 7,
        "partNumber": "NMV-5WA",
        "megapixels": 0,
        "focalLength": 4.5,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 9,
        "minWorkingDistance": 200,
        "pupilPosition": 40.08294751,
        "resolvableFrequencyCenter": 100,
        "resolvableFrequencyEdge": 60,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "54": {
        "id": 54,
        "productFamilyId": 7,
        "partNumber": "NMV-6WA",
        "megapixels": 0,
        "focalLength": 6,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 9,
        "minWorkingDistance": 200,
        "pupilPosition": 11.40218105,
        "resolvableFrequencyCenter": 100,
        "resolvableFrequencyEdge": 60,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "55": {
        "id": 55,
        "productFamilyId": 7,
        "partNumber": "NMV-12WA",
        "megapixels": 0,
        "focalLength": 12,
        "fNumberLow": 2.8,
        "fNumberHigh": 32,
        "maxSensorDiagonal": 9,
        "minWorkingDistance": 300,
        "pupilPosition": 13.78242011,
        "resolvableFrequencyCenter": 100,
        "resolvableFrequencyEdge": 60,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "56": {
        "id": 56,
        "productFamilyId": 7,
        "partNumber": "1-26382",
        "megapixels": 5,
        "focalLength": 8,
        "fNumberLow": 1.4,
        "fNumberHigh": 8,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 100,
        "pupilPosition": 20.81034483,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 80,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "57": {
        "id": 57,
        "productFamilyId": 7,
        "partNumber": "1-26383",
        "megapixels": 5,
        "focalLength": 12.5,
        "fNumberLow": 1.4,
        "fNumberHigh": 8,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 300,
        "pupilPosition": 19.92819399,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 80,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "58": {
        "id": 58,
        "productFamilyId": 7,
        "partNumber": "1-26384",
        "megapixels": 5,
        "focalLength": 16,
        "fNumberLow": 1.4,
        "fNumberHigh": 8,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 300,
        "pupilPosition": 12.34109251,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 80,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "59": {
        "id": 59,
        "productFamilyId": 7,
        "partNumber": "1-26385",
        "megapixels": 5,
        "focalLength": 25,
        "fNumberLow": 1.4,
        "fNumberHigh": 8,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 300,
        "pupilPosition": 12.13239128,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 80,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "60": {
        "id": 60,
        "productFamilyId": 7,
        "partNumber": "1-26386",
        "megapixels": 5,
        "focalLength": 35,
        "fNumberLow": 1.4,
        "fNumberHigh": 8,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 300,
        "pupilPosition": 0.002848245481,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 80,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "61": {
        "id": 61,
        "productFamilyId": 7,
        "partNumber": "1-26387",
        "megapixels": 5,
        "focalLength": 50,
        "fNumberLow": 1.4,
        "fNumberHigh": 8,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 500,
        "pupilPosition": -24.34899398,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 80,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "62": {
        "id": 62,
        "productFamilyId": 7,
        "partNumber": "1-25551",
        "megapixels": 2,
        "focalLength": 8,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 100,
        "pupilPosition": 9.227102486,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 100,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "63": {
        "id": 63,
        "productFamilyId": 7,
        "partNumber": "1-25552",
        "megapixels": 2,
        "focalLength": 12,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 150,
        "pupilPosition": 0,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 100,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "64": {
        "id": 64,
        "productFamilyId": 7,
        "partNumber": "1-25553",
        "megapixels": 2,
        "focalLength": 16,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 200,
        "pupilPosition": 4.848197956,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 100,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "65": {
        "id": 65,
        "productFamilyId": 7,
        "partNumber": "1-25554",
        "megapixels": 2,
        "focalLength": 25,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 200,
        "pupilPosition": 1.941276777,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 100,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "66": {
        "id": 66,
        "productFamilyId": 7,
        "partNumber": "1-25555",
        "megapixels": 2,
        "focalLength": 35,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 200,
        "pupilPosition": -9.820653379,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 100,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "67": {
        "id": 67,
        "productFamilyId": 7,
        "partNumber": "1-25556",
        "megapixels": 2,
        "focalLength": 50,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 200,
        "pupilPosition": -33.80730381,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 100,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "68": {
        "id": 68,
        "productFamilyId": 7,
        "partNumber": "SWIR-8",
        "megapixels": 0,
        "focalLength": 8,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 100,
        "pupilPosition": 15.63612393,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 80,
        "wavelengthVisible": false,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": true
    },
    "69": {
        "id": 69,
        "productFamilyId": 7,
        "partNumber": "SWIR-12",
        "megapixels": 0,
        "focalLength": 12.5,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 300,
        "pupilPosition": 6.15627286,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 80,
        "wavelengthVisible": false,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": true
    },
    "70": {
        "id": 70,
        "productFamilyId": 7,
        "partNumber": "SWIR-16",
        "megapixels": 0,
        "focalLength": 16,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 300,
        "pupilPosition": -0.694746261,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 80,
        "wavelengthVisible": false,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": true
    },
    "71": {
        "id": 71,
        "productFamilyId": 7,
        "partNumber": "SWIR-25",
        "megapixels": 0,
        "focalLength": 25,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 300,
        "pupilPosition": -0.8159236305,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 80,
        "wavelengthVisible": false,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": true
    },
    "72": {
        "id": 72,
        "productFamilyId": 7,
        "partNumber": "SWIR-35",
        "megapixels": 0,
        "focalLength": 35,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 300,
        "pupilPosition": -12.27873689,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 80,
        "wavelengthVisible": false,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": true
    },
    "73": {
        "id": 73,
        "productFamilyId": 7,
        "partNumber": "SWIR-50",
        "megapixels": 0,
        "focalLength": 50,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 500,
        "pupilPosition": -43.41003153,
        "resolvableFrequencyCenter": 120,
        "resolvableFrequencyEdge": 80,
        "wavelengthVisible": false,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": true
    },
    "74": {
        "id": 74,
        "productFamilyId": 7,
        "partNumber": "1-19711",
        "megapixels": 0,
        "focalLength": 28,
        "fNumberLow": 2.8,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 43.26661531,
        "minWorkingDistance": 300,
        "pupilPosition": 28.97732727,
        "resolvableFrequencyCenter": 160,
        "resolvableFrequencyEdge": 63,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "75": {
        "id": 75,
        "productFamilyId": 7,
        "partNumber": "1-19712",
        "megapixels": 0,
        "focalLength": 35,
        "fNumberLow": 2.8,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 43.26661531,
        "minWorkingDistance": 260,
        "pupilPosition": -16.99329866,
        "resolvableFrequencyCenter": 160,
        "resolvableFrequencyEdge": 63,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "76": {
        "id": 76,
        "productFamilyId": 7,
        "partNumber": "1-19713",
        "megapixels": 0,
        "focalLength": 50,
        "fNumberLow": 2.8,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 43.26661531,
        "minWorkingDistance": 260,
        "pupilPosition": -25.31254623,
        "resolvableFrequencyCenter": 160,
        "resolvableFrequencyEdge": 63,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "77": {
        "id": 77,
        "productFamilyId": 7,
        "partNumber": "1-19909",
        "megapixels": 0,
        "focalLength": 50,
        "fNumberLow": 1.9,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 43.26661531,
        "minWorkingDistance": 500,
        "pupilPosition": 7.737354396,
        "resolvableFrequencyCenter": 0,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "78": {
        "id": 78,
        "productFamilyId": 7,
        "partNumber": "DO-1795",
        "megapixels": 0,
        "focalLength": 17,
        "fNumberLow": 0.95,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 420,
        "pupilPosition": 41.72399329,
        "resolvableFrequencyCenter": 0,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "79": {
        "id": 79,
        "productFamilyId": 7,
        "partNumber": "DO-2595",
        "megapixels": 0,
        "focalLength": 25,
        "fNumberLow": 0.95,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 450,
        "pupilPosition": 30.02827481,
        "resolvableFrequencyCenter": 0,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "80": {
        "id": 80,
        "productFamilyId": 7,
        "partNumber": "DO-5095",
        "megapixels": 0,
        "focalLength": 50,
        "fNumberLow": 0.95,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 600,
        "pupilPosition": -53.125,
        "resolvableFrequencyCenter": 0,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "81": {
        "id": 81,
        "productFamilyId": 7,
        "partNumber": "NMV-8M1-VIS-SWIR",
        "megapixels": 12,
        "focalLength": 8,
        "fNumberLow": 1.8,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 200,
        "pupilPosition": 32.70125224,
        "resolvableFrequencyCenter": 160,
        "resolvableFrequencyEdge": 80,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": true
    },
    "82": {
        "id": 82,
        "productFamilyId": 7,
        "partNumber": "NMV-12M1-VIS-SWIR",
        "megapixels": 12,
        "focalLength": 12,
        "fNumberLow": 1.8,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 200,
        "pupilPosition": 20.45535082,
        "resolvableFrequencyCenter": 160,
        "resolvableFrequencyEdge": 100,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": true
    },
    "83": {
        "id": 83,
        "productFamilyId": 7,
        "partNumber": "NMV-16M1-VIS-SWIR",
        "megapixels": 12,
        "focalLength": 16,
        "fNumberLow": 1.8,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 200,
        "pupilPosition": 16.24277457,
        "resolvableFrequencyCenter": 160,
        "resolvableFrequencyEdge": 100,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": true
    },
    "84": {
        "id": 84,
        "productFamilyId": 7,
        "partNumber": "NMV-25M1-VIS-SWIR",
        "megapixels": 12,
        "focalLength": 25,
        "fNumberLow": 1.8,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 200,
        "pupilPosition": 11.90956221,
        "resolvableFrequencyCenter": 160,
        "resolvableFrequencyEdge": 100,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": true
    },
    "85": {
        "id": 85,
        "productFamilyId": 7,
        "partNumber": "NMV-35M1-VIS-SWIR",
        "megapixels": 12,
        "focalLength": 35,
        "fNumberLow": 1.8,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 200,
        "pupilPosition": -14.0625,
        "resolvableFrequencyCenter": 160,
        "resolvableFrequencyEdge": 100,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": true
    },
    "86": {
        "id": 86,
        "productFamilyId": 7,
        "partNumber": "NMV-50M1-VIS-SWIR",
        "megapixels": 12,
        "focalLength": 50,
        "fNumberLow": 2.5,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 500,
        "pupilPosition": -4.558147175,
        "resolvableFrequencyCenter": 160,
        "resolvableFrequencyEdge": 100,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": true
    },
    "87": {
        "id": 87,
        "productFamilyId": 7,
        "partNumber": "CF8ZA-1S",
        "megapixels": 24,
        "focalLength": 8,
        "fNumberLow": 1.8,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 19.2,
        "minWorkingDistance": 100,
        "pupilPosition": 21.42004707,
        "resolvableFrequencyCenter": 91.2,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "88": {
        "id": 88,
        "productFamilyId": 7,
        "partNumber": "CF12ZA-1S",
        "megapixels": 24,
        "focalLength": 12,
        "fNumberLow": 1.8,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 19.2,
        "minWorkingDistance": 100,
        "pupilPosition": 22.34248111,
        "resolvableFrequencyCenter": 91.2,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "89": {
        "id": 89,
        "productFamilyId": 7,
        "partNumber": "CF16ZA-1S",
        "megapixels": 24,
        "focalLength": 16,
        "fNumberLow": 1.8,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 19.2,
        "minWorkingDistance": 100,
        "pupilPosition": 20.09812056,
        "resolvableFrequencyCenter": 91.2,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "90": {
        "id": 90,
        "productFamilyId": 7,
        "partNumber": "CF25ZA-1S",
        "megapixels": 24,
        "focalLength": 25,
        "fNumberLow": 1.8,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 21.6,
        "minWorkingDistance": 100,
        "pupilPosition": 29.00591043,
        "resolvableFrequencyCenter": 91.2,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "91": {
        "id": 91,
        "productFamilyId": 7,
        "partNumber": "CF35ZA-1S",
        "megapixels": 24,
        "focalLength": 35,
        "fNumberLow": 1.8,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 21.6,
        "minWorkingDistance": 200,
        "pupilPosition": 42.26558109,
        "resolvableFrequencyCenter": 91.2,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "92": {
        "id": 92,
        "productFamilyId": 7,
        "partNumber": "CF50ZA-1S",
        "megapixels": 24,
        "focalLength": 50,
        "fNumberLow": 2.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 21.6,
        "minWorkingDistance": 200,
        "pupilPosition": 39.65156568,
        "resolvableFrequencyCenter": 91.2,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "93": {
        "id": 93,
        "productFamilyId": 7,
        "partNumber": "CF12.5HA-1",
        "megapixels": 1.5,
        "focalLength": 12.5,
        "fNumberLow": 1.4,
        "fNumberHigh": 22,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 100,
        "pupilPosition": 21.30350195,
        "resolvableFrequencyCenter": 27.5,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "94": {
        "id": 94,
        "productFamilyId": 7,
        "partNumber": "CF16HA-1",
        "megapixels": 1.5,
        "focalLength": 16,
        "fNumberLow": 1.4,
        "fNumberHigh": 22,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 100,
        "pupilPosition": 29.70607131,
        "resolvableFrequencyCenter": 27.5,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "95": {
        "id": 95,
        "productFamilyId": 7,
        "partNumber": "CF35HA-1",
        "megapixels": 1.5,
        "focalLength": 35,
        "fNumberLow": 1.4,
        "fNumberHigh": 22,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 100,
        "pupilPosition": 90.03552158,
        "resolvableFrequencyCenter": 27.5,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "96": {
        "id": 96,
        "productFamilyId": 7,
        "partNumber": "CF25HA-1",
        "megapixels": 1.5,
        "focalLength": 25,
        "fNumberLow": 1.4,
        "fNumberHigh": 22,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 100,
        "pupilPosition": 49.0551294,
        "resolvableFrequencyCenter": 27.5,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "97": {
        "id": 97,
        "productFamilyId": 7,
        "partNumber": "CF50HA-1",
        "megapixels": 1.5,
        "focalLength": 50,
        "fNumberLow": 1.8,
        "fNumberHigh": 22,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 400,
        "pupilPosition": -10.28650184,
        "resolvableFrequencyCenter": 27.5,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "98": {
        "id": 98,
        "productFamilyId": 7,
        "partNumber": "CF75HA-1",
        "megapixels": 1.5,
        "focalLength": 75,
        "fNumberLow": 1.8,
        "fNumberHigh": 22,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 900,
        "pupilPosition": -36.44620038,
        "resolvableFrequencyCenter": 27.5,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "99": {
        "id": 99,
        "productFamilyId": 7,
        "partNumber": "HF818-12M",
        "megapixels": 12,
        "focalLength": 8,
        "fNumberLow": 1.8,
        "fNumberHigh": 22,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 100,
        "pupilPosition": 12.7552222,
        "resolvableFrequencyCenter": 119,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "100": {
        "id": 100,
        "productFamilyId": 7,
        "partNumber": "HF1218-12M",
        "megapixels": 12,
        "focalLength": 12,
        "fNumberLow": 1.8,
        "fNumberHigh": 22,
        "maxSensorDiagonal": 13.3,
        "minWorkingDistance": 100,
        "pupilPosition": 16.64418344,
        "resolvableFrequencyCenter": 119,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "101": {
        "id": 101,
        "productFamilyId": 7,
        "partNumber": "HF1618-12M",
        "megapixels": 12,
        "focalLength": 16,
        "fNumberLow": 1.8,
        "fNumberHigh": 22,
        "maxSensorDiagonal": 13.3,
        "minWorkingDistance": 100,
        "pupilPosition": 19.63129453,
        "resolvableFrequencyCenter": 119,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "102": {
        "id": 102,
        "productFamilyId": 7,
        "partNumber": "HF2518-12M",
        "megapixels": 12,
        "focalLength": 25,
        "fNumberLow": 1.8,
        "fNumberHigh": 22,
        "maxSensorDiagonal": 13.3,
        "minWorkingDistance": 100,
        "pupilPosition": 22.08988521,
        "resolvableFrequencyCenter": 119,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "103": {
        "id": 103,
        "productFamilyId": 7,
        "partNumber": "HF3520-12M",
        "megapixels": 12,
        "focalLength": 35,
        "fNumberLow": 2,
        "fNumberHigh": 22,
        "maxSensorDiagonal": 16,
        "minWorkingDistance": 200,
        "pupilPosition": 17.92934442,
        "resolvableFrequencyCenter": 119,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "104": {
        "id": 104,
        "productFamilyId": 7,
        "partNumber": "HF12.5SA-1",
        "megapixels": 5,
        "focalLength": 12.5,
        "fNumberLow": 1.4,
        "fNumberHigh": 22,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 100,
        "pupilPosition": 20.2404285,
        "resolvableFrequencyCenter": 72.5,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "105": {
        "id": 105,
        "productFamilyId": 7,
        "partNumber": "HF16SA-1",
        "megapixels": 5,
        "focalLength": 16,
        "fNumberLow": 1.4,
        "fNumberHigh": 22,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 100,
        "pupilPosition": 28.57489341,
        "resolvableFrequencyCenter": 72.5,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "106": {
        "id": 106,
        "productFamilyId": 7,
        "partNumber": "HF25SA-1",
        "megapixels": 5,
        "focalLength": 25,
        "fNumberLow": 1.4,
        "fNumberHigh": 22,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 100,
        "pupilPosition": 34.5156955,
        "resolvableFrequencyCenter": 72.5,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "107": {
        "id": 107,
        "productFamilyId": 7,
        "partNumber": "HF35SA-1",
        "megapixels": 5,
        "focalLength": 35,
        "fNumberLow": 1.4,
        "fNumberHigh": 22,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 200,
        "pupilPosition": 7.945054627,
        "resolvableFrequencyCenter": 72.5,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "108": {
        "id": 108,
        "productFamilyId": 7,
        "partNumber": "HF50SA-1",
        "megapixels": 5,
        "focalLength": 50,
        "fNumberLow": 1.8,
        "fNumberHigh": 22,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 400,
        "pupilPosition": -11.07975297,
        "resolvableFrequencyCenter": 72.5,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "109": {
        "id": 109,
        "productFamilyId": 7,
        "partNumber": "HF75SA-1",
        "megapixels": 5,
        "focalLength": 75,
        "fNumberLow": 1.8,
        "fNumberHigh": 22,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 900,
        "pupilPosition": -33.09661418,
        "resolvableFrequencyCenter": 72.5,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "110": {
        "id": 110,
        "productFamilyId": 7,
        "partNumber": "HF6XA-5M",
        "megapixels": 5,
        "focalLength": 6,
        "fNumberLow": 1.9,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 100,
        "pupilPosition": 16.78988327,
        "resolvableFrequencyCenter": 72.5,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "111": {
        "id": 111,
        "productFamilyId": 7,
        "partNumber": "HF8XA-5M",
        "megapixels": 5,
        "focalLength": 8,
        "fNumberLow": 1.6,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 100,
        "pupilPosition": 12.69940974,
        "resolvableFrequencyCenter": 72.5,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "112": {
        "id": 112,
        "productFamilyId": 7,
        "partNumber": "HF12XA-5M",
        "megapixels": 5,
        "focalLength": 12,
        "fNumberLow": 1.6,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 13.3,
        "minWorkingDistance": 100,
        "pupilPosition": 15.15418518,
        "resolvableFrequencyCenter": 72.5,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "113": {
        "id": 113,
        "productFamilyId": 7,
        "partNumber": "HF16XA-5M",
        "megapixels": 5,
        "focalLength": 16,
        "fNumberLow": 1.6,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 13.3,
        "minWorkingDistance": 100,
        "pupilPosition": 22.60042211,
        "resolvableFrequencyCenter": 72.5,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "114": {
        "id": 114,
        "productFamilyId": 7,
        "partNumber": "HF25XA-5M",
        "megapixels": 5,
        "focalLength": 25,
        "fNumberLow": 1.6,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 13.3,
        "minWorkingDistance": 100,
        "pupilPosition": 17.12954049,
        "resolvableFrequencyCenter": 72.5,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "115": {
        "id": 115,
        "productFamilyId": 7,
        "partNumber": "HF35XA-5M",
        "megapixels": 5,
        "focalLength": 35,
        "fNumberLow": 1.9,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 13.3,
        "minWorkingDistance": 200,
        "pupilPosition": 0.3983688769,
        "resolvableFrequencyCenter": 72.5,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "116": {
        "id": 116,
        "productFamilyId": 7,
        "partNumber": "HF50XA-5M",
        "megapixels": 5,
        "focalLength": 50,
        "fNumberLow": 2.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 17.6,
        "minWorkingDistance": 200,
        "pupilPosition": 15.11162259,
        "resolvableFrequencyCenter": 72.5,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "117": {
        "id": 117,
        "productFamilyId": 7,
        "partNumber": "HF6HA-1S",
        "megapixels": 1.5,
        "focalLength": 6,
        "fNumberLow": 1.2,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 100,
        "pupilPosition": -15.10268639,
        "resolvableFrequencyCenter": 40.3,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "118": {
        "id": 118,
        "productFamilyId": 7,
        "partNumber": "HF9HA-1S",
        "megapixels": 1.5,
        "focalLength": 9,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 100,
        "pupilPosition": 15.48051948,
        "resolvableFrequencyCenter": 40.3,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "119": {
        "id": 119,
        "productFamilyId": 7,
        "partNumber": "HF12.5HA-1S",
        "megapixels": 1.5,
        "focalLength": 12.5,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 100,
        "pupilPosition": 14.49856222,
        "resolvableFrequencyCenter": 40.3,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "120": {
        "id": 120,
        "productFamilyId": 7,
        "partNumber": "HF16HA-1S",
        "megapixels": 1.5,
        "focalLength": 16,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 100,
        "pupilPosition": 15.2067333,
        "resolvableFrequencyCenter": 40.3,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "121": {
        "id": 121,
        "productFamilyId": 7,
        "partNumber": "HF25HA-1S",
        "megapixels": 1.5,
        "focalLength": 25,
        "fNumberLow": 1.4,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 100,
        "pupilPosition": 51.89299479,
        "resolvableFrequencyCenter": 40.3,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "122": {
        "id": 122,
        "productFamilyId": 7,
        "partNumber": "HF35HA-1S",
        "megapixels": 1.5,
        "focalLength": 35,
        "fNumberLow": 1.6,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 250,
        "pupilPosition": -14.34675873,
        "resolvableFrequencyCenter": 40.3,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "123": {
        "id": 123,
        "productFamilyId": 7,
        "partNumber": "HF50HA-1S",
        "megapixels": 1.5,
        "focalLength": 50,
        "fNumberLow": 2.3,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 500,
        "pupilPosition": -59.09090909,
        "resolvableFrequencyCenter": 40.3,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    },
    "124": {
        "id": 124,
        "productFamilyId": 7,
        "partNumber": "HF75HA-1S",
        "megapixels": 1.5,
        "focalLength": 75,
        "fNumberLow": 2.8,
        "fNumberHigh": 16,
        "maxSensorDiagonal": 11,
        "minWorkingDistance": 1100,
        "pupilPosition": -131.6771574,
        "resolvableFrequencyCenter": 40.3,
        "resolvableFrequencyEdge": 0,
        "wavelengthVisible": true,
        "wavelengthNearInfrared": false,
        "wavelengthShortWaveInfrared": false
    }
};
