import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../../../util';
import { cssScope } from './css_scope';

type Props = {};

const c = scopedClasses(cssScope);

export const Documentation = mobxReactLite.observer(function Documentation(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    return h('div', { className: c('root') },
        h('div', { className: c('page-title') }, 'Documentation'),
        h('div', { className: c('content') },
            h('h1', {}, 'Users'),
            h('p', {}, 'Users determine who can log into the admin and edit data. Feel free to add and remove users as needed. When adding a user you will notice there’s no way to set the password for that user. Just add the user with no password, and have the user reset their password when they get to the login page.'),

            h('h1', {}, 'Camera Sensor Formats'),
            h('p', {}, 'These are used to filter the Pixelink cameras both in the Camera workflow and the Lens workflow. Each Camera Sensor is assigned to one of these formats. The "order" field lets you control the order these appear in the dropdown (the higher the number, the further down it will appear).'),

            h('h1', {}, 'Camera Sensors'),
            h('p', {}, 'These hold information that is common between multiple Pixelink cameras so it only has to be updated once and will affect all cameras that use that sensor. You will need to create a sensor before you create a camera which uses that sensor.'),

            h('h1', {}, 'Cameras'),
            h('p', {}, 'These represent Pixelink cameras and they appear in the camera selection guide of the wizard. Data which is shared between cameras that use the same sensor (like resolution) is stored in the "Camera Sensors" section so you must edit it there. In order to add mounts to a camera, you should first create the camera and save it, then when you edit the camera you will see "Camera Mounts" under "Related Data". Click that and then create or delete mounts for the camera.'),

            h('h1', {}, 'Mounts'),
            h('p', {}, 'These represent all the mounts the system knows about. Once you create a mount, you can associate it with a camera while editing the camera. You will rarely need to create new mounts in this section; only if a new mount type is invented.'),

            h('h1', {}, 'Camera Mounts'),
            h('p', {}, 'These represent the association of a camera with a mount. This section will almost never need to be used because you can more easily associate mounts with a camera in the "Camera" section.'),

            h('h1', {}, 'Common Sensors'),
            h('p', {}, 'These are the sensor formats shown in the lens guide when "common sensor size" is selected. They are automatically sorted by size.'),

            h('h1', {}, 'Product Families'),
            h('p', {}, 'These are the product families shown in the lens guide. Every lens will be within one of these families.'),

            h('h1', {}, 'Adapters'),
            h('p', {}, 'These are lens adapters and they will be paired with cores and attachments to form a full lens solution. If the sensor diagonal is greater than the "warn sensor diagonal" field the yellow triangle will be shown next to the result. If the sensor diagonal is greater than the "max sensor diagonal" field then this solution will not be shown at all.'),

            h('h1', {}, 'Standard Cores'),
            h('p', {}, 'The standard cores will be paired with adapters and standard attachments from the same product family to form a lens solution. If the "attachment required" field is set to "no", then the suggested solutions will include standard cores with no attachments, and in that case the working distance, which usually is pulled from the attachment, will instead be pulled from the "no attachment working distance" field of the standard core.'),

            h('h1', {}, 'Standard Core NA Coefficients'),
            h('p', {}, 'Since the NA of the standard cores is not linear, we do a polynomial fit to figure it out. These are the coefficients of the polynomial. The first coefficient is for the 0th power of x, the second for the 1st power, the third for the 2nd power, etc.'),

            h('h1', {}, 'Standard Attachments'),
            h('p', {}, 'The standard attachments will be paired with adapters and standard cores from the same product family to form a lens solution.'),

            h('h1', {}, 'Objective Cores'),
            h('p', {}, 'These will be paired with adapters and objective attachments of the same product family to form a lens solution. If the "adapter required" field is no, then the solutions will be allowed to have no adapter, and in that case the max sensor diagonal which normally comes from the adapter will come from the "no adapter max sensor diagonal" field of the objective core instead.'),

            h('h1', {}, 'Objective Core Stop Diam Coefficients'),
            h('p', {}, 'Since the stop semi-diameter of the objective cores is not linear, we do a polynomial fit to figure it out. These are the coefficients of the polynomial. The first coefficient is for the 0th power of x, the second for the 1st power, the third for the 2nd power, etc.'),

            h('h1', {}, 'Objective Attachments'),
            h('p', {}, 'These will be paired with adapters and objective cores from all product families to form a lens solution.'),

            h('h1', {}, 'Single Shot Cores'),
            h('p', {}, 'For single shot even though the same parts can generally be used as either the core or the objective, we differentiate between them because some focal lengths are not allowed to be used as a core.'),

            h('h1', {}, 'Single Shot Objectives'),
            h('p', {}, 'For single shot even though the same parts can generally be used as either the core or the objective, we differentiate between them because some focal lengths are not allowed to be used as a core.'),

            h('h1', {}, 'Machine Vision Lenses'),
            h('p', {}, 'Machine vision lenses are self-contained lens solutions, and are not paired with any of the other parts.'),

            h('h1', {}, 'Zoom Xtenders'),
            h('p', {}, 'Zoom Xtenders are special configurations of parts in the Zoom 6000 and 12x Zoom families. They have complex optics calculations which cannot be reduced to a simple polynomial fit, so these calculations are hard coded and not editable through the admin. These calculations are what the Fit Equation ID field references, so there is not a reason to change that field without also editing the code. But all the other fields can be freely edited via the admin.'),

            h('h1', {}, 'Zoom Xtender Magnification Coefficients'),
            h('p', {}, 'Since the magnification of the Zoom Xtenders is not linear, we do a polynomial fit to figure it out. These are the coefficients of the polynomial. The first coefficient is for the 0th power of x, the second for the 1st power, the third for the 2nd power, etc.'),

            h('h1', {}, 'Zoom Xtender NA Coefficients'),
            h('p', {}, 'Since the NA of the Zoom Xtenders is not linear, we do a polynomial fit to figure it out. These are the coefficients of the polynomial. The first coefficient is for the 0th power of x, the second for the 1st power, the third for the 2nd power, etc.'),
        ),
    );
});
