import * as mobxReactLite from 'mobx-react-lite';
import { h } from '../../util';

type Props = {
    size: number;
};

export const FooterPhoneIcon = mobxReactLite.observer(function FooterPhoneIcon(props: Props) {
    return h('svg',
        {
            width: props.size.toString(),
            height: props.size.toString(),
            viewBox: '0 0 24 24',
            xmlns: 'http://www.w3.org/2000/svg',
        },
        h('path', {
            d: 'M20.487 17.14L16.422 13.444C16.2299 13.2694 15.9774 13.1762 15.7178 13.1842C15.4583 13.1923 15.212 13.3008 15.031 13.487L12.638 15.948C12.062 15.838 10.904 15.477 9.71201 14.288C8.52001 13.095 8.15901 11.934 8.05201 11.362L10.511 8.968C10.6974 8.78713 10.8061 8.54082 10.8142 8.2812C10.8222 8.02159 10.7289 7.76904 10.554 7.577L6.85901 3.513C6.68405 3.32035 6.44089 3.2035 6.18116 3.18726C5.92144 3.17101 5.66561 3.25666 5.46801 3.426L3.29801 5.287C3.12512 5.46051 3.02193 5.69145 3.00801 5.936C2.99301 6.186 2.70701 12.108 7.29901 16.702C11.305 20.707 16.323 21 17.705 21C17.907 21 18.031 20.994 18.064 20.992C18.3085 20.9783 18.5393 20.8747 18.712 20.701L20.572 18.53C20.742 18.333 20.8283 18.0774 20.8124 17.8177C20.7965 17.558 20.6798 17.3148 20.487 17.14V17.14Z',
        }),
    );
});
