import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, noop, scopedClasses } from '../../../../util';
import { cssScope } from './css_scope';
import { DbTableTypes } from '../../../../db_types';
import { Link } from '../../../link';
import { routeToUrl } from '../../../../route_util';
import { AdminBreadcrumb } from '../../../../admin_breadcrumb_types';
import { pushBreadcrumb } from '../../../../admin_util';
import { AdminPagination } from '../../../admin_pagination/admin_pagination';
import * as controller from './controller';
import { Button } from '../../../button/button';

type Props = {
    tableName: keyof DbTableTypes;
    hiddenFilterFieldId?: string;
    hiddenFilterValue?: number;
    breadcrumbs?: AdminBreadcrumb[];
};

const c = scopedClasses(cssScope);

export const BottomBar = mobxReactLite.observer(function BottomBar(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    if (!(controller.state.staticLoaded && controller.state.dynamicLoaded)) {
        return null;
    }

    const tableSettings = controller.getTableSettings();
    const rows = controller.getSortedRows();

    const detailBreadcrumbs = pushBreadcrumb(props.breadcrumbs, {
        name: tableSettings.namePlural,
        route: {
            id: '/admin/listing',
            params: {
                tableName: props.tableName,
                hiddenFilterFieldId: props.hiddenFilterFieldId,
                hiddenFilterValue: props.hiddenFilterValue,
            },
        },
    });

    return h('div', { className: c('bottom-bar') },
        h('div', { className: c('pagination') },
            h(AdminPagination, {
                itemsPerPage: controller.itemsPerPage,
                pageId: controller.state.pageId,
                itemCount: rows.length,
                previousLoading: controller.state.pagePreviousLoading,
                nextLoading: controller.state.pageNextLoading,
                onPreviousClick: controller.onPaginationPrevious,
                onNextClick: controller.onPaginationNext,
            }),
        ),

        h('div', { className: c('button') },
            h(Link,
                {
                    url: routeToUrl({
                        id: '/admin/detail',
                        params: {
                            tableName: props.tableName,
                            rowId: null,
                            hiddenFilterFieldId: props.hiddenFilterFieldId,
                            hiddenFilterValue: props.hiddenFilterValue,
                            breadcrumbs: detailBreadcrumbs,
                        },
                    }),
                },
                h(Button, {
                    title: 'Create',
                    style: 'blue',
                    loading: false,
                    onClick: noop,
                }),
            ),
        ),
    );
});
