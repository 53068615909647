import * as mobxReactLite from 'mobx-react-lite';
import { h } from '../../util';

type Props = {
    size: number;
};

export const SuccessIcon = mobxReactLite.observer(function SuccessIcon(props: Props) {
    return h('svg',
        {
            width: props.size.toString(),
            height: props.size.toString(),
            viewBox: '0 0 18 18',
            xmlns: 'http://www.w3.org/2000/svg',
        },
        h('path', {
            d: 'M0 9C0 4.02944 4.02944 0 9 0C13.9682 0.00578607 17.9942 4.03184 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9ZM9.0615 13.1168L14.0124 6.46798C14.1859 6.25316 14.2274 5.96036 14.1203 5.7058C14.0132 5.45125 13.7749 5.27614 13.5 5.25C13.2251 5.22386 12.958 5.35092 12.8049 5.58073L8.3175 11.601L5.2605 9.15525C4.937 8.89637 4.46488 8.94875 4.206 9.27225C3.94712 9.59575 3.9995 10.0679 4.323 10.3268L7.989 13.2578C8.14926 13.383 8.35314 13.4387 8.55482 13.4121C8.7565 13.3856 8.93906 13.2792 9.0615 13.1168Z',
        }),
    );
});
