import * as React from 'react';
import * as mobxReactLite from 'mobx-react-lite';
import { h, scopedClasses } from '../../util';
import { cssScope } from './css_scope';
import { BackIcon } from '../icons/back';

type Props = {
    backText: string;
    backOnClick: () => void;
    titleText: string;
    titleIcon: React.ReactNode;
    bottomBorder: boolean;
};

const c = scopedClasses(cssScope);

export const WizardTitleBar = mobxReactLite.observer(function WizardTitleBar(props: Props) {
    const propsRef = React.useRef(props);
    propsRef.current = props;

    return h('div', { className: c('root', 'bottom-border-' + props.bottomBorder) },
        (props.backText !== '' ?
            h('div', { className: c('back'), onClick: props.backOnClick },
                h('div', { className: c('back-icon') },
                    h(BackIcon, { size: 18 }),
                ),
                h('div', { className: c('back-text') },
                    props.backText,
                ),
            ) : null
        ),

        h('div', { className: c('icon') },
            props.titleIcon,
        ),
        h('div', { className: c('title') },
            props.titleText,
        ),
    );
});
