import * as React from 'react';
import * as mobx from 'mobx';
import * as mobxReactLite from 'mobx-react-lite';
import { api } from '../../../api';
import { routeToUrl } from '../../../route_util';
import { h, scopedClasses, addSnackbarItem, setRoute, getAdminUrl } from '../../../util';
import { Button } from '../../button/button';
import { Link } from '../../link';
import { Form } from '../../form';
import { cssScope } from './css_scope';
import { createTwoWayBinding } from '../../two_way_binding';
import { EmailInput } from '../../email_input';
import { PasswordInput } from '../../password_input/password_input';
import { FieldLabel } from '../../field_label/field_label';
import { store } from '../../../store';

const c = scopedClasses(cssScope);

export const Login = mobxReactLite.observer(function Login() {
    const state = mobxReactLite.useLocalObservable(() => {
        return {
            fieldValues: {
                email: '',
                password: '',
            },
            submitting: false,
        };
    });

    const validateForm = React.useCallback(
        () => {
            const errors = [];
            const requiredFieldErrors = [];

            if (state.fieldValues.email === '') {
                requiredFieldErrors.push('Email');
            }

            if (state.fieldValues.password === '') {
                requiredFieldErrors.push('Password');
            }

            if (requiredFieldErrors.length !== 0) {
                errors.push('The following fields are required: ' + requiredFieldErrors.join(', '));
            }

            if (state.fieldValues.email !== '' && state.fieldValues.email.indexOf('@') < 0) {
                errors.push('Email must contain an @ symbol');
            }

            return errors;
        },
        [],
    );

    const onSubmit = React.useCallback(
        () => {
            if (state.submitting) return;

            const validationErrors = validateForm();
            if (validationErrors.length !== 0) {
                mobx.runInAction(() => {
                    for (const validationError of validationErrors) {
                        addSnackbarItem(store, 'error', validationError);
                    }
                });
                return;
            }

            mobx.runInAction(() => {
                state.submitting = true;
            });

            api('/api/login', store, false, null, {
                email: state.fieldValues.email,
                password: state.fieldValues.password,
            }).then((response) => {
                if (response.status === 'success') {
                    mobx.runInAction(() => {
                        store.user = response.user;
                        setRoute(store, getAdminUrl());
                    });
                } else if (response.status === 'wrong_credentials') {
                    mobx.runInAction(() => {
                        state.submitting = false;
                        addSnackbarItem(store, 'error', 'Incorrect email or password');
                    });
                }
            });
        },
        [],
    );

    return h('div', { className: c('root') },
        h('div', { className: c('content') },
            h('div', { className: c('title') }, 'Login'),

            h(Form, { className: c('form'), onSubmit: onSubmit },
                h('div', { className: c('form-fields') },
                    h('div', { className: c('form-field') },
                        h(FieldLabel, { label: 'Email' }),
                        createTwoWayBinding({
                            observable: state.fieldValues,
                            property: 'email',
                            childType: EmailInput,
                            childProps: {},
                        }),
                    ),

                    h('div', { className: c('form-field') },
                        h(FieldLabel, { label: 'Password' }),
                        createTwoWayBinding({
                            observable: state.fieldValues,
                            property: 'password',
                            childType: PasswordInput,
                            childProps: {},
                        }),
                    ),
                ),

                h('div', { className: c('forgot-password-wrapper') },
                    h(Link, { className: c('forgot-password'), url: routeToUrl({ id: '/send-password-reset' }) },
                        'Forgot your password?',
                    ),
                ),

                h('div', { className: c('submit') },
                    h(Button, {
                        title: 'Log In',
                        style: 'blue',
                        loading: state.submitting,
                        onClick: onSubmit,
                    }),
                ),
            ),
        ),
    );
});
